import { InputText, InputTextProps } from "primereact/inputtext";
import { RefAttributes } from "react";

const SearchBox = (props: InputTextProps & RefAttributes<HTMLInputElement>) => {
  return (
    <div className="p-input-icon-right search-input">
      <InputText
        {...props}
      />
      <i className="pi pi-search" />
    </div>
  );
};

export default SearchBox;
