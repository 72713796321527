import {post, get, remove, put} from "../AxiosReqeust";
const forgetPasswordOrganisation = async (params: any) => {
    return await post("/organisation/user/forget-password", {
        email: params,
    });
};

const resetPasswordOrganisation = (
    token: string,
    password: string,
    confirmPassword: string
) => {
    return post("/organisation/user/reset-password/token", {
        token,
        password,
        confirmPassword,
    });
};

const createOrganisation = (name: string, logoPicture:File, translations: any, userEmail:string, userPassword:string, userFName:string, userLName:string, orgProfilePdf: File, order_no_prefix:string) => {
    const formData = new FormData();

    formData.append('name', name)
    formData.append('logoPicture', logoPicture, logoPicture.name)
    formData.append('translations', translations)
    formData.append('orgProfilePdf', orgProfilePdf, orgProfilePdf.name)
    formData.append('userEmail', userEmail)
    formData.append('userPassword', userPassword)
    formData.append('userFName', userFName)
    formData.append('userLName', userLName)
    formData.append('order_no_prefix', order_no_prefix)

    return post("/organisation/create", formData, {
        'Content-Type': 'multipart/form-data'
    });
};

const getOrganisationListing = async (query?: any) => {
    return await get(`/organisation/list?${query ?? ''}`);
};

const getOrganisationData = async (query: any) => {
    return await get(`/organisation/${query}`);
};

const getOrganisationDropdown = async () => {
    return await get(`/organisation/dropdown`);
};

const editOrganisationData = async (id: any, params:any) => {
    const formData = new FormData();

    Object.entries(params).forEach(([key, value]: [string, any]) => {
         if (typeof value === 'object' && Object.keys(value).includes('objectURL')) {
             formData.append(key.toString(), value, value?.name)
         }
         else {
             formData.append(key.toString(), value)
         }
    })

    return await put(`/organisation/edit/${id}`, formData, {
        'Content-Type': 'multipart/form-data'
    });
}

const deleteOrganisation = async (query:any) => {
    return await remove(`/organisation/${query}`);
}


// organisation user
const createOrganisationUser = async (body: object) => {
    return await post(`/organisation/user/create`, body);
};

const getOrganisationUserListing = async (query?: string) => {
    return await get(`/organisation/user?${query ?? ''}`);
};

const organisationUserLogin = async (params: { email: string; password: string }) => {
    return await post("/organisation/user/login", {
        email: params?.email,
        password: params?.password,
    });
};

const updateOrganisationUser = async (id: string, body: any) => {
    return await put(`/organisation/user/${id}`, body);
};

const deleteOrganisationUser = async (id: string) => {
    return await remove(`/organisation/user/${id}`);
};

const editOrganisationUser = async ( contactNo: string, profilePicture: string )=> {
    const formData = new FormData();

    formData.append('contactNo', contactNo)
    formData.append('profilePicture', profilePicture)

    return await post("/organisation/user/profile", formData, {
        'Content-Type': 'multipart/form-data'
    })
}


const updatePasswordOrganisationUser = (oldPassword: string, newPassword: string) => {
    return post("/organisation/user/profile/update-password", {
        oldPassword,
        newPassword,
    });
};

export {
    forgetPasswordOrganisation,
    resetPasswordOrganisation,
    createOrganisation,
    getOrganisationListing,
    getOrganisationData,
    getOrganisationDropdown,
    organisationUserLogin,
    editOrganisationData,
    deleteOrganisation,
    createOrganisationUser,
    getOrganisationUserListing,
    deleteOrganisationUser,
    updateOrganisationUser,
    editOrganisationUser,
    updatePasswordOrganisationUser,
};
