import React, { useEffect, useState } from 'react';
import Cropper from 'react-easy-crop';
import { Slider } from 'primereact/slider';
import getCroppedImg from './cropImageFunction';

const ImageCropper = ({ imageSrc, croppingResult, aspectRatio = 0 }: any) => {
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)

    useEffect(() => {
        const imageElement: any = document.querySelector('.no-hide');
        setTimeout(() => {
            const imageWidth = window.getComputedStyle(imageElement).width;
            if (imageWidth) {
                const element: any = document.querySelector('.no-hide');
                if (element) {
                    element.style.width = `calc(${imageWidth} + 1px)`;
                } else {
                    console.log('Element with class name not found');
                }
            }
        }, 100);
    }, []);

    const onCropComplete = (croppedArea: any, croppedAreaPixels: any) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }

    const showCroppedImage = async () => {
        try {
            const { file, url } = await getCroppedImg(
                imageSrc,
                croppedAreaPixels
            )
            croppingResult(file, url)
        } catch (e) {
            console.error(e)
        }
    }

    const onZoomChange = (zoom: any) => {
        setZoom(zoom);
    };

    return (
        <div>
            <div style={{ width: '100%' }} className=' mb-5 h-[300px] relative'>
                <Cropper
                    image={imageSrc}
                    crop={crop}
                    zoom={zoom}
                    aspect={aspectRatio}
                    onCropChange={setCrop}
                    onCropComplete={onCropComplete}
                    onZoomChange={onZoomChange}
                // cropSize={{ height: 500, width: 1920 }}
                />
                <Cropper
                    image={imageSrc}
                    crop={crop}
                    zoom={zoom}
                    aspect={aspectRatio}
                    onCropChange={setCrop}
                    onCropComplete={onCropComplete}
                    onZoomChange={onZoomChange}
                // cropSize={{ height: 500, width: 1920 }}
                />
            </div>
            <div className='flex items-center justify-center mb-8'>
                <div className='w-[50%] pr-3'>
                    <Slider
                        style={{ width: '100%' }}
                        value={zoom}
                        onChange={(e: any) => setZoom(e.value)}
                        min={1}
                        max={3}
                        step={0.1}
                    />
                </div>
                <div
                    className=' bg-aria_dark text-white px-4 py-2 cursor-pointer'
                    onClick={showCroppedImage}
                >
                    Apply
                </div>
            </div>
        </div>
    );
};

export default ImageCropper;