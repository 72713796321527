import React, { FormHTMLAttributes, ReactNode } from "react";
import { useFormik, FormikProps, FormikValues } from "formik";
import * as Yup from "yup";

import { FocusError } from 'focus-formik-error'


export interface FormWrapperProps extends FormHTMLAttributes<HTMLFormElement> {
    initialValues: object,
    validationSchema: Yup.AnyObjectSchema,
    formStyles?: object,
    className?: string,
    formInputs: (formikProps: FormikProps<FormikValues>) => ReactNode,
    submitButton?: ReactNode,
    onSubmit: (values: any, action?: any) => void,
    toggleHoneyPot?: boolean,
    noValidate?: boolean,
    enableReinitialize?: boolean,
}

const FormWrapper = ({ initialValues, validationSchema, formStyles, formInputs, submitButton, onSubmit, toggleHoneyPot, noValidate, className, enableReinitialize, ...props }: FormWrapperProps) => {
    const formik = useFormik({
        initialValues: Object.assign(toggleHoneyPot ? {
            firstNamePot: '',
            lastNamePot: '',
            countryPot: '',
            statePot: '',
            cityPot: '',
        } : {}, initialValues),
        validationSchema: validationSchema?.shape({
            firstNamePot: Yup.string().nullable().length(0, 'Bot is not Allowed'),
            lastNamePot: Yup.string().nullable().length(0, 'Bot is not Allowed'),
            countryPot: Yup.string().nullable().length(0, 'Bot is not Allowed'),
            statePot: Yup.string().nullable().length(0, 'Bot is not Allowed'),
            cityPot: Yup.string().nullable().length(0, 'Bot is not Allowed'),
        }),
        onSubmit: (values, action) => {
            onSubmit(values, action)
        },
        enableReinitialize,
    })

    return <>
        {
            formik && <form {...props} style={formStyles} className={className} onSubmit={formik?.handleSubmit} noValidate={noValidate}>
                {formInputs(formik)}
                <FocusError formik={formik} />

                {
                    toggleHoneyPot &&
                    <div style={{
                        opacity: 0,
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        height: 0,
                        width: 0,
                        zIndex: -1,
                    }}>
                        <input type='text'
                            onChange={formik?.handleChange} onBlur={formik?.handleBlur}
                            value={formik.values?.firstNamePot} name="firstNamePot" id="firstNamePot" />
                        <input type='text'
                            onChange={formik?.handleChange} onBlur={formik?.handleBlur}
                            value={formik.values?.lastNamePot} name="lastNamePot" id="lastNamePot" />
                        <input type='text'
                            onChange={formik?.handleChange} onBlur={formik?.handleBlur}
                            value={formik.values?.countryPot} name="countryPot" id="countryPot" />
                        <input type='text'
                            onChange={formik?.handleChange} onBlur={formik?.handleBlur}
                            value={formik.values?.statePot} name="statePot" id="statePot" />
                        <input type='text'
                            onChange={formik?.handleChange} onBlur={formik?.handleBlur}
                            value={formik.values?.cityPot} name="cityPot" id="cityPot" />
                    </div>
                }

                {submitButton || <button type='submit' className="px-4 py-2 mt-4 font-bold text-white bg-blue-500 rounded hover:bg-blue-700">
                    Submit
                </button>}
            </form>
        }
    </>
}

export default FormWrapper;