import React from "react";
import {useLocation, Navigate, Outlet} from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import {Helmet} from "react-helmet-async";

const UnauthenticatedOnly = () => {
    const location = useLocation()
    const { loggedIn } = useAuth()


    return <>
        <Helmet>
            <title>
                ARIA - Admin Portal
            </title>
        </Helmet>
        {loggedIn ? <Navigate to='/' state={{from: location}} replace /> :
            <Outlet/>}
    </>
}

export default UnauthenticatedOnly