import DashboardIcon from '../assets/images/dashboard-icon.svg';
import ArtisteIcon from '../assets/images/artiste_icon.svg';
import OrgIcon from '../assets/images/org_icon.svg';
import OrgUserIcon from '../assets/images/orgusr_icon.svg'
import UserIcon from '../assets/images/user_icon.svg';
import EventsIcon from '../assets/images/events_icon.svg';
import SettingsIcon from '../assets/images/settings_icon.svg';
import CMSHomePageIcon from '../assets/images/cms_homepage_icon.svg';

export const navigationMenuContent = [
    {
        label: "Dashboard",
        iconClass: "pi pi-home",
        url: "/",
        access: ["admin", "organisation"],
        icon: DashboardIcon,
    },
    {
        label: "Artiste",
        iconClass: "pi pi-star",
        url: "/artist",
        access: ["admin", "organisation"],
        icon: ArtisteIcon,
    },
    {
        label: "Organisations",
        iconClass: "pi pi-building",
        url: "/organisations",
        access: ["admin"],
        icon: OrgIcon,
    },
    {
        label: "Org Users",
        iconClass: "pi pi-users",
        url: "/organisations/users",
        access: ["admin", "organisation"],
        icon: OrgUserIcon,
    },
    {
        label: "Admins",
        iconClass: "pi pi-users",
        url: "/users",
        access: ["admin"],
        icon: UserIcon,
    },
    {
        label: "Customers",
        iconClass: "pi pi-users",
        url: "/customer",
        access: ["admin"],
        icon: UserIcon,
    },
    {
        label: "Events",
        iconClass: "pi pi-calendar",
        url: "/events",
        access: ["admin", "organisation"],
        icon: EventsIcon,
    },
    {
        hide: true,
        url: "/events/create",
        access: ["admin", "organisation"],
    },
    {
        hide: true,
        url: "/events/detail",
        access: ["admin", "organisation"],
    },
    {
        hide: true,
        url: "/events/detail/dashboard",
        access: ["admin", "organisation"],
    },
    {
        hide: true,
        url: "/events/detail/details",
        access: ["admin", "organisation"],
    },
    {
        hide: true,
        url: "/events/detail/gallery",
        access: ["admin", "organisation"],
    },
    {
        hide: true,
        url: "/events/detail/tickets",
        access: ["admin", "organisation"],
    },
    {
        hide: true,
        url: "/events/detail/tickets/create-group",
        access: ["admin", "organisation"],
    },
    {
        hide: true,
        url: "/events/detail/tickets/create",
        access: ["admin", "organisation"],
    },
    {
        hide: true,
        url: "/events/detail/addons",
        access: ["admin", "organisation"],
    },
    {
        hide: true,
        url: "/events/detail/addons/create",
        access: ["admin", "organisation"],
    },
    {
        hide: true,
        url: "/events/detail/promocodes",
        access: ["admin", "organisation"],
    },
    {
        hide: true,
        url: "/events/detail/promocodes/create",
        access: ["admin", "organisation"],
    },
    {
        hide: true,
        url: "/events/detail/checkout-custom-content",
        access: ["admin", "organisation"],
    },
    {
        hide: true,
        url: "/events/detail/policy-custom-content",
        access: ["admin", "organisation"],
    },
    {
        label: "CMS - Home page",
        iconClass: "pi pi-file-edit",
        url: "/cms/home",
        access: ["admin"],
        icon: CMSHomePageIcon,
    },
    {
        label: "Transactions",
        iconClass: "pi pi-ticket",
        url: "/transactions",
        access: ["admin"],
    },
    {
        hide: true, // todo - remove this line when re-enable boxoffice
        label: "Boxoffice",
        iconClass: "pi pi-users",
        url: "/boxoffice",
        access: ["admin"],
    },
    {
        label: "Settings",
        iconClass: "pi pi-ticket",
        url: "/settings",
        access: ["admin"],
        icon: SettingsIcon,
    },
    // {
    //     label: "Finance",
    //     iconClass: "pi pi-money-bill",
    //     url: "/finance",
    //     access: ["admin", "organisation"],
    //     icon: SettingsIcon,
    // },
    {
        hide: true,
        url: "/settings/currency",
        access: ["admin"],
    },
    {
        hide: true,
        url: "/events/:id/platform-fee",
        access: ["admin"],
    },
];

export default navigationMenuContent;
