import React, { useState, useEffect, useRef } from "react";
import { Chart } from 'primereact/chart';
import { Carousel } from 'primereact/carousel';
import {useAppSelector} from "../../hooks/useStore";

const Dashboard = () => {
    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});
    const [trigger, setTrigger] = useState(false);
    const [count, setCount] = useState(0);
    const [dataMain, setDataMain] = useState([
        {
            title: 'Artist',
            icon: <svg width="19" height="16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 6.385C12 7.277 12.774 8 13.728 8s1.728-.723 1.728-1.615V5.047c0-.153-.133-.278-.297-.278h-1.431c-.954 0-1.728.724-1.728 1.616ZM18.548 2.214l-3.484-.385c-.25-.028-.452-.24-.452-.474V.376c0-.234.203-.4.452-.373l3.484.385c.25.028.452.24.452.473v.98c0 .233-.202.4-.452.373Z" fill="#fff" /><path d="M15.11.127h-.208c-.16 0-.29.121-.29.27v3.639c0 .15.13.27.29.27h.208c.16 0 .29-.12.29-.27V.398c0-.15-.13-.271-.29-.271ZM7 8.385a2.889 2.889 0 0 1-2.119-.881A2.889 2.889 0 0 1 4 5.384c0-.824.294-1.53.881-2.118A2.889 2.889 0 0 1 7 2.385c.825 0 1.531.294 2.119.881.587.588.881 1.294.881 2.119 0 .825-.294 1.531-.881 2.119A2.889 2.889 0 0 1 7 8.384Zm5.961 7.23H1.038c-.293 0-.54-.1-.739-.299-.2-.199-.299-.445-.299-.739v-.608c0-.412.12-.799.36-1.158.241-.36.564-.639.97-.838a14.189 14.189 0 0 1 2.833-1.018A12.14 12.14 0 0 1 7 10.615c.946 0 1.892.114 2.837.34.944.226 1.889.566 2.832 1.018.407.199.73.478.97.838s.361.746.361 1.158v.608c0 .294-.1.54-.299.74-.2.199-.446.299-.74.299ZM1 14.616h12v-.646c0-.221-.072-.43-.214-.625a1.725 1.725 0 0 0-.594-.494 12.689 12.689 0 0 0-2.546-.916 11.18 11.18 0 0 0-5.292 0c-.875.212-1.723.517-2.546.916-.253.135-.45.3-.594.494A1.037 1.037 0 0 0 1 13.97v.647Zm6-7.23c.55 0 1.02-.196 1.412-.588.392-.391.588-.862.588-1.412 0-.55-.196-1.021-.588-1.413A1.926 1.926 0 0 0 7 3.385c-.55 0-1.02.196-1.412.587A1.926 1.926 0 0 0 5 5.385c0 .55.196 1.02.588 1.412.391.392.862.588 1.412.588Z" fill="#fff" /></svg>,
            total: '102',
            newNumber: '123',
            description: 'Newly Registered',
        },
        {
            title: 'Event',
            icon: <svg width="16" height="18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.7 10.85a.67.67 0 0 1-.488-.213A.67.67 0 0 1 3 10.15a.67.67 0 0 1 .212-.488.67.67 0 0 1 .488-.212.67.67 0 0 1 .487.213.67.67 0 0 1 .213.487.67.67 0 0 1-.213.487.67.67 0 0 1-.487.213Zm4 0a.67.67 0 0 1-.487-.213A.67.67 0 0 1 7 10.15a.67.67 0 0 1 .213-.488.67.67 0 0 1 .487-.212.67.67 0 0 1 .487.213.67.67 0 0 1 .213.487.67.67 0 0 1-.213.487.67.67 0 0 1-.487.213Zm4 0a.67.67 0 0 1-.487-.213.67.67 0 0 1-.213-.487.67.67 0 0 1 .213-.488.67.67 0 0 1 .487-.212.67.67 0 0 1 .488.213.67.67 0 0 1 .212.487.67.67 0 0 1-.213.487.67.67 0 0 1-.487.213ZM1.5 17.7c-.433 0-.792-.142-1.075-.425C.142 16.992 0 16.633 0 16.2V3.8c0-.433.142-.792.425-1.075.283-.283.642-.425 1.075-.425h2V.4a.39.39 0 0 1 .112-.288A.39.39 0 0 1 3.9 0a.39.39 0 0 1 .288.113A.39.39 0 0 1 4.3.4v1.9h6.9V.35a.34.34 0 0 1 .35-.35.34.34 0 0 1 .35.35V2.3h2c.433 0 .792.142 1.075.425.283.283.425.642.425 1.075v12.4c0 .433-.142.792-.425 1.075-.283.283-.642.425-1.075.425H1.5Zm0-.7h12.4c.2 0 .383-.083.55-.25.167-.167.25-.35.25-.55V7.8H.7v8.4c0 .2.083.383.25.55.167.167.35.25.55.25ZM.7 7.1h14V3.8c0-.2-.083-.383-.25-.55-.167-.167-.35-.25-.55-.25H1.5c-.2 0-.383.083-.55.25-.167.167-.25.35-.25.55v3.3Z" fill="#fff" /></svg>,
            total: '102',
            newNumber: '123',
            description: 'Newly Event Registered',
        },
        {
            title: 'Ticket Sale',
            icon: <svg width="14" height="18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m8.4 9 .785-1c.124-.158.128-.32.012-.485-.116-.165-.271-.219-.466-.161l-1.285.408-.934-1.214a.398.398 0 0 0-.457-.13c-.18.057-.27.183-.27.378V8.17l-1.368.452c-.195.058-.292.184-.292.379s.097.321.292.379l1.368.452v1.398c0 .195.09.32.27.376.18.056.332.011.457-.134l.934-1.182 1.329.407c.195.058.35.01.466-.146.116-.155.112-.314-.012-.475L8.4 9Zm5.6 7.385c0 .447-.157.828-.472 1.143a1.557 1.557 0 0 1-1.143.472h-2.106a.763.763 0 0 1-.398-.108.66.66 0 0 1-.27-.305 3.3 3.3 0 0 0-1.09-1.151A2.757 2.757 0 0 0 7 16c-.562 0-1.069.145-1.521.436a3.3 3.3 0 0 0-1.09 1.15.66.66 0 0 1-.27.306.763.763 0 0 1-.398.108H1.615c-.447 0-.828-.157-1.143-.472A1.557 1.557 0 0 1 0 16.385V1.615C0 1.168.157.787.472.472A1.557 1.557 0 0 1 1.615 0h2.106c.145 0 .278.036.398.108a.66.66 0 0 1 .27.305 3.3 3.3 0 0 0 1.09 1.151C5.93 1.854 6.439 2 7 2c.562 0 1.069-.145 1.521-.436a3.3 3.3 0 0 0 1.09-1.15.66.66 0 0 1 .27-.306.763.763 0 0 1 .398-.108h2.106c.447 0 .828.157 1.143.472.315.315.472.696.472 1.143v14.77Zm-1 0V1.615a.599.599 0 0 0-.173-.442.599.599 0 0 0-.442-.173H10.45a4.1 4.1 0 0 1-1.462 1.462A3.846 3.846 0 0 1 7 3c-.717 0-1.38-.18-1.987-.538A4.1 4.1 0 0 1 3.55 1H1.615a.599.599 0 0 0-.442.173.599.599 0 0 0-.173.442v14.77c0 .18.058.326.173.442a.599.599 0 0 0 .442.173H3.55a4.099 4.099 0 0 1 1.463-1.463A3.847 3.847 0 0 1 7 15c.717 0 1.38.18 1.988.537A4.1 4.1 0 0 1 10.45 17h1.935c.18 0 .326-.058.442-.173a.599.599 0 0 0 .173-.442Z" fill="#fff" /></svg>,
            total: '102',
            newNumber: '123',
            description: 'Total Sale',
        },
        {
            title: 'Venue',
            icon: <svg width="14" height="18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m8.4 9 .785-1c.124-.158.128-.32.012-.485-.116-.165-.271-.219-.466-.161l-1.285.408-.934-1.214a.398.398 0 0 0-.457-.13c-.18.057-.27.183-.27.378V8.17l-1.368.452c-.195.058-.292.184-.292.379s.097.321.292.379l1.368.452v1.398c0 .195.09.32.27.376.18.056.332.011.457-.134l.934-1.182 1.329.407c.195.058.35.01.466-.146.116-.155.112-.314-.012-.475L8.4 9Zm5.6 7.385c0 .447-.157.828-.472 1.143a1.557 1.557 0 0 1-1.143.472h-2.106a.763.763 0 0 1-.398-.108.66.66 0 0 1-.27-.305 3.3 3.3 0 0 0-1.09-1.151A2.757 2.757 0 0 0 7 16c-.562 0-1.069.145-1.521.436a3.3 3.3 0 0 0-1.09 1.15.66.66 0 0 1-.27.306.763.763 0 0 1-.398.108H1.615c-.447 0-.828-.157-1.143-.472A1.557 1.557 0 0 1 0 16.385V1.615C0 1.168.157.787.472.472A1.557 1.557 0 0 1 1.615 0h2.106c.145 0 .278.036.398.108a.66.66 0 0 1 .27.305 3.3 3.3 0 0 0 1.09 1.151C5.93 1.854 6.439 2 7 2c.562 0 1.069-.145 1.521-.436a3.3 3.3 0 0 0 1.09-1.15.66.66 0 0 1 .27-.306.763.763 0 0 1 .398-.108h2.106c.447 0 .828.157 1.143.472.315.315.472.696.472 1.143v14.77Zm-1 0V1.615a.599.599 0 0 0-.173-.442.599.599 0 0 0-.442-.173H10.45a4.1 4.1 0 0 1-1.462 1.462A3.846 3.846 0 0 1 7 3c-.717 0-1.38-.18-1.987-.538A4.1 4.1 0 0 1 3.55 1H1.615a.599.599 0 0 0-.442.173.599.599 0 0 0-.173.442v14.77c0 .18.058.326.173.442a.599.599 0 0 0 .442.173H3.55a4.099 4.099 0 0 1 1.463-1.463A3.847 3.847 0 0 1 7 15c.717 0 1.38.18 1.988.537A4.1 4.1 0 0 1 10.45 17h1.935c.18 0 .326-.058.442-.173a.599.599 0 0 0 .173-.442Z" fill="#fff" /></svg>,
            total: '102',
            newNumber: '123',
            description: 'Venue Registered',
        },
        {
            title: 'Venue',
            icon: <svg width="14" height="18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m8.4 9 .785-1c.124-.158.128-.32.012-.485-.116-.165-.271-.219-.466-.161l-1.285.408-.934-1.214a.398.398 0 0 0-.457-.13c-.18.057-.27.183-.27.378V8.17l-1.368.452c-.195.058-.292.184-.292.379s.097.321.292.379l1.368.452v1.398c0 .195.09.32.27.376.18.056.332.011.457-.134l.934-1.182 1.329.407c.195.058.35.01.466-.146.116-.155.112-.314-.012-.475L8.4 9Zm5.6 7.385c0 .447-.157.828-.472 1.143a1.557 1.557 0 0 1-1.143.472h-2.106a.763.763 0 0 1-.398-.108.66.66 0 0 1-.27-.305 3.3 3.3 0 0 0-1.09-1.151A2.757 2.757 0 0 0 7 16c-.562 0-1.069.145-1.521.436a3.3 3.3 0 0 0-1.09 1.15.66.66 0 0 1-.27.306.763.763 0 0 1-.398.108H1.615c-.447 0-.828-.157-1.143-.472A1.557 1.557 0 0 1 0 16.385V1.615C0 1.168.157.787.472.472A1.557 1.557 0 0 1 1.615 0h2.106c.145 0 .278.036.398.108a.66.66 0 0 1 .27.305 3.3 3.3 0 0 0 1.09 1.151C5.93 1.854 6.439 2 7 2c.562 0 1.069-.145 1.521-.436a3.3 3.3 0 0 0 1.09-1.15.66.66 0 0 1 .27-.306.763.763 0 0 1 .398-.108h2.106c.447 0 .828.157 1.143.472.315.315.472.696.472 1.143v14.77Zm-1 0V1.615a.599.599 0 0 0-.173-.442.599.599 0 0 0-.442-.173H10.45a4.1 4.1 0 0 1-1.462 1.462A3.846 3.846 0 0 1 7 3c-.717 0-1.38-.18-1.987-.538A4.1 4.1 0 0 1 3.55 1H1.615a.599.599 0 0 0-.442.173.599.599 0 0 0-.173.442v14.77c0 .18.058.326.173.442a.599.599 0 0 0 .442.173H3.55a4.099 4.099 0 0 1 1.463-1.463A3.847 3.847 0 0 1 7 15c.717 0 1.38.18 1.988.537A4.1 4.1 0 0 1 10.45 17h1.935c.18 0 .326-.058.442-.173a.599.599 0 0 0 .173-.442Z" fill="#fff" /></svg>,
            total: '102',
            newNumber: '123',
            description: 'Venue Registered',
        }
    ])

    useEffect(() => {
        const data = {
            labels: ['Q1', 'Q2', 'Q3', 'Q4'],
            datasets: [
                {
                    label: 'Sales',
                    data: [540, 325, 702, 620],
                    backgroundColor: [
                        '#D04EFF',
                        '#D04EFF',
                        '#D04EFF',
                        '#D04EFF'
                    ],
                    borderColor: [
                        '#28D7FE',
                        '#28D7FE',
                        '#28D7FE',
                        '#28D7FE'
                    ],
                    borderWidth: 1
                }
            ]
        };
        const options = {
            scales: {
                y: {
                    beginAtZero: true
                }
            }
        };
        setChartData(data);
        setChartOptions(options);
        setTrigger(true);
    }, []);


    const { navExpand } = useAppSelector((state) => state.common);
    useEffect(() => {
        if (trigger) {
            setTimeout(()=>{
                setCount(prevState => (prevState + 1));
            }, 301)
        }
    }, [navExpand]);

    const CardComponent = (dataMain: any) => {
        const { title, icon, total, newNumber, description } = dataMain;
        return (<div className="w-full px-2">
            <div className="surface-card shadow-2 p-4 rounded-2xl min-h-[138px] bg-aria_dark_purple shadow-md">
                <div className="flex justify-between mb-3 items-start">
                    <div>
                        <span className="block text-sm font-bold mb-1 capitalize text-main">{title}</span>
                        <div className="text-white text-2xl font-semibold">{total}</div>
                    </div>
                    <div className="flex items-center justify-center bg-aria_dark rounded-full" style={{ width: '2.5rem', height: '2.5rem' }}>
                        {icon}
                    </div>
                </div>
                <div className="mt-2">
                    <span className="text-main font-bold">{newNumber}</span>
                    <span className="font-normal text-[#919191] ml-1">{description}</span>
                </div>
            </div>
        </div>)
    }

    return (
        // <div>
        //     <div className="flex w-full items-start justify-between flex-wrap mx-[-0.5rem] mb-10 dashboard-content">
        //         <Carousel showIndicators={false} value={dataMain} numVisible={4} numScroll={1} itemTemplate={CardComponent} />
        //     </div>
        //     <div className="flex justify-between mx-[-0.5rem]" key={count}>
        //         <div className="px-2 w-full">
        //             <div className="card surface-card shadow-2 p-4 border-round bg-white rounded-xl shadow-md mb-5">
        //                 <label className="block text-500 font-medium mb-3 uppercase text-main" >Ticket Sale</label>
        //                 <Chart type="bar" data={chartData} options={chartOptions} />
        //             </div>
        //         </div>
        //         <div className="px-2 w-full">
        //             <div className="card surface-card shadow-2 p-4 border-round bg-white rounded-xl shadow-md">
        //                 <label className="block text-500 font-medium mb-3 uppercase text-main" >Ticket Sale</label>
        //                 <Chart type="line" data={chartData} options={chartOptions} />
        //             </div>
        //         </div>
        //     </div>
        // </div>
        <div className='top-[50%] left-[50%] absolute translate-y-[-50%] translate-x-[-50%] text-center'>
            <h2 className='font-bold text-aria_dark_purple text-[64px]'>Coming Soon</h2>
        </div>
    );
};

export default Dashboard;
