export default function ImageTemplate(image: string) {
  if (!image) return null;

  return (
    <div className="w-[50px] h-[50px] overflow-hidden mx-auto">
      <img
        src={image}
        alt={""}
        className="w-full h-full w-6rem rounded-full overflow-hidden object-cover"
      />
    </div>
  );
}
