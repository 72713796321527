export default function GripIcon(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={11}
      height={21}
      viewBox="0 0 11 21"
      fill="none"
    >
      <circle cx="1.5" cy="1.5" r="1.5" fill="#6650F2" />
      <circle cx="9.5" cy="1.5" r="1.5" fill="#6650F2" />
      <circle cx="1.5" cy="10.5" r="1.5" fill="#6650F2" />
      <circle cx="9.5" cy="10.5" r="1.5" fill="#6650F2" />
      <circle cx="1.5" cy="19.5" r="1.5" fill="#6650F2" />
      <circle cx="9.5" cy="19.5" r="1.5" fill="#6650F2" />
    </svg>
  );
}
