import React, {useEffect, useState} from 'react'
import {Button} from "primereact/button";
import TableWrapper, {OnSortProps} from "../../../components/listing/TableWrapper";
import {Column} from "primereact/column";
import {get, remove} from "../../../utils/AxiosReqeust";
import {Dialog} from "primereact/dialog";
import EventCategoriesForm from "../components/EventCategoriesForm";
import deleteConfirmation from "../../../utils/deleteConfirmation";

const EventCategories = () => {

    const [categoriesList, setCategoriesList] = useState<any>([])
    const [page, setPage] = useState(1);
    const [records, setRecords] = useState(10);
    const [updateListing, setUpdateListing] = useState(0);

    const [visible, setVisible] = useState(false)
    const [formHeader, setFormHeader] = useState('Add Event Categories');
    const [currentData, setCurrentData] = useState<any>(null);

    const setPageLimit = (limit: number) => {
        setRecords(limit);
        setPage(1);
    }

    useEffect(() => {
        getEventCategoriesList();
    }, [page, records, updateListing]);

    const getEventCategoriesList = async () => {
        const response = await get(`/settings/event-categories/listing?&page=${page}&records=${records}`);
        if (response?.data?.success) {
            setCategoriesList(response.data.data);
        }
    }

    const deleteEventCategories = async (id:any) => {
        const response = await remove(`/settings/event-categories/${id}`);
        if (response?.data?.success) {
            setUpdateListing((prev)=> prev + 1)
        }
    }

    const listColumnBody = (res: any) => {
        return <div>{res?.translations[0]?.name}</div>
    }

    const actionColumnBody = (res: any) => {
        return <div className="flex gap-2">
            <Button
                className="mr-2 !rounded-full w-8 h-[32px] p-0 flex items-center justify-center"
                onClick={() => {
                    setCurrentData(res); setVisible(true); setFormHeader('Edit Event Category')
                }}
                size="small"
            >
                <i className="pi pi-pencil" />
            </Button>
            <Button onClick={() => deleteConfirmation(async () => await deleteEventCategories(res?._id))}
            size="small" className="mr-2 !rounded-full w-8 h-[32px] p-0 flex items-center justify-center">
                <i className="pi pi-trash" />
            </Button>
        </div>
    }

    return (
        <div>

            <Dialog header={formHeader} visible={visible} onHide={() => setVisible(false)}
                    style={{ width: '50vw' }} breakpoints={{ '960px': '75vw', '641px': '100vw' }}
                    contentClassName="!pb-4"
            >
                <EventCategoriesForm
                    setUpdateListing={setUpdateListing}
                    currentData={currentData}
                    setVisible={setVisible}
                />
            </Dialog>

            <h2 className="text-3xl font-semibold text-aria_dark mb-[15px]">Event Categories</h2>
            <div className="flex justify-between align-middle mb-5">
                <Button
                    label="Add Event Categories"
                    icon="pi pi-plus"
                    size="large"
                    className="!text-[14px] !px-12"
                    onClick={() => {
                        setCurrentData(null)
                        setFormHeader('Create Event Category')
                        setVisible(true)
                    }}
                />
            </div>
            <div className="mt-4">
                <TableWrapper
                    data={categoriesList}
                    loading={false}
                    records={records}
                    setRecords={setPageLimit}
                    nextPage={() => {
                        if (!categoriesList.hasNextPage) return
                        setPage(current => (current + 1))
                    }}
                    prevPage={() => {
                        if (!categoriesList.hasPrevPage) return
                        setPage(current => (current - 1))
                    }}
                >
                <Column body={listColumnBody} header="Event Categories"></Column>
                <Column header="Actions" style={{width: '10%'}} body={actionColumnBody}/>
                </TableWrapper>
            </div>
        </div>
    )
}
export default EventCategories;