import React, { useEffect, useState } from "react";
import { nanoid } from "@reduxjs/toolkit";
import ImageUploadPreview from "../../../components/ImageUploadPreview";
import { useAppSelector } from "../../../hooks/useStore";
import * as Yup from 'yup';
import { FormikProps, FormikValues } from "formik";
import { Button } from "primereact/button";
import FormWrapper from "../../../components/form/FormWrapper";
import { eventInnerDetailsUpdate } from "../../../utils/apiRequestList/eventAPI";
import { MultiSelect } from "primereact/multiselect";
import deleteConfirmation from "../../../utils/deleteConfirmation";
import { toast } from "react-toastify";
import { setLoading } from "../../../redux/slices/commonSlice";
import { useAppDispatch } from "../../../hooks/useStore";
import LanguageTab from "../../../components/LanguageTab";
import TextEditor from "../../../components/TextEditor";
import Repeater from "../../CMS/Home/Repeater/Index";
import { isEqualWith } from "lodash-es";

const DetailsForm = (props: any) => {
    const { locales, loading }: any = useAppSelector((state) => state.common);
    const [currentLang, setCurrentLang] = useState(locales?.default_language ?? 'en');
    const [innerData, setInnerData] = useState(props.data)
    const [isEditCIDesktop, setIsEditCIDesktop] = useState(false);
    const [isEditCIMobile, setIsEditCIMobile] = useState(false);
    const dispatch = useAppDispatch();
    const [initialValues, setInitialValues] = useState<any>(null);

    const InnerDetailSchema = Yup.object({
        eventLineUp: Yup.array(),
        eventBackgroundImages: Yup.array().of(
            Yup.object().shape({
                landscape_media_url: Yup.mixed().required('Landscape background Image is required'),
                portrait_media_url: Yup.mixed().required('Potrait background Image is required'),
                sort_order: Yup.number(),
                _id: Yup.string()
            })
        ),
        translations: Yup.array().of(
            Yup.object().shape({
                title: Yup.string(),
                description: Yup.string()
            })
        ),
    });

    useEffect(() => {
        setInnerData(props.data)
        if (props.data) {
            setInitialValues({
                eventLineUp: props.data?.eventLineUp ?? [],
                cover_image_desktop: props.data?.cover_image_desktop ?? '',
                cover_image_mobile: props.data?.cover_image_mobile ?? '',
                eventBackgroundImages: props.data.eventBackgroundImages.length > 0 ? props.data?.eventBackgroundImages.map((item: any) => ({
                    landscape_media_url: item.landscape_media_url,
                    portrait_media_url: item.portrait_media_url,
                    sort_order: item.sort_order,
                    _id: item._id,
                    sortId: item._id
                })) : [],
                translations: locales?.available_languages?.map((item: any) => {
                    const translation = Array.isArray(props?.data.translations) ? props?.data.translations.filter((tr: any) => tr.language === item) : [];
                    if (translation.length > 0) {
                        return translation[0]
                    }
                    else {
                        return {
                            language: item,
                            title: "",
                            description: ""
                        }
                    }
                }),
            })
        }
    }, [props.data])

    let prevData = initialValues;

    function customizer(objValue: any, othValue: any, key: any) {
        if (key === "_id") {
            return true;
        }
    }

    const handleSubmit = async (value: any) => {
        if (isEqualWith(value, prevData, customizer)) return;

        dispatch(setLoading(true));
        const response = await eventInnerDetailsUpdate(props.id, value);
        if (response.data.success) {
            toast.success('Success Update Event');
            setIsEditCIDesktop(false)
            setIsEditCIMobile(false)
            prevData = value;
            props.fetchData()
        } else {
            toast.error('Failed Update Event');
            setIsEditCIDesktop(true)
            setIsEditCIMobile(true)
        }
        dispatch(setLoading(false));
    }

    const onChangeImage = (type: string) => {
        if (type === 'desktop') {
            setIsEditCIDesktop(true);
        } else if (type === 'mobile') {
            setIsEditCIMobile(true);
        }
    }

    const formInputs = ({ errors, values, touched, handleBlur, handleChange, setFieldValue }: FormikProps<FormikValues>) => {
        return (
            <>
                <div>
                    <h2 className="mb-4 text-3xl font-semibold text-aria_dark">Details</h2>
                    <div className="mb-3 text-base font-bold">Event Line up</div>
                    <div className="mb-5 w-[400px]">
                        <MultiSelect
                            id="eventLineUp"
                            name="eventLineUp"
                            options={innerData?.artistsList}
                            value={values.eventLineUp}
                            onChange={(e) => {
                                setFieldValue('eventLineUp', e.value);
                            }}
                            itemTemplate={({ label, profilePicture_thumb }) => {
                                return (
                                    <div className="flex items-center gap-2">
                                        <img
                                            src={profilePicture_thumb}
                                            alt=""
                                            className="object-cover w-6 h-6 border rounded-full" />
                                        {label}
                                    </div>
                                );
                            }}
                            className="w-full md:w-20rem"
                            display="chip"
                            filter
                        />
                    </div>
                </div>
                <div className="mb-8 ">
                    <div className="text-base font-bold">Cover Image</div>
                    {/* <div className="text-xs font-normal w-full border-b border-[black] pb-3">Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem IpsumLorem IpsumLorem IpsumLorem IpsumLorem Ipsum</div> */}
                    <div className="flex items-center justify-between pt-3">
                        <div className="w-full">
                            <div className="mb-2 text-sm font-bold">Mobile Cover Image (800 x 800px)</div>
                            {!isEditCIMobile && innerData?.cover_image_mobile ? <div className={"relative"}>
                                <div className="absolute cursor-pointer right-4 top-4" onClick={() => onChangeImage('mobile')}>
                                    <svg width="32" height="33" fill="none" xmlns="http://www.w3.org/2000/svg"><ellipse cx="16" cy="16.893" rx="16" ry="15.939" fill="#6650F2" /><path d="m10.283 19.277.582.146-.582-.146-.662 2.638-.007.03a3.51 3.51 0 0 0-.098.476c-.016.165-.015.464.226.706.241.241.54.243.706.227a3.514 3.514 0 0 0 .506-.104l2.654-.66.049-.013c.218-.054.428-.106.62-.214l-.295-.522.295.522c.19-.108.344-.26.503-.42l.035-.035 7.186-7.158.027-.027c.312-.31.588-.585.78-.837.209-.272.375-.586.375-.978s-.166-.706-.375-.979c-.192-.25-.468-.525-.78-.836l-.027-.027-.166-.166-.027-.026c-.31-.309-.584-.582-.834-.772-.271-.206-.584-.371-.974-.371s-.703.165-.974.37c-.25.191-.524.464-.834.773l-.027.026-7.195 7.168-.036.035c-.16.16-.314.313-.423.504-.109.192-.161.403-.216.622l-.012.048Z" fill="#6650F2" stroke="#fff" strokeWidth="1.2" /><path d="m17.5 11.414 4 3.984" stroke="#fff" strokeWidth="1.2" /></svg>
                                </div>
                                <img className="w-full h-[260px] object-contain rounded-xl border border-aria_dark" src={values?.cover_image_mobile?.objectURL ? values?.cover_image_mobile?.objectURL : values.cover_image_mobile} alt="" />
                            </div>
                                :
                                <div>
                                    <ImageUploadPreview
                                        crop
                                        aspectRatio={1}
                                        cropWidth={800}
                                        cropHeight={800}
                                        customHeight={'h260px'}
                                        id={'mobile'}
                                        label={' Upload Cover Image'}
                                        value={values.cover_image_mobile} setUploadImage={(file: any) => setFieldValue('cover_image_mobile', file)} />
                                </div>
                            }
                        </div>
                        <div className="w-[30px]"></div>
                        <div className="w-full">
                            <div className="mb-2 text-sm font-bold">Desktop Cover Image (800 x 450px)'</div>
                            {!isEditCIDesktop && innerData?.cover_image_desktop ? <div className={"relative"}>
                                <div className="absolute cursor-pointer right-4 top-4" onClick={() => onChangeImage('desktop')}>
                                    <svg width="32" height="33" fill="none" xmlns="http://www.w3.org/2000/svg"><ellipse cx="16" cy="16.893" rx="16" ry="15.939" fill="#6650F2" /><path d="m10.283 19.277.582.146-.582-.146-.662 2.638-.007.03a3.51 3.51 0 0 0-.098.476c-.016.165-.015.464.226.706.241.241.54.243.706.227a3.514 3.514 0 0 0 .506-.104l2.654-.66.049-.013c.218-.054.428-.106.62-.214l-.295-.522.295.522c.19-.108.344-.26.503-.42l.035-.035 7.186-7.158.027-.027c.312-.31.588-.585.78-.837.209-.272.375-.586.375-.978s-.166-.706-.375-.979c-.192-.25-.468-.525-.78-.836l-.027-.027-.166-.166-.027-.026c-.31-.309-.584-.582-.834-.772-.271-.206-.584-.371-.974-.371s-.703.165-.974.37c-.25.191-.524.464-.834.773l-.027.026-7.195 7.168-.036.035c-.16.16-.314.313-.423.504-.109.192-.161.403-.216.622l-.012.048Z" fill="#6650F2" stroke="#fff" strokeWidth="1.2" /><path d="m17.5 11.414 4 3.984" stroke="#fff" strokeWidth="1.2" /></svg>
                                </div>
                                <img className="w-full h-[260px] object-contain rounded-xl border border-aria_dark" src={values?.cover_image_desktop?.objectURL ? values?.cover_image_desktop?.objectURL : values.cover_image_desktop} alt="" />
                            </div>
                                :
                                <ImageUploadPreview
                                    crop
                                    aspectRatio={16 / 9}
                                    cropWidth={800}
                                    cropHeight={450}
                                    customHeight={'h260px'}
                                    id={'desktop'}
                                    label={' Upload Cover Image'}
                                    value={values.cover_image_desktop}
                                    setUploadImage={(file: any) => setFieldValue('cover_image_desktop', file)} />
                            }
                        </div>
                    </div>
                </div>

                <div className=" mb-7">
                    <LanguageTab onChange={setCurrentLang} activeLanguage={currentLang} />
                    <div>
                        {innerData?.translations && innerData?.translations.length > 0 ?
                            innerData?.translations.map((user: any, index: number) => (
                                <div key={user.language} className={user.language === currentLang ? 'block' : 'hidden'}>
                                    <label htmlFor={`translations.${index}.description`} className="">Description</label>
                                    <TextEditor
                                        id={`translations.${index}.description`}
                                        name={`translations.${index}.description`}
                                        value={values.translations && values.translations[index] && values.translations[index].description}
                                        onTextChange={(e: any) => {
                                            setFieldValue(`translations.${index}.description`, e.htmlValue);
                                        }} />
                                </div>
                            ))
                            : null}
                    </div>
                </div>

                <div className="mb-5">
                    <div className=" text-lg text-aria_dark font-semibold border-b border-[black] mb-3 pb-1">Background Image</div>
                    {values.eventBackgroundImages.length > 0 && <Repeater
                        data={values.eventBackgroundImages}
                        titleKey={(eventBackgroundImages) => {
                            return <>
                                {eventBackgroundImages.landscape_media_url && !(Object.prototype.toString.call(eventBackgroundImages.landscape_media_url) === '[object File]') ?
                                    <span>
                                        <img className="w-[125px] h-[75px] object-contain rounded-md" src={eventBackgroundImages.landscape_media_url} alt='' />
                                    </span>
                                    : <span>New Item</span>}
                            </>
                        }}
                        groupName="Event Background Image"
                        onOrderChange={(updated) => {
                            const mappedArray = updated.map((item: any) => ({
                                ...item,
                                sort_order: item.order
                            }));
                            setFieldValue("eventBackgroundImages", mappedArray);
                        }}
                        template={{
                            render: (item: any, index: number) => {
                                const joinKey = (key: string) => `eventBackgroundImages.${index}.${key}`;
                                return <>
                                    <div className="mb-5 ">
                                        <ImageUploadPreview
                                            crop
                                            aspectRatio={3.84/1}
                                            cropWidth={1920}
                                            cropHeight={500}
                                            value={values.eventBackgroundImages[index].landscape_media_url}
                                            id={`landscape-${item._id}`}
                                            label={'Desktop Background Image (1920 x 500px)'}
                                            setUploadImage={(file: any) => {
                                                setFieldValue(joinKey("landscape_media_url"), file);
                                            }} />
                                        {Array.isArray(errors?.eventBackgroundImages) && Object.values(errors?.eventBackgroundImages)[index]?.landscape_media_url && Array.isArray(touched?.eventBackgroundImages) && touched?.eventBackgroundImages[index]?.landscape_media_url ? (
                                            <small className="block mt-2 p-error">{Object.values(errors?.eventBackgroundImages)[index]?.landscape_media_url}</small>
                                        ) : null}
                                    </div>
                                    <div className="mb-5 ">
                                        <ImageUploadPreview
                                            crop
                                            aspectRatio={1.4545 / 1}
                                            cropWidth={640}
                                            cropHeight={440}
                                            value={values.eventBackgroundImages[index].portrait_media_url}
                                            id={`potrait-${item._id}`}
                                            label={'Mobile Background Image (640 x 440px)'}
                                            setUploadImage={(file: any) => {
                                                setFieldValue(joinKey("portrait_media_url"), file);
                                            }} />
                                        {Array.isArray(errors?.eventBackgroundImages) && Object.values(errors?.eventBackgroundImages)[index]?.portrait_media_url && Array.isArray(touched?.eventBackgroundImages) && touched?.eventBackgroundImages[index]?.portrait_media_url ? (
                                            <small className="block mt-2 p-error">{Object.values(errors?.eventBackgroundImages)[index]?.portrait_media_url}</small>
                                        ) : null}
                                    </div>

                                    <div className="flex items-center px-5 py-3 border rounded-lg cursor-pointer border-aria_dark w-fit" onClick={() => {
                                        deleteConfirmation(async () => {
                                            const updatedItems = values.eventBackgroundImages.filter((item: any, i: number) => i !== index);
                                            setFieldValue(
                                                "eventBackgroundImages", [
                                                ...updatedItems
                                            ]
                                            );
                                        });
                                    }}>
                                        <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg"><g stroke="#211F2E" strokeLinecap="round"><path d="M5.833 10.916v-2.5M9.166 10.916v-2.5M0 4.25h15v0c-.465 0-.697 0-.89.038a2 2 0 0 0-1.572 1.572c-.038.193-.038.425-.038.89v4.333c0 1.886 0 2.829-.586 3.415-.586.585-1.528.585-3.414.585h-2c-1.886 0-2.828 0-3.414-.585-.586-.586-.586-1.529-.586-3.415V6.75c0-.465 0-.697-.038-.89A2 2 0 0 0 .89 4.288C.697 4.25.465 4.25 0 4.25v0ZM5.89 1.225c.095-.088.304-.167.595-.222A5.566 5.566 0 0 1 7.5.917c.367 0 .723.03 1.014.086.292.055.5.134.596.222" /></g></svg>
                                        <span className="ml-2">Delete Item</span>
                                    </div>
                                </>
                            }
                        }}
                    />
                    }
                    {values.eventBackgroundImages.length < 1 && <div className="p-4 text-xs text-center border text-slate-600">
                        No item
                    </div>
                    }
                    {
                        values.eventBackgroundImages.length < 5 && <div className="flex justify-end mt-2">
                            <Button
                                size="small"
                                type="button"
                                icon="pi pi-plus"
                                outlined
                                label="Add item"
                                severity="secondary"
                                className="mt-4"
                                onClick={() => {
                                    if (values.eventBackgroundImages.length < 5) {
                                        const newItem = { landscape_media_url: '', portrait_media_url: '', sort_order: values.eventBackgroundImages.length + 1, sortId: nanoid() };
                                        const updatedItems: any = [...values.eventBackgroundImages, newItem];
                                        setFieldValue("eventBackgroundImages", [
                                            ...updatedItems
                                        ]);
                                    }
                                }} />
                        </div>
                    }
                </div>
            </>
        );
    }

    return (
        <>
            {initialValues && <FormWrapper
                noValidate
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={InnerDetailSchema}
                formInputs={formInputs}
                submitButton={
                    <Button className="justify-center w-full" size="large" type="submit" loading={loading}>
                        Submit
                    </Button>
                }
                className="gap-2" />
            }
        </>
    )
}

export default DetailsForm;