import { combineReducers } from "@reduxjs/toolkit";
import commonSlice from "./slices/commonSlice";
import authSlice from "./slices/authSlice";
import eventSlice from "./slices/eventSlice";

const rootReducer = combineReducers({
    userAuth: authSlice,
    common: commonSlice,
    event: eventSlice,
});

export default rootReducer;
