import React, { useState, useEffect } from "react";

import { Button } from "primereact/button";
import { Dialog } from 'primereact/dialog';
import { useDebounce } from "use-debounce";

import OrganisationForm from "./formOrganisation";
import TableWrapper, { OnSortProps } from "../../components/listing/TableWrapper";
import { getOrganisationListing, getOrganisationData, deleteOrganisation } from "../../utils/apiRequestList/organisationAPI";
import { Column } from "primereact/column";
import { useAppDispatch } from "../../hooks/useStore";
import { setLoading } from "../../redux/slices/commonSlice";
import TagStatus from "../../components/TagStatus";
import { useLoaderData } from "react-router-dom";
import deleteConfirmation from "../../utils/deleteConfirmation";
import SearchBox from "../../components/SearchBox";
import pdfIcon from "../../assets/images/pdf-icon.png";

const Organisation = () => {
    const [visible, setVisible] = useState(false);
    const [formHeader, setFormHeader] = useState('Create Organisation');
    const [page, setPage] = useState(1);
    const [records, setRecords] = useState(10);
    const [data, setData]: any = useState(null);

    const [updateListing, setUpdateListing] = useState(false);
    const [currentData, setCurrentData] = useState({});

    const [sortField, setSortField] = useState<string>('');
    const [sortOrder, setSortOrder] = useState<0 | 1 | -1 | null | undefined>(null);
    const [searchByName, setSearchByName] = useState("");
    const [debouncedSearchByName] = useDebounce(searchByName, 500);
    // const loaderData = useLoaderData()

    const dispatch = useAppDispatch();

    const logoColumnBody = (e: any) => {
        return <div className="max-w-[40px] overflow-hidden mx-auto">
            <img
                src={e.logo}
                alt={""}
                className="object-contain w-full h-full w-6rem"
            />
        </div>
    }
    const descriptionColumnBody = (e: any) => {
        return <div className='overflow-hidden text-ellipsis line-clamp-4' dangerouslySetInnerHTML={{ __html: e.translations[0]?.description }} />
    }

    const businessFileColumnBody = (e: any) => {
        if (e.businessProfileUrl) {
            return <div className='flex justify-center'>
                <Button
                    className="!rounded-3xl !p-3"
                    onClick={() => {
                        window.open(e.businessProfileUrl, '_blank');
                    }}
                ><img className="max-w-[16px]" src={pdfIcon} alt="" /></Button>
            </div>
        }
    }

    const statusColumnBody = (e: any) => {
        return <TagStatus status={e.status} />
    }

    const actionColumnBody = (res: any) => {
        return <div className="flex align-middle">
            <div className="mr-2">
                <Button
                    className="!rounded-3xl !p-3"
                    onClick={() => {
                        editOrganisationData(res.organisationId)
                    }}
                ><i className='pi pi-pencil' /></Button>
            </div>
            <div>
                <Button
                    className="!rounded-3xl !p-3"
                    onClick={() => deleteOrganisationData(res.organisationId)}
                ><i className='pi pi-trash' /></Button>
            </div>
        </div>
    }

    const editOrganisationData = async (id: any) => {
        const response = await getOrganisationData(id);
        if (response.status) {
            setFormHeader("Edit Organisation")
            const xx = {
                organisationId: id,
                ...response?.data?.data,
            }
            setCurrentData(xx)
            setVisible(true)
        }
    };

    const deleteOrganisationData = async (id: any) => {
        deleteConfirmation(async () => {
            const response = await deleteOrganisation(id);
            if (response.status) {
                // showToast('success', 'Success', 'Success Delete Data', 3000);
                setUpdateListing(true)
            }
        })
    }

    const createOrganisation = () => {
        setVisible(true)
        setCurrentData('')
    }

    const getOrganisationList = async () => {
        let sortQuery = ''
        let sortName = ''

        if (sortField) {
            sortQuery += `&sortBy=${sortField}`
            if (sortOrder) {  // positive
                sortQuery += `&sortOrder=${sortOrder}`
            }
        }

        if (debouncedSearchByName) {
            sortName += `&searchName=${debouncedSearchByName}`
        }

        dispatch(setLoading(true));
        const response = await getOrganisationListing((`&page=${page}&records=${records}${sortQuery}${sortName}`));
        if (response?.data?.success) {
            setData(response?.data?.data);
        }
        dispatch(setLoading(false));
        setUpdateListing(false);
    }

    const updateData = () => {
        if (!data) {
            setData(data);
        } else {
            getOrganisationList();
        }
    }

    useEffect(() => {
        updateData()
    }, [page, records, sortField, sortOrder, debouncedSearchByName])

    useEffect(() => {
        if (updateListing) {
            updateData()
        }
    }, [updateListing])

    useEffect(() => {
        async function fetchData() {
            try {
                dispatch(setLoading(true))
                const organisation =
                    await getOrganisationListing(
                        `records=10`
                    );
                setData(organisation?.data?.data)
                dispatch(setLoading(false))
            } catch (error) {
                dispatch(setLoading(false))
                console.error('Error:', error);
            }
        }
        fetchData();
    }, [])

    const setPageLimit = (limit: number) => {
        setRecords(limit);
        setPage(1);
    }

    const onSort = ({ sortField, sortOrder }: OnSortProps) => {
        setSortField(sortField);
        setSortOrder(sortOrder);
        setPage(1);
    }

    const handleChange = (e: any) => {
        setSearchByName(e.target.value);
    }

    return (
        <>
            <Dialog header={formHeader} visible={visible} onHide={() => setVisible(false)}
                style={{ width: '50vw' }} breakpoints={{ '960px': '75vw', '641px': '100vw' }}
                contentClassName="!pb-4"
            >
                <OrganisationForm setVisible={setVisible}
                    setUpdateListing={setUpdateListing}
                    currentData={currentData}
                />
            </Dialog>
            <h2 className="mb-[15px] !pb-0 h2 text-aria_dark">Organisation</h2>
            <div className="flex justify-between align-middle">
                <Button
                    label="Create Organisation"
                    icon="pi pi-plus"
                    size="large"
                    className="!text-[14px] !px-12"
                    onClick={() => createOrganisation()}
                />
                <SearchBox
                    placeholder="Search By Name"
                    onChange={(e) => {
                        setPage(1);
                        setSearchByName(e.target.value);
                    }}
                />
            </div>
            <div className="mt-5">
                {!!data ? (
                    <TableWrapper
                        data={data}
                        loading={false}
                        setRecords={setPageLimit}
                        scrollable
                        scrollHeight="70vh"
                        nextPage={() => {
                            if (!data.hasNextPage) return
                            setPage(current => (current + 1))
                        }}
                        prevPage={() => {
                            if (!data.hasPrevPage) return
                            setPage(current => (current - 1))
                        }}
                        sortField={sortField}
                        sortOrder={sortOrder}
                        onSort={onSort}
                    >
                        <Column field="logo" header="Logo" body={logoColumnBody} style={{ width: '121px' }}></Column>
                        <Column field="translations.display_name" header="Display Name" body={
                            (field) => {
                                return <div>{field.translations[0]?.display_name}</div>
                            }
                        } ></Column>
                        <Column field="name" header="Name" sortable ></Column>
                        <Column field="desc" header="Description" body={descriptionColumnBody}></Column>
                        <Column field="businessProfileUrl" header="Business Profile" body={businessFileColumnBody} style={{ width: '151px' }}></Column>
                        <Column field="status" header="Status" align={"center"} body={statusColumnBody} style={{ width: '87px' }} sortable ></Column>
                        <Column header="Action" body={actionColumnBody} style={{ width: '124px' }}></Column>
                    </TableWrapper>
                ) : null}
            </div>
        </>
    )

}
export default Organisation;