import ReactCountryFlag from "react-country-flag";
import { useAppSelector } from "../hooks/useStore";
import { useState } from "react";
import languageInfo from "../utils/languageInfo";

export default function LanguageTab({
  activeLanguage,
  onChange,
}: {
  activeLanguage: string;
  onChange: (lang: string) => void;
}) {
  const { locales }: any = useAppSelector((state) => state.common);
  const [currentLang, setCurrentLang] = useState(
    activeLanguage ?? locales?.default_language ?? "en"
  );

  return (
    <div className="flex mb-4">
      {locales?.available_languages?.map((lang: any) => (
        <div
          key={lang}
          className={`py-[7px] min-w-[100px] flex items-center transition-all justify-center px-[10px] border-[0.5px] rounded-[5px] mr-[6px] cursor-pointer
                    ${
                      currentLang === lang
                        ? "bg-white border-white"
                        : "bg-none border-[#6650F2]"
                    }
                 `}
          style={{ fontSize: "16px" }}
          onClick={() => {
            setCurrentLang(lang);
            onChange(lang);
          }}
        >
          {currentLang === lang && (
            <ReactCountryFlag
              style={{ fontSize: "20px", marginRight: "4px" }}
              countryCode={languageInfo(lang)?.country || ""}
            />
          )}
          {languageInfo(lang)?.displayLang}
        </div>
      ))}
    </div>
  );
}
