import React from "react";
import Lottie from 'react-lottie-player'
import lottieJson from '../../assets/lottie/animation_lnu59lja.json'

//when url link is invalid
const NotFound = () => {
    return <div className='top-[25%] left-[50%] absolute translate-y-[-50%] translate-x-[-50%] text-center'>
        <Lottie
            loop
            animationData={lottieJson}
            play
            style={{ width: 350, height: 220 }}
        />
        <h2 className='font-bold text-aria_dark_purple text-[18px]'>Page not found</h2>
    </div>
}

export default NotFound;