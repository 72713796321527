import React, {useState} from "react";
import * as Yup from 'yup';
import { FormikProps, FormikValues } from "formik";
import FormWrapper from "../../../components/form/FormWrapper";
import FooterAction from "../../../components/form/FooterAction";
import {Button} from "primereact/button";
import {InputText} from "primereact/inputtext";
import {useAppDispatch, useAppSelector} from "../../../hooks/useStore";
import {isEqual} from "lodash-es";
import {setLoading} from "../../../redux/slices/commonSlice";
import {post} from "../../../utils/AxiosReqeust";
import {toast} from "react-toastify";
import LanguageTab from "../../../components/LanguageTab";

const EventCategoriesForm = (props:any) => {
    const dispatch = useAppDispatch()
    const { locales, loading }: any = useAppSelector((state) => state.common);
    const [currentLang, setCurrentLang] = useState<string>(locales?.default_language ?? 'en')

    const initialValues = props?.currentData ? {
        _id: props?.currentData?._id,
        translations: locales?.available_languages?.map((item: any) => {
            const translation = Array.isArray(props?.currentData.translations) ? props?.currentData.translations.filter((tr: any) => tr.language === item) : [];
            if (translation.length > 0) {
                return translation[0]
            }
            else {
                return {
                    language: item,
                    name: "",
                }
            }
        }),
    } : {
        translations: locales?.available_languages?.map((item: any) => {
            return {
                language: item,
                name: "",
            }
        }),
    };

    let prevData = initialValues

    const formSchema = Yup.object({
        translations: Yup.array().of(
            Yup.object().shape({
                name: Yup.string().when("language", (language, schema) => {
                    if (language[0] === 'en') {
                        return schema.required("Required");
                    }
                    return schema;
                })
            })
        ),
    })

    const handleSubmit = async (values: any) => {
        if (isEqual(values, prevData)) return
        dispatch(setLoading(true))
        try {
            const response = await post('/settings/event-categories/create', values)
            if (response.status) {
                toast.success(props.currentData ? 'Edited successful' : "Created event category");
                props.setUpdateListing((prev:any) => prev + 1)
                setTimeout(() => {
                    dispatch(setLoading(false))
                    props.setVisible(false)
                }, 1000);
            } else {
                dispatch(setLoading(false))
                toast.error(props.currentData ? 'Failed edit' : 'Failed create event category');
                setTimeout(() => {
                    props.setVisible(false)
                }, 1000);
            }
        } catch (error: any) {
            dispatch(setLoading(false))
            toast.error(error);
            console.error(error);
        }
    }

    const formInputs = ({ errors, values, touched, handleBlur, handleChange, setFieldValue }: FormikProps<FormikValues>) => (
        <>
            <LanguageTab onChange={setCurrentLang} activeLanguage={currentLang} />
            
            <div className="mb-6">
                {values.translations && values.translations.length > 0
                    ? values.translations.map((item: any, index: number) => (
                        <div className='w-full' key={`basic-info-${item.language}`} style={{ display: item.language !== currentLang ? 'none' : 'block' }}>
                            <div className="pb-[20px] mr-[9px]">
                                <label htmlFor={`translations.${index}.name`} className="">
                                    Event Category Name
                                </label>
                                <InputText
                                    id={`translations.${index}.name`}
                                    className={`w-full ${(Array.isArray(errors?.translations) && Object.values(errors?.translations)[index]?.name && Array.isArray(touched?.translations) && touched?.translations[index]?.name) && "p-invalid"}`}
                                    name={`translations.${index}.name`}
                                    placeholder='name'
                                    value={values.translations && values.translations[index] && values.translations[index].name}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                />
                                {Array.isArray(errors?.translations) && Object.values(errors?.translations)[index]?.name && Array.isArray(touched?.translations) && touched?.translations[index]?.name ? (
                                    <small className="block mt-2 p-error">{Object.values(errors?.translations)[index]?.name}</small>
                                ) : null}
                            </div>
                        </div>
                    ))
                    : null}
            </div>

        </>
    )

    return(
        <>
            <FormWrapper
                noValidate
                enableReinitialize
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={formSchema}
                formInputs={formInputs}
                submitButton={
                    <FooterAction>
                        <Button
                            loading={loading}
                            className="justify-center w-full" size="large" type="submit">
                            {props.currentData ? 'Update' : 'Create'}
                        </Button>
                    </FooterAction>
                }
                className="gap-2" />
        </>
    )

}

export default EventCategoriesForm;