import { Tag } from 'primereact/tag';

const TagStatus = (props: {
    status: number,
    label?: string[],
    severity?: ("success" | "danger" | "warning" | "info" | null | undefined)[]
}) => {
    const { status, label, severity } = props;

    let statusLabel = '';
    let statusSeverity: "success" | "danger" | "warning" | "info" | null | undefined;

    switch (status) {
        case 0:
            statusLabel = label ? label[0] || 'Inactive' : 'Inactive';
            statusSeverity = severity ? (severity[0] as ("success" | "danger" | "warning" | "info" | null | undefined)) : 'danger';
            break;
        case 1:
            statusLabel = label ? label[1] || 'Active' : 'Active';
            statusSeverity = severity ? (severity[1] as ("success" | "danger" | "warning" | "info" | null | undefined)) : 'success';
            break;
        case 2:
            statusLabel = label ? label[2] || 'Unverified' : 'Unverified';
            statusSeverity = severity ? (severity[2] as ("success" | "danger" | "warning" | "info" | null | undefined)) : 'warning';
            break;
        default:
            statusLabel = '';
            statusSeverity = undefined;
            break;
    }

    return <Tag value={statusLabel} severity={statusSeverity} className="p-tag-rounded !px-2 !py-1.5 !font-medium !text-[14px] leading-6"></Tag>;
}

export default TagStatus;