import React from "react";
import FormWrapper from "../../../components/form/FormWrapper";
import { FormikProps, FormikValues } from "formik";
import FooterAction from "../../../components/form/FooterAction";
import { Button } from "primereact/button";
import * as Yup from 'yup';
import ImageUploadPreview from "../../../components/ImageUploadPreview";


const CreateTagForm = (props: any) => {

    const initialValues = {
        galleryImages: ''
    };

    const UploadAllFormSchema = Yup.object({
        galleryImages: Yup.mixed().required('Required Upload')
    });

    const handleSubmit = (values: any) => {
        if (values) {
            props.uploadMany(values)
        }
    }

    const formInputs = ({ errors, values, touched, setFieldValue }: FormikProps<FormikValues>) => (
        <div className="flex mb-6">
            <div className="flex-1 ">
                <ImageUploadPreview multiple id={'upload-photo'} label={'Upload Image'} value={values.galleryImages}
                                    setUploadImage={(files: any) => setFieldValue('galleryImages', files)}
                                    onFileRemove={(file: any) => {
                                        const items = values.galleryImages;
                                        const final = Array.isArray(items) ? items.filter(item => {
                                            return item?.objectURL !== file?.objectURL
                                        }) : items
                                        setFieldValue('galleryImages', final)
                                    }}
                />
                {errors.galleryImages && touched.galleryImages ? (
                    <small className="block mt-2 p-error">{errors?.galleryImages?.toString()}</small>
                ) : null}
            </div>
        </div>
    )


    return <>
        <FormWrapper
            noValidate
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={UploadAllFormSchema}
            formInputs={formInputs}
            submitButton={
                <div className="pb-7">
                    <Button className="justify-center w-full" size="large" type="submit">
                        Upload
                    </Button>
                </div>
            }
            className="gap-2" />
    </>
}

export default CreateTagForm;