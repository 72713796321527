const langInfo: any = {
    en: {
        country: 'US',
        displayLang: 'English',
    },
    zh: {
        country: 'CN',
        displayLang: 'Chinese',
    },
    ja: {
        country: 'JP',
        displayLang: 'Japanese',
    }
}

export default function (lang: string) {
    return langInfo[lang] ? langInfo[lang] : { country: 'NA', displayLang: 'NA', }
}