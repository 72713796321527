import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../hooks/useStore';
import PromoCodeForm from '../components/PromoCodeForm';

const CreatePromoCode = () => {
    const { selectedPromotion } = useAppSelector((state) => state.event);
    const { locales }: any = useAppSelector((state) => state.common);
    const [currentLang, setCurrentLang] = useState<string>(locales?.default_language ?? 'en');
    const navigate = useNavigate();

    return (
        <>
            <div
                className='flex items-center mb-[8px] h-[24px] cursor-pointer'
                onClick={() => navigate(`/events/detail/promocodes`)}
            >
                <i className='pi pi-angle-left' />
                Back
            </div>
            <div className='flex items-center justify-between'>
                <div>
                    <h2 className='text-[20px] text-aria_dark'>{selectedPromotion?.id ? 'Edit ' : 'Create '} Promo code</h2>
                </div>
            </div>
            <PromoCodeForm
                currentLang={currentLang}
                onSubmitSuccess={() => navigate(`/events/detail/promocodes`)}
                resetDefaultLang={() => setCurrentLang(locales?.default_language)}
                currentData={null} />
        </>
    )
}

export default CreatePromoCode
