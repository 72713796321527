import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { Panel, PanelHeaderTemplateOptions } from "primereact/panel";
import deleteConfirmation from "../../../utils/deleteConfirmation";
import ImageUploadPreview from "../../../components/ImageUploadPreview";
import LanguageTab from "../../../components/LanguageTab";
import { useAppSelector } from "../../../hooks/useStore";
import FormWrapper from "../../../components/form/FormWrapper";
import * as Yup from 'yup';
import { FormikProps, FormikValues } from "formik";
import { EditorTextChangeEvent } from "primereact/editor";
import { nanoid } from "nanoid";
import CreateTagForm from "../components/CreateTagForm";
import UploadAllForm from "../components/UploadAllForm";
import { Dialog } from "primereact/dialog";
import { toast } from "react-toastify";
import { Dropdown } from "primereact/dropdown";
import PreviewVideo from "../components/PreviewVideo";
import { Tooltip } from 'primereact/tooltip';
import { convertFileSize } from "../../../utils/convertFileSize";
import TextEditor from "../../../components/TextEditor";
import { isEqualWith } from "lodash";

import {
    eventGalleryData,
    createGalleryFolder,
    updateImageGalleryByFolder,
    createImageGalleryByFolder,
    createVideoGalleryByFolder,
    updateVideoGalleryByFolder,
    uploadManyImages,
    setItemIsFeatured,
    getGalleryItemType,
    deleteGalleryItem,
    deleteGalleryFolder
} from "../../../utils/apiRequestList/eventAPI";
import { setLoading } from "../../../redux/slices/commonSlice";
import { useAppDispatch } from "../../../hooks/useStore";

const EventDetailGallery = () => {
    const videoIcon = <svg width="36" height="30" fill="none" xmlns="http://www.w3.org/2000/svg"><g clipPath="url(#a)"><path d="M4.388 4.394c-5.85 5.857-5.85 15.355 0 21.213 5.851 5.857 15.338 5.857 21.188 0l8.778-8.789a2.573 2.573 0 0 0 0-3.637l-8.778-8.789c-5.85-5.857-15.337-5.857-21.188 0v.002Z" fill="#6650F2" /><path d="m21.315 13.95-7.603-4.395a1.212 1.212 0 0 0-1.817 1.05v8.79c0 .933 1.01 1.516 1.817 1.05l7.603-4.395a1.213 1.213 0 0 0 0-2.1Z" fill="#fff" /></g><defs><clipPath id="a"><path fill="#fff" d="M0 0h35.106v30H0z" /></clipPath></defs></svg>
    const uploadIcon = <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m9.757 12.193 2.264-2.341v6.469c0 .508.162.935.488 1.273.327.338.742.506 1.236.506h5.845c1.001 0 1.84-.356 2.509-1.067.668-.71 1.001-1.584 1.001-2.615 0-1.032-.343-1.906-1.03-2.616-.686-.71-1.533-1.066-2.534-1.066h-.766V9.38c0-1.803-.608-3.336-1.824-4.593C15.73 3.53 14.246 2.9 12.5 2.9c-1.595 0-2.985.541-4.167 1.621-1.161 1.061-1.84 2.388-2.038 3.977-1.131.024-2.141.499-3.027 1.415-.911.942-1.368 2.072-1.368 3.386 0 1.339.451 2.476 1.354 3.406.903.929 2.002 1.395 3.292 1.395h2.807v-.984H6.546c-1.018 0-1.886-.37-2.607-1.117-.721-.745-1.081-1.644-1.081-2.7 0-1.057.364-1.955 1.094-2.701.732-.747 1.577-1.117 2.54-1.117h.696v-.1c0-1.524.518-2.818 1.555-3.89C9.78 4.417 11.03 3.883 12.5 3.883c1.47 0 2.72.534 3.757 1.606 1.037 1.073 1.555 2.367 1.555 3.89V11.72H19.536c.73 0 1.345.26 1.85.78.504.522.756 1.16.756 1.918 0 .759-.252 1.396-.756 1.917a2.47 2.47 0 0 1-1.85.781h-5.791c-.184 0-.358-.078-.524-.249-.165-.171-.242-.352-.242-.546V9.85l2.264 2.342.07.072.071-.07.542-.532.07-.07-.068-.071-3.356-3.47-.072-.075-.072.075-3.356 3.47-.069.071.071.07.541.531.072.071.07-.072Z" fill="#6650F2" stroke="#6650F2" strokeWidth=".2" /></svg>
    const removeIcon = <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><g stroke="#6650F2" strokeLinecap="round"><path d="M8 15v-3M12 15v-3M1 7h18v0c-.932 0-1.398 0-1.765.153a2 2 0 0 0-1.083 1.082C16 8.603 16 9.07 16 10.001v6c0 1.885 0 2.828-.586 3.414C14.828 20 13.886 20 12 20H8c-1.886 0-2.828 0-3.414-.585C4 18.829 4 17.886 4 16v-6c0-.931 0-1.397-.152-1.765a2 2 0 0 0-1.083-1.082C2.398 7 1.932 7 1 7v0ZM8.068 3.37c.114-.106.365-.2.714-.267A6.68 6.68 0 0 1 10 3c.44 0 .868.036 1.217.103.35.067.6.161.714.268" /></g></svg>
    const { basicInfo } = useAppSelector((state) => state.event);
    const { locales, loading }: any = useAppSelector((state) => state.common);
    const dispatch = useAppDispatch();
    const [currentLang, setCurrentLang] = useState(locales?.default_language ?? 'en');
    const [galleryData, setGalleryData] = useState<any>(null);
    const [currentFileSize, setCurrentFileSize] = useState('');
    const [visible, setVisible] = useState(false);
    const [uploadAllVisible, setUploadAllVisible] = useState(false);
    const [visibleWatchVideo, setVisibleWatchVideo] = useState(false);
    const [currentFolderId, setCurrentFolderId]: any = useState(null);
    const [typeFile, setTypeFile]: any = useState([])
    const [selectedVideoFile, setSelectedVideoFile] = useState('')

    const getFileType = async () => {
        const responseTypeFile = await getGalleryItemType(basicInfo?._id)
        const reFormate = responseTypeFile?.data?.data.map((item: any) => {
            return {
                name: item.name,
                value: item._id,
            }
        })
        setTypeFile(reFormate)
    }

    const fetchData = async () => {
        const response = await eventGalleryData(basicInfo?._id);
        setGalleryData(response?.data?.data?.event_gallery)
        setCurrentFileSize(convertFileSize(response?.data?.data?.gallery_file_size))
        dispatch(setLoading(false));
    }

    useEffect(() => {
        getFileType()
        fetchData()
    }, [])

    const createNewFolderData = async (value: any) => {
        dispatch(setLoading(true));
        const response = await createGalleryFolder(basicInfo?._id, value);
        if (response.data.success) {
            toast.success('Success adding new data');
            fetchData()
            setVisible(false)
        } else {
            toast.error('Failed adding new data');
        }
    }

    const createNewTag = () => {
        if (galleryData.length < 5) {
            setVisible(true)
        } else {
            toast.warning('You have reached the limit')
        }
    }

    const multipleUpload = (folderId: any) => {
        setCurrentFolderId(folderId)
        setUploadAllVisible(true)
    }

    const failedUpload = (data: any) => {
        const uploadedString = JSON.stringify({ failUpload: data });
        const uploadedParsed = JSON.parse(uploadedString);
        const uploadedList = Object.entries(uploadedParsed.failUpload).map(([key, value]) => ({ [key]: value }));
        return <div>
            <label>Failed upload file(s) list : </label>
            <ul className=" pl-6 max-h-60 overflow-y-auto">
                {uploadedList.map((item: any, index: number) => {
                    const [key, value] = Object.entries(item)[0];
                    return <li className=" list-disc" key={index}>{`${key}: ${value}`}</li>
                })}
            </ul>
        </div>
    }

    const uploadMany = async (value: any) => {
        dispatch(setLoading(true));
        setUploadAllVisible(false)
        const response = await uploadManyImages(basicInfo?._id, currentFolderId, value);
        if (response.data.success) {
            if (Object.keys(response?.data?.data?.failedUpload).length !== 0) {
                toast.warning(failedUpload(response?.data?.data?.failedUpload), { autoClose: false });
            } else {
                toast.success('Success adding multiple image');
            }

            fetchData()
        } else {
            toast.error('Failed adding multiple image');
        }
    }

    const deleteTag = async (folderId: number) => {
        deleteConfirmation(async () => {
            dispatch(setLoading(true));
            const response = await deleteGalleryFolder(basicInfo?._id, folderId);
            if (response.data.success) {
                fetchData()
            }
        })
    }

    const changeFeatured = async ({ _id, event_gallery_folder_id, is_featured }: any) => {
        dispatch(setLoading(true));
        const body = {
            is_featured: !is_featured
        }
        const response = await setItemIsFeatured(basicInfo?._id, event_gallery_folder_id, _id, body);
        if (response.data.success) {
            toast.success('Success updated feature');
            fetchData()
        } else {
            toast.error('Failed updated feature');
        }
    }


    const panelTemplateFile = (
        options2: PanelHeaderTemplateOptions,
        photo: any,
        i: number
    ) => {
        const toggleIcon = options2.collapsed
            ? "pi pi-chevron-down"
            : "pi pi-chevron-up";
        const className = `${options2.className} !justify-starts !rounded-none relative font-sans !border-0 !p-0 !bg-[transparent]`;

        const playVideo = (url: string) => {
            setSelectedVideoFile(url)
            setVisibleWatchVideo(true)
        }

        return <div className={className}>
            <div className="flex items-center pl-12">
                {photo.url !== '' ? <img className="w-[125px] h-[75px] object-cover" src={photo.video_thumbnail_url ? photo.video_thumbnail_url : photo.url} alt='' /> : <div>{`item - ${i + 1}`}</div>}
                {photo.type === '651b90d91630d2545c7d526e' && <button className=" ml-3" onClick={() => playVideo(photo.url)}> {videoIcon}
                </button>}
            </div>
            <div className="flex items-center">
                <Tooltip target=".featured-button" />
                <button
                    className=' mr-3 featured-button'
                    onClick={() => changeFeatured(photo)}
                    data-pr-tooltip={`Max 10 Favourite Photo/\nvideo will show on top row.`}
                    data-pr-position="bottom"
                    data-pr-at="right+5 bottom+32"
                    data-pr-my="left center-2"
                >
                    {photo.is_featured ? <svg width="18" height="17" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m3.354 16.683 1.432-6.17L0 6.365l6.315-.548L8.78 0l2.463 5.817 6.316.548-4.787 4.148 1.433 6.17-5.425-3.274-5.425 3.274Z" fill="#D04EFF" /></svg> : <svg width="18" height="17" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m5.629 13.546 3.15-1.9 3.15 1.925-.825-3.6 2.775-2.4-3.65-.325-1.45-3.4-1.45 3.375-3.65.325 2.775 2.425-.825 3.575Zm-2.275 3.137 1.432-6.17L0 6.365l6.315-.548L8.78 0l2.463 5.817 6.316.548-4.787 4.148 1.433 6.17-5.425-3.274-5.425 3.274Z" fill="#919191" /></svg>}
                </button>

                <button
                    className={options2.togglerClassName}
                    onClick={options2.onTogglerClick}
                >
                    <span className={toggleIcon}></span>
                </button>
            </div>
        </div>

    }

    const panelTemplate = (
        options: PanelHeaderTemplateOptions,
        item: any,
        index: number
    ) => {
        const toggleIcon = options.collapsed
            ?
            <svg className="rotate-180" width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="12" cy="12" r="12" transform="rotate(-180 12 12)" fill="#6650F2" /><path d="m18 15-6-6-6 6" stroke="#fff" strokeWidth="2" /></svg>
            :
            <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="12" cy="12" r="12" transform="rotate(-180 12 12)" fill="#6650F2" /><path d="m18 15-6-6-6 6" stroke="#fff" strokeWidth="2" /></svg>

        const className = `${options.className} !justify-content-start !rounded-none relative font-sans !border-0 !p-0 !bg-[transparent]`;

        return (
            <div className={className}>
                <div className="flex items-center justify-between border-b-aria_dark border-b-2 pb-2 w-full">
                    <div className=" text-xl font-semibold text-aria_dark">{item?.name}</div>
                    <div className="flex items-center justify-end">
                        <Tooltip target=".upload-all" />
                        <button className="ml-4 upload-all" onClick={() => multipleUpload(item._id)}
                            data-pr-tooltip="Upload all"
                            data-pr-position="top"
                            data-pr-at="center top-32"
                            data-pr-my="left-10 center-2">
                            {uploadIcon}
                        </button>
                        <button className="ml-4" onClick={() => deleteTag(item._id)}>
                            {removeIcon}
                        </button>
                        <button className="ml-4" onClick={options.onTogglerClick}>
                            {toggleIcon}
                        </button>
                    </div>
                </div>
            </div>
        );
    };

    return <>
        <Dialog header={'Create Tag'} visible={visible} onHide={() => setVisible(false)}
            style={{ width: '30vw' }} breakpoints={{ '960px': '75vw', '641px': '100vw' }}
            contentClassName="!pb-0"
        >
            <CreateTagForm setVisible={setVisible} createNewFolderData={createNewFolderData} />
        </Dialog>
        <Dialog header={'Upload Image(s)'} visible={uploadAllVisible} onHide={() => setUploadAllVisible(false)}
            style={{ width: '50vw' }} breakpoints={{ '960px': '75vw', '641px': '100vw' }}
            contentClassName="!pb-0"
        >
            <UploadAllForm setUploadAllVisible={setUploadAllVisible} uploadMany={uploadMany} />
        </Dialog>
        <Dialog header={'Preview Video'} visible={visibleWatchVideo} onHide={() => setVisibleWatchVideo(false)}
            style={{ width: '50vw' }} breakpoints={{ '960px': '75vw', '641px': '100vw' }}
            contentClassName="!pb-0"
        >
            <PreviewVideo setVisibleWatchVideo={setVisibleWatchVideo} selectedVideoFile={selectedVideoFile} />
        </Dialog>
        <div className="w-[550px]">
            <h2 className="text-3xl font-semibold text-aria_dark">Gallery</h2>
            <div className=" text-base font-normal text-aria_dark mb-4">
                ( Max File size 800mb per event, your current total all files size is <b>{currentFileSize}</b>)
            </div>
            <Button
                label="Create New Tag"
                icon="pi pi-plus"
                size="large"
                className="!text-[14px] !px-12"
                onClick={() => createNewTag()}
            />
            <div className="mt-4">
                {galleryData && galleryData.map((item: any, index: number) => {
                    return <div key={index} className=" mb-8">
                        <Panel
                            collapsed={true}
                            headerTemplate={(options) => panelTemplate(options, item, index)}
                            toggleable
                            pt={{
                                header: {
                                    className: "!rounded-none",
                                },
                                content: { className: "!rounded-none !bg-[transparent] !border-none !p-0" },
                                toggler: {
                                    className: "text-white hover:bg-primary-reverse",
                                },
                            }}
                        >
                            {item?.items?.map((photo: any, i: number) => {

                                const initialValues = photo ?
                                    {
                                        galleryImage: photo.url ?? '',
                                        galleryVideo: photo.video_thumbnail_url ?? '',
                                        fileType: photo.type ?? '',
                                        translations: photo.translations.length > 0 ?
                                            photo.translations.map((localeItem: any) => ({
                                                description: localeItem.description,
                                                language: localeItem.language,
                                            })) :
                                            locales?.available_languages?.map((lang: any) => {
                                                return { language: lang, description: "" }
                                            }),
                                    }
                                    :
                                    {
                                        galleryImage: '',
                                        galleryVideo: '',
                                        fileType: '651b90d91630d2545c7d526d',
                                        translations: locales?.available_languages?.map((lang: any) => {
                                            return { language: lang, description: "" }
                                        }),
                                    };

                                const uploadImageSchema = Yup.object({
                                    galleryImage: Yup.mixed(),
                                    galleryVideo: Yup.mixed(),
                                    fileType: Yup.string(),
                                    translations: Yup.array().of(
                                        Yup.object().shape({
                                            description: Yup.string()
                                        })
                                    ),
                                });

                                let prevData = initialValues;

                                function customizer(objValue: any, othValue: any, key: any) {
                                    if (key === "_id") {
                                        return true;
                                    }
                                }

                                const handleSubmit = async (value: any) => {
                                    if (isEqualWith(value, prevData, customizer)) return;

                                    dispatch(setLoading(true));
                                    if (value.fileType === "651b90d91630d2545c7d526e") {
                                        delete value.galleryImage;

                                        const isExistingItem = !!photo._id;
                                        const response = isExistingItem
                                            ? await updateVideoGalleryByFolder(basicInfo?._id, photo.event_gallery_folder_id, photo._id, value)
                                            : await createVideoGalleryByFolder(basicInfo?._id, item._id, value);

                                        if (response.data.success) {
                                            toast.success('Success Update Video Gallery Data');
                                            fetchData();
                                        } else {
                                            toast.error('Failed Update Video Gallery Data');
                                        }
                                    } else {
                                        delete value.galleryVideo;
                                        const isExistingItem = !!photo._id;
                                        const response = isExistingItem
                                            ? await updateImageGalleryByFolder(basicInfo?._id, photo.event_gallery_folder_id, photo._id, value)
                                            : await createImageGalleryByFolder(basicInfo?._id, item._id, value);

                                        if (response.data.success) {
                                            toast.success('Success Update Image Gallery Data');
                                            fetchData();
                                        } else {
                                            toast.error('Failed Update Image Gallery Data');
                                        }
                                    }
                                }

                                const formInputs = ({ errors, values, touched, handleBlur, handleChange, setFieldValue }: FormikProps<FormikValues>) => {
                                    return (
                                        <>
                                            <div className="mb-6">
                                                <label htmlFor="fileType" className="">File Type</label>
                                                <Dropdown
                                                    inputId="fileType"
                                                    name="fileType"
                                                    value={values.fileType}
                                                    options={typeFile}
                                                    optionLabel="name"
                                                    className={`w-full ${(errors.fileType && touched.fileType) && "p-invalid"}`}
                                                    onChange={(e) => {
                                                        setFieldValue('fileType', e.value);
                                                    }}
                                                />
                                            </div>
                                            {values.fileType === '651b90d91630d2545c7d526d'
                                                ?
                                                <ImageUploadPreview id={'photo-' + index + '-' + i} label={'Photo'} value={values.galleryImage} setUploadImage={(file: any) => setFieldValue('galleryImage', file)} />
                                                :
                                                <ImageUploadPreview id={'video-' + index + '-' + i} label={'Video'} value={values.galleryVideo} fileType='.mp4' setUploadImage={(file: any) => setFieldValue('galleryVideo', file)} maxFileSize='200000000' />
                                            }

                                            <div className=" mt-6">
                                                <LanguageTab
                                                    onChange={(lang: any) => setCurrentLang((prev: any) => ({ ...prev, [photo._id]: lang }))}
                                                    activeLanguage={currentLang[photo._id] ?? 'en'}
                                                />
                                                <div>
                                                    {values?.translations && values?.translations.length > 0 ?
                                                        values?.translations.map((user: any, idx: number) => {
                                                            let curLang = currentLang[photo._id] ? currentLang[photo._id] : 'en'
                                                            return <div
                                                                key={user.language + idx}
                                                                className={user.language === curLang ? 'block' : 'hidden'}
                                                            >
                                                                <label htmlFor={`translations.${idx}.description`} className="">Description</label>
                                                                <TextEditor
                                                                    id={`translations.${idx}.description`}
                                                                    name={`translations.${idx}.description`}
                                                                    value={values.translations && values.translations[idx] && values.translations[idx].description}
                                                                    onTextChange={(e: EditorTextChangeEvent) => {
                                                                        setFieldValue(`translations.${idx}.description`, e.htmlValue);
                                                                    }}
                                                                />
                                                            </div>
                                                        })
                                                        : null}
                                                </div>
                                            </div>
                                        </>
                                    );
                                }

                                return <div key={i} className="bg-white mt-3 p-4 rounded-xl">
                                    <Panel
                                        collapsed={true}
                                        headerTemplate={(options2) => panelTemplateFile(options2, photo, i)}
                                        toggleable
                                        pt={{
                                            header: {
                                                className: "!rounded-none",
                                            },
                                            content: { className: "!rounded-none !bg-[transparent] !border-none !p-0" },
                                            toggler: {
                                                className: "text-white hover:bg-primary-reverse",
                                            },
                                        }}
                                    >
                                        <div className="mt-5">
                                            <FormWrapper
                                                noValidate
                                                initialValues={initialValues}
                                                onSubmit={handleSubmit}
                                                validationSchema={uploadImageSchema}
                                                formInputs={formInputs}
                                                submitButton={

                                                    <div className="flex items-center justify-between mt-4">
                                                        <div className="flex items-center px-5 py-3 border rounded-lg cursor-pointer border-aria_dark w-fit" onClick={() => {
                                                            deleteConfirmation(async () => {
                                                                if (photo._id) {
                                                                    dispatch(setLoading(true));
                                                                    const response = await deleteGalleryItem(basicInfo?._id, photo.event_gallery_folder_id, photo._id);
                                                                    if (response.data.success) {
                                                                        toast.success('Success delete data')
                                                                        fetchData()
                                                                    }
                                                                } else if (photo.nanoId) {
                                                                    setGalleryData((prevGalleryData: any) => {
                                                                        const updatedGalleryData = [...prevGalleryData];
                                                                        const updatedItems = updatedGalleryData[index].items.filter((pth: any) => pth.nanoId !== photo.nanoId);
                                                                        updatedGalleryData[index] = {
                                                                            ...updatedGalleryData[index],
                                                                            items: updatedItems
                                                                        };

                                                                        return updatedGalleryData;
                                                                    })
                                                                }
                                                            });
                                                        }}>
                                                            {removeIcon}
                                                            <span className="ml-2">Delete Item</span>
                                                        </div>
                                                        <button disabled={loading} type="submit" className="flex items-center px-5 py-3 border rounded-lg cursor-pointer border-aria_dark w-fit">
                                                            <span>{photo._id ? 'Save' : 'Create'}</span>
                                                        </button>
                                                    </div>
                                                }
                                                className="gap-2" />
                                        </div>
                                    </Panel>
                                </div>
                            })}

                            <div className="flex justify-end mt-6">
                                <Button
                                    label="Add Item"
                                    icon="pi pi-plus"
                                    size="large"
                                    onClick={() => setGalleryData((prevGalleryData: any) => {
                                        const updatedGalleryData = [...prevGalleryData];
                                        updatedGalleryData[index] = {
                                            ...updatedGalleryData[index],
                                            items: [
                                                ...updatedGalleryData[index].items,
                                                {
                                                    url: '',
                                                    translations: [
                                                        {
                                                            language: "en",
                                                            description: "",
                                                        },
                                                        {
                                                            language: "zh",
                                                            description: "",
                                                        }],
                                                    event_gallery_folder_id: item.event_gallery_folder_id,
                                                    is_featured: item.is_featured,
                                                    nanoId: nanoid(),
                                                    type: '651b90d91630d2545c7d526d'
                                                }
                                            ]
                                        };
                                        return updatedGalleryData;
                                    })}
                                />
                            </div>
                        </Panel>
                    </div>
                })}
            </div >
        </div >
    </>
}

export default EventDetailGallery;