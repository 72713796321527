
import React, { useEffect, useState } from "react";
import FormWrapper from "../../components/form/FormWrapper";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import * as Yup from 'yup';
import { FormikProps, FormikValues } from "formik";
import ImageUploadPreview from "../../components/ImageUploadPreview";
import { createArtist, editArtist } from "../../utils/apiRequestList/artistAPI";
import { Dropdown } from 'primereact/dropdown';
import TypingPrompt from "../../components/form/TypingPrompt";
import FooterAction from "../../components/form/FooterAction";
import { MultiSelect } from 'primereact/multiselect';
import { useAppSelector, useAppDispatch } from '../../hooks/useStore';
import { toast } from "react-toastify";
import LanguageTab from "../../components/LanguageTab";
import TextEditor from "../../components/TextEditor";
import isEqual from "lodash-es/isEqual";
import { setLoading } from "../../redux/slices/commonSlice";

const ArtistForm = (props: any) => {
    const dispatch = useAppDispatch();
    const { locales, loading }: any = useAppSelector((state) => state.common);
    const [currentLang, setCurrentLang] = useState(locales?.default_language ?? 'en');
    const statusOption = [
        {
            name: 'Active',
            value: 1
        },
        {
            name: 'Non Active',
            value: 0
        }
    ]
    const initialValues = props.currentData ?
        {
            coverImage: props.currentData.coverImage,
            profilePicture: props.currentData.profilePicture,
            status: props.currentData.status,
            genreList: props.currentData.genreList,
            translations: locales?.available_languages.map((localeItem: any) => {
                const translation = Array.isArray(props.currentData.translations) ? props.currentData.translations.filter((tr: any) => tr.language === localeItem) : [];
                if (translation.length > 0) {
                    return translation[0]
                }
                else {
                    return {
                        language: localeItem,
                        artistName: "",
                        artistDes: "",
                        artistBio: ""
                    }
                }
            }),
        } : {
            coverImage: '',
            profilePicture: '',
            status: 1,
            genreList: [],
            translations: locales?.available_languages?.map((item: any) => {
                return { language: item, artistName: "", artistDes: "", artistBio: "" }
            }),
        };

    let prevData = initialValues

    const ArtistFormSchema = Yup.object({
        coverImage: Yup.string().required('Required Cover Image'),
        profilePicture: Yup.string().required('Required Profile Picture'),
        status: Yup.number(),
        genreList: Yup.array(),
        translations: Yup.array().of(
            Yup.object().shape({
                artistName: Yup.string().when("language", (language, schema) => {
                    if (language[0] === 'en') {
                        return schema.required("Required Artist Name");
                    }
                    return schema;
                }),
                artistDes: Yup.string(),
                artistBio: Yup.string(),
            })
        ),
    });

    const handleSubmit = async (value: any) => {
        if (isEqual(value, prevData)) return

        dispatch(setLoading(true))
        const translations = JSON.stringify(value.translations);
        const genreList = JSON.stringify(value.genreList);
        const stringifyData = {
            ...value,
            genreList,
            translations,
        }
        const response = props.currentData ? await editArtist(props.currentData.artistId, stringifyData) : await createArtist(stringifyData);
        if (response.data.success) {
            toast.success(props.currentData ? 'Success Edit Data' : 'Success Adding Data');
            props.setUpdateListing(true)
            setTimeout(() => {
                props.setVisible(false)
            }, 1000);
            prevData = value;
        } else {
            dispatch(setLoading(false))
            toast.error('Failed Adding Artist');
            setTimeout(() => {
                props.setVisible(false)
            }, 1000);
        }
    }

    const formInputs = ({ errors, values, touched, handleBlur, handleChange, setFieldValue }: FormikProps<FormikValues>) => (
        <>
            <div className="flex mb-6">
                <div className="flex-1 relative">
                    <ImageUploadPreview id={'cover-image'} label={' Upload Cover Image'} value={values.coverImage} setUploadImage={(file: any) => setFieldValue('coverImage', file)} />
                    <InputText
                        id="coverImage"
                        type="text"
                        className={` opacity-0 absolute w-full top-0 cursor-default ${errors.coverImage && touched.coverImage && "p-invalid"}`}
                        name="coverImage"
                        value={JSON.stringify(values.coverImage)}
                        onBlur={handleBlur}
                        onChange={handleChange}
                    />
                    {errors.coverImage && touched.coverImage ? (
                        <small className="block mt-2 p-error">{errors?.coverImage?.toString()}</small>
                    ) : null}
                </div>
            </div>
            <div className="mb-6">
                <div className="flex-1 relative">
                    <ImageUploadPreview id={'profile-image'} label={'Upload Profile Picture'} value={values.profilePicture} setUploadImage={(file: any) => setFieldValue('profilePicture', file)} />
                    <InputText
                        id="profilePicture"
                        type="text"
                        className={` opacity-0 absolute top-0 w-full cursor-default ${errors.profilePicture && touched.profilePicture && "p-invalid"}`}
                        name="profilePicture"
                        value={JSON.stringify(values.profilePicture)}
                        onBlur={handleBlur}
                        onChange={handleChange}
                    />
                    {errors.profilePicture && touched.profilePicture ? (
                        <small className="block mt-2 p-error">{errors?.profilePicture?.toString()}</small>
                    ) : null}
                </div>
            </div>
            <div className="mb-6">
                <LanguageTab onChange={setCurrentLang} activeLanguage={currentLang} />
                {values.translations && values.translations.length > 0 ?
                    values.translations.map((user: any, index: number) => (
                        <div key={user.language} className={user.language === currentLang ? 'block' : 'hidden'}>
                            <div className="mb-6">
                                <label htmlFor={`translations.${index}.artistName`} className="">
                                    Artist Name
                                </label>
                                <InputText
                                    id={`translations.${index}.artistName`}
                                    className={`w-full ${(Array.isArray(errors?.translations) && Object.values(errors?.translations)[index]?.artistName && Array.isArray(touched?.translations) && touched?.translations[index]?.artistName) && "p-invalid"}`}
                                    name={`translations.${index}.artistName`}
                                    value={values.translations && values.translations[index] && values.translations[index].artistName}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                />
                                {Array.isArray(errors?.translations) && Object.values(errors?.translations)[index]?.artistName && Array.isArray(touched?.translations) && touched?.translations[index]?.artistName ? (
                                    <small className="block mt-2 p-error">{Object.values(errors?.translations)[index]?.artistName}</small>
                                ) : null}
                            </div>
                            {/* <div className="mb-6">
                                <label htmlFor={`translations.${index}.artistDes`} className="">Description</label>
                                <TextEditor
                                    id={`translations.${index}.artistDes`}
                                    name={`translations.${index}.artistDes`}
                                    value={values.translations && values.translations[index] && values.translations[index].artistDes}
                                    onTextChange={(e: any) => {
                                        setFieldValue(`translations.${index}.artistDes`, e.htmlValue);
                                    }} />
                            </div> */}
                            <div className="mb-6">
                                <label htmlFor={`translations.${index}.artistBio`} className="">Biography</label>
                                <TextEditor
                                    id={`translations.${index}.artistBio`}
                                    name={`translations.${index}.artistBio`}
                                    value={values.translations && values.translations[index] && values.translations[index].artistBio}
                                    onTextChange={(e: any) => {
                                        setFieldValue(`translations.${index}.artistBio`, e.htmlValue);
                                    }}
                                />
                            </div>
                        </div>
                    ))
                    : null
                }
            </div>
            <div className="mb-6">
                <label htmlFor="genreList" className="">Genre(s)</label>
                <MultiSelect
                    id="genreList"
                    name="genreList"
                    options={props.genreOptions.map((option: any) => ({
                        ...option,
                        key: option?.value,
                    }))}
                    value={values.genreList}
                    onChange={(e) => {
                        setFieldValue('genreList', e.value);
                    }}
                    optionLabel="name"
                    className="w-full md:w-20rem"
                    display="chip"
                    filter
                />
            </div>
            <div className="mb-6">
                <label htmlFor="statusOption" className="">Status</label>

                {props?.currentData ? (
                    <TypingPrompt typeChallange={props?.currentData?.translations[0]?.artistName} >
                        {(disabled) => (
                            <Dropdown
                                inputId="status"
                                name="status"
                                value={values.status}
                                options={statusOption}
                                optionLabel="name"
                                disabled={disabled}
                                className={`w-full ${(errors.status && touched.status) && "p-invalid"}`}
                                onChange={(e) => {
                                    setFieldValue('status', e.value);
                                }}
                            />
                        )}
                    </TypingPrompt>
                ) : (
                    <Dropdown
                        inputId="status"
                        name="status"
                        value={values.status}
                        options={statusOption}
                        optionLabel="name"
                        className={`w-full ${(errors.status && touched.status) && "p-invalid"}`}
                        onChange={(e) => {
                            setFieldValue('status', e.value);
                        }}
                    />
                )}
            </div>
        </>
    )

    return <>
        <FormWrapper
            noValidate
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={ArtistFormSchema}
            formInputs={formInputs}
            submitButton={
                <FooterAction>
                    <Button className="justify-center w-full" size="large" type="submit" loading={loading}>
                        {props.currentData ? 'Update' : 'Create'}
                    </Button>
                </FooterAction>
            }
            className="gap-2" />
    </>
}

export default ArtistForm;