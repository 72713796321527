import { useEffect, useState } from "react";
import { getHomeContent } from "../../../utils/apiRequestList/cmsAPI";
import HomeForm from "./homeForm";
import { setLoading } from "../../../redux/slices/commonSlice";
import { useAppDispatch } from "../../../hooks/useStore";

const Home = () => {
  const dispatch = useAppDispatch()
  const [data, setData] = useState<any>(null)

  const fetchData = async () => {
    try {
      dispatch(setLoading(true))
      const response = await getHomeContent();
      setData(response?.data?.data)
      dispatch(setLoading(false))
    } catch (error) {
      dispatch(setLoading(false))
      console.error('Error:', error);
    }
  }

  useEffect(() => {
    fetchData();
  }, [])

  const updateData = () => {
    fetchData();
  }

  return <>
    {data && <HomeForm data={data} updateData={updateData} />}
  </>

}
export default Home;