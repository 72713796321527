import { FormikProps, FormikValues } from 'formik';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputSwitch } from 'primereact/inputswitch';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import { RadioButton } from 'primereact/radiobutton';
import React, { useEffect, useState } from 'react';
import CurrencyInput from "react-currency-input-field";
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import FormWrapper from '../../../components/form/FormWrapper';
import { useAppDispatch, useAppSelector } from '../../../hooks/useStore';
import { setLoading } from '../../../redux/slices/commonSlice';
import { setSelectedPromotion } from '../../../redux/slices/eventSlice';
import { PromotionTicketTagging, Ticket } from '../../../redux/types/event';
import { get, post } from '../../../utils/AxiosReqeust';
import { removeNull } from '../../../utils/removeNull';
import FormCategoryTitle from './FormCategoryTitle';
import { isEqualWith } from 'lodash';

import 'rc-time-picker/assets/index.css';
import moment from 'moment';
import TimePicker from 'rc-time-picker';
const format = 'h:mm a';

const PromoCodeForm = ({currentLang, onSubmitSuccess, resetDefaultLang, currentData}: any) => {
    const { basicInfo, selectedPromotion } = useAppSelector((state) => state.event);
    const { loading }: any = useAppSelector((state) => state.common);
    const dispatch = useAppDispatch();
    const [tickets, setTickets] = useState<any[]>([]);
    const [ticketsHidden, setTicketsHidden] = useState<any[]>([]);

    useEffect(() => {
        const getData = async () => {
            try {
                const [
                    ticketGroups,
                ] = await Promise.all([
                    get(`/ticket/list-group/${basicInfo?._id}`),
                ]);
                if (ticketGroups.data.success && ticketGroups.data.data) {
                    const tcx: Ticket[] = [];
                    ticketGroups.data.data.map((ticketGroup: any) => {
                        return {
                            ...ticketGroup,
                            ...{
                                tickets: ticketGroup?.tickets?.map((ticket: any) => {
                                    tcx.push({
                                        ...ticket,
                                        ...{
                                            name: `${ ticketGroup?.translations?.filter((translation: any) => translation?.language === currentLang)[0]?.name } ${ ticket?.translations?.filter((translation: any) => translation?.language === currentLang)[0]?.name }`,
                                        }
                                    });
                                }),
                            }
                        }
                    })
                    setTicketsHidden(tcx?.filter((htcx: Ticket) => htcx.ticket_viewability_type_id._id === "64f7df09728437a86d7b75a8")?.map((item: Ticket) => {
                        return {label: item?.name, value: item?._id}
                    }));
                    setTickets(tcx?.filter((htcx: Ticket) => htcx.ticket_viewability_type_id._id !== "64f7df09728437a86d7b75a8")?.map((item: Ticket) => {
                        return {label: item?.name, value: item?._id}
                    }));
                }
                if (selectedPromotion && Object.keys(selectedPromotion).length !== 0) {
                    const [
                        ticketTagging,
                    ] = await Promise.all([
                        get(`/promotion/tickets-tagging/${selectedPromotion._id}`),
                    ]);
                    if (ticketTagging.data.success && ticketTagging.data.data) {
                        dispatch(setSelectedPromotion({...selectedPromotion, ...{ticket_ids: ticketTagging.data.data}}));
                    }
                }
            }
            catch (error) {
                console.log(error);
            }
        }
        getData();
    }, []);

    const initialValues = selectedPromotion && Object.keys(selectedPromotion).length !== 0 ? 
    {
        ...selectedPromotion,
        ...{
            code_name: selectedPromotion?.promoCode ? selectedPromotion?.promoCode : '',
            promo_name: selectedPromotion?.promo_name ? selectedPromotion?.promo_name : '',
            promo_start_date: selectedPromotion?.promo_start_date ? new Date(selectedPromotion?.promo_start_date) : '',
            promo_start_time: selectedPromotion?.promo_start_time ? moment(selectedPromotion?.promo_start_time) : '',
            promo_end_date: selectedPromotion?.promo_end_date ? new Date(selectedPromotion?.promo_end_date) : '',
            promo_end_time: selectedPromotion?.promo_end_time ? moment(selectedPromotion?.promo_end_time) : '',
            applied_type: Number(selectedPromotion?.applied_type) < 2 ? selectedPromotion?.applied_type : '',
            applied_type_hidden: Number(selectedPromotion?.applied_type) > 1 ? selectedPromotion?.applied_type : '',
            select_ticket_ids: Number(selectedPromotion?.applied_type) < 2 ? selectedPromotion?.ticket_ids?.map((ticket_id: PromotionTicketTagging) => ticket_id?.ticket_id) : [],
            select_ticket_ids_hidden: Number(selectedPromotion?.applied_type) > 1 ? selectedPromotion?.ticket_ids?.map((ticket_id: PromotionTicketTagging) => ticket_id?.ticket_id) : [],
            ticket_limit_amount: selectedPromotion?.ticket_limit_amount ??'',
            discount_amount: selectedPromotion?.discount_amount ??'',
            discount_percentage: selectedPromotion?.discount_percentage ??'',
        }
    } : {
        id: null,
        code_name: '',
        promo_name: '',
        promo_quantity: '',
        reveal_hidden_ticket: false,
        is_public: false,
        ticket_limit: false,
        ticket_limit_amount: '',
        discount_amount: '',
        discount_percentage: '',
        promo_start_type: 0,
        promo_start_date: '',
        promo_start_time: '',
        promo_end_type: 0,
        promo_end_date: '',
        promo_end_time: '',
        applied_type: '',
        applied_type_hidden: '',
        select_ticket_ids: [],
        select_ticket_ids_hidden: [],
    };

    const schema = Yup.object({
        code_name: Yup.string().when(
            [
                "reveal_hidden_ticket",
            ],
            ([
                reveal_hidden_ticket,
            ], schema) => {
                if (reveal_hidden_ticket === false) {
                    return schema.required("Code name is required");
                }
                return schema;
            }
        ),
        promo_name: Yup.string(),
        promo_quantity: Yup.number().when(
            [
                "reveal_hidden_ticket",
            ],
            ([
                reveal_hidden_ticket,
            ], schema) => {
                if (reveal_hidden_ticket === true) {
                    return schema.integer()
                        .min(2, "Code quantity must be greater than or equal to 2")
                        .required("Code quantity is required");
                }
                return schema;
            }
        ),
        discount_amount: Yup.number().min(0),
        discount_percentage: Yup.number().min(0),
        // TODO: validation having issue
        // discount_amount: Yup.number().when(
        //     ["reveal_hidden_ticket", "discount_percentage"],
        //     ([reveal_hidden_ticket, discount_percentage], schema) => {
        //     if (reveal_hidden_ticket === false && discount_percentage === '') {
        //       return schema.min(0).required("Discount Amount is required");
        //     }
        //     return schema;
        // }),
        // discount_percentage: Yup.number().when(
        //     ["reveal_hidden_ticket", "discount_amount"],
        //     ([reveal_hidden_ticket, discount_amount], schema) => {
        //     if (reveal_hidden_ticket === false && discount_amount === '') {
        //       return schema.min(0).required("Discount Percentage is required");
        //     }
        //     return schema;
        // }),
        promo_start_date: Yup.string().when("promo_start_type", ([promo_start_type], schema) => {
            if (promo_start_type === 1) {
                return schema.required("Promo Start Date is required");
            }
            return schema;
        }),
        promo_start_time: Yup.string().when("promo_start_type", ([promo_start_type], schema) => {
            if (promo_start_type === 1) {
                return schema.required("Promo Start Time is required");
            }
            return schema;
        }),
        promo_end_date: Yup.string().when("promo_end_type", ([promo_end_type], schema) => {
            if (promo_end_type === 1) {
                return schema.required("Promo End Date is required");
            }
            return schema;
        }),
        promo_end_time: Yup.string().when("promo_end_type", ([promo_end_type], schema) => {
            if (promo_end_type === 1) {
                return schema.required("Promo End Time is required");
            }
            return schema;
        }),
        // event_timezone_id: Yup.string().required(
        //     "Event Timezone is required"
        // ),
        ticket_limit_amount: Yup.number().when("ticket_limit", ([ticket_limit], schema) => {
            if (ticket_limit === true) {
                return schema.min(1).required("Ticket Limit Amount is required");
            }
            return schema;
        }),
        applied_type: Yup.string().when(
            ["reveal_hidden_ticket"],
            ([reveal_hidden_ticket], schema) => {
            if (reveal_hidden_ticket === false) {
                return schema.required("Apply code to is required");
            }
            return schema;
        }),
        applied_type_hidden: Yup.string().when(
            ["reveal_hidden_ticket"],
            ([reveal_hidden_ticket], schema) => {
            if (reveal_hidden_ticket === true) {
                return schema.required("Apply code to is required");
            }
            return schema;
        }),
        select_ticket_ids: Yup.array().when(
            [
                "reveal_hidden_ticket",
                "applied_type",
            ],
            ([
                reveal_hidden_ticket,
                applied_type
            ], schema) => {
                if (reveal_hidden_ticket === false && applied_type === '1') {
                    return schema.min(1, "Select Tickets must have at least 1 items")
                        .required("Select Tickets is required");
                }
                return schema;
            }
        ),
        select_ticket_ids_hidden: Yup.array().when(
            [
                "reveal_hidden_ticket",
                "applied_type_hidden",
            ],
            ([
                reveal_hidden_ticket,
                applied_type_hidden
            ], schema) => {
                if (reveal_hidden_ticket === true && applied_type_hidden === '3') {
                    return schema.min(1, "Select Tickets must have at least 1 items")
                        .required("Select Tickets is required");
                }
                return schema;
            }
        ),
    });
    let prevData = initialValues;
    
    function customizer(objValue: any, othValue: any, key: any) {
        if (key === "_id") {
            return true;
        }
    }

    const handleSubmit = async (values: any, actions: any) => {
        if (isEqualWith(values, prevData, customizer)) return;

        const {applied_type_hidden, select_ticket_ids, select_ticket_ids_hidden, ...formData} = values;
        let updatedValues = {...formData, ...{event_id: basicInfo?._id}};
        if (formData.reveal_hidden_ticket) {
            if (applied_type_hidden === 2) {
                updatedValues = {...updatedValues, ...{applied_type: applied_type_hidden, ticket_ids: ticketsHidden?.map((ticketHidden: any) => ticketHidden?.value)}};
            } else {
                updatedValues = {...updatedValues, ...{applied_type: applied_type_hidden, ticket_ids: select_ticket_ids_hidden}};
            }
        } else {
            if (formData.applied_type === 0) {
                updatedValues = {...updatedValues, ...{ticket_ids: tickets?.map((ticket: any) => ticket?.value)}};
            } else {
                updatedValues = {...updatedValues, ...{ticket_ids: select_ticket_ids}};
            }
        }
        dispatch(setLoading(true));
        try {
            const response: any = await post(`/promotion/create`, removeNull(updatedValues));
            dispatch(setLoading(false));
            if (response?.data?.success && response?.data?.data) {
                if (!!onSubmitSuccess) onSubmitSuccess();
                toast.success(response?.data?.message);
                actions.resetForm();
            }
        }
        catch (error) {
            dispatch(setLoading(false));
            // toast.error(JSON.stringify(error));
        }
    };

    const formInputs = (
        {
            errors,
            values,
            touched,
            handleBlur,
            handleChange,
            setFieldValue
        }: FormikProps<FormikValues>
    ) => (
        <div className='flex flex-wrap justify-between'>
            <div className='w-full grid grid-cols-2 gap-x-[9px]'>
                {values.id === null && (
                    <div className='py-[10px]'>
                        <label htmlFor="reveal_hidden_ticket">
                            Reveal hidden tickets during checkout
                        </label>
                        <div className="flex items-center h-[35px]">
                            <InputSwitch
                                inputId="reveal_hidden_ticket"
                                className={`${(errors.reveal_hidden_ticket && touched.reveal_hidden_ticket) && "p-invalid"}`}
                                name="reveal_hidden_ticket"
                                checked={values.reveal_hidden_ticket}
                                onBlur={handleBlur}
                                onChange={handleChange}
                            />
                        </div>
                        {errors.reveal_hidden_ticket && touched.reveal_hidden_ticket ? (
                            <small className="block mt-2 p-error">{errors?.reveal_hidden_ticket?.toString()}</small>
                        ) : null}
                    </div>
                )}
                {values.reveal_hidden_ticket === false && (
                    <div className="py-[10px]">
                        <label>
                            Share options
                        </label>
                        <div className='flex'>
                            {
                                [
                                    {name: 'Public', value: true},
                                    {name: 'Private', value: false},
                                ].map((item: any, index: number) => {
                                    return (
                                        <div key={index} className="relative flex flex-row justify-start items-center mr-[20px]">
                                            <RadioButton
                                                className={`custom-p-radiobutton ${
                                                    touched.is_public && errors.is_public
                                                        ? "p-invalid"
                                                        : ""
                                                }`}
                                                inputId={`is_public_${index}`}
                                                name="is_public"
                                                value={item?.value}
                                                onChange={handleChange}
                                                checked={item?.value === values.is_public}
                                            />
                                            <label htmlFor={`is_public_${index}`} className="custom-label mb-0 ml-0">{item?.name}</label>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        {errors.is_public && touched.is_public ? (
                            <small className="block mt-2 p-error">
                                {errors?.is_public?.toString()}
                            </small>
                        ) : null}
                    </div>
                )}
            </div>
            <div className='w-full grid grid-cols-2 gap-x-[9px]'>
                <div className='py-[10px]'>
                    <label htmlFor="promo_name">
                        Promo name (label only)
                    </label>
                    <InputText
                        id="promo_name"
                        className={`w-full ${(errors.promo_name && touched.promo_name) && "p-invalid"}`}
                        name="promo_name"
                        placeholder='Promo name (label only)'
                        value={values.promo_name}
                        onBlur={handleBlur}
                        onChange={handleChange}
                    />
                    {errors.promo_name && touched.promo_name ? (
                        <small className="block mt-2 p-error">{errors?.promo_name?.toString()}</small>
                    ) : null}
                </div>
            </div>
            <div className='w-full grid grid-cols-2 gap-x-[9px]'>
                {values.reveal_hidden_ticket === false && (
                    <div className='py-[10px]'>
                        <label htmlFor="code_name">
                            Code name
                        </label>
                        <InputText
                            id="code_name"
                            className={`w-full ${(errors.code_name && touched.code_name) && "p-invalid"}`}
                            name="code_name"
                            placeholder='Code name'
                            value={values.code_name}
                            onBlur={handleBlur}
                            onChange={handleChange}
                        />
                        {errors.code_name && touched.code_name ? (
                            <small className="block mt-2 p-error">{errors?.code_name?.toString()}</small>
                        ) : null}
                    </div>
                )}
                {values.reveal_hidden_ticket === true && (
                    <div className='py-[10px]'>
                        <label htmlFor="promo_quantity">
                            Code quantity
                        </label>
                        <InputText
                            id="promo_quantity"
                            className={`w-full ${(errors.promo_quantity && touched.promo_quantity) && "p-invalid"}`}
                            name="promo_quantity"
                            placeholder='Code quantity'
                            value={values.promo_quantity}
                            onBlur={handleBlur}
                            onChange={handleChange}
                        />
                        {errors.promo_quantity && touched.promo_quantity ? (
                            <small className="block mt-2 p-error">{errors?.promo_quantity?.toString()}</small>
                        ) : null}
                    </div>
                )}
            </div>
            <div className='w-full flex flex-row justify-between items-center gap-x-[9px]'>
                <div className='py-[10px] grow'>
                    <label htmlFor="discount_amount">
                        Discount Amount
                    </label>
                    <CurrencyInput
                        id="discount_amount"
                        className={`p-inputtext p-component w-full ${(errors.discount_amount && touched.discount_amount) && "p-invalid"}`}
                        name="discount_amount"
                        placeholder='0.00'
                        defaultValue={values.discount_amount}
                        onValueChange={(value, name) => setFieldValue(name ?? 'discount_amount', value)}
                        disabled={values.discount_percentage > 0}
                    />
                    {errors.discount_amount && touched.discount_amount ? (
                        <small className="block mt-2 p-error">{errors?.discount_amount?.toString()}</small>
                    ) : null}
                </div>
                <div className='py-[10px] shrink-0'>
                    <label className='text-transparent'>
                        or
                    </label>
                    <span className="h-[35px]">or</span>
                </div>
                <div className='py-[10px] grow'>
                    <label htmlFor="discount_percentage">
                        Discount Percentage
                    </label>
                    <InputText
                        id="discount_percentage"
                        className={`w-full ${(errors.discount_percentage && touched.discount_percentage) && "p-invalid"}`}
                        name="discount_percentage"
                        placeholder='%'
                        value={values.discount_percentage}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        disabled={values.discount_amount > 0}
                    />
                    {errors.discount_percentage && touched.discount_percentage ? (
                        <small className="block mt-2 p-error">{errors?.discount_percentage?.toString()}</small>
                    ) : null}
                </div>
            </div>
            {values.reveal_hidden_ticket === false && (
                <div className='w-full grid grid-cols-2 gap-x-[9px]'>
                    <div className="py-[10px]">
                        <label htmlFor="ticket_limit">Ticket Limit</label>
                        <Dropdown
                            inputId="ticket_limit"
                            name="ticket_limit"
                            value={values?.ticket_limit}
                            options={[
                                {name: 'Limit to', value: true},
                                {name: 'Unlimited', value: false},
                            ]}
                            optionLabel="name"
                            placeholder="Select"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            className={`w-full ${
                                errors.ticket_limit &&
                                touched.ticket_limit &&
                                "p-invalid"
                            }`}
                        />
                        {errors.ticket_limit && touched.ticket_limit ? (
                            <small className="block mt-2 p-error">
                                {errors?.ticket_limit?.toString()}
                            </small>
                        ) : null}
                    </div>
                    {values?.ticket_limit === true && (
                        <div className='py-[10px]'>
                            <label htmlFor="ticket_limit_amount">
                                Ticket Amount
                            </label>
                            <InputText
                                id="ticket_limit_amount"
                                className={`w-full ${(errors.ticket_limit_amount && touched.ticket_limit_amount) && "p-invalid"}`}
                                name="ticket_limit_amount"
                                placeholder='No. of tickets'
                                value={values.ticket_limit_amount}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                disabled={values.reveal_hidden_ticket === true && values.promo_quantity > 1}
                            />
                            {errors.ticket_limit_amount && touched.ticket_limit_amount ? (
                                <small className="block mt-2 p-error">{errors?.ticket_limit_amount?.toString()}</small>
                            ) : null}
                        </div>
                    )}
                </div>
            )}
            <div className='w-full grid grid-cols-2 gap-x-[9px]'>
                {!values.reveal_hidden_ticket && (<>
                    <div className="py-[10px]">
                        <label>Apply code to</label>
                        <div className='flex flex-col gap-y-2'>
                            {
                                [
                                    {label: 'All visible tickets', value: 0},
                                    {label: 'Only certain visible tickets', value: 1},
                                ].map((mode: any) => {
                                    return (
                                        <div key={mode?.value} className={`flex flex-row justify-start items-center mr-[20px]`}>
                                            <RadioButton
                                                className={`${
                                                    touched.applied_type && errors.applied_type
                                                        ? "p-invalid"
                                                        : ""
                                                }`}
                                                inputId={mode?.value}
                                                name="applied_type"
                                                value={mode?.value}
                                                onChange={handleChange}
                                                checked={mode?.value === values.applied_type}
                                            />
                                            <label htmlFor={mode?.value} className="mb-0 ml-2">{mode?.label}</label>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        {errors.applied_type && touched.applied_type ? (
                            <small className="block mt-2 p-error">
                                {errors?.applied_type?.toString()}
                            </small>
                        ) : null}
                    </div>
                    {values.applied_type === 1 && (
                        <div className="py-[10px]">
                            <label htmlFor="select_ticket_ids">
                                Select Tickets
                            </label>
                            <MultiSelect
                                id="select_ticket_ids"
                                name="select_ticket_ids"
                                placeholder='Select Tickets'
                                options={tickets}
                                value={values.select_ticket_ids}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                className="w-full md:w-20rem"
                                display="chip"
                                filter
                            />
                            {errors.select_ticket_ids && touched.select_ticket_ids ? (
                                <small className="block mt-2 p-error">
                                    {errors?.select_ticket_ids?.toString()}
                                </small>
                            ) : null}
                        </div>
                    )}
                </>)}
                {values.reveal_hidden_ticket && (<>
                    <div className="py-[10px]">
                        <label>Apply code to</label>
                        <div className='flex flex-col gap-y-2'>
                            {
                                [
                                    {label: 'All hidden tickets', value: 2},
                                    {label: 'Only certain hidden tickets', value: 3},
                                ].map((mode: any) => {
                                    return (
                                        <div key={mode?.value} className={`flex flex-row justify-start items-center mr-[20px]`}>
                                            <RadioButton
                                                className={`${
                                                    touched.applied_type_hidden && errors.applied_type_hidden
                                                        ? "p-invalid"
                                                        : ""
                                                }`}
                                                inputId={mode?.value}
                                                name="applied_type_hidden"
                                                value={mode?.value}
                                                onChange={handleChange}
                                                checked={mode?.value === values.applied_type_hidden}
                                            />
                                            <label htmlFor={mode?.value} className="mb-0 ml-2">{mode?.label}</label>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        {errors.applied_type_hidden && touched.applied_type_hidden ? (
                            <small className="block mt-2 p-error">
                                {errors?.applied_type_hidden?.toString()}
                            </small>
                        ) : null}
                    </div>
                    {values.applied_type_hidden === 3 && (
                        <div className="py-[10px]">
                            <label htmlFor="select_ticket_ids_hidden">
                                Select Tickets
                            </label>
                            <MultiSelect
                                id="select_ticket_ids_hidden"
                                name="select_ticket_ids_hidden"
                                placeholder='Select Tickets'
                                options={ticketsHidden}
                                value={values.select_ticket_ids_hidden}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                className="w-full md:w-20rem"
                                display="chip"
                                filter
                            />
                            {errors.select_ticket_ids_hidden && touched.select_ticket_ids_hidden ? (
                                <small className="block mt-2 p-error">
                                    {errors?.select_ticket_ids_hidden?.toString()}
                                </small>
                            ) : null}
                        </div>
                    )}
                </>)}
            </div>
            <div className='w-full mb-[30px]'>
                <FormCategoryTitle title='Date and Time' subtitle='Lorem ipsum dolor sit amet consectetur adipisicing elit. Iste, cumque!' />
                <div className="py-[10px]">
                    <label>Promo code starts</label>
                    <div className='flex'>
                        {
                            [
                                {label: 'Now', value: 0},
                                {label: 'Scheduled time', value: 1},
                            ].map((mode: any) => {
                                return (
                                    <div key={mode?.value} className="flex flex-row justify-start items-center mr-[20px]">
                                        <RadioButton
                                            className={`${
                                                touched.promo_start_type && errors.promo_start_type
                                                    ? "p-invalid"
                                                    : ""
                                            }`}
                                            inputId={mode?.value}
                                            name="promo_start_type"
                                            value={mode?.value}
                                            onChange={handleChange}
                                            checked={mode?.value === values.promo_start_type}
                                        />
                                        <label htmlFor={mode?.value} className="mb-0 ml-2">{mode?.label}</label>
                                    </div>
                                )
                            })
                        }
                    </div>
                    {errors.promo_start_type && touched.promo_start_type ? (
                        <small className="block mt-2 p-error">
                            {errors?.promo_start_type?.toString()}
                        </small>
                    ) : null}
                </div>
                {values.promo_start_type === 1 && (
                    <div className='w-full grid grid-cols-2 gap-x-[9px]'>
                        <div className='py-[10px] w-full'>
                            <label htmlFor="promo_start_date">
                                Promo Starts
                            </label>
                            <Calendar
                                inputId="promo_start_date"
                                name="promo_start_date"
                                readOnlyInput
                                minDate={new Date(new Date().setDate(new Date().getDate() - 1))}
                                dateFormat="dd M yy, D"
                                placeholder="Start Date"
                                className={`w-full ${
                                    touched.promo_start_date && errors.promo_start_date
                                        ? "p-invalid"
                                        : ""
                                }`}
                                value={values.promo_start_date}
                                onChange={handleChange}
                            />
                            {errors.promo_start_date && touched.promo_start_date ? (
                                <small className="block mt-2 p-error">
                                    {errors?.promo_start_date?.toString()}
                                </small>
                            ) : null}
                        </div>
                        <div className='py-[10px] w-full'>
                            <label htmlFor="promo_start_time">
                                Start Time
                            </label>
                            <TimePicker
                                id="promo_start_time"
                                name="promo_start_time"
                                showSecond={false}
                                defaultValue={values.promo_start_time}
                                className={`w-full ${touched.promo_start_time && errors.promo_start_time
                                    ? "p-invalid"
                                    : ""
                                }`}
                                onChange={(momentValue:any) => {
                                    setFieldValue('promo_start_time', momentValue.toISOString());
                                }}
                                format={format}
                                use12Hours
                                inputReadOnly
                                allowEmpty={false}
                                placeholder={'Start Time'}
                            />
                            {errors.promo_start_time && touched.promo_start_time ? (
                                <small className="block mt-2 p-error">
                                    {errors?.promo_start_time?.toString()}
                                </small>
                            ) : null}
                        </div>
                    </div>
                )}
                <div className="py-[10px]">
                    <label>Promo code ends</label>
                    <div className='flex'>
                        {
                            [
                                {label: 'When ticket sales end', value: 0},
                                {label: 'Scheduled time', value: 1},
                            ].map((mode: any) => {
                                return (
                                    <div key={mode?.value} className="flex flex-row justify-start items-center mr-[20px]">
                                        <RadioButton
                                            className={`${
                                                touched.promo_end_type && errors.promo_end_type
                                                    ? "p-invalid"
                                                    : ""
                                            }`}
                                            inputId={mode?.value}
                                            name="promo_end_type"
                                            value={mode?.value}
                                            onChange={handleChange}
                                            checked={mode?.value === values.promo_end_type}
                                        />
                                        <label htmlFor={mode?.value} className="mb-0 ml-2">{mode?.label}</label>
                                    </div>
                                )
                            })
                        }
                    </div>
                    {errors.promo_end_type && touched.promo_end_type ? (
                        <small className="block mt-2 p-error">
                            {errors?.promo_end_type?.toString()}
                        </small>
                    ) : null}
                </div>
                {values.promo_end_type === 1 && (
                    <div className='w-full grid grid-cols-2 gap-x-[9px]'>
                        <div className='py-[10px] w-full'>
                            <label htmlFor="promo_end_date">
                                Promo End
                            </label>
                            <Calendar
                                inputId="promo_end_date"
                                name="promo_end_date"
                                readOnlyInput
                                minDate={new Date(new Date().setDate(new Date().getDate() - 1))}
                                dateFormat="dd M yy, D"
                                placeholder="End Date"
                                className={`w-full ${
                                    touched.promo_end_date && errors.promo_end_date
                                        ? "p-invalid"
                                        : ""
                                }`}
                                value={values.promo_end_date}
                                onChange={handleChange}
                            />
                            {errors.promo_end_date && touched.promo_end_date ? (
                                <small className="block mt-2 p-error">
                                    {errors?.promo_end_date?.toString()}
                                </small>
                            ) : null}
                        </div>
                        <div className='py-[10px] w-full'>
                            <label htmlFor="promo_end_time">
                                End Time
                            </label>
                            <TimePicker
                                id="promo_end_time"
                                name="promo_end_time"
                                showSecond={false}
                                defaultValue={values.promo_end_time}
                                className={`w-full ${touched.promo_end_time && errors.promo_end_time
                                    ? "p-invalid"
                                    : ""
                                }`}
                                onChange={(momentValue:any) => {
                                    setFieldValue('promo_end_time', momentValue.toISOString());
                                }}
                                format={format}
                                use12Hours
                                inputReadOnly
                                allowEmpty={false}
                                placeholder={'End Time'}
                            />
                            {errors.promo_end_time && touched.promo_end_time ? (
                                <small className="block mt-2 p-error">
                                    {errors?.promo_end_time?.toString()}
                                </small>
                            ) : null}
                        </div>
                        {/* <div className="py-[10px] w-full">
                            <label htmlFor="event_timezone_id">
                                Time Zone
                            </label>
                            <Dropdown
                                inputId="event_timezone_id"
                                name="event_timezone_id"
                                value={values.event_timezone_id}
                                options={eventTimezones}
                                optionLabel="name"
                                filter
                                placeholder="Select"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                className={`w-full ${
                                    errors.event_timezone_id &&
                                    touched.event_timezone_id &&
                                    "p-invalid"
                                }`}
                            />
                            {errors.event_timezone_id && touched.event_timezone_id ? (
                                <small className="block mt-2 p-error">
                                    {errors?.event_timezone_id?.toString()}
                                </small>
                            ) : null}
                        </div> */}
                    </div>
                )}
            </div>
        </div>
    );

    return (
        <FormWrapper
            noValidate
            enableReinitialize
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={schema}
            formInputs={formInputs}
            submitButton={
                <div className='flex justify-end'>
                    <Button loading={loading} className="justify-center w-full md:w-[23.75%]" size="large" type="submit"
                            onClick={() => {
                                if (!!resetDefaultLang) resetDefaultLang();
                            }}
                    >
                        {selectedPromotion?.id ? 'Update' : 'Save'}
                    </Button>
                </div>
            }
            className="gap-2"
        />
    )
}

export default PromoCodeForm
