import React, {useState} from 'react'
import {useAppDispatch, useAppSelector} from "../../hooks/useStore";
import * as Yup from "yup";
import isEqual from "lodash-es/isEqual";
import {setLoading} from "../../redux/slices/commonSlice";
import {createBoxOfficeUser, editBoxOfficeUser} from "../../utils/apiRequestList/boxofficeAPI";
import {toast} from "react-toastify";
import {FormikProps, FormikValues} from "formik";
import {InputText} from "primereact/inputtext";
import FormWrapper from "../../components/form/FormWrapper";
import FooterAction from "../../components/form/FooterAction";
import {Button} from "primereact/button";
import {MultiSelect} from "primereact/multiselect";
import {InputSwitch} from "primereact/inputswitch";
import {Password} from "primereact/password";

function BoxfficeUserForm(props: any) {
    const dispatch = useAppDispatch();

    const { loading }: any = useAppSelector((state) => state.common);

    const initialValues = props?.currentData ? {
        email: props?.currentData?.email,
        name: props?.currentData?.name,
        password: '',
        is_active: props?.currentData?.is_active,
        event_ids: props?.currentData?.event_ids.map((obj:any) => obj?.event_id),
    } : {
        email: '',
        name: '',
        password: '',
        is_active: true,
        event_ids: [],
    };
    let prevData = initialValues

    const schema = Yup.object().shape({
        email: Yup.string().email("Invalid email format").required("Email is required"),
        name: Yup.string().required('Required'),
        is_active: Yup.boolean().required('Required'),
        event_ids: Yup.array(),
    })

    const BoxofficeUserFormSchema = !props.currentData
        ? schema.shape({
            password: Yup.string().required("Password is required"),
        })
        : schema;


    const handleSubmit = async (values: any) => {
        if (isEqual(values, prevData)) return

        dispatch(setLoading(true))
        try {
            const response = props.currentData ?
                await editBoxOfficeUser(props.currentData._id, values) :
                await createBoxOfficeUser(values);
            if (response.status) {
                toast.success(`Boxoffice User ${props?.currentData ? 'Updated' : 'Created'}.`);
                props.setUpdateListing(true)
                setTimeout(() => {
                    props.setVisible(false)
                }, 1000);
                prevData = values;
            } else {
                toast.error(`Failed ${props?.currentData ? 'Updating' : 'Creating'} Boxoffice User`);
                setTimeout(() => {
                    props.setVisible(false)
                }, 1000);
            }
        } catch (error: any) {
            dispatch(setLoading(false))
            toast.error(error);
            console.error(error);
        }
    }

    const formInputs = ({
        errors,
        values,
        touched,
        handleBlur,
        handleChange,
        setFieldValue,
    }: FormikProps<FormikValues>) => {
        return (
            <>
                <div className="flex mb-6 gap-[7px]">
                    <div className="flex-1">
                        <label htmlFor="email" className="">
                            Email
                        </label>
                        <InputText
                            id="email"
                            type="text"
                            autoComplete="off"
                            aria-autocomplete="none"
                            className={`w-full ${errors.email && touched.email && "p-invalid"
                            }`}
                            name="email"
                            value={values.email}
                            onBlur={handleBlur}
                            onChange={handleChange}
                        />
                        {errors.email && touched.email ? (
                            <small className="block mt-2 p-error">
                                {errors?.email?.toString()}
                            </small>
                        ) : null}
                    </div>

                    <div className="flex-1">
                        <label htmlFor="name" className="">
                            Name
                        </label>
                        <InputText
                            id="name"
                            type="text"
                            className={`w-full ${errors.name && touched.name && "p-invalid"}`}
                            name="name"
                            value={values.name}
                            onBlur={handleBlur}
                            onChange={handleChange}
                        />
                        {errors.name && touched.name ? (
                            <small className="block mt-2 p-error">
                                {errors?.name?.toString()}
                            </small>
                        ) : null}
                    </div>
                </div>

                <div className="flex mb-6 gap-[7px]">
                    <div className="flex-1">
                        <label htmlFor="event_ids" className="">Events Access</label>
                        <MultiSelect
                            id="event_ids"
                            name="event_ids"
                            options={props?.eventDropdownOptions.map((option: any) => ({
                                key: option?._id,
                                name: option?.name,
                                value: option?._id
                            }))}
                            value={values.event_ids}
                            onChange={(e) => {
                                setFieldValue('event_ids', e.value);
                            }}
                            optionLabel="name"
                            className="w-full md:w-20rem"
                            display="chip"
                            filter
                        />
                    </div>

                    <div className="flex-1">
                        <label htmlFor="password" className="">
                            Password
                        </label>
                        <Password
                            id="password"
                            type="text"
                            className={`w-full ${errors.password && touched.password && "p-invalid"
                            }`}
                            name="password"
                            value={values.password}
                            autoComplete="off"
                            aria-autocomplete="none"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            toggleMask
                            inputClassName="w-full"
                        />
                        {errors.password && touched.password ? (
                            <small className="block mt-2 p-error">
                                {errors?.password?.toString()}
                            </small>
                        ) : null}
                    </div>
                </div>

                <div className='flex mb-6 gap-[7px]'>
                    <div className="flex-1">
                        <label htmlFor="is_active" className="">
                            Active
                        </label>
                        <InputSwitch
                            id="is_active"
                            type="is_active"
                            className={`w-full ${(errors.is_active && touched.is_active) && "p-invalid"}`}
                            name="is_active"
                            checked={values.is_active}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="flex-1"></div>
                </div>
            </>
        );
    };

    return (
        <>
            <FormWrapper
                noValidate
                enableReinitialize
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={BoxofficeUserFormSchema}
                formInputs={formInputs}
                submitButton={
                    <FooterAction>
                        <Button className="justify-center w-full" size="large" type="submit" loading={loading}>
                            {props.currentData ? 'Update' : 'Create'}
                        </Button>
                    </FooterAction>
                }
                className="gap-2" />
        </>
    )
}

export default BoxfficeUserForm;