import { useState } from 'react';
import * as Yup from 'yup';

import { Button } from "primereact/button";

import FormWrapper from "../../../components/form/FormWrapper";
import { FormikProps, FormikValues } from "formik";
import { InputText } from "primereact/inputtext";
import FooterAction from '../../../components/form/FooterAction';
import { useAppSelector, useAppDispatch } from "../../../hooks/useStore";
import { toast } from "react-toastify";
import { post } from "../../../utils/AxiosReqeust";
import {isEqual} from "lodash-es";
import { setLoading } from '../../../redux/slices/commonSlice';

const CurrencyForm = (props: any) => {
    const dispatch = useAppDispatch()
    const { locales, loading }: any = useAppSelector((state) => state.common);

    const initialValues = props?.currentData ? {
        _id: props?.currentData?._id,
        currency_code: props?.currentData?.currency_code,
        currency_symbol: props?.currentData?.currency_symbol
    } : {
        currency_code: '',
        currency_symbol: ''
    };
    let prevData = initialValues

    const CurrencyFormSchema = Yup.object().shape({
        currency_code: Yup.string().required('Required'),
        currency_symbol: Yup.string().required('Required'),
    })


    const handleSubmit = async (values: any) => {
        if (isEqual(values, prevData)) return
        dispatch(setLoading(true))
        try {
            const response = await post('/settings/currency/create', values)
            if (response.status) {
                toast.success(props.currentData ? 'Edited Currency' : "Currency Created.");
                props.setUpdateListing((prev:any) => prev + 1)
                setTimeout(() => {
                    props.setVisible(false)
                }, 1000);
            } else {
                dispatch(setLoading(false))
                toast.error(props.currentData ? 'Failed Edit Currency' : 'Failed Create Currency');
                setTimeout(() => {
                    props.setVisible(false)
                }, 1000);
            }
        } catch (error: any) {
            dispatch(setLoading(false))
            toast.error(error);
            console.error(error);
        }
    }

    const formInputs = ({ errors, values, touched, handleBlur, handleChange, setFieldValue }: FormikProps<FormikValues>) => (
        <>
            <div className="mb-6">
                <label htmlFor="currency_code" className="">
                    Currency Code
                </label>
                <InputText
                    id="currency_code"
                    type="text"
                    className={`w-full ${(errors.currency_code && touched.currency_code) && "p-invalid"}`}
                    name="currency_code"
                    value={values.currency_code}
                    onBlur={handleBlur}
                    onChange={handleChange}
                />
                {errors.currency_code && touched.currency_code ? (
                    <small className="block mt-2 p-error">{errors?.currency_code?.toString()}</small>
                ) : null}
            </div>

            <div className="mb-6">
                <label htmlFor="currency_symbol" className="">
                    Currency Symbol
                </label>
                <InputText
                    id="currency_symbol"
                    type="text"
                    className={`w-full ${(errors.currency_symbol && touched.currency_symbol) && "p-invalid"}`}
                    name="currency_symbol"
                    value={values.currency_symbol}
                    onBlur={handleBlur}
                    onChange={handleChange}
                />
                {errors.currency_symbol && touched.currency_symbol ? (
                    <small className="block mt-2 p-error">{errors?.currency_symbol?.toString()}</small>
                ) : null}
            </div>
        </>
    )

    return (
        <>
            <FormWrapper
                noValidate
                enableReinitialize
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={CurrencyFormSchema}
                formInputs={formInputs}
                submitButton={
                    <FooterAction>
                        <Button loading={loading} className="justify-center w-full" size="large" type="submit">
                            {props.currentData ? 'Update' : 'Create'}
                        </Button>
                    </FooterAction>
                }
                className="gap-2" />
        </>
    )

}

export default CurrencyForm;