import React from 'react';
import ReactDOM from 'react-dom/client';

import '../src/styles/index.scss';
// Prime React
//theme
import "./styles/theme.scss";
//core
import "primereact/resources/primereact.min.css";
//icons
import 'primeicons/primeicons.css';


import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Routes from "./Routes";
import { axiosInit } from './utils/AxiosReqeust';

// @ts-ignore
const router = createBrowserRouter(Routes)

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

axiosInit();
root.render(
  //react-router-dom use for routering
  //global style put on index.scss, other than that use tailwind that already installed
  <RouterProvider router={router} />
);

reportWebVitals();
