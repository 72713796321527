import React from 'react'

const EventDetailDashboard = () => {
    return (
        <>
            <h2 className="text-3xl font-semibold text-aria_dark mb-4">Dashboard</h2>
        </>
    )
}

export default EventDetailDashboard
