import React from "react";
import NotFound from "../screens/NotFound";

import App from "../App";

import PublicRoutes from "./PublicRoutes";
import ProtectedRoutes from "./ProtectedRoutes";

const Routes = [
  {
    path: "/",
    element: <App />,
    children: [
      ...PublicRoutes,
      ...ProtectedRoutes,
    ],
  },
];

export default Routes;
