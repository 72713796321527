import React from "react";
import _default from "chart.js/dist/plugins/plugin.tooltip";

interface FormCategoryTitleProps {
    title: string,
    subtitle?: string,
}

const FormCategoryTitle = (props: FormCategoryTitleProps) => {

    return (
        <>
            <div
                style={{
                    fontFamily: 'Nunito',
                    fontSize: '16px',
                    lineHeight: '24px',
                    color: 'black',
                    fontWeight: '700'
                }}
            >
                {props.title}
            </div>
            {
                props?.subtitle &&
                <div
                    style={{
                        fontFamily: 'Nunito',
                        fontSize: '12px',
                        fontWeight: 400,
                        lineHeight: '18px',
                        color: 'black'
                    }}
                >
                    {props?.subtitle}
                </div>
            }
            <hr style={{ borderColor: '#211F2E', margin: '8px 0px 12px 0px', }} />
        </>
    )
}

export default FormCategoryTitle;