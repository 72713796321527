import React, {useState,useEffect} from 'react';
import * as Yup from "yup";
import {FormikProps, FormikValues} from "formik";

import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";

import FormWrapper from "../../components/form/FormWrapper";
import AuthWrapper from "../../components/AuthWrapper";
import {resetPassword} from "../../utils/apiRequestList/userAPI";
import {resetPasswordOrganisation} from "../../utils/apiRequestList/organisationAPI";
import {Link, useNavigate} from "react-router-dom";


const ResetPasswordSchema = Yup.object({
    password: Yup.string().required('Required'),
    confirmPassword: Yup.string().required('Required').oneOf([Yup.ref('password')], 'Passwords must match')
});

const ResetPassword = () => {

    const navigate = useNavigate();
    const [type, setType] = useState('');
    const [token, setToken] = useState('');
    const [statusResult, setStatusResult] = useState(false);
    const [title, setTitle] = useState('Reset Password');
    const [countdown, setCountdown]:any = useState(null);

    useEffect(() => {
        const getQueryParameters = () => {
            const searchParams:any = new URLSearchParams(window.location.search);
            const params:any = {};
            for (const [key, value] of searchParams) {
                params[key] = value;
            }
            return params;
        };
        const queryParams = getQueryParameters();
        setToken(queryParams.token);
        setType(queryParams.type)
    }, []);

    const handleSubmit = async (values:any) =>{
        try {
            if (type === 'organisation') {
                await resetPasswordOrganisation(token, values.password, values.confirmPassword);
            }else{
                await resetPassword(token, values.password, values.confirmPassword);
            }
            setStatusResult(true);
            setTitle('Reset password successful')
            setCountdown(5);
        } catch (error:any) {
            console.error(error.response.data.message)
        }
    }

    useEffect(() => {
        if (countdown > 0) {
            const timer = setInterval(() => {
                // Check if the countdown is greater than 0 before decrementing
                setCountdown((prevCountdown:any) => prevCountdown - 1);
            }, 1000);
            // Cleanup function to clear the interval when the component is unmounted or countdown reaches 0
            return () => {
                clearInterval(timer);
            };
        }else if ( countdown === 0){
            // redirect
            navigate('/login')
        }
    }, [countdown]);

    const initialValues = { password: '', confirmPassword: ''};

    const formInputs = ({errors, touched, handleBlur, handleChange}: FormikProps<FormikValues>) => (
        <>
            <div className="mb-6">
                <label htmlFor="password" className=" ">
                    Password
                </label>
                <InputText type="password" className={`w-full ${(errors.password && touched.password) && "p-invalid"}`} name="password"
                           onBlur={handleBlur}
                           onChange={handleChange}
                />
                {errors.password && touched.password ? (
                    <p className="p-error">{errors?.password?.toString()}</p>
                ) : null}
            </div>
            <div className="mb-6">
                <label htmlFor="confirmPassword" className=" ">
                    Confirm password
                </label>
                <InputText type="password" className={`w-full ${(errors.confirmPassword && touched.confirmPassword) && "p-invalid"}`} name="confirmPassword"
                           onBlur={handleBlur}
                           onChange={handleChange}
                />
                {errors.confirmPassword && touched.confirmPassword ? (
                    <p className="p-error">{errors?.confirmPassword?.toString()}</p>
                ) : null}
            </div>
        </>
    )

    return(
        <AuthWrapper title={title} description={statusResult ? <h4>Redirect {countdown} second to login page</h4> : <></>}>
            {!statusResult
                ?
                <FormWrapper
                    noValidate
                    initialValues={initialValues}
                    validationSchema={ResetPasswordSchema}
                    formInputs={formInputs}
                    onSubmit={handleSubmit}
                    submitButton={
                        <div className="mb-6">
                            <Button className="justify-center w-full" size="large" type="submit">
                                Send
                            </Button>
                        </div>
                    }
                    className="gap-2"
                />
                :
                <div className="mt-6 text-sm text-zinc-600">
                    Go to <Link to={"/login"} className="font-semibold underline">Login</Link> page
                </div>
            }
        </AuthWrapper>
    )
}

export default ResetPassword;
