import React, { useState } from "react";
import { FileUpload, ItemTemplateOptions } from 'primereact/fileupload';
import { Button } from "primereact/button";
import { Tag } from "primereact/tag";
import { convertFileSize } from "../utils/convertFileSize";
import ImageCropper from "./imageCrop/ImageCrop";
import { Dialog } from "primereact/dialog";

const ImageUploadPreview = (props: any) => {
    const fileType = props?.fileType;
    const multiple = props?.multiple;
    const maxFile = props?.maxFileSize;
    const chooseOptions = { icon: fileType === '.pdf' ? 'pi pi-fw pi-file-pdf' : fileType === '.mp4' ? 'pi pi-video' : multiple ? 'pi pi-fw pi-images' : 'pi pi-fw pi-image', iconOnly: true, className: 'custom-choose-btn bg-[#3e37ff] p-button-rounded p-button-outlined' };
    const [selectedFile, setSelectedFile] = useState(props.value ? props.value : null);
    const [selectedMultipleFile, setSelectedMultipleFile] = useState(props.value ? props.value : null);
    const [errorMessage, setErrorMessage] = useState('');
    const [selectedImage, setSelectedImage] = useState(null);
    const [tempEvent, setTempEvent] = useState<any>(null);
    const [visibleCropDialog, setVisibleCropDialog] = useState(false);

    const [cropUploadWidth, setCropUploadWidth] = useState(0);
    const [cropUploadHeight, setCropUploadHeight] = useState(0);


    const handleClick = () => {
        const container = document.getElementById(props.id);
        if (!container) return;
        const fileInput: any = container.querySelector('input[type=file]');
        if (fileInput) {
            fileInput.click();
        }
    };

    const onFileSelect = (event: any) => {

        const file = event.files[0];

        if (!file || (multiple && !file)) {
            setErrorMessage(`Your file exceeds the maximum allowed file size. maximum ${maxFile ? convertFileSize(maxFile) : '6MB'}`);
        } else if ((props.fileType !== '.pdf' && file.type.includes('pdf')) ||
            (props.fileType === '.pdf' && !file.type.includes('pdf'))) {
            setErrorMessage(props.fileType === '.pdf' ? 'Invalid file format, please upload pdf only.' : 'Invalid file format, please upload image only.');
        } else if ((props.fileType !== '.mp4' && file.type.includes('mp4')) ||
            (props.fileType === '.mp4' && !file.type.includes('mp4'))) {
            setErrorMessage(props.fileType === '.mp4' ? 'Invalid file format, please upload mp4 only.' : 'Invalid file format, please upload image only.');
        } else {
            setErrorMessage('');
            if (file && props.crop) {
                const reader = new FileReader();
                reader.onload = function (e) {
                    const img = new Image();
                    img.onload = function (imageEvent) {
                        const image = imageEvent.target as HTMLImageElement;
                        const width = image.naturalWidth;
                        const height = image.naturalHeight;

                        setCropUploadWidth(width);
                        setCropUploadHeight(height);

                        if (!multiple && props.crop && (props.cropWidth <= width) && (props.cropHeight <= height)) {
                            setSelectedImage(file.objectURL);
                            setTempEvent(event);
                            setVisibleCropDialog(true);
                        } else if (!multiple && !(props.crop && (props.cropWidth <= width) && (props.cropHeight <= height))) {
                            setErrorMessage(`Wrong image uploaded size. Image size must be at least ${props.cropWidth}x${props.cropHeight} pixels, or larger.`)
                        } else {
                            onFileUpload(event, '');
                        }
                    };
                    img.src = e.target?.result as string;
                };
                reader.readAsDataURL(file);
            } else {
                onFileUpload(event, '');
            }
        }
    };

    const croppingResult = (file: any, url: string) => {
        const croppedBlob = new Blob([file], { type: 'image/jpeg' });
        const generateRandomString = (length: number) => {
            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            let result = '';
            for (let i = 0; i < length; i++) {
                result += characters.charAt(Math.floor(Math.random() * characters.length));
            }
            return result;
        }

        const files = new File([croppedBlob], generateRandomString(8) + 'cropped.jpeg', { type: 'image/jpeg' });

        onFileUpload(files, url)
        setTempEvent(null)

        setVisibleCropDialog(false);
    }

    const onFileUpload = async (event: any, url: string) => {
        // convert file to base64 encoded
        if (!multiple) {
            // todo handled multiple files upload
            const file = event.files && event.files[0] ? event.files[0] : event;
            // todo handled multiple files upload

            if (!file.hasOwnProperty('objectURL')) {
                file.objectURL = url
            }

            if (file) {
                setSelectedFile(file.objectURL);
                props.setUploadImage(file)
            }
        } else {
            const files = event.files;
            files.map((item: any) => {
                return setSelectedMultipleFile(item.objectURL);
            })

            props.setUploadImage(files)
        }

    };

    const onTemplateRemove = (callback: any, file: File) => {
        if (!multiple) {
            if (!callback) {
                props.setUploadImage(null)
                setSelectedFile(null)
            } else {
                props.setUploadImage(null)
                setSelectedFile(null)
                callback();
            }
        } else {
            if (props?.onFileRemove) props?.onFileRemove(file)
            setSelectedMultipleFile(null)
            callback();
        }
    };

    const EmptyTemplate = () => {
        return (
            <button type="button" className="w-full flex justify-center items-center h-full" onClick={() => handleClick()}>
                <div className="text-center p-5">
                    <div className="flex justify-center mb-3">
                        <svg width="69" height="69" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="34.5" cy="34.5" r="34.5" fill="#EDECF3" /><path d="M24.794 47c-2.165 0-4.005-.758-5.52-2.275-1.516-1.517-2.274-3.37-2.274-5.56 0-2.146.765-3.989 2.296-5.528 1.531-1.54 3.271-2.309 5.22-2.309.309-2.674 1.46-4.897 3.456-6.67C29.966 22.887 32.309 22 35 22c2.947 0 5.446 1.032 7.499 3.095 2.052 2.064 3.078 4.577 3.078 7.54v2.425h1.485c1.67 0 3.077.575 4.221 1.725C52.428 37.935 53 39.351 53 41.03c0 1.679-.557 3.094-1.67 4.244S48.825 47 47.155 47H37.134c-.804 0-1.47-.264-1.995-.793-.526-.529-.789-1.197-.789-2.005V33.008l-4.175 4.197-.928-.886L35 30.535l5.753 5.784-.928.886-4.175-4.197v11.194c0 .373.154.715.463 1.026.31.31.65.466 1.021.466h9.928c1.299 0 2.397-.45 3.294-1.353.897-.901 1.345-2.005 1.345-3.311s-.448-2.41-1.345-3.312c-.897-.902-1.995-1.352-3.294-1.352h-2.784v-3.732c0-2.58-.904-4.78-2.714-6.6-1.809-1.819-3.997-2.728-6.564-2.728s-4.755.91-6.564 2.729c-1.81 1.819-2.714 4.018-2.714 6.6H24.7c-1.701 0-3.193.637-4.477 1.912-1.283 1.275-1.925 2.814-1.925 4.617 0 1.804.634 3.343 1.902 4.618 1.268 1.275 2.799 1.912 4.593 1.912h4.639V47h-4.64Z" fill="#6650F2" /></svg>
                    </div>
                    <span style={{ fontSize: '14px', color: 'var(--text-color-secondary)' }}>
                        {fileType === '.pdf' ? 'Drag and Drop PDF file here' : multiple ? 'Drag and Drop your file(s) here' : 'Drag and Drop your file here'}
                    </span>
                </div>
            </button>
        );
    };

    const headerTemplate = (options: any) => {
        const { className, chooseButton } = options;
        return (
            <div className={className + ' !bg-aria_dark rounded-tl-md border !border-[#919191]'} style={{ display: 'flex', alignItems: 'center' }}>
                <div className="flex items-center justify-between w-full ">
                    <div className="text-sm font-bold text-white">{props.label}</div>
                    {chooseButton}
                </div>
            </div>
        );
    };

    const ItemTemplate = (inFile: object, props: ItemTemplateOptions) => {
        const file: any = inFile as File;
        return (
            <div className="custom-height flex flex-wrap items-center justify-between p-4" onClick={() => onFileSelect}>
                <div className="flex items-center max-w-[80%]">
                    {fileType === '.pdf' || (file.type && file.type.includes('pdf'))
                        ?
                        <i className="pi pi-file-pdf" style={{ fontSize: '5rem' }}></i>
                        :
                        fileType === '.mp4' || (file.type && file.type.includes('mp4'))
                            ?
                            <i className="pi pi pi-video" style={{ fontSize: '5rem' }}></i>
                            :
                            <div className="bg-[#EDECF3] w-[100px] h-[100px] p-1 rounded text-center">
                                {multiple ?
                                    <img src={file.objectURL} alt={file.name} className="m-[auto] w-full h-full object-contain" />
                                    :
                                    <img src={typeof selectedFile === 'string' ? selectedFile : selectedFile?.objectURL} alt={file.name} className="m-[auto] w-full h-full object-contain" />
                                }
                            </div>
                    }
                    {Object.keys(file).length > 0 && <div className="ml-3 text-left">
                        <div className="mb-2 text-sm font-normal">{file.name}</div>
                        <div className="custom-img-upload"><Tag value={props.formatSize} severity="warning" className="px-3 py-2" /></div>
                    </div>
                    }
                </div>
                <Button type="button" icon="pi pi-times" className="remove-button ml-auto p-button-outlined p-button-rounded bg-white text-aria_dark" onClick={() => onTemplateRemove(props ? props.onRemove : null, file)} />
            </div >
        );
    };

    return <div>
        {
            !multiple && props.crop && (props.cropWidth <= cropUploadWidth) && (props.cropHeight <= cropUploadHeight) && <Dialog
                className="no-hide"
                header={'Image Crop'}
                visible={visibleCropDialog}
                onHide={() => false}
                style={{ width: '700px' }}
                contentClassName="!pb-0"
            >
                <ImageCropper
                    imageSrc={selectedImage}
                    croppingResult={croppingResult}
                    aspectRatio={props.aspectRatio}
                    cropWidth={props.cropWidth}
                    cropHeight={props.cropHeight}
                />
            </Dialog>
        }
        <div className={`card custom-upload ${multiple ? 'custom-message' : ''}  ${props.customHeight}`} id={props.id}>
            <FileUpload
                name="demo[]"
                chooseOptions={chooseOptions}
                mode="advanced"
                accept={fileType ? fileType : ".jpg,.png,.jpeg"}
                maxFileSize={maxFile ?? 6000000}
                className="p-mb-2"
                customUpload
                auto
                multiple={multiple ?? false}
                onSelect={onFileSelect}
                headerTemplate={headerTemplate}
                emptyTemplate={selectedFile || (selectedMultipleFile && multiple) ? <ItemTemplate /> : <EmptyTemplate />}
                itemTemplate={ItemTemplate}
            />
        </div>

        {errorMessage && <small className="mt-2 block text-[#ea6c6c]">{errorMessage}</small>}

    </div>
}

export default ImageUploadPreview;