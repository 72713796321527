import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { useEffect, useState } from "react";
import TableWrapper from "../../components/listing/TableWrapper";
import { User } from "../../redux/types/user";
import { deleteUser, userListing } from "../../utils/apiRequestList/userAPI";
import UserForm from "./UserForm";
import objectToQueryString from "../../utils/objectToQueryString";
import { PaginatedResult } from "../../redux/types/api";
import TagStatus from "../../components/TagStatus";
import { useAppDispatch } from "../../hooks/useStore";
import { setLoading } from "../../redux/slices/commonSlice";
import { useDebounce } from "use-debounce";
import useAuth from "../../hooks/useAuth";
import deleteConfirmation from "../../utils/deleteConfirmation";
import { toast } from "react-toastify";
import SearchBox from "../../components/SearchBox";
import { getCountryList } from "../../utils/apiRequestList/countryAPI";

const Users = () => {
    const [visible, setVisible] = useState(false);
    const [formHeader, setFormHeader] = useState("Create User");
    const [data, setData] = useState<PaginatedResult<User> | null>(null);
    const [records, setRecords] = useState(10);
    const [page, setPage] = useState(1);
    const [updateListing, setUpdateListing] = useState(false);
    const [currentData, setCurrentData] = useState<User | null>(null);
    const [searchByEmail, setSearchByEmail] = useState("");
    const [params, setParams] = useState<Record<string, string | number>>({});
    const [debouncedSearchByEmail] = useDebounce(searchByEmail, 500);
    const dispatch = useAppDispatch();
    const { isSuperAdmin } = useAuth();
    const [countriesList, setCountriesList] = useState([]);
    const [dialCode, setDialCode] = useState([]);

    const fetchUserList = async (queryObj?: object) => {
        dispatch(setLoading(true));
        const queryString = queryObj ? objectToQueryString(queryObj) : "";
        try {
            const response = await userListing(queryString);

            if (response?.data?.success) {
                setData(response?.data?.data);
            }
            setUpdateListing(false);
        } catch (error) {
            console.error(error);
        }
        dispatch(setLoading(false));
    };

    const fetchCountryList = async () => {
        const response = await getCountryList();
        if (response.data.success) {
            setCountriesList(response.data.data.map((item: any) => {
                return { name: item.name, code: item.code, value: item._id }
            }));
            setDialCode(response.data.data.map((item: any) => {
                return { name: item.dial_code, code: item.dial_code, flag: `https://flagcdn.com/${item.code.toLowerCase()}.svg`, value: item.dial_code }
            }));
        }
    }


    const updateData = () => {
        fetchCountryList()
        if (!data) {
            setData(data);
        } else {
            fetchUserList({
                ...params,
                page,
                email_address: searchByEmail,
                pageSize: records,
            });
        }
    }
    useEffect(() => {
        updateData()
    }, [page, updateListing, records, debouncedSearchByEmail, params]);

    useEffect(() => {
        if (updateListing) {
            updateData()
        }
    }, [updateListing]);

    useEffect(() => {
        async function fetchData() {
            dispatch(setLoading(true))
            try {
                const userlist =
                    await userListing(
                        `pageSize=10`
                    );
                setData(userlist?.data?.data)
                dispatch(setLoading(false))
            } catch (error) {
                dispatch(setLoading(false))
                console.error('Error:', error);
            }
        }
        fetchData();
    }, []);

    const actionsBody = ({ _id, user_role_id }: User) => {
        return (
            <div className="flex gap-2">
                <Button
                    className="mr-2 !rounded-full w-8 h-[32px] p-0 flex items-center justify-center"
                    onClick={() => openModelEdit(_id)}
                    size="small"
                >
                    <i className="pi pi-pencil" />
                </Button>
                {
                    user_role_id?.name !== 'superadmin' ? (
                        <Button onClick={() => removeUser(_id)} size="small" className="mr-2 !rounded-full w-8 h-[32px] p-0 flex items-center justify-center">
                            <i className="pi pi-trash" />
                        </Button>
                    ) : null
                }
            </div>
        );
    };

    const statusBody = ({ status }: User) => {
        return <TagStatus status={status} />;
    };

    const openModelEdit = async (id: string) => {
        if (data && data.docs.length) {
            setFormHeader("Edit User");
            const row = data.docs.find((item) => item._id === id) || null;
            setCurrentData(row);
            setVisible(true);
        }
    };

    const removeUser = async (id: string): Promise<void> => {
        deleteConfirmation(async () => {
            const response = await deleteUser(id);
            if (
                response !== undefined &&
                response !== null &&
                "status" in response
            ) {
                toast.success("Successfully remove the user");
                setUpdateListing(true);
            }
        })
    };

    const openModalCreate = () => {
        setVisible(true);
        setCurrentData(null);
    };

    const handleApplyFilter = (values: object) => {
        fetchUserList(values);
    };

    const handleResetFilter = () => {
        fetchUserList();
    };

    return (
        <>
            <Dialog
                header={formHeader}
                visible={visible}
                onHide={() => setVisible(false)}
                style={{ width: "50vw", fontSize: '14px' }}
                breakpoints={{ "960px": "75vw", "641px": "100vw" }}
                contentClassName="!pb-4 text-sm"
            >
                <UserForm
                    setVisible={setVisible}
                    setUpdateListing={setUpdateListing}
                    currentData={currentData}
                    countriesList={countriesList}
                    dialCode={dialCode}
                />
            </Dialog>

            <h2 className="mb-[15px] h2 !pb-0 text-aria_dark">Manage users</h2>
            <div className="flex justify-between align-middle">
                <Button
                    label="Add a member"
                    icon="pi pi-plus"
                    size="large"
                    className="!text-[14px] !px-12"
                    onClick={() => openModalCreate()}
                />

                <SearchBox
                    placeholder="Search email"
                    onChange={(e) => {
                        setPage(1);
                        setSearchByEmail(e.target.value);
                    }}
                />
            </div>
            <div className="mt-5">
                {data ? (
                    <TableWrapper
                        data={data}
                        loading={false}
                        records={records}
                        setRecords={setRecords}
                        scrollable
                        scrollHeight="70vh"
                        onSort={(e) => {
                            setPage(1);
                            setParams({
                                ...params,
                                sortBy: e.sortField,
                                sortOrder:
                                    params?.sortOrder === "asc" ? "dsc" : "asc",
                            });
                        }}
                        nextPage={() => {
                            if (!data?.hasNextPage) return;
                            setPage((current) => current + 1);
                        }}
                        prevPage={() => {
                            if (!data?.hasPrevPage) return;
                            setPage((current) => current - 1);
                        }}
                    >
                        <Column
                            field="first_name"
                            header="First name"
                            sortable
                        ></Column>
                        <Column
                            field="last_name"
                            header="Last name"
                            sortable
                        ></Column>
                        <Column
                            field="email_address"
                            header="Email"
                            sortable
                        ></Column>
                        <Column
                            field="contact_no"
                            header="Contact number"
                        ></Column>
                        <Column
                            field="country"
                            header="Country"
                            body={(country) => {
                                country = countriesList.find((item: any) => item.value === country.country)
                                return country?.name
                            }}
                        ></Column>
                        <Column
                            field="status"
                            sortable
                            header="Status"
                            align={"center"}
                            body={statusBody}
                        ></Column>
                        {isSuperAdmin && (
                            <Column
                                header="Actions"
                                style={{ width: "10%" }}
                                body={actionsBody}
                            ></Column>
                        )}
                    </TableWrapper>
                ) : null}
            </div>
        </>
    );
};

export default Users;
