import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Panel, PanelHeaderTemplateOptions } from "primereact/panel";
import { ConfirmPopup } from "primereact/confirmpopup";
import { confirmPopup } from "primereact/confirmpopup";

import { PropsWithChildren } from "react";
import { Button } from "primereact/button";
import GripIcon from "./GripIcon";
import { toast } from "react-toastify";

export interface RepeaterItem {
  isCollapsed: boolean;
  _id: string;
  title: string;
  order: number;
  [key: string]: any;
}

export interface RepeaterList {
  items: { [key: string]: RepeaterItem };
  itemIds: string[];
}

// type TransformedData = {
//   itemIds: string[];
//   items: Record<string, any>;
// };

// function initialData(data: RepeaterItem[]): TransformedData {
//   const transformed: TransformedData = {
//     itemIds: [],
//     items: {},
//   };

//   data.forEach((item) => {
//     if (!item._id) return console.warn("Each item need to have a unique id");
//     item.isCollapsed = true;
//     transformed.items[item._id] = item;
//     transformed.itemIds.push(item._id);
//   });
//   return transformed;
// }

export default function Repeater<T extends RepeaterItem>({
  titleKey,
  headerType = "text",
  groupName,
  data,
  template,
  onAddItem,
  onDelete,
  onDuplicate,
  onOrderChange,
  max,
}: PropsWithChildren<{
  data: Array<T>;
  // can be a key of object you want to display or use a custom render function
  titleKey?: string | ((record: any) => any);
  headerType?: "image" | "text";
  template: {
    render: (item: RepeaterItem, index: number) => JSX.Element;
  };
  groupName: string;
  onAddItem?: () => void;
  onDelete?: (id: string) => void;
  onDuplicate?: (id: any) => void;
  onOrderChange?: (
    updated: any,
    source?: number,
    destination?: number,
    id?: string
  ) => void;
  min?: number;
  max?: number;
}>) {
  const onDragEnd = (result: any) => {
    const { draggableId, source, destination } = result;

    if (!destination) return;

    if (
      destination.droppableId._id === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    if (onOrderChange && typeof onOrderChange === "function") {
      const updated = Array.from(data);
      const [removed] = updated.splice(source.index, 1);
      updated.splice(destination.index, 0, removed);
      onOrderChange(
        updated.map((item, index) => ({ ...item, order: index + 1 })),
        source.index,
        destination.index,
        draggableId
      );
      return;
    }
  };

  const panelTemplate = (
    options: PanelHeaderTemplateOptions,
    index: number,
    provided: any,
    record: any
  ) => {
    const toggleIcon = options.collapsed
      ? "pi pi-chevron-down"
      : "pi pi-chevron-up";
    const className = `${options.className} !justify-content-start !rounded-none relative font-sans !bg-[#edecf3] !border-0 !p-0`;
    const titleClassName = `${options.titleClassName} flex flex-1 justify-between ml-4`;
    const style = { fontSize: "1.25rem" };

    const renderTitle = () => {
      return titleKey && typeof titleKey === "function"
        ? titleKey(record)
          ? titleKey(record)
          : `${groupName} ${index + 1}`
        : `${groupName} ${index + 1}`;
    };

    return (
      <div className={className}>
        <div className="flex items-center gap-4 italic font-semibold left-4">
          <div {...provided.dragHandleProps}>
            <GripIcon />
          </div>
        </div>
        <div className={titleClassName} style={style}>
          {headerType === "image" &&
          titleKey &&
          typeof titleKey === "string" &&
          record[titleKey] ? (
            <div className="flex w-full text-sm font-medium gap-[9px]">
              <div className="overflow-hidden rounded-md w-60 aspect-video">
                <img
                  src={
                    titleKey && record[titleKey] && record[titleKey].objectURL
                      ? record[titleKey].objectURL
                      : record[titleKey]
                  }
                  alt=""
                  className="object-cover w-full h-full"
                />
              </div>

              <div>
                <p className="text-[16px] font-bold">
                  {groupName} {index + 1}
                </p>
              </div>
            </div>
          ) : (
            <div className="w-full">{renderTitle()}</div>
          )}
        </div>
        <button
          className={`${options.togglerClassName} !bg-white`}
          onClick={options.onTogglerClick}
        >
          <span className={toggleIcon}></span>
        </button>
      </div>
    );
  };

  const accept = (id: string) => {
    if (onDelete && typeof onDelete === "function") {
      onDelete(id);
    }
  };

  const reject = () => {
  };

  return (
    <div className="font-sans">
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="layer-droppable">
          {(provided, snapshot) => {
            return (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                className={`flex flex-col bg-[#edecf3]`}
              >
                {data.length ? (
                  data
                    .sort((a, b) => a.order - b.order)
                    .map((item, i) => {
                      return (
                        <Draggable
                          draggableId={item._id ?? item.sortId}
                          key={item._id ?? item.sortId}
                          index={i}
                        >
                          {(provided, snapshot) => (
                            <div
                              className={`relative select-none flex items-center p-3 bg-[#edecf3] border border-solid border-[#6650f2] my-2 rounded-lg full-pannel`}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                            >
                              <Panel
                                collapsed={true}
                                headerTemplate={(options) =>
                                  panelTemplate(options, i, provided, {
                                    ...item,
                                    titleKey,
                                    groupName,
                                  })
                                }
                                toggleable
                                pt={{
                                  header: {
                                    className: "bg-white !rounded-none",
                                  },
                                  title: { className: "" },
                                  content: { className: "!bg-[#edecf3] !rounded-none !px-[7px]" },
                                  toggler: {
                                    className:
                                      "text-white hover:bg-primary-reverse",
                                  },
                                }}
                              >
                                <div className="m-0">
                                  {template.render(item, i)}
                                </div>

                                <div className="flex gap-4">
                                  {onDelete && (
                                    <Button
                                      onClick={(event) => {
                                        confirmPopup({
                                          target: event.currentTarget,
                                          message:
                                            "Are you sure you want to proceed?",
                                          icon: "pi pi-exclamation-triangle",
                                          acceptClassName: "p-button-danger",
                                          rejectClassName: "p-button-secondary",
                                          accept: () => accept(item._id),
                                          reject,
                                        });
                                      }}
                                      label="Delete item"
                                      size="small"
                                      type="button"
                                      link
                                      severity="secondary"
                                      outlined
                                      icon="pi pi-trash"
                                      className="mt-4 !text-[!211F2E]"
                                    />
                                  )}
                                  {onDuplicate && (
                                    <Button
                                      onClick={() => {
                                        if (typeof onDuplicate === "function") {
                                          onDuplicate(i);
                                        }
                                      }}
                                      label="Duplicate item"
                                      size="small"
                                      type="button"
                                      link
                                      severity="secondary"
                                      outlined
                                      icon="pi pi-copy"
                                      className="mt-4 !text-[!211F2E]"
                                    />
                                  )}
                                </div>
                                <ConfirmPopup />
                              </Panel>
                            </div>
                          )}
                        </Draggable>
                      );
                    })
                ) : (
                  <div className="p-4 text-xs text-center border text-slate-600">
                    {/* <EmptyIcon className="w-5 h-5 mx-auto" /> */}
                    No item
                  </div>
                )}

                {provided.placeholder}
              </div>
            );
          }}
        </Droppable>
      </DragDropContext>
      {onAddItem && (
        <div className="flex justify-end mt-2">
          <Button
            size="small"
            type="button"
            icon="pi pi-plus"
            label="Add item"
            className="mt-4"
            onClick={() => {
              if (data.length === max) {
                toast.warn(`The maximum item is ${max}`);
                return;
              }
              if (onAddItem && typeof onAddItem === "function") onAddItem();
              // const newId = `item-${repeaterList.itemIds.length + 1}`;
              // setRepeaterList({
              //   ...repeaterList,
              //   items: {
              //     ...repeaterList.items,
              //     [newId]: {
              //       id: newId,
              //       title: data[0].title,
              //       content: data[0].content,
              //     },
              //   },
              //   itemIds: [...repeaterList.itemIds, newId],
              // });
            }}
          />
        </div>
      )}
    </div>
  );
}
