import { nanoid } from "@reduxjs/toolkit";
import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { useEffect, useState } from "react";
import * as Yup from "yup";
// import { TabPanel, TabView } from "primereact/tabview";

import ImageUploadPreview from "../../../components/ImageUploadPreview";
import { artistDropdown } from "../../../utils/apiRequestList/artistAPI";
import Repeater from "./Repeater/Index";
import { updateHomeContent } from "../../../utils/apiRequestList/cmsAPI";
import { DEFAULT_COLOR } from "./constant";
import { toast } from "react-toastify";
// import ReactCountryFlag from "react-country-flag";
// import languageInfo from "../../../utils/languageInfo";
import { useAppSelector, useAppDispatch } from "../../../hooks/useStore";
import LanguageTab from "../../../components/LanguageTab";
import { isEqualWith } from "lodash-es";
import { setLoading } from "../../../redux/slices/commonSlice";

export default function HomeForm(props: any) {
  const dispatch = useAppDispatch()
  const { locales, loading }: any = useAppSelector((state) => state.common);
  const [artists, setArtists] = useState<any>(null);
  const [currentLang, setCurrentLang] = useState(locales?.default_language ?? 'en');
  const {
    info_carousel_show,
    info_carousel_max,
    featured_artist_show,
    featured_artist_max,
    upcoming_event_show,
    upcoming_event_max,
    venue_show,
    venue_max,
    new_event_show,
    new_event_max,
    recent_view_show,
    recent_view_max,
    status,
    url,
    info_carousel = [],
    featured_artist = [],
    createdAt,
    updatedAt,
  }: any = props.data;

  useEffect(() => {
    artistDropdown().then((res) => {
      setArtists(res?.data?.data);
    });
  }, []);

  const DEFAULT_CAROUSEL = {
    _id: nanoid(),
    cText: "",
    coverImageL: "",
    coverImageP: "",
    order: 1,
    redirectUrl: "",
    translations: locales?.available_languages?.map((item: any) => {
      return {
        tyText: "",
        titText: "",
        titImage: "",
        subText: "",
        textCTA: "",
        language: item
      }
    })
  }

  const initialValues = {
    carouselShow: info_carousel_show ?? true,
    corouselMax: info_carousel_max ?? 5,
    featuredArtistShow: featured_artist_show ?? true,
    featuredArtistMax: featured_artist_max ?? 5,
    upcommingEventShow: upcoming_event_show && true,
    upcommingEventMax: upcoming_event_max ?? 5,
    venueShow: venue_show ?? false,
    venueMax: venue_max ?? 5,
    newEventShow: new_event_show ?? true,
    newEventMax: new_event_max ?? 5,
    recentViewShow: recent_view_show ?? false,
    recentViewMax: recent_view_max ?? 5,
    redirectUrl: url ?? "",
    meta: {
      title: "",
      description: "",
      openGraph: "",
    },
    carouselList: info_carousel.length
      ? info_carousel.map((carouselItem: any) => ({
        _id: nanoid(),
        cText: carouselItem.text_color,
        coverImageL: carouselItem.cover_image_landscape,
        coverImageP: carouselItem.cover_image_portrait,
        redirectUrl: carouselItem.url,
        order: carouselItem.order,
        translations: locales?.available_languages.map((item: any) => {
          const translation = Array.isArray(carouselItem.translations) ? carouselItem.translations.filter((tr: any) => tr.language === item) : [];
          if (translation.length > 0) {
            return {
              tyText: translation[0].type_text,
              titText: translation[0].title_text,
              titImage: translation[0].title_image,
              subText: translation[0].subtitle_text,
              textCTA: translation[0].cta_text,
              language: item
            }
          }
          else {
            return {
              tyText: "",
              titText: "",
              titImage: "",
              subText: "",
              textCTA: "",
              language: item
            }
          }
        })
      }))
      : DEFAULT_CAROUSEL,
    artistList: featured_artist.length
      ? featured_artist.map((item: any) => {
        return {
          id: item._id,
          _id: nanoid(),
          order: item.order,
        };
      })
      : [],
  };

  let prevData = initialValues;

  function customizer(objValue: any, othValue: any, key: any) {
    if (key === "_id") {
      return true;
    }
  }

  const {
    handleBlur,
    handleChange,
    handleSubmit,
    values,
    setFieldValue,
    getFieldProps,
    errors,
    touched,
  } = useFormik({
    initialValues,
    validationSchema: Yup.object({
      meta: Yup.object({
        title: Yup.string(),
        description: Yup.string(),
        openGraph: Yup.string(),
      }),
      carouselShow: Yup.bool(),
      corouselMax: Yup.number(),
      featuredArtistShow: Yup.bool(),
      featuredArtistMax: Yup.number(),
      upcommingEventShow: Yup.bool(),
      upcommingEventMax: Yup.number(),
      venueShow: Yup.bool(),
      venueMax: Yup.number(),
      newEventShow: Yup.bool(),
      newEventMax: Yup.number(),
      recentViewShow: Yup.bool(),
      recentViewMax: Yup.number(),
      carouselList: Yup.array().of(
        Yup.object().shape({
          cText: Yup.string(),
          coverImageL: Yup.string().nullable(),
          coverImageP: Yup.string().nullable(),
          redirectUrl: Yup.string().nullable(),
          order: Yup.number().required(),
          translations: Yup.array().of(
            Yup.object().shape({
              tyText: Yup.string().nullable(),
              titText: Yup.string().nullable(),
              titImage: Yup.string().nullable(),
              subText: Yup.string().nullable(),
              textCTA: Yup.string().nullable(),
            })
          ),
        })
      ),
      artistList: Yup.array().of(
        Yup.object().shape({
          id: Yup.string().required("Select an artist to continue"),
          artistName: Yup.string(),
          order: Yup.number().required(),
        })
      ),
    }),

    onSubmit: async (values) => {
      if (isEqualWith(values, prevData, customizer)) return;
      dispatch(setLoading(true));
      try {
        const response = await updateHomeContent(values);
        if (response.data.success) {
          toast.success("Succesfully update the content");
          dispatch(setLoading(false));
          prevData = values;
          props.updateData()
        } else {
          throw Error(response.data.message);
        }
      } catch (error) {
        dispatch(setLoading(false));
        const message =
          (error instanceof Error && error.message) || "Something went wrong";
        toast.error(message);
      }
    },
  });

  const renderVisibilityToggle = (fieldName: keyof typeof values) => {
    return (
      <div className="">
        <div className="flex items-center gap-2">
          <InputSwitch
            tooltip="Show/hide in the front page"
            name={fieldName}
            checked={values[fieldName]}
            inputId="toggle-carousel"
            onBlur={handleBlur}
            onChange={handleChange}
          />{" "}
        </div>
      </div>
    );
  };

  return (
    <div>
      <h2 className="text-aria_dark text-[26px] font-bold">CMS Homepage</h2>
      <form className="flex gap-[53px]" onSubmit={handleSubmit}>
        <div className="flex-1">
          <div className="flex items-center justify-between border-b border-aria_dark mb-[13px]">
            <h3 className="h3 text-aria_dark text-[18px] py-[10px]">
              Info Carousel
            </h3>

            {renderVisibilityToggle("carouselShow")}
          </div>

          <Repeater
            max={values.corouselMax}
            headerType="image"
            titleKey="coverImageL"
            groupName="Slide"
            onOrderChange={(updated) => {
              setFieldValue("carouselList", updated);
            }}
            onDelete={(id) => {
              setFieldValue(
                "carouselList",
                values.carouselList
                  .filter((item: any) => item._id !== id)
                  .map((item: any, i: number) => ({ ...item, order: i + 1 }))
              );
            }}
            onDuplicate={(index) => {
              const duplicatedArray = [
                ...values.carouselList.slice(0, index + 1),
                { ...values.carouselList[index], _id: nanoid() },
                ...values.carouselList.slice(index + 1),
              ];
              setFieldValue(
                "carouselList",
                duplicatedArray.map((item: any, i: number) => ({
                  ...item,
                  order: i + 1,
                }))
              );
            }}
            onAddItem={() => {
              const newCarouselList = Array.isArray(values.carouselList) ? values.carouselList : [];

              setFieldValue("carouselList", [
                ...newCarouselList,
                {
                  ...DEFAULT_CAROUSEL,
                  _id: nanoid(),
                  order: newCarouselList.length + 1,
                },
              ])
            }
            }
            template={{
              render: (item, index) => {
                const carouselItem = values.carouselList[index];
                const localeFields = () => {
                  return carouselItem.translations &&
                    carouselItem.translations.length > 0 ? (
                    <>
                      <LanguageTab onChange={setCurrentLang} activeLanguage={currentLang} />
                      {carouselItem.translations.map(
                        (localeItem: any, _index: number) => {
                          const joinKey = (key: string) =>
                            `carouselList.${index}.translations.${_index}.${key}`;
                          return (
                            <div
                              key={_index}
                              className={localeItem.language === currentLang ? 'block' : 'hidden'}
                            >
                              <div className="mb-4">
                                <label className="block mb-1 text-sm">
                                  Type
                                </label>
                                <InputText
                                  className="w-full"
                                  {...getFieldProps(joinKey("tyText"))}
                                />
                              </div>

                              <div className="mb-4">
                                <label className="block mb-1 text-sm">
                                  Title
                                </label>
                                <InputText
                                  className="w-full"
                                  {...getFieldProps(joinKey("titText"))}
                                />
                              </div>

                              <div className="mb-4">
                                <label className="block mb-1 text-sm">
                                  Title image
                                </label>
                                <ImageUploadPreview
                                  id={
                                    "title-image" + _index + localeItem.language
                                  }
                                  value={item.translations[_index].titImage}
                                  setUploadImage={(file: any) =>
                                    setFieldValue(joinKey("titImage"), file)
                                  }
                                />
                              </div>
                              <div className="mb-4">
                                <label className="block mb-1 text-sm">
                                  Subtitle
                                </label>
                                <InputText
                                  className="w-full"
                                  {...getFieldProps(joinKey("subText"))}
                                />
                              </div>
                              <div className="mb-4">
                                <label className="block mb-1 text-sm">
                                  CTA text
                                </label>
                                <InputText
                                  className="w-full"
                                  {...getFieldProps(joinKey("textCTA"))}
                                />
                              </div>
                            </div>
                          );
                        }
                      )}
                    </>

                  ) : null;
                };
                return (
                  <div>
                    <div className="mb-4">
                      <label className="block mb-1 text-sm">CTA url</label>
                      <InputText
                        className="w-full"
                        {...getFieldProps(`carouselList.${index}.redirectUrl`)}
                      />
                    </div>

                    <div className="mb-4">
                      <label className="block mb-1 text-sm">Text color</label>
                      <Dropdown
                        options={DEFAULT_COLOR}
                        valueTemplate={(obj) => {
                          if (!obj) return "Select";
                          return (
                            <div className="flex items-center gap-2">
                              <span
                                style={{ background: obj.code }}
                                className="w-6 h-6 border rounded-md"
                              ></span>
                              {obj.label}
                            </div>
                          );
                        }}
                        itemTemplate={({ label, code }) => {
                          return (
                            <div className="flex items-center gap-2">
                              <span
                                style={{ background: code }}
                                className="w-6 h-6 border rounded-md"
                              ></span>
                              {label}
                            </div>
                          );
                        }}
                        className="w-full"
                        optionValue="code"
                        // optionLabel="label"
                        placeholder="Select"
                        value={values.carouselList[index].cText}
                        name={`carouselList.${index}.cText`}
                        onChange={(e) => {
                          setFieldValue(e.target.name, e.value);
                        }}
                      />
                    </div>

                    <div className="mb-4">
                      <h4 className="block mb-1 text-base font-semibold">
                        Cover image
                      </h4>
                      <div className="mb-4">
                        <label className="block mb-1 text-sm">Desktop Cover Image (1920 x 500px)</label>
                        <ImageUploadPreview
                          crop
                          cropWidth={1920}
                          cropHeight={500}
                          aspectRatio={3.84/1}
                          id={"cover-imagel" + index}
                          value={item.coverImageL}
                          setUploadImage={(file: any) =>
                            setFieldValue(
                              `carouselList.${index}.coverImageL`,
                              file
                            )
                          }
                        />
                      </div>
                      <div className="mb-4">
                        <label className="block mb-1 text-sm">Mobile Cover Image (640 x 440px)</label>
                        <ImageUploadPreview
                          crop
                          aspectRatio={1.4545 / 1}
                          cropWidth={640}
                          cropHeight={440}
                          id={"cover-imagep" + index}
                          value={item.coverImageP}
                          setUploadImage={(file: any) => {
                            setFieldValue(
                              `carouselList.${index}.coverImageP`,
                              file
                            );
                          }}
                        />
                      </div>
                    </div>

                    {localeFields()}
                  </div>
                );
              },
            }}
            data={values.carouselList}
          />

          <div className="flex items-center justify-between border-b border-aria_dark mb-[13px] mt-[40px]">
            <h3 className="h3 text-aria_dark text-[18px] py-[5px]">
              Featured artiste
            </h3>
            {renderVisibilityToggle("featuredArtistShow")}
          </div>
          <Repeater
            max={values.featuredArtistMax}
            data={values.artistList}
            titleKey={(record) => {
              if (!record || !artists) return;
              const artistObj = artists.find(
                (artist: any) => artist.artistId === record.id
              );
              return artistObj?.name;
            }}
            groupName="Artist"
            onOrderChange={(updated) => {
              setFieldValue("artistList", updated);
            }}
            // onDuplicate={(index) => {
            //   const duplicatedArray = [
            //     ...values.artistList.slice(0, index + 1),
            //     { ...values.artistList[index], _id: nanoid() },
            //     ...values.artistList.slice(index + 1),
            //   ];
            //   setFieldValue(
            //     "artistList",
            //     duplicatedArray.map((item: any, i: number) => ({
            //       ...item,
            //       order: i + 1,
            //     }))
            //   );
            // }}
            onDelete={(id) => {
              setFieldValue(
                "artistList",
                values.artistList.filter((item: any) => item._id !== id)
              );
            }}
            onAddItem={() => {
              setFieldValue("artistList", [
                ...values.artistList,
                { _id: nanoid(), order: values.artistList.length + 1 },
              ])

            }
            }
            template={{
              render: (item, index) => (
                <div>
                  <div className="mb-4">
                    <label className="block mb-1 text-sm">Artiste</label>
                    <Dropdown
                      className={`w-full ${touched.artistList &&
                        Array.isArray(touched.artistList) &&
                        touched.artistList[index] &&
                        typeof touched.artistList[index] === "object" &&
                        touched.artistList[index] &&
                        Array.isArray(errors.artistList) &&
                        errors.artistList[index] &&
                        typeof errors.artistList[index] === "object" &&
                        errors.artistList[index] &&
                        "p-invalid"
                        }`}
                      style={{
                        height: "50px",
                        alignItems: "center",
                      }}
                      options={artists}
                      optionLabel="artistName"
                      valueTemplate={(obj) => {
                        if (!obj) return "Select";
                        return (
                          <div className="flex items-center gap-2 bg-[#EDECF3] rounded-full p-[5px] pr-[8px]">
                            <img
                              src={obj.profilePicture_thumb}
                              alt=""
                              className="object-cover w-[24px] h-[23px] border rounded-full"
                            />
                            {obj.name}
                          </div>
                        );
                      }}
                      itemTemplate={({ name, profilePicture_thumb }) => {
                        return (
                          <div className="flex items-center gap-2">
                            <img
                              src={profilePicture_thumb}
                              alt=""
                              className="object-cover w-[24px] h-[23px] border rounded-full"
                            />
                            {name}
                          </div>
                        );
                      }}
                      optionValue="artistId"
                      placeholder="Select"
                      value={values.artistList[index].id}
                      name={`artistList.${index}.id`}
                      onChange={(e) => {
                        setFieldValue(
                          `artistList.${index}.artistName`,
                          artists.find((item: any) => item.artistId === e.value)
                            ?.artistName
                        );
                        setFieldValue(e.target.name, e.value);
                      }}
                    />
                    {touched.artistList &&
                      Array.isArray(touched.artistList) &&
                      touched.artistList[index] &&
                      errors.artistList &&
                      Array.isArray(errors.artistList) &&
                      errors.artistList[index] ? (
                      <p className="p-error">
                        {Object.values(
                          errors.artistList[index] as Record<string, string>
                        ).map((value) => value)}
                      </p>
                    ) : null}
                  </div>
                </div>
              ),
            }}
          />

          <div className="flex items-center justify-between border-b border-aria_dark mb-[13px] mt-[40px]">
            <h3 className="h3 text-aria_dark text-[18px] py-[10px]">
              Upcoming event
            </h3>

            {renderVisibilityToggle("upcommingEventShow")}
          </div>
          <div className="flex items-center justify-between border-b border-aria_dark mb-[13px]">
            <h3 className="h3 text-aria_dark text-[18px] py-[10px]">Venue</h3>

            {renderVisibilityToggle("venueShow")}
          </div>

          <div className="flex items-center justify-between border-b border-aria_dark mb-[13px]">
            <h3 className="h3 text-aria_dark text-[18px] py-[10px]">
              Recent View Event
            </h3>

            {renderVisibilityToggle("recentViewShow")}
          </div>
          <div className="flex items-center justify-between border-b border-aria_dark mb-[13px]">
            <h3 className="h3 text-aria_dark text-[18px] py-[10px]">
              New Event
            </h3>

            {renderVisibilityToggle("newEventShow")}
          </div>
        </div>

        <aside className="basis-[400px] grow-0 shrink-0  relative">
          <div className="sticky top-0 left-0 px-8 py-8 transition border border-aria_dark rounded-[10px]">
            <div>
              <div className="mb-4">
                <h4>Title</h4>
                <InputText
                  className="w-full"
                  {...getFieldProps("meta.title")}
                />
              </div>
              <div className="mb-4">
                <h4>Meta description</h4>
                <InputText
                  className="w-full"
                  {...getFieldProps("meta.description")}
                />
              </div>
            </div>
            <div className="flex justify-center gap-4 mt-4">
              <Button
                label="Save"
                size="large"
                type="submit"
                loading={loading}
              />
              <Button
                label="Unpublish"
                size="large"
                severity="secondary"
                outlined
              />
            </div>
          </div>
        </aside>
      </form>
    </div>
  );
}
