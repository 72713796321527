import React, {useState, useEffect} from "react";
import EventForm from "../components/EventForm";
import {useAppSelector} from "../../../hooks/useStore";
import {useNavigate} from "react-router-dom";
import LanguageTab from "../../../components/LanguageTab";
import FormCategoryTitle from "../components/FormCategoryTitle";

const CreateEventPage = () => {
    const { locales }: any = useAppSelector((state) => state.common);
    const [currentLang, setCurrentLang] = useState<string>(locales?.default_language ?? 'en')
    const navigate = useNavigate()

    useEffect(() => {
        setCurrentLang(locales?.default_language ?? 'en')
    }, [locales]);

    return (
        <>
            <div className='flex items-center mb-[8px] h-[24px] cursor-pointer' onClick={() => navigate('/events')}>
                <i className='pi pi-angle-left' />
                Back
            </div>

            <div className='flex items-center justify-between'>
                <div>
                    <h2 className='text-[20px] text-aria_dark'>Create Event</h2>
                </div>
                <LanguageTab onChange={setCurrentLang} activeLanguage={currentLang} />
            </div>

            <div>
                <FormCategoryTitle title='Basic Info' />
            </div>

            <EventForm
                currentLang={currentLang}
                onSubmitSuccess={() => {navigate('/events')}}
                resetDefaultLang={() => setCurrentLang(locales?.default_language)}
                currentData={null} />
        </>
    )
}

export default CreateEventPage;