import React, {useEffect, useState} from "react";
import EventForm from "../components/EventForm";
import {useAppSelector} from "../../../hooks/useStore";
import LanguageTab from "../../../components/LanguageTab";
import FormCategoryTitle from "../components/FormCategoryTitle";
import { useOutletContext } from "react-router-dom";

const EventDetailBasicInfo = () => {
    const { locales }: any = useAppSelector((state) => state.common);
    const { basicInfo }: any = useAppSelector((state) => state.event);
    const [currentLang, setCurrentLang] = useState<string>(locales?.default_language ?? 'en')
    const [currentData, setCurrentData] = useState({});

    const [publishError, setPublishError] = useOutletContext<any>();

    useEffect(() => {
        console.log('publishError', publishError)
    }, [publishError]);

    useEffect(() => {
        const venue: any = {};
        for(let key in basicInfo?.event_venue_id) {
            if(basicInfo?.event_venue_id.hasOwnProperty(key)) {
                venue["venue_" + key] = basicInfo?.event_venue_id[key];
            }
        }
        setCurrentData(basicInfo?.event_venue_id ? {...basicInfo, ...venue} : basicInfo);
    }, [basicInfo])

    return (
        <>
            <div className='flex justify-between mb-[20px] relative'>
                <div className='w-full mt-[20px]'>
                    <FormCategoryTitle title='Basic Info' />
                </div>
                <div className='flex items-center justify-between mb-6 absolute right-0'>
                    <LanguageTab onChange={setCurrentLang} activeLanguage={currentLang} />
                </div>
            </div>

            {Object.keys(currentData).length > 0 && <EventForm
                publishError={publishError}
                setPublishError={setPublishError}
                currentLang={currentLang}
                onSubmitSuccess={() => {
                }}
                resetDefaultLang={() => setCurrentLang(locales?.default_language)}
                currentData={currentData}/>}
        </>
    )
}

export default EventDetailBasicInfo;