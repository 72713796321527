import { ReactNode, useRef, useState } from "react";
import CloseIcon from "../icons/CloseIcon";

interface Props {
    children: (disabled: boolean) => ReactNode;
    typeChallange: string;
}

export default function TypingPrompt({ children, typeChallange }: Props) {
    const [disabled, setDisabled] = useState(true);
    const [expanded, setExpanded] = useState(false);
    const [confirmed, setConfirmed] = useState(false);
    const [typed, setTyped] = useState("");
    const promptRef = useRef<HTMLDivElement>(null);

    // useEffect(() => {
    //     const elem = promptRef.current?.getBoundingClientRect()
    //     if (expanded && elem) {
    //         document.querySelector('.p-dialog-content')?.scrollTo({
    //             top: elem.top
    //         })
    //     }
    // }, [expanded])

    return (
        <div className="">
            <div className="relative">
                {children(disabled && confirmed)}
                {!confirmed ? (
                    <div className="">
                        <div
                            className="absolute top-0 left-0 w-full h-full bg-[#1c089c70] rounded-[5px] overflow-hidden"
                            style={{
                                backgroundColor: "#DFDBE5",
                                backgroundImage: `url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%239C92AC' fill-opacity='0.4' fill-rule='evenodd'%3E%3Cpath d='M0 40L40 0H20L0 20M40 40V20L20 40'/%3E%3C/g%3E%3C/svg%3E")`,
                            }}
                        />
                        <button
                            onClick={() => {
                                setExpanded(true);
                                setTimeout(() => {
                                    const elem = promptRef.current;
                                    elem?.scrollIntoView({
                                        behavior: "smooth",
                                    });
                                }, 20);
                            }}
                            type="button"
                            className="absolute flex items-center gap-1 font-bold -translate-x-1/2 -translate-y-1/2 text-aria_dark top-1/2 left-1/2"
                        >
                            <i className="pi pi-lock" />
                            {!expanded ? "Click to edit" : null}
                        </button>
                    </div>
                ) : null}
            </div>

            {expanded ? (
                <div
                    className="max-w-lg p-4 mx-auto mt-1 border rounded-[5px] shadow-lg typing-prompt bg-[#EDECF3] border-[#6650F2]"
                    ref={promptRef}
                >
                    <div className="flex justify-end">
                        <span
                            className="cursor-pointer"
                            onClick={() => {
                                setDisabled(true);
                                setExpanded(false);
                                setTyped("");
                            }}
                        >
                            <CloseIcon />
                        </span>
                    </div>

                    <div className="mb-2">
                        <p className="text-sm font-medium text-[#211F2E] text-center ">
                            This action is dangerous, type the challange bellow
                            to proceed
                        </p>

                        <i className="block py-2 my-2 text-sm text-center border-dashed select-none bg-slate-50 border-y">
                            {typeChallange}
                        </i>
                    </div>
                    <div className="flex">
                        <input
                            className="flex-1 px-4 border border-r-0 outline-none rounded-l-md h-[45px]"
                            type="text"
                            value={typed}
                            onChange={(e) => {
                                setTyped(e.target.value);
                                if (e.target.value === typeChallange) {
                                    setDisabled(false);
                                } else {
                                    setDisabled(true);
                                }
                            }}
                        />
                        <button
                            className={`${
                                disabled
                                    ? "bg-[#6650F2] opacity-40"
                                    : "bg-[#6650F2] border-[#6650F2] hover:bg-[#6650F2] hover:border-[#6650F2]"
                            } h-[45px] px-4 text-white  transition-all rounded-r-md duration-500 font-semibold`}
                            disabled={disabled}
                            title="Type the challenge to activate this button"
                            onClick={() => {
                                if (typed.trim() === typeChallange) {
                                    setExpanded(false);
                                    setDisabled(false);
                                    setConfirmed(true);
                                }
                            }}
                        >
                            I understand
                        </button>
                    </div>
                </div>
            ) : null}
        </div>
    );
}
