import { post, get, put, remove } from '../AxiosReqeust'
import { serialize } from "object-to-formdata";

const eventListing = async (query: any) => {
    return await get(`/event/listing?${query}`);
}

const getEventBasicInfo = async (id: string) => {
    return await get(`/event/${id}/basic-info`);
}

const eventInnerDetails = async (query: any) => {
    return await get(`/event/${query}/details`);
}

const eventInnerDetailsUpdate = async (id: any, body: any) => {
    const serializedFormData = serialize(body, {
        indices: true,
        dotsForObjectNotation: true,
        allowEmptyArrays: true,
    });

    return await post(`/event/${id}/details`, serializedFormData, {
        "Content-Type": "multipart/form-data",
    });
}

const eventGalleryData = async (id: any) => {
    return await get(`/event/${id}/gallery`);
}

const createGalleryFolder = async (id: any, body: any) => {
    return await post(`/event/${id}/gallery/folder`, body);
}

const updateImageGalleryByFolder = async (id: any, folderId: any, itemId: any, body: any) => {
    const serializedFormData = serialize(body, {
        indices: true,
        dotsForObjectNotation: true,
        allowEmptyArrays: true,
    });

    return await post(`/event/${id}/gallery/folder/${folderId}/image-item/${itemId}`, serializedFormData, {
        "Content-Type": "multipart/form-data",
    });
}

const createImageGalleryByFolder = async (id: any, folderId: any, body: any) => {
    const serializedFormData = serialize(body, {
        indices: true,
        dotsForObjectNotation: true,
        allowEmptyArrays: true,
    });

    return await post(`/event/${id}/gallery/folder/${folderId}/image-item`, serializedFormData, {
        "Content-Type": "multipart/form-data",
    });
}

const createVideoGalleryByFolder = async (id: any, folderId: any, body: any) => {
    const serializedFormData = serialize(body, {
        indices: true,
        dotsForObjectNotation: true,
        allowEmptyArrays: true,
    });

    return await post(`/event/${id}/gallery/folder/${folderId}/upload-video`, serializedFormData, {
        "Content-Type": "multipart/form-data",
    });
}

const updateVideoGalleryByFolder = async (id: any, folderId: any, itemId: any, body: any) => {
    const serializedFormData = serialize(body, {
        indices: true,
        dotsForObjectNotation: true,
        allowEmptyArrays: true,
    });

    return await post(`/event/${id}/gallery/folder/${folderId}/upload-video/${itemId}`, serializedFormData, {
        "Content-Type": "multipart/form-data",
    });
}

const uploadManyImages = async (id: any, folderId: any, body: any) => {
    const formData = new FormData();

    for (let i = 0; i < body.galleryImages.length; i++) {
        formData.append(`galleryImages`, body.galleryImages[i]);
    }
    return await post(`/event/${id}/gallery/folder/${folderId}/image-items`, formData, {
        'Content-Type': 'multipart/form-data'
    });
}

const setItemIsFeatured = async (id: any, folderId: any, itemId: any, body: any) => {
    return await post(`/event/${id}/gallery/folder/${folderId}/item/${itemId}/featured`, body);
}

const purchaseCustomContentData = async (id: any, body: any) => {
    return await post(`/event/${id}/checkout-ticket-custom-content`, body);
}
const getPurchaseCustomContentData = async (id: any) => {
    return await get(`/event/${id}/checkout-ticket-custom-content`);
}

const policyCustomContent = async (id: any, body: any) => {
    return await post(`/event/${id}/policy-custom-content`, body);
}
const getPolicyCustomContent = async (id: any) => {
    return await get(`/event/${id}/policy-custom-content`);
}


const getGalleryItemType = async (id: any) => {
    return await get(`/event/${id}/gallery/item-type`);
}

const deleteGalleryItem = async (id: any, folderId: any, itemId: any) => {
    return await remove(`/event/${id}/gallery/folder/${folderId}/item/${itemId}`);
}

const deleteGalleryFolder = async (id: any, folderId: any) => {
    return await remove(`/event/${id}/gallery/folder/${folderId}`);
}

const getEventsStatusOptions = async () => {
    return await get('/event/status')
}

const setEventStatus = async (id: any, status: any) => {
    return await post(`/event/${id}/status`, status)
}

const getEventDropdown = async () => {
    return await get(`/event/dropdown`)
}


export {
    eventListing,
    getEventBasicInfo,
    eventInnerDetails,
    eventInnerDetailsUpdate,
    eventGalleryData,
    createGalleryFolder,
    updateImageGalleryByFolder,
    createImageGalleryByFolder,
    createVideoGalleryByFolder,
    updateVideoGalleryByFolder,
    uploadManyImages,
    setItemIsFeatured,
    getGalleryItemType,
    deleteGalleryItem,
    deleteGalleryFolder,
    purchaseCustomContentData,
    getPurchaseCustomContentData,
    getEventsStatusOptions,
    setEventStatus,
    getEventDropdown,
    policyCustomContent,
    getPolicyCustomContent
}