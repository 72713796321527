import React, {useEffect, useState} from 'react';
import {Dropdown} from "primereact/dropdown";
import {useAppSelector} from "../../hooks/useStore";

const CountryDropdown = (props:any) => {
    const { country }:any = useAppSelector((state) => state.common);
    const [countries, setCountries] = useState([]);

    useEffect(() => {
        setCountries(country.map((item:any) => {
            return  {name: item.country, code: item.code, value: item._id}
        }));
    }, [country]);

    return <>
        <Dropdown
            inputId="country"
            name="country"
            value={props?.value}
            options={countries}
            optionLabel="name"
            placeholder="Select"
            onChange={(e:any)=>{
                props?.onChange(e)
            }}
            filter
            className={`w-full ${
                props?.errors && props?.touched && "p-invalid"
            }`}
        />
        {props?.errors && props?.touched ? (
            <small className="block mt-2 p-error">
                {props?.errors?.toString()}
            </small>
        ) : null}
    </>

}

export default CountryDropdown;