import React, { useEffect, useState } from "react";
import DetailsForm from "../components/DetailsForm";
import { get } from "../../../utils/AxiosReqeust";
import { useAppSelector } from "../../../hooks/useStore";

const EventDetailDetails = () => {

    const { basicInfo } = useAppSelector((state) => state.event);
    const [data, setData]: any = useState(null)

    const fetchData = async () => {
        const [
            artistDropdwon,
            innerData
        ] = await Promise.all([
            get(`/artist/dropdown`),
            get(`/event/${basicInfo?._id}/details`),
        ]);
        const artistList = artistDropdwon?.data?.data.map((item: any) => ({
            label: item.name,
            profilePicture_thumb: item.profilePicture_thumb,
            value: item.artistId,
        }));

        setData({ ...innerData?.data?.data, artistsList: artistList })
    }

    useEffect(() => {
        fetchData();
    }, [])

    return <>
        <DetailsForm id={basicInfo?._id} data={data} fetchData={fetchData} />
    </>
}

export default EventDetailDetails;