import { NavLink, useMatches } from "react-router-dom";
import ArrowDownIcon from "../../../components/icons/ArrowDownIcon";
import CalendarIcon from "../../../components/icons/Calendar";
import LinkIcon from "../../../components/icons/LinkIcon";
import PinIcon from "../../../components/icons/Pin";
import { useAppSelector, useAppDispatch } from "../../../hooks/useStore";
import moment from "moment";
import { Dropdown } from "primereact/dropdown";
import React, { useEffect, useState } from "react";
import {
  getEventsStatusOptions,
  setEventStatus
} from "../../../utils/apiRequestList/eventAPI";
import { toast } from "react-toastify";
import { setBasicInfo } from "../../../redux/slices/eventSlice";

const menu = [
  // {
  //   name: "Dashboard",
  //   path: "/dashboard",
  // },
  {
    name: "Basic info",
    path: "",
  },
  {
    name: "Details",
    path: "/details",
  },
  {
    name: "Gallery",
    path: "/gallery",
  },
  {
    name: "Tickets",
    path: "/tickets",
  },
  {
    name: "Addons",
    path: "/addons",
  },
  {
    name: "Promo codes",
    path: "/promocodes",
  },
  {
    name: "Checkout Custom Content",
    path: "/checkout-custom-content",
  },
  {
    name: "Policy Custom Content",
    path: "/policy-custom-content",
  },
];

export default function EventSidebar(props:any) {
  const matches = useMatches();
  const prefix = matches[5]?.pathname;
  const { basicInfo } = useAppSelector((state) => state.event);
  const startDate = moment(basicInfo.start_date).format("D MMM YYYY");
  const endDate = moment(basicInfo.end_date).format("D MMM YYYY");
  const { locales }: any = useAppSelector((state) => state.common);
  const currentLanguage = locales?.default_language ?? "en";
  const [statusOptions, setStatusOptions] = useState([]);
  const [selectedEventStatus, setSelectedEventStatus] = useState<any>(basicInfo?.status);
  const dispatch = useAppDispatch();

  const optionEventsStatus = async () => {
    try {
      const response = await getEventsStatusOptions();
      setStatusOptions(response?.data?.data)
    } catch (error) {
      console.error('Error:', error);
    }
  }

  const handleEventStatus = async (value: any) => {
    try {
      const response = await setEventStatus(basicInfo?._id, { status: value })
      if (response?.data?.success) {
        setSelectedEventStatus(value);
        dispatch(setBasicInfo({
          ...basicInfo,
          status: value
        }))
        toast.success(value == 0 ? 'Draft' : value == 1 ? "Publish successful!" : value == 2 ? "Unpublish successful!" : 'Successful!');
      }
    } catch (e: any) {
      console.error(e.message);
      props?.setPublishError(true)
    }
  }

  useEffect(() => {
    optionEventsStatus();
  }, []);

  return (
    <div className="h-full bg-white ">
      <div className="px-[20px] pt-[20px]">
        <Dropdown
          name="event-status"
          value={selectedEventStatus}
          options={statusOptions}
          optionLabel="label"
          onChange={(e: any) => {
            handleEventStatus(e.value)
          }}
          dropdownIcon={<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" className="p-icon p-dropdown-trigger-icon p-clickable" aria-hidden="true" data-pc-section="dropdownicon"><path d="M7.01744 10.398C6.91269 10.3985 6.8089 10.378 6.71215 10.3379C6.61541 10.2977 6.52766 10.2386 6.45405 10.1641L1.13907 4.84913C1.03306 4.69404 0.985221 4.5065 1.00399 4.31958C1.02276 4.13266 1.10693 3.95838 1.24166 3.82747C1.37639 3.69655 1.55301 3.61742 1.74039 3.60402C1.92777 3.59062 2.11386 3.64382 2.26584 3.75424L7.01744 8.47394L11.769 3.75424C11.9189 3.65709 12.097 3.61306 12.2748 3.62921C12.4527 3.64535 12.6199 3.72073 12.7498 3.84328C12.8797 3.96582 12.9647 4.12842 12.9912 4.30502C13.0177 4.48162 12.9841 4.662 12.8958 4.81724L7.58083 10.1322C7.50996 10.2125 7.42344 10.2775 7.32656 10.3232C7.22968 10.3689 7.12449 10.3944 7.01744 10.398Z" fill="white"></path></svg>}
          className={`event-status-dropdown w-full mb-[16px] text-[white]`}
          style={{ backgroundColor: selectedEventStatus == 0 ? '#9B8BFF' : selectedEventStatus === 1 ? '#6650F2' : selectedEventStatus === 2 ? 'grey' : 'initial' }}
        />
        <h3 className="mb-2 text-[18px] font-semibold text-aria_dark">
          {
            basicInfo.translations.find(
              (translation) => translation?.language === currentLanguage
            )?.title
          }
        </h3>
        <a
            href={`${process.env.REACT_APP_FE_DOMAIN}/events/${basicInfo.slug}`}
            target="_blank"
            className="inline-flex items-center gap-2 mb-5 text-aria_dark"
        >
          <span className="underline">View your content</span> <LinkIcon/>
        </a>
        <div className="mb-10">
          <p className="flex items-center gap-2 mb-3">
            <CalendarIcon/> {startDate} - {endDate}
          </p>
          <div className="flex items-start gap-2">
            <div className="translate-y-[6px] shrink-0">
              <PinIcon/>
            </div>
            {basicInfo?.event_venue_id?.address}{" "}
            {basicInfo?.event_venue_id?.country}{" "}
            {basicInfo?.event_venue_id?.postalCode}
          </div>
        </div>
      </div>

      {menu.map((menuItem, i) => {
        const isActive =
          matches[6]?.pathname ===
          prefix + (menuItem.path ? menuItem.path : "/");
        return (
          <NavLink
            key={i}
            to={prefix + menuItem.path}
            className={`h-[65px] px-8 border-b border-aria_dark flex items-center ${i === 0 ? "border-t" : ""
              } ${isActive ? "bg-aria_dark text-white" : "bg-white"}`}
          >
            {menuItem.name}
          </NavLink>
        );
      })}
    </div>
  );
}
