import { post, get, put, remove } from '../AxiosReqeust'

const createArtist = async (params: any) => {
    const formData = new FormData();
    Object.entries(params).forEach(([key, value]: [string, any]) => {
        if (typeof value === 'object' && Object.keys(value).includes('objectURL')) {
            formData.append(key.toString(), value, value?.name)
        }
        else {
            formData.append(key.toString(), value)
        }
    })
    return await post(`/artist/create`, formData, {
        'Content-Type': 'multipart/form-data'
    });
}

const artistListing = async (query?: any) => {
    return await get(`/artist/listing?${query ?? ''}`);
}

const getArtistData = async (query: any) => {
    return await get(`/artist/${query}`);
}

const artistDropdown = async () => {
    return await get(`/artist/dropdown`);
}

const editArtist = async (query: any, params: any) => {
    const formData = new FormData();
    Object.entries(params).forEach(([key, value]: [string, any]) => {
        if (typeof value === 'object' && Object.keys(value).includes('objectURL')) {
            formData.append(key.toString(), value, value?.name)
        }
        else {
            formData.append(key.toString(), value)
        }
    })
    return await put(`/artist/edit/${query}`, formData, {
        'Content-Type': 'multipart/form-data'
    });
}

const deleteArtist = async (query: any) => {
    return await remove(`/artist/delete/${query}`);
}

const getArtistGenres = async () => {
    return await get(`/artist/genre-list`)
}

export {
    createArtist,
    artistListing,
    getArtistData,
    artistDropdown,
    editArtist,
    deleteArtist,
    getArtistGenres
};