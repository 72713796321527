import React from 'react'

const EventDetailPublish = () => {
    return (
        <>
            <h2 className="text-3xl font-semibold text-aria_dark mb-4">Publish</h2>
        </>
    )
}

export default EventDetailPublish
