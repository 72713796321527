import { get, put } from "../AxiosReqeust";
import { serialize } from "object-to-formdata";

const getHomeContent = async () => {
  return await get("/homepage-cms");
};

const updateHomeContent = async (body: any) => {
  const serializedFormData = serialize(body, {
    indices: true,
    dotsForObjectNotation: true,
    allowEmptyArrays: true,
  });

  return await put(`/homepage-cms`, serializedFormData, {
    "Content-Type": "multipart/form-data",
  });
};

export { getHomeContent, updateHomeContent };
