import React, { useEffect } from 'react';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import axios from "axios";
import { useAppDispatch } from "../../hooks/useStore";
import { setLoading, setLocales } from "../../redux/slices/commonSlice";
import { logout } from "../../redux/slices/authSlice";
import { useNavigate } from "react-router-dom";
import { get } from '../../utils/AxiosReqeust';

const ExceptionHandler = () => {
    useEffect(() => {
        const locales = async () => {
          try {
            const response: any = await get(`/locales`);
            if (response.data.success && response.data.data) {
              dispatch(setLocales(response.data.data));
            }
          }
          catch (error) {
            console.log(error);
          }
        }
        locales();
    }, []);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const onLogout = () => {
        dispatch(logout())
        navigate('/login')
    }
    axios.interceptors.response.use(response => {
        return response;
    },
        error => {
            dispatch(setLoading(false))
            if (error.response) {
                if (error.response?.status === 401) {
                    confirmDialog({
                        closable: false,
                        message: error.response?.data?.message,
                        header: "Logged Out",
                        contentClassName: 'min-w-[400px] w-auto h-auto max-w-[200px]',
                        icon: 'pi pi-info-circle',
                        acceptLabel: 'Ok',
                        rejectClassName: '!hidden',
                        accept: () => {
                            onLogout()
                        },
                        pt: {
                            footer: {
                                style: { backgroundColor: "#edecf3" },
                            },
                        },
                    })
                }
                else if (error.response?.status === 403) {
                    confirmDialog({
                        closable: false,
                        message: error.response?.data?.message,
                        header: "Unauthorized access",
                        contentClassName: 'min-w-[400px] w-auto h-auto max-w-[200px]',
                        icon: 'pi pi-info-circle',
                        acceptLabel: 'Ok',
                        rejectClassName: '!hidden',
                        accept: () => {
                            navigate(-1)
                        },
                        pt: {
                            footer: {
                                style: { backgroundColor: "#edecf3" },
                            },
                        },
                    })
                } else if (typeof error.response?.data?.message === 'string' || typeof error.response?.data?.data === 'string' || typeof error.response?.data?.data === 'object') {
                    confirmDialog({
                        closable: false,
                        message: error.response?.data?.message,
                        contentClassName: 'min-w-[400px] w-auto h-auto max-w-[200px]',
                        header: "Failed",
                        icon: 'pi pi-info-circle',
                        acceptLabel: 'Ok',
                        rejectClassName: '!hidden',
                        pt: {
                            footer: {
                                style: { backgroundColor: "#edecf3" },
                            },
                        },
                    })
                }
            } else {
                if (error?.message === 'canceled' || error?.name === 'CanceledError') {
                    console.error('Pending request cancelled.')
                } else {
                    confirmDialog({
                        closable: true,
                        contentClassName: 'min-w-[400px] w-auto h-auto max-w-[200px]',
                        message: 'Something went wrong',
                        header: "Oops!",
                        icon: 'pi pi-info-circle',
                        acceptLabel: 'Ok',
                        rejectClassName: '!hidden',
                        pt: {
                            footer: {
                                style: { backgroundColor: "#edecf3" },
                            },
                        },
                    })
                }
            }

            return Promise.reject(error);
        });

    return <>
        <ConfirmDialog dismissableMask={true} />
    </>
}

export default ExceptionHandler