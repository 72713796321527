import {get, post, remove} from "../AxiosReqeust";

const getBoxofficeUserListing = async (query?: any) => {
    return await get(`/boxoffice/user/list?${query ?? ''}`);
};

const createBoxOfficeUser = async (data:any) => {
    return await post('/boxoffice/user/create', data)
}

const editBoxOfficeUser = async (id:any, data:any) => {
    return await post(`/boxoffice/user/${id}`, data)
}

const removeBoxOfficeUser = async (id:string) => {
    return await remove(`/boxoffice/user/${id}`)
}

export {
    getBoxofficeUserListing,
    createBoxOfficeUser,
    editBoxOfficeUser,
    removeBoxOfficeUser
};
