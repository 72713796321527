import { FormikProps, FormikValues,Field } from "formik";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { useState, useContext } from "react";
import * as Yup from "yup";

import FormWrapper from "../../components/form/FormWrapper";
import { OrganisationUser } from "../../redux/types/user";
import {
    createOrganisationUser,
    updateOrganisationUser,
} from "../../utils/apiRequestList/organisationAPI";
import countryList from "../../utils/countryList";
import useAuth from "../../hooks/useAuth";
import TypingPrompt from "../../components/form/TypingPrompt";
import FooterAction from "../../components/form/FooterAction";
import DialCode from "../../components/form/DialCode";
import CountryDropdown from "../../components/form/CountryDropdown";
import isEqual from "lodash-es/isEqual";
import { setLoading } from "../../redux/slices/commonSlice";
import { useAppSelector, useAppDispatch } from "../../hooks/useStore";

type Props = {
    currentData: OrganisationUser | null;
    setVisible: (arg0: boolean) => void;
    setUpdateListing: (arg0: boolean) => void;
    organisations: Array<{ code: string; name: string }>;
    countriesList: Array<{name: string; code: string; value: string}>;
};

const statusOption = [
    {
        name: "Active",
        value: 1,
    },
    {
        name: "Inactive",
        value: 0,
    },
];

const OrganisationUsersForm = ({
       currentData,
       setUpdateListing,
       setVisible,
       organisations,
       countriesList,
    }: Props) => {
    const { isAdmin } = useAuth();
    const [togglePassword, setTogglePassword] = useState(false);
    const dispatch = useAppDispatch();
    const { loading }: any = useAppSelector((state) => state.common);
    const initialValues = {
        first_name: currentData?.first_name || "",
        last_name: currentData?.last_name || "",
        email_address: currentData?.email_address || "",
        country: currentData?.country || "",
        contact_no: currentData?.contact_no || "",
        calling_code: currentData?.calling_code || "",
        password: currentData?.password || "",
        status: currentData?.status,
        organisation_id:
            {
                name: isAdmin ? currentData?.organisation_id?.name : "",
                code: isAdmin ? currentData?.organisation_id?._id : "",
            } || "",
    };

    let prevData = initialValues

    const formSchema = Yup.object({
        first_name: Yup.string().required("First name is required"),
        last_name: Yup.string().required("Last name is required"),
        email_address: Yup.string()
            .email("Invalid email format")
            .required("Email is required"),
        country: Yup.string(),
        contact_no: Yup.string(),
        calling_code: Yup.string(),
        password: currentData
            ? Yup.string()
            : Yup.string().required("Password is required"),
        status: Yup.number(),
    });

    if (isAdmin) {
        formSchema.shape({
            organisation_id: Yup.object().required("Organisation is required"),
        });
    }

    const handleSubmit = async (values: any) => {
        if (isEqual(values, prevData)) return
        dispatch(setLoading(true))
        if (!values.password && currentData) {
            delete values.password;
        }
        const transformedValues = {
            ...values,
            organisation_id: values.organisation_id.code,
            calling_code: values.calling_code,
            country: values.country,
            email_address: values.email_address.toLowerCase(),
        };
        try {
            const response = !!currentData
                ? await updateOrganisationUser(
                    currentData?._id,
                    transformedValues
                )
                : await createOrganisationUser(transformedValues);
            if (response.status) {
                dispatch(setLoading(true))
                // showToast("success", "Success", "Success Adding Data", 3000);
                setUpdateListing(true);
                setTimeout(() => {
                    setVisible(false);
                }, 1000);
                prevData = values;
            }
        } catch (error) {
            dispatch(setLoading(true))
            setVisible(false);
            // showToast("error", "Error", "Failed adding user", 3000);
            setTimeout(() => {
                setVisible(false);
            }, 1000);
        }
    };

    const formInputs = ({
        errors,
        values,
        touched,
        handleBlur,
        handleChange,
        setFieldValue,
    }: FormikProps<FormikValues>) => {
        return (
            <>
                {isAdmin && (
                    <div className="mb-6">
                        <label htmlFor="organisation_id" className="">
                            Select an organisation
                        </label>

                        <Dropdown
                            inputId="organisation_id"
                            name="organisation_id"
                            value={values.organisation_id}
                            options={organisations}
                            filter
                            optionLabel="name"
                            placeholder="Select"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            className={`w-full ${
                                errors.organisation_id &&
                                touched.organisation_id &&
                                "p-invalid"
                            }`}
                        />
                        {errors.organisation_id && touched.organisation_id ? (
                            <small className="block mt-2 p-error">
                                {errors?.organisation_id?.toString()}
                            </small>
                        ) : null}
                    </div>
                )}
                <div className="flex mb-6 gap-[15px]">
                    <div className="flex-1">
                        <label htmlFor="first_name" className="">
                            First Name
                        </label>
                        <InputText
                            id="first_name"
                            type="text"
                            className={`w-full ${
                                errors.name && touched.name && "p-invalid"
                            }`}
                            name="first_name"
                            value={values.first_name}
                            onBlur={handleBlur}
                            onChange={handleChange}
                        />
                        {errors.first_name && touched.first_name ? (
                            <small className="block mt-2 p-error">
                                {errors?.first_name?.toString()}
                            </small>
                        ) : null}
                    </div>
                    <div className="flex-1">
                        <label htmlFor="last_name" className="">
                            Last Name
                        </label>
                        <InputText
                            id="last_name"
                            type="text"
                            className={`w-full ${
                                errors.last_name &&
                                touched.last_name &&
                                "p-invalid"
                            }`}
                            name="last_name"
                            value={values.last_name}
                            onBlur={handleBlur}
                            onChange={handleChange}
                        />
                        {errors.last_name && touched.last_name ? (
                            <small className="block mt-2 p-error">
                                {errors?.last_name?.toString()}
                            </small>
                        ) : null}
                    </div>
                </div>

                <div className="flex mb-6 gap-[7px]">
                    <div className="flex-1">
                        <label htmlFor="email_address" className="">
                            Email
                        </label>
                        <InputText
                            id="email_address"
                            type="text"
                            disabled={!!currentData?._id}
                            autoComplete="off"
                            aria-autocomplete="none"
                            className={`w-full ${
                                errors.email_address &&
                                touched.email_address &&
                                "p-invalid"
                            }`}
                            name="email_address"
                            value={values.email_address}
                            onBlur={handleBlur}
                            onChange={handleChange}
                        />
                        {errors.email_address && touched.email_address ? (
                            <small className="block mt-2 p-error">
                                {errors?.email_address?.toString()}
                            </small>
                        ) : null}
                    </div>

                    <div className="flex-1">
                        <label htmlFor="country" className="">
                            Country
                        </label>
                        <CountryDropdown
                            inputId="country"
                            name="country"
                            value={values.country}
                            optionLabel="name"
                            placeholder="Select"
                            onChange={(e:any)=>{
                                setFieldValue('country', e.value)
                            }}
                            errors={errors.country}
                            touched={touched.country}
                        />
                    </div>
                </div>

                <div className="flex mb-6 gap-[7px]">
                    <div className="w-[calc(50%-3.5px)] grow-0">
                        <label htmlFor="contact_no" className="">
                            Contact number
                        </label>
                        <div className="flex gap-[5px]">
                            <DialCode
                                inputId="calling_code"
                                value={values.calling_code}
                                onChange={(e:any)=>{
                                    setFieldValue('calling_code', e.value)
                                }}
                                name="calling_code"
                                optionLabel="name"
                                placeholder="e.g +65"
                                errors={errors.calling_code}
                                touched={touched.calling_code}
                            />
                            <InputText
                                id="contact_no"
                                type="tel"
                                className={`w-full ${
                                    errors.contact_no &&
                                    touched.contact_no &&
                                    "p-invalid"
                                }`}
                                name="contact_no"
                                value={values.contact_no}
                                onBlur={handleBlur}
                                onChange={handleChange}
                            />
                            {errors.contact_no && touched.contact_no ? (
                                <small className="block mt-2 p-error">
                                    {errors?.contact_no?.toString()}
                                </small>
                            ) : null}
                        </div>
                    </div>

                    <div className="flex-1">
                        {!currentData && (
                            <div className="mb-6">
                                <label htmlFor="password" className="">
                                    Password
                                </label>
                                <Password
                                    id="password"
                                    disabled={!!currentData}
                                    type="text"
                                    className={`w-full ${
                                        errors.password &&
                                        touched.password &&
                                        "p-invalid"
                                    }`}
                                    name="password"
                                    value={values.password}
                                    autoComplete="off"
                                    aria-autocomplete="none"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    toggleMask
                                    inputClassName="w-full"
                                />
                                {errors.password && touched.password ? (
                                    <small className="block mt-2 p-error">
                                        {errors?.password?.toString()}
                                    </small>
                                ) : null}
                            </div>
                        )}
                    </div>
                </div>

                {!!currentData?._id ? (
                    <div className="mb-6">
                        <label htmlFor="status" className="">
                            Status
                        </label>
                        <TypingPrompt typeChallange={currentData.email_address}>
                            {(disabled) => (
                                <Dropdown
                                    inputId="status"
                                    name="status"
                                    value={values.status}
                                    options={statusOption}
                                    optionLabel="name"
                                    disabled={disabled}
                                    className={`w-full ${
                                        errors.status &&
                                        touched.status &&
                                        "p-invalid"
                                    }`}
                                    onChange={(e) => {
                                        setFieldValue("status", e.value);
                                    }}
                                />
                            )}
                        </TypingPrompt>
                    </div>
                ) : null}
            </>
        );
    };

    return (
        <>
            <FormWrapper
                noValidate
                initialValues={initialValues || {}}
                onSubmit={handleSubmit}
                validationSchema={formSchema}
                formInputs={formInputs}
                autoComplete="off"
                aria-autocomplete="none"
                submitButton={
                    <FooterAction>
                        <Button
                            className="justify-center w-full font-bold"
                            size="large"
                            type="submit"
                            loading={loading}
                        >
                            {!!currentData?._id ? "Update" : "Create"} user
                        </Button>
                    </FooterAction>
                }
                className="gap-2"
            />
        </>
    );
};

export default OrganisationUsersForm;
