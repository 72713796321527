import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { Dialog } from 'primereact/dialog';
import ArtistForm from "./formArtist";
import TableWrapper from "../../components/listing/TableWrapper";
import { Column } from "primereact/column";
import { artistListing, getArtistData, deleteArtist, getArtistGenres } from "../../utils/apiRequestList/artistAPI"
import TagStatus from "../../components/TagStatus";
import { useDebounce } from "use-debounce";
import { useAppDispatch } from "../../hooks/useStore";
import { setLoading } from "../../redux/slices/commonSlice";
import useAuth from "../../hooks/useAuth";
import { useLoaderData } from "react-router-dom";
import ImageTemplate from "../../components/listing/ImageTemplate";
import starIcon from "../../assets/images/star.png"
import deleteConfirmation from "../../utils/deleteConfirmation";
import SearchBox from "../../components/SearchBox";
import { get } from "../../utils/AxiosReqeust";

interface Translation {
    artistName: string;
    artistDes: string;
    artistBio: string;
    language: string;
}

interface ApiResponseType {
    artistId: string;
    coverImage: string;
    profilePicture: string;
    status: number;
    createdAt: string;
    updatedAt: string;
    isFeatured: boolean,
    translations: Translation[];
}

const Artist = () => {
    const { isOrganisation } = useAuth();
    const [visible, setVisible] = useState(false);
    const [formHeader, setFormHeader] = useState('Create Artist');
    const [data, setData]: any = useState(null);
    const [searchByName, setSearchByName] = useState('')
    const [params, setParams] = useState({
        sortBy: 'createdAt',
        sortOrder: 'desc'
    })
    const [records, setRecords] = useState(10);
    const [page, setPage] = useState(1);
    const [updateListing, setUpdateListing] = useState(false);
    const [currentData, setCurrentData] = useState({});
    const [debouncedSearchByName] = useDebounce(searchByName, 500);
    const dispatch = useAppDispatch();
    // const loaderData = useLoaderData();
    const [genreOptions, setGenreOptions] = useState([]);

    const actionColumnBody = ({ isFeatured, artistId }: ApiResponseType) => {
        return <div className="flex align-middle">
            <div className="mr-2">
                <Button className="!rounded-3xl !p-3" onClick={() => editArtistData(artistId)}><i className='pi pi-pencil' /></Button>
            </div>
            {!isFeatured &&
                <div>
                    <Button className="!rounded-3xl !p-3" onClick={() => deleteArtistData(artistId)}><i className='pi pi-trash' /></Button>
                </div>}
        </div>
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const getArtistListing = async (getparams: any) => {
        dispatch(setLoading(true));
        let sortName = '';
        if (debouncedSearchByName) {
            sortName += `&searchByName=${debouncedSearchByName}`
        }
        const response = await artistListing((`&page=${page}&records=${records}&sortOrder=${getparams?.sortOrder}&sortBy=${getparams?.sortBy}${sortName}`));
        if (response?.data?.success) {
            setData(response?.data?.data)
        }
        setUpdateListing(false);
        dispatch(setLoading(false));
    }

    const profilePictureTemplate = ({ profilePicture }: ApiResponseType) => {
        return ImageTemplate(profilePicture);
    };

    const editArtistData = async (id: any) => {
        const response = await getArtistData(id);
        const data = response?.data?.data.genreList.map((item: any) => item._id.toString())
        const massageTranslations = response?.data?.data.translations.map((item: any) => ({
            artistName: item.name,
            artistDes: item.description,
            artistBio: item.biography,
            language: item.language,
        }))
        if (response.status) {
            setFormHeader("Edit Artist")
            setCurrentData({ ...response?.data?.data, ...{ translations: massageTranslations }, ...{ genreList: data } })
            setVisible(true)
        }
    }

    const deleteArtistData = async (id: any) => {
        deleteConfirmation(async () => {
            const response = await deleteArtist(id);
            if (response.status) {
                // showToast('info', 'Confirmed', 'Success Delete Data', 3000);
                setUpdateListing(true)
            }
        })
    }

    const createArtist = () => {
        setVisible(true)
        setCurrentData('')
    }

    const statusTemplate = ({ status }: ApiResponseType) => {
        return <div className="text-center"><TagStatus status={status} /></div>
    }

    const artistNameTemplate = ({ isFeatured, translations }: ApiResponseType) => {
        return <div className='overflow-hidden text-ellipsis line-clamp-2 flex items-center'>
            <span>{translations[0].artistName}</span>
            <span>{isFeatured ? <img className='w-[20px] ml-1' src={starIcon} alt="" /> : ''}</span>
        </div>
    }

    const setPageLimit = (limit: number) => {
        setRecords(limit);
        setPage(1);
    }

    const updateData = () => {
        if (!data) {
            setData(data);
        } else {
            getArtistListing(params)
        }
    }

    useEffect(() => {
        updateData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, params, records, debouncedSearchByName])

    useEffect(() => {
        if (updateListing) {
            updateData()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateListing])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        async function fetchData() {
            dispatch(setLoading(true))
            try {
                const [
                    artistList,
                    genre
                ] = await Promise.all([
                    get(`/artist/listing?${`records=10&sortBy=createdAt&sortOrder=desc` ?? ''}`),
                    get(`/artist/genre-list`),
                ]);

                setData(artistList?.data?.data)
                const data = genre?.data?.data.map((item: any) => ({
                    name: item.translations[0].name,
                    value: item._id,
                }));
                setGenreOptions(data)

                dispatch(setLoading(false))
            } catch (error) {

                dispatch(setLoading(false))
                console.error('Error:', error);
            }
        }
        fetchData();
    }, [])

    return <>
        <Dialog header={formHeader} visible={visible} onHide={() => setVisible(false)}
            style={{ width: '50vw' }} breakpoints={{ '960px': '75vw', '641px': '100vw' }}
            contentClassName="!pb-0"
        >
            <ArtistForm setVisible={setVisible} setUpdateListing={setUpdateListing} currentData={currentData} genreOptions={genreOptions} />
        </Dialog>
        <h2 className="mb-[15px] h2 !pb-0 text-aria_dark">Manage Artistes</h2>
        <div className="flex justify-between align-middle mb-5">
            {
                !isOrganisation &&
                <Button
                    label="Create Artist"
                    icon="pi pi-plus"
                    size="large"
                    className="!text-[14px] !px-12"
                    onClick={() => createArtist()}
                />
            }
            <SearchBox
                placeholder="Search By Artist Name"
                onChange={(e) => {
                    setPage(1)
                    setSearchByName(e.target.value)
                }}
            />
        </div>
        <div>
            {data ? (
                <TableWrapper
                    data={data}
                    loading={false}
                    setRecords={setPageLimit}
                    scrollable
                    scrollHeight='70vh'
                    onSort={(e) => {
                        setPage(1)
                        setParams({ ...params, sortBy: e.sortField, sortOrder: params.sortOrder === 'asc' ? 'dsc' : 'asc' })
                    }}
                    nextPage={() => {
                        if (!data.hasNextPage && (page < data.totalPages)) return
                        setPage((current: any) => (current + 1))
                    }}
                    prevPage={() => {
                        if (!data.hasPrevPage) return
                        setPage((current: any) => (current - 1))
                    }}
                >
                    <Column field="profilePicture" header="Profile Picture" style={{ width: '121px' }} body={profilePictureTemplate}></Column>
                    <Column field="artistName" header="Name" sortable body={artistNameTemplate}></Column>
                    {!isOrganisation && <Column field="status" header="Status" sortable body={statusTemplate} style={{ width: '136px' }}></Column>}
                    {!isOrganisation && <Column header="Action" body={actionColumnBody} style={{ width: '150px' }}></Column>}
                </TableWrapper>
            ) : null}
        </div>
    </>
}

export default Artist;