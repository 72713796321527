import { Navigate, Outlet, useLocation, useParams } from "react-router-dom";
import { navigationMenuContent } from "../utils/navigationMenuContent";
import useAuth from "../hooks/useAuth";

const RoleAccessRoute = () => {
  const location = useLocation();
  const params = useParams();
  const { userAccess } = useAuth();

  const matchedRoute = navigationMenuContent.find(
    (route) => {
      let finalUrl = route.url
      const keys = Object.keys(params)

      keys.forEach(key => {
        finalUrl = finalUrl.replace(`:${key}`, params[key] ?? '')
      })

      return finalUrl === location.pathname
    }
  );
  const isAllowed = userAccess && matchedRoute?.access.includes(userAccess); // 'admin' 'organisation'

  return !isAllowed ? <Navigate to="/" /> : <Outlet />;
};

export default RoleAccessRoute;
