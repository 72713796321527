import TextEditor from "../../../components/TextEditor";
import LanguageTab from "../../../components/LanguageTab";
import FormWrapper from "../../../components/form/FormWrapper";
import { Button } from "primereact/button";
import { useState, useEffect } from "react";
import { useAppSelector } from "../../../hooks/useStore";
import * as Yup from 'yup';
import { FormikProps, FormikValues } from "formik";
import { policyCustomContent } from "../../../utils/apiRequestList/eventAPI";
import { isEqualWith } from "lodash-es";
import { setLoading } from "../../../redux/slices/commonSlice";
import { useAppDispatch } from "../../../hooks/useStore";
import { toast } from "react-toastify";

const PolicyCustomContentForm = (props: any) => {
    const { locales, loading }: any = useAppSelector((state) => state.common);
    const [currentLang, setCurrentLang] = useState(locales?.default_language ?? 'en');
    const [initialValues, setInitialValues] = useState<any>(null);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (props?.currentData) {
            setInitialValues({
                translations: locales?.available_languages?.map((item: any) => {
                    const translation = Array.isArray(props?.currentData?.translations) ? props?.currentData?.translations.filter((tr: any) => tr.language === item) : [];
                    if (translation.length > 0) {
                        return translation[0]
                    }
                    else {
                        return {
                            language: item,
                            event_policy: '',
                            purchase_policy: ''
                        }
                    }
                }),
            })
        }
    }, [props.currentData])


    let prevData = initialValues;

    function customize(objValue: any, othValue: any, key: any) {
        if (key === "_id") {
            return true;
        }
    }

    const handleSubmit = async (value: any) => {
        if (isEqualWith(value, prevData, customize)) return;
        dispatch(setLoading(true));
        const response = await policyCustomContent(props?.basicInfo?._id, value)

        if (response.data.success) {
            toast.success('Success Update Custom Content');
            prevData = value;
            props.fetchData()
        } else {
            toast.error('Failed Update Custom Content');
        }

        dispatch(setLoading(false));
    }

    const CustomContentSchema = Yup.object({
        translations: Yup.array().of(
            Yup.object().shape({
                // event_policy: Yup.string(),
                // purchase_policy: Yup.string()
            })
        ),
    });

    const formInputs = ({ errors, values, touched, handleBlur, handleChange, setFieldValue }: FormikProps<FormikValues>) => {

        return <>
            <LanguageTab onChange={setCurrentLang} activeLanguage={currentLang} />
            {values.translations && values.translations.length > 0 ?
                values.translations.map((user: any, index: number) => (
                    <>
                        <div key={user.language} className={user.language === currentLang ? 'block' : 'hidden'}>
                            <div className="mb-6">
                                <label htmlFor={`translations.${index}.purchase_policy`} className="">Purchase Policy</label>
                                <TextEditor
                                    id={`translations.${index}.purchase_policy`}
                                    name={`translations.${index}.purchase_policy`}
                                    normalEditor
                                    value={values.translations && values.translations[index] && values.translations[index].purchase_policy}
                                    onTextChange={(e: any) => {
                                        setFieldValue(`translations.${index}.purchase_policy`, e.htmlValue);
                                    }} />
                            </div>
                            <div className="mb-6">
                                <label htmlFor={`translations.${index}.event_policy`} className="">Event Policy</label>
                                <TextEditor
                                    id={`translations.${index}.event_policy`}
                                    name={`translations.${index}.event_policy`}
                                    normalEditor
                                    value={values.translations && values.translations[index] && values.translations[index].event_policy}
                                    onTextChange={(e: any) => {
                                        setFieldValue(`translations.${index}.event_policy`, e.htmlValue);
                                    }} />
                            </div>
                        </div></>
                ))
                : null
            }
        </>
    }

    return <>
        <h2 className="text-3xl font-semibold text-aria_dark mb-4">Policy Custom Content</h2>
        {initialValues && <FormWrapper
            noValidate
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={CustomContentSchema}
            formInputs={formInputs}
            submitButton={
                <Button loading={loading} className="justify-center w-full" size="large" type="submit">
                    Submit
                </Button>
            }
            className="gap-2" />}
    </>
}

export default PolicyCustomContentForm;