import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LanguageTab from '../../../components/LanguageTab';
import { useAppSelector } from '../../../hooks/useStore';
import AddonForm from '../components/AddonForm';

const CreateAddon = () => {
    const { selectedAddon } = useAppSelector((state) => state.event);
    const { locales }: any = useAppSelector((state) => state.common);
    const [currentLang, setCurrentLang] = useState<string>(locales?.default_language ?? 'en')
    const navigate = useNavigate()

    useEffect(() => {
        setCurrentLang(locales?.default_language ?? 'en')
    }, [locales]);
    

    return (
        <>
            <div
                className='flex items-center mb-[8px] h-[24px] cursor-pointer'
                onClick={() => navigate(`/events/detail/addons`)}
            >
                <i className='pi pi-angle-left' />
                Back
            </div>
            <div className='flex items-center justify-between'>
                <div>
                    <h2 className='text-[20px] text-aria_dark'>{selectedAddon?.id ? 'Edit ' : 'Create '} Addon</h2>
                </div>
                <LanguageTab onChange={setCurrentLang} activeLanguage={currentLang} />
            </div>
            <AddonForm
                currentLang={currentLang}
                onSubmitSuccess={() => navigate(`/events/detail/addons`)}
                resetDefaultLang={() => setCurrentLang(locales?.default_language)}
                currentData={null} />
        </>
    )
}

export default CreateAddon
