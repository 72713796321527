import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { useEffect, useState } from "react";
import TableWrapper from "../../components/listing/TableWrapper";
import { PaginatedResult } from "../../redux/types/api";
import { OrganisationDropdownRes } from "../../redux/types/organisation";
import { OrganisationUser, User } from "../../redux/types/user";
import {
    deleteOrganisationUser,
    getOrganisationDropdown,
    getOrganisationUserListing,
} from "../../utils/apiRequestList/organisationAPI";
import objectToQueryString from "../../utils/objectToQueryString";
import OrganisationUsersForm from "./OrganisationUsersForm";
import { useAppDispatch, useAppSelector } from "../../hooks/useStore";
import { setLoading } from "../../redux/slices/commonSlice";
import { useDebounce } from "use-debounce";
import useAuth from "../../hooks/useAuth";
import TagStatus from "../../components/TagStatus";
import deleteConfirmation from "../../utils/deleteConfirmation";
import SearchBox from "../../components/SearchBox";

const OrganisationUsers = () => {
    const { isAdmin } = useAuth();
    const { country }: any = useAppSelector((state) => state.common);
    const [visible, setVisible] = useState(false);
    const [formHeader, setFormHeader] = useState("Create User");
    const [data, setData] = useState<PaginatedResult<OrganisationUser> | null>(
        null
    );
    const [organisations, setOrganisations] =
        useState<Array<{ code: string, name: string }>>([]);
    const [records, setRecords] = useState(10);
    const [page, setPage] = useState(1);
    const [searchByEmail, setSearchByEmail] = useState("");
    const [params, setParams] = useState<Record<string, string | number>>({});
    const [debouncedSearchByEmail] = useDebounce(searchByEmail, 500);
    const [updateListing, setUpdateListing] = useState(false);
    const [currentData, setCurrentData] = useState<OrganisationUser | null>(
        null
    );
    // const loaderData = useLoaderData() as PaginatedResult<OrganisationUser>;
    const dispatch = useAppDispatch();
    const [countriesList, setCountriesList] = useState([]);

    useEffect(() => {
        if (isAdmin) {
            fetchOrgList();
        }
        async function fetchData() {
            try {
                dispatch(setLoading(true))
                const organisation =
                    await getOrganisationUserListing(
                        `pageSize=10`
                    );
                setData(organisation?.data?.data)
                dispatch(setLoading(false))
            } catch (error) {
                dispatch(setLoading(false))
                console.error('Error:', error);
            }
        }
        fetchData();
    }, []);

    useEffect(() => {
        if (country) {
            setCountriesList(country.map((item: any) => {
                return { name: item.country, code: item.code, value: item._id }
            }));
        }
    }, [country]);

    const updateData = () => {
        if (!data) {
            setData(data);
        } else {
            fetchOrgUserList({
                ...params,
                page,
                email_address: searchByEmail,
                pageSize: records,
            });
        }
    }

    useEffect(() => {
        updateData()
    }, [page, records, debouncedSearchByEmail, params]);

    useEffect(() => {
        if (updateListing) {
            updateData()
        }
    }, [updateListing]);

    const actionsBody = ({ _id }: OrganisationUser) => {
        return (
            <div className="flex gap-2">
                <Button
                    className="mr-2 !rounded-full w-8 h-[32px] p-0 flex items-center justify-center"
                    onClick={() => openModelEdit(_id)}
                    size="small"
                >
                    <i className="pi pi-pencil" />
                </Button>
                <Button onClick={() => removeUser(_id)} size="small" className="mr-2 !rounded-full w-8 h-[32px] p-0 flex items-center justify-center">
                    <i className="pi pi-trash" />
                </Button>
            </div>
        );
    };

    const statusBody = ({ status }: User) => {
        return <TagStatus status={status} />;
    };

    const fetchOrgUserList = async (queryObj?: object) => {
        const queryString = queryObj ? objectToQueryString(queryObj) : "";
        dispatch(setLoading(true));
        try {
            const response = await getOrganisationUserListing(queryString);

            if (response.status) {
                setData(response?.data?.data);
            }
            setUpdateListing(false);
        } catch (error) {
            console.error(error);
        }
        dispatch(setLoading(false));
    };

    const fetchOrgList = async (queryObj?: object) => {
        try {
            const response = await getOrganisationDropdown();
            if (response.status) {
                setOrganisations(response?.data?.data.map((org: OrganisationDropdownRes) => ({
                    name: org.name,
                    code: org._id,
                })));
            }
        } catch (error) {
            console.error(error);
        }
    };

    const openModelEdit = async (id: string) => {
        if (data && data.docs.length) {
            setFormHeader("Edit User");
            const row = data.docs.find((item) => item._id === id) || null;
            setCurrentData(row);
            setVisible(true);
        }
    };

    const removeUser = async (id: string): Promise<void> => {
        deleteConfirmation(async () => {
            const response = await deleteOrganisationUser(id);
            if (
                response !== undefined &&
                response !== null &&
                "status" in response
            ) {
                // showToast('success', 'Success', 'Successfully delete data', 3000);
                setUpdateListing(true);
            }
        })
    };

    const openModalCreate = () => {
        setFormHeader("Create User");
        setVisible(true);
        setCurrentData(null);
    };

    const handleApplyFilter = (values: object) => {
        fetchOrgUserList(values);
    };

    const handleResetFilter = () => {
        fetchOrgUserList();
    };

    return (
        <>
            <Dialog
                header={formHeader}
                visible={visible}
                onHide={() => setVisible(false)}
                style={{ width: "50vw" }}
                breakpoints={{ "960px": "75vw", "641px": "100vw" }}
                contentClassName="!pb-4"
            >
                <OrganisationUsersForm
                    setVisible={setVisible}
                    setUpdateListing={setUpdateListing}
                    currentData={currentData}
                    countriesList={countriesList}
                    organisations={organisations}
                />
            </Dialog>

            <h2 className="mb-[15px] h2 !pb-0 text-aria_dark">Manage organisation's users</h2>

            <div className="flex justify-between align-middle">
                <Button
                    label="Add a member"
                    icon="pi pi-plus"
                    size="large"
                    className="!text-[14px] !px-12"
                    onClick={() => openModalCreate()}
                />

                <SearchBox
                    placeholder="Search email"
                    onChange={(e) => {
                        setPage(1);
                        setSearchByEmail(e.target.value);
                    }}
                />
            </div>
            <div className="mt-5">
                {data ? (
                    <TableWrapper
                        data={data}
                        loading={false}
                        records={records}
                        setRecords={setRecords}
                        scrollable
                        scrollHeight="70vh"
                        onSort={(e) => {
                            setPage(1);
                            setParams({
                                ...params,
                                sortBy: e.sortField,
                                sortOrder:
                                    params?.sortOrder === "asc" ? "dsc" : "asc",
                            });
                        }}
                        nextPage={() => {
                            if (!data?.hasNextPage) return;
                            setPage((current) => current + 1);
                        }}
                        prevPage={() => {
                            if (!data?.hasPrevPage) return;
                            setPage((current) => current - 1);
                        }}
                    >
                        <Column
                            field="first_name"
                            header="First name"
                            sortable
                        ></Column>
                        <Column
                            field="last_name"
                            header="Last name"
                            sortable
                        ></Column>
                        <Column
                            field="email_address"
                            header="Email"
                            sortable
                        ></Column>
                        <Column
                            field="contact_no"
                            header="Contact number"
                        ></Column>
                        <Column
                            field="country"
                            header="Country"
                            body={(country) => {
                                country = countriesList.find((item: any) => item.value === country.country)
                                return country?.name
                            }}
                        ></Column>
                        <Column
                            field="organisation_id"
                            header="Organisation"
                            body={({ organisation_id }) => organisation_id?.name}
                        ></Column>
                        <Column
                            field="status"
                            sortable
                            align={"center"}
                            header="Status"
                            body={statusBody}
                        ></Column>
                        {isAdmin && (
                            <Column
                                header="Actions"
                                style={{ width: "10%" }}
                                body={actionsBody}
                            ></Column>
                        )}
                    </TableWrapper>
                ) : null}
            </div>
        </>
    );
};

export default OrganisationUsers;
