import TextEditor from "../../../components/TextEditor";
import LanguageTab from "../../../components/LanguageTab";
import FormWrapper from "../../../components/form/FormWrapper";
import { Button } from "primereact/button";
import { useState, useEffect } from "react";
import { useAppSelector } from "../../../hooks/useStore";
import * as Yup from 'yup';
import { FormikProps, FormikValues } from "formik";
import { purchaseCustomContentData } from "../../../utils/apiRequestList/eventAPI";
import { isEqualWith } from "lodash-es";
import { setLoading } from "../../../redux/slices/commonSlice";
import { useAppDispatch } from "../../../hooks/useStore";
import { toast } from "react-toastify";

const CheckoutCustomContentForm = (props: any) => {
    const { locales, loading }: any = useAppSelector((state) => state.common);
    const [currentLang, setCurrentLang] = useState(locales?.default_language ?? 'en');
    const [initialValues, setInitialValues] = useState<any>(null);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (props?.currentData) {
            setInitialValues({
                translations: locales?.available_languages?.map((item: any) => {
                    const translation = Array.isArray(props?.currentData?.translations) ? props?.currentData?.translations.filter((tr: any) => tr.language === item) : [];
                    if (translation.length > 0) {
                        return translation[0]
                    }
                    else {
                        return {
                            language: item,
                            description: ''
                        }
                    }
                }),
            })
        }
    }, [props.currentData])


    let prevData = initialValues;

    function customizer(objValue: any, othValue: any, key: any) {
        if (key === "_id") {
            return true;
        }
    }

    const handleSubmit = async (value: any) => {
        if (isEqualWith(value, prevData, customizer)) return;
        dispatch(setLoading(true));
        const response = await purchaseCustomContentData(props?.basicInfo?._id, value)

        if (response.data.success) {
            toast.success('Success Update Custom Content');
            prevData = value;
            props.fetchData()
        } else {
            toast.error('Failed Update Custom Content');
        }

        dispatch(setLoading(false));
    }

    const CustomContentSchema = Yup.object({
        translations: Yup.array().of(
            Yup.object().shape({
                description: Yup.string().when("language", (language, schema) => {
                    if (language[0] === 'en') {
                        return schema.required("Required first description");
                    }
                    return schema;
                })
            })
        ),
    });

    const formInputs = ({ errors, values, touched, handleBlur, handleChange, setFieldValue }: FormikProps<FormikValues>) => {

        const addingText = (text: string) => {
            if (!values.translations) return;
            const currentIndex = values.translations.findIndex((translation: any) => translation.language === currentLang);

            if (currentIndex !== -1) {
                const updatedTranslations = [...values.translations];
                updatedTranslations[currentIndex].description += text;
                setFieldValue('translations', updatedTranslations);
            }
        }

        return <>
            <LanguageTab onChange={setCurrentLang} activeLanguage={currentLang} />
            {props?.basicInfo?.translations && props?.basicInfo?.translations.length > 0 ?
                props?.basicInfo?.translations.map((item: any, index: number) => {
                    return <>
                        <div key={item.language} className={item.language === currentLang ? 'block' : 'hidden'}>
                            <div className="mb-6">
                                <label htmlFor={`translations.${index}.description`} className="">Generate Text</label>
                                {item.title.length > 0 && <span className="border cursor-pointer rounded-full w-auto border-aria_dark px-4 py-2 bg-white" onClick={() => addingText(item.title)}>{item.title}</span>}
                            </div>
                        </div>
                    </>
                }) : null
            }
            {values.translations && values.translations.length > 0 ?
                values.translations.map((user: any, index: number) => (
                    <>
                        <div key={user.language} className={user.language === currentLang ? 'block' : 'hidden'}>
                            <div className="mb-6">
                                <label htmlFor={`translations.${index}.description`} className="">Description</label>
                                <TextEditor
                                    id={`translations.${index}.description`}
                                    name={`translations.${index}.description`}
                                    value={values.translations && values.translations[index] && values.translations[index].description}
                                    onTextChange={(e: any) => {
                                        setFieldValue(`translations.${index}.description`, e.htmlValue);
                                    }} />
                            </div>
                        </div></>
                ))
                : null
            }
        </>
    }

    return <>
        <h2 className="text-3xl font-semibold text-aria_dark mb-4">Checkout Custom Content</h2>
        {initialValues && <FormWrapper
            noValidate
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={CustomContentSchema}
            formInputs={formInputs}
            submitButton={
                <Button loading={loading} className="justify-center w-full" size="large" type="submit">
                    Submit
                </Button>
            }
            className="gap-2" />}
    </>
}

export default CheckoutCustomContentForm;