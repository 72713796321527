import Login from "../screens/Login";
import ForgetPassword from "../screens/ForgetPassword";
import ResetPassword from "../screens/ResetPassword";
import UnauthenticatedOnly from "./features/UnauthenticatedOnly";

const PublicRoutes = [
    {
        element: <UnauthenticatedOnly/>,
        children: [
            {
                path: '/login',
                element: <Login/>,
            },
            {
                path: '/forgot-password',
                element: <ForgetPassword/>,
            },
            {
                path: '/reset-password',
                element: <ResetPassword />,
            }
        ]
    }
];

export default PublicRoutes