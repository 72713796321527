export default function LinkIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={12}
            height={12}
            viewBox="0 0 12 12"
            fill="none"
        >
            <path
                d="M11 1L11.5 1L11.5 0.5L11 0.5L11 1ZM4.76409 6.5288C4.56883 6.72406 4.56883 7.04064 4.76409 7.23591C4.95936 7.43117 5.27594 7.43117 5.4712 7.23591L4.76409 6.5288ZM6.29412 1.5L11 1.5L11 0.5L6.29412 0.5L6.29412 1.5ZM10.5 1L10.5 5.70588L11.5 5.70588L11.5 1L10.5 1ZM10.6464 0.646447L4.76409 6.5288L5.4712 7.23591L11.3536 1.35355L10.6464 0.646447Z"
                fill="#6650F2"
            />
            <path
                d="M3.94115 1.58838V1.58838C2.31679 1.58838 0.999978 2.90519 0.999978 4.52956L0.999978 6.29426C0.999978 7.88569 0.999978 8.6814 1.29421 9.29584C1.5897 9.9129 2.08722 10.4104 2.70428 10.7059C3.31872 11.0001 4.11443 11.0001 5.70586 11.0001L7.47057 11.0001C9.09493 11.0001 10.4117 9.68333 10.4117 8.05897V8.05897"
                stroke="#6650F2"
                strokeLinecap="round"
            />
        </svg>
    );
}
