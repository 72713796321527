import React, {useEffect, useState} from "react";
import {Formik, Form, ErrorMessage, FormikProps, FormikValues} from "formik";
import * as Yup from 'yup';

import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";

import AuthWrapper from "../../components/AuthWrapper";
import {forgetPassword} from "../../utils/apiRequestList/userAPI";
import {forgetPasswordOrganisation} from "../../utils/apiRequestList/organisationAPI";
import FormWrapper from "../../components/form/FormWrapper";

const ForgetPasswordSchema = Yup.object({
    email: Yup.string().email('Invalid email').required('Required'),
});

const ForgetPassword = () => {

    const [email, setEmail] = useState('');
    const [statusResult, setStatusResult] = useState(false);
    const [title, setTitle] = useState("Forget Password");
    const [userType, setUserType] = useState('');

    const initialValues = { email: ''};

    let handleSubmit = async (values:any) => {
        try {
            if (userType === "individual") {
                await forgetPassword(values.email);
            } else if (userType === "organisation") {
                await forgetPasswordOrganisation(values.email);
            }
            setEmail(values.email);
            setStatusResult(true);
            setTitle("Check your email")
        } catch (error) {
            console.error('Password reset failed:', error);
        }
    };

    useEffect(() => {
        const getQueryParameters = () => {
            const searchParams:any = new URLSearchParams(window.location.search);
            const params:any = {};
            for (const [key, value] of searchParams) {
                params[key] = value;
            }
            return params;
        };
        const queryParams = getQueryParameters();
        setUserType(queryParams.type);
    }, []);

    const formInputs = ({errors, touched, handleBlur, handleChange}: FormikProps<FormikValues>) => (
        <>
            <div className="mb-6">
                <label htmlFor="email" className=" ">
                    Email
                </label>
                <InputText type="email" className={`w-full ${(errors.email && touched.email) && "p-invalid"}`} name="email"
                           onBlur={handleBlur}
                           onChange={handleChange}
                />
                {/*<ErrorMessage name="email" component="div" />*/}
                {errors.email && touched.email ? (
                    // <Message severity="error" text={errors?.email?.toString()}></Message>
                    <p className="p-error">{errors?.email?.toString()}</p>
                ) : null}
            </div>
        </>
    )

    return (
        <AuthWrapper title={title}>
            {!statusResult
                ?
                <FormWrapper
                    noValidate
                    initialValues={initialValues}
                    validationSchema={ForgetPasswordSchema}
                    onSubmit={handleSubmit}
                    formInputs={formInputs}
                    submitButton={
                        <div className="mb-6">
                            <Button className="justify-center w-full" size="large" type="submit">
                                Send
                            </Button>
                        </div>
                    }
                    className="gap-2"
                />
                :
                <div>
                    <div className="mb-12 text-zinc-600">We sent an email to {email}. Please follow the instruction sent in the email to reset your password.</div>
                </div>
            }
        </AuthWrapper>
    );
};

export default ForgetPassword;