import React, {useState} from "react";
import { Outlet } from "react-router-dom";
import EventSidebar from "../components/EventSidebar";

const EventDetailLayout = () => {
    const [publishError, setPublishError] = useState<any>(false)

    return (
        <div className='flex gap-x-10'>
            <div className='w-[235px] grow-0 shrink-0 h-[calc(100vh-49px)] pt-[20px] sticky left-0 top-0 overflow-y-auto'>
                <EventSidebar setPublishError={setPublishError}/>
            </div>
            <div className='py-[26px] flex-1'>
                <Outlet context={[publishError, setPublishError]}/>
            </div>
        </div>
    )
};

export default EventDetailLayout;