import { Editor } from "primereact/editor";

//warning on quil node_module /node_modules/quill/dist/quill.js
//the part need to update _this.domNode.addEventListener('DOMNodeInserted', function () {}); 'DOMNodeInserted' change to 'MutationObserver'
const TextEditor = (props: any) => {
    const renderHeader = () => {
        return (
            <span className="ql-formats">
                { !props.normalEditor && <select className="ql-font" aria-label="Font"></select>}
                { !props.normalEditor && <select className="ql-size" aria-label="Size"></select>}
                <button className="ql-bold" aria-label="Bold"></button>
                <button className="ql-italic" aria-label="Italic"></button>
                <button className="ql-underline" aria-label="Underline"></button>
                <button className="ql-link" aria-label="Link"></button>
                { !props.normalEditor && <select className="ql-color" aria-label="Color"></select> }
                { !props.normalEditor &&  <select className="ql-background" aria-label="Background"></select> }
                <button className="ql-list" value="ordered" aria-label="Ordered List"></button>
                <button className="ql-list" value="bullet" aria-label="Unordered List"></button>
            </span>
        );
    };
    const header = renderHeader()

    //onPaste only texts excluding formatting
    const handlePaste = (e:any) => {
        e.preventDefault();
        const text = e.clipboardData.getData('text/plain');
        document.execCommand('inserttext', false, text);
    }

    return (
        <Editor
            id={props.id}
            name={props.name}
            value={props.value}
            onTextChange={props.onTextChange}
            style={{ height: '100px' }}
            headerTemplate={header}
            onPaste={handlePaste}
        />
    );
};

export default TextEditor;