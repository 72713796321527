import React, {useState, useRef, useEffect, useContext} from "react";

import {FormikProps, FormikValues} from "formik";
import * as Yup from 'yup';
import { FileUpload } from 'primereact/fileupload';
import { Avatar } from 'primereact/avatar';
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import { Password } from "primereact/password";

import useAuth from "../../hooks/useAuth";
import FormWrapper from "../../components/form/FormWrapper";
import {editProfile, updatePassword} from "../../utils/apiRequestList/userAPI";
import {useAppDispatch, useAppSelector} from "../../hooks/useStore";
import {editOrganisationUser, updatePasswordOrganisationUser} from "../../utils/apiRequestList/organisationAPI";
import {setProfile} from "../../redux/slices/authSlice";
import { User } from "../../redux/types/user";
import profilePic from "../../assets/images/avatar.jpg"
import {toast} from "react-toastify";

const editProfileSchema = Yup.object({
    mobileNumber: Yup.string().required('Required'),
    profilePicture: Yup.string(),
});

const changePasswordSchema = Yup.object({
    existingPassword: Yup.string().required('Required'),
    newPassword: Yup.string().required('Required'),
    confirmPassword: Yup.string().required('Required').oneOf([Yup.ref('newPassword')], 'Passwords must match')
});

const Profile = () => {
    const { userInfo, isAdmin, isOrganisation, token } = useAuth();
    const dispatch = useAppDispatch();
    const [fname, setFname] = useState('');
    const [lname, setLname] = useState('');
    const [contactNo, setContactNo] = useState('');
    const [emailAdd, setEmailAdd] = useState('');
    const [organisationName, setOrganisationName] = useState('');
    const [avatarImg, setAvatarImg] = useState(profilePic);

    useEffect(() => {
        //@ts-ignore
        setAvatarImg(userInfo?.profile_picture_url ?  userInfo?.profile_picture_url : profilePic)
        setContactNo(userInfo?.contact_no ? userInfo.contact_no : "");
        setFname(userInfo?.first_name ? userInfo.first_name : "");
        setLname(userInfo?.last_name ? userInfo.last_name : "");
        setEmailAdd(userInfo?.email_address ? userInfo.email_address : "");
        // @ts-ignore
        setOrganisationName(userInfo?.organisation?.name ? userInfo?.organisation?.name : "");
    }, [userInfo] );

    const initialValues={
        mobileNumber: userInfo?.contact_no ,
        profilePicture: userInfo?.profile_picture_url,
    }
    const initialValuesPassword={
        existingPassword: '',
        newPassword: '',
        confirmPassword: '',
    }

    const formInputs = ({errors, touched, handleBlur, handleChange, setFieldValue, values}: FormikProps<FormikValues>) => (
        <>
            <div className="flex flex-col items-center">
                <Avatar image={avatarImg} shape="circle" style={profilePicStyle}  className="user-avatar mb-6"/>
                <div className="flex card justify-content-center">
                    <FileUpload mode="basic" name="profilePicture"
                                url={`${process.env.REACT_APP_API_URL}/upload-image`}
                                accept="image/*" maxFileSize={5000000}
                                onUpload={async(e: any) => {
                                    const file = e.files[0];
                                    if (file) {
                                        const reader = new FileReader();
                                        let blob = await fetch(file.objectURL).then((r) => r.blob()); //blob:url
                                        reader.readAsDataURL(blob);
                                        reader.onloadend = function () {
                                            const base64data = reader.result;
                                            setAvatarImg(base64data)
                                        };
                                    }
                                    setFieldValue('profilePicture', file);
                                }}
                                auto chooseLabel="Upload"
                    />
                </div>
            </div>
            <div className=''>
                {organisationName &&
                <div className="mb-6">
                    <label htmlFor="organisationName" className=" ">
                        Organisation
                    </label>
                    <InputText disabled type="text" className='w-full' name="organisationName"
                               onBlur={handleBlur}
                               onChange={handleChange}
                               placeholder={organisationName}
                    />
                </div>
                }
                <div className="mb-6">
                    <label htmlFor="firstName" className=" ">
                        First name
                    </label>
                    <InputText disabled type="text" className='w-full' name="firstName"
                               onBlur={handleBlur}
                               onChange={handleChange}
                               placeholder={fname}
                    />
                </div>
                <div className="mb-6">
                    <label htmlFor="lastName" className=" ">
                        Last name
                    </label>
                    <InputText disabled type="text" className='w-full' name="lastName"
                               onBlur={handleBlur}
                               onChange={handleChange}
                               placeholder={lname}
                    />
                </div>
                <div className="mb-6">
                    <label htmlFor="mobileNumber" className=" ">
                        Phone number
                    </label>
                    <InputText type="number" className={`w-full ${(errors.mobileNumber && touched.mobileNumber) && "p-invalid"}`} name="mobileNumber"
                               onBlur={handleBlur}
                               onChange={handleChange}
                               value={values.mobileNumber}
                    />
                    {errors.mobileNumber && touched.mobileNumber ? (
                        <p className="p-error">{errors?.mobileNumber?.toString()}</p>
                    ) : null}
                </div>
                <div className="mb-6">
                    <label htmlFor="email" className=" ">
                        Email address
                    </label>
                    <InputText disabled type="email" className='w-full' name="email"
                               onBlur={handleBlur}
                               onChange={handleChange}
                               placeholder={emailAdd}
                    />
                </div>
            </div>
        </>
    )

    const formInputsPassword = ({errors, touched, handleBlur, handleChange, values}: FormikProps<FormikValues>) => (
        <>
          <div className="mb-6">
            <label htmlFor="existingPassword" className=" ">
              Existing password
            </label>
            <InputText type='password' className={`w-full ${(errors.existingPassword && touched.existingPassword) && "p-invalid"}`} name="existingPassword"
                       onBlur={handleBlur}
                       onChange={handleChange}
                       placeholder="Existing password"
            />
              {errors.existingPassword && touched.existingPassword ? (
                  <p className="p-error">{errors?.existingPassword?.toString()}</p>
              ) : null}
          </div>
          <div className="mb-6">
            <label htmlFor="newPassword" className=" ">
              New password
            </label>
            <Password className={`w-full ${(errors.newPassword && touched.newPassword) && "p-invalid"}`} name="newPassword"
                       onBlur={handleBlur}
                       onChange={handleChange}
                       placeholder="New password"
                       inputClassName="w-full"
            />
              {errors.newPassword && touched.newPassword ? (
                  <p className="p-error">{errors?.newPassword?.toString()}</p>
              ) : null}
          </div>
            <div className="mb-6">
                <label htmlFor="confirmPassword" className=" ">
                    Confirm password
                </label>
                <Password className={`w-full ${(errors.confirmPassword && touched.confirmPassword) && "p-invalid"}`} name="confirmPassword"
                           onBlur={handleBlur}
                           onChange={handleChange}
                           placeholder="Confirm Password"
                           inputClassName="w-full"
                />
                {errors.confirmPassword && touched.confirmPassword ? (
                    <p className="p-error">{errors?.confirmPassword?.toString()}</p>
                ) : null}
            </div>
        </>
    )

    let handleSubmit = async (values:any) => {
        try {
            if (isAdmin){
                const response = await editProfile(values.mobileNumber, values.profilePicture)
                if (response.data.success && response.data.data) {
                    const updateUserInfo = {
                        ...userInfo,
                        contact_no: response.data.data.mobileNo,
                        profile_picture_url: response.data.data.profilePictureUrl,
                        profile_picture_thumb_url: response.data.data.profilePictureThumbUrl,
                    }
                    dispatch(setProfile(updateUserInfo as User));
                    toast.success("Profile Edited");
                }
            } else if ( isOrganisation) {
                const response = await editOrganisationUser(values.mobileNumber, values.profilePicture)
                if (response.data.success && response.data.data) {
                    const updateUserInfo = {
                        ...userInfo,
                        contact_no: response.data.data.mobileNo,
                        profile_picture_url: response.data.data.profilePictureUrl,
                        profile_picture_thumb_url: response.data.data.profilePictureThumbUrl,
                    }
                    dispatch(setProfile(updateUserInfo as User));
                    toast.success("Profile Edited");
                }
            }
        } catch (error) {
            // toast.error(JSON.stringify(error));
            console.error('error', error);
        }
    };

    let handleSubmitPassword = async (values:any) => {
        try {
            if (isAdmin) {
                await updatePassword(values.existingPassword, values.newPassword);
            }else if (isOrganisation) {
                await updatePasswordOrganisationUser(values.existingPassword, values.newPassword)
            }
            toast.success("New Password Updated");
        } catch (error) {
            // toast.error(JSON.stringify(error));
            console.error('error', error);
        }
    };

    return (
        <>
            <div >
                <h2 className="mb-6 h2">Edit your profile</h2>
            </div>
            <div className="flex">
                <div className="min-w-[500px]">
                    <FormWrapper
                        noValidate
                        initialValues={initialValues}
                        validationSchema={editProfileSchema}
                        formInputs={formInputs}
                        onSubmit={handleSubmit}
                        className=''
                        submitButton={
                            <div className="mb-6">
                                <Button className="justify-center w-full" size="large" type="submit">
                                    Save
                                </Button>
                            </div>
                        }
                    />
                    <FormWrapper
                        noValidate
                        initialValues={initialValuesPassword}
                        validationSchema={changePasswordSchema}
                        formInputs={formInputsPassword}
                        onSubmit={handleSubmitPassword}
                        submitButton={
                            <div className="mb-6">
                                <Button className="justify-center w-full" size="large" type="submit">
                                    Save Password
                                </Button>
                            </div>
                        }
                    />
                </div>
            </div>
        </>
    )
}

export default Profile;

const profilePicStyle = {
    width: '250px',
    height: '250px',
}