import React from "react";


const PreviewVideo = (props: any) => {

    return <div className="pb-5">
        <video className="w-full h-[400px] bg-black" controls>
            <source src={props.selectedVideoFile} type="video/mp4" />
            Your browser does not support HTML video.
        </video>
    </div>
}

export default PreviewVideo;