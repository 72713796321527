import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
// import SearchBox from '../../../components/SearchBox';
import { toast } from 'react-toastify';
import TableWrapper, { OnSortProps } from '../../../components/listing/TableWrapper';
import { useAppDispatch, useAppSelector } from '../../../hooks/useStore';
import { setLoading } from '../../../redux/slices/commonSlice';
import { setSelectedPromotion } from '../../../redux/slices/eventSlice';
import { Promotion } from '../../../redux/types/event';
import { get, remove } from '../../../utils/AxiosReqeust';
import { formatCurrency } from '../../../utils/formatCurrency';
import deleteConfirmation from '../../../utils/deleteConfirmation';

const EventDetailPromoCodes = () => {
    const { basicInfo } = useAppSelector((state) => state.event);
    const [promoCodes, setPromoCodes]:any = useState(null);
    const [page, setPage] = useState(1);
    const [records, setRecords] = useState(10);
    const [sortField, setSortField] = useState<string>('');
    const [sortOrder, setSortOrder] = useState<0 | 1 | -1 | null | undefined>(null);
    const [searchByName, setSearchByName] = useState("");
    const [debouncedSearchByName] = useDebounce(searchByName, 500);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const getPromoCodeList = async () => {
        let sortQuery = ''
        let sortName = ''

        // if (sortField) {
        //     sortQuery += `&sortBy=${sortField}`
        //     if (sortOrder) {  // positive
        //         sortQuery += `&sortOrder=${sortOrder}`
        //     }
        // }

        // if (debouncedSearchByName) {
        //     sortName += `&searchName=${debouncedSearchByName}`
        // }

        dispatch(setLoading(true));
        const response = await get(`/promotion/listing/${basicInfo?._id}?page=${page}&records=${records}${sortQuery}${sortName}`);
        if (response?.data?.success && response?.data?.data) {
            setPromoCodes(response.data.data);
        }
        dispatch(setLoading(false));
    }

    useEffect(() => {
        getPromoCodeList();
    }, [page, records, sortOrder, sortField, debouncedSearchByName])

    const deleteRecord = async (res: any) => {
        dispatch(setLoading(true));
        const response: any = await remove(`promotion/${res?._id}`);
        if (response?.data?.success) {
            await getPromoCodeList();
            toast.success(response?.data?.message);
        }
        dispatch(setLoading(false));
    }

    const setPageLimit = (limit: number) => {
        setRecords(limit);
        setPage(1);
    }

    const onSort = ({ sortField, sortOrder }: OnSortProps) => {
        setSortField(sortField);
        setSortOrder(sortOrder);
        setPage(1);
    }

    // const handleChange = (e: any) => {
    //     setPage(1);
    //     setSearchByName(e.target.value);
    // }

    const download = async (promo: Promotion, all: boolean = true) => {
        const response = await get(all ? `/promotion/export/${promo._id}` : promo?.csv_url ?? '', {responseType: 'blob'});
        const filename = response.headers['content-disposition'].split('filename=')[1];
        const blob = new Blob([response.data], { type: 'text/csv' });
        const link = document.createElement('a');
        const url = window.URL.createObjectURL(blob);
        link.href = url
        link.download = filename || 'promo.csv';
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);
    }

    const promoNameColumnBody = (res: any) => {
        return <div className="text-center">
            {res?.promo_name || 'N/A'}
        </div>
    }

    const codeNameColumnBody = (res: any) => {
        const downloadButton = <>
            <div>
                <Button
                    pt={{root: { className: '!p-0 !rounded-none hover:!bg-sky-100' }}}
                    link
                    onClick={() => download(res)}
                >
                    Download All
                </Button>
            </div>
            {
                res?.csv_url &&
                <div className="mt-2">
                    <Button
                        pt={{root: { className: '!p-0 !rounded-none hover:!bg-sky-100' }}}
                        link
                        onClick={() => {
                            const link = document.createElement('a');
                            link.href = res?.csv_url ?? '';
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                        }}
                    >
                        Download Bind Only
                    </Button>
                </div>
            }
        </>
        return <div className="text-center">
            {res?.promoCode ? res?.promoCode : downloadButton}
        </div>
    }

    const discountColumnBody = (res: any) => {
        return <div className="text-right">
            {res?.discount_amount ? formatCurrency(res?.discount_amount, basicInfo.currency_id.currency_code) : ''}
            {res?.discount_percentage ? `%${res?.discount_percentage.toFixed(2)}` : ''}
        </div>
    }

    const typeColumnBody = (res: any) => {
        return <div className="text-center">
            {res?.is_public ? 'Public' : 'Private'}
        </div>
    }

    const usageColumnBody = (res: any) => {
        return <div className="text-center">
            {res?.promoCode && (<span>{res?.promoCodeCount} / {res?.ticket_limit ? res?.ticket_limit_amount : 'Unlimited'}</span>)}
            {!res?.promoCode && (<span>{res?.accessCodeCount} / {res?.accessCodeTotal}</span>)}
        </div>
    }

    const actionColumnBody = (res: any) => {
        return <div className="flex justify-end gap-2">
            {
                res?.applied_type > 1 &&
                <Button
                    className="mr-2 !rounded-full w-8 h-[32px] p-0 flex items-center justify-center"
                    size="small"
                    onClick={() => {
                        navigator.clipboard.writeText(`{ \"event_id\": \"${basicInfo?._id}\", \"promotion_id\": \"${res?._id}\" }`)
                        toast.info("Binding API Payload Copied!");
                    }}
                >
                    <i className="pi pi-copy" />
                </Button>
            }
            <Button
                className="mr-2 !rounded-full w-8 h-[32px] p-0 flex items-center justify-center"
                size="small"
                onClick={() => {
                    dispatch(setSelectedPromotion(res));
                    navigate(`/events/detail/promocodes/create`);
                }}
            >
                <i className="pi pi-pencil" />
            </Button>
            <Button
                className="mr-2 !rounded-full w-8 h-[32px] p-0 flex items-center justify-center"
                size="small"
                onClick={() => deleteConfirmation(async () => await deleteRecord(res))}
            >
                <i className="pi pi-trash" />
            </Button>
        </div>
    }

    return (
        <>
            <h2 className="text-3xl font-semibold text-aria_dark mb-4">Promo codes</h2>
            <div className="flex justify-between items-center">
                <Button
                    label="Create"
                    icon="pi pi-plus"
                    size="large"
                    className="!text-[14px] !px-12"
                    onClick={() => {
                        dispatch(setSelectedPromotion({} as Promotion));
                        navigate(`/events/detail/promocodes/create`)
                    }}
                />
                {/* <SearchBox
                    placeholder="Search Code Name"
                    onChange={handleChange}
                /> */}
            </div>
            <div className="mt-4">
                {promoCodes &&
                    <TableWrapper
                        data={promoCodes}
                        loading={false}
                        records={records}
                        setRecords={setPageLimit}
                        scrollable
                        scrollHeight="70vh"
                        nextPage={() => {
                            if (!promoCodes.hasNextPage) return
                            setPage(current => (current + 1))
                        }}
                        prevPage={() => {
                            if (!promoCodes.hasPrevPage) return
                            setPage(current => (current - 1))
                        }}
                        sortField={sortField}
                        sortOrder={sortOrder}
                        onSort={onSort}
                    >
                        <Column style={{width: '18%'}} header="Promo Name" body={promoNameColumnBody} />
                        <Column style={{width: '18%'}} header="Code Name" body={codeNameColumnBody} />
                        <Column style={{width: '18%'}} header="Discount" body={discountColumnBody} />
                        <Column field="type" style={{width: '18%'}} header="Type" body={typeColumnBody} />
                        <Column field="usage" style={{width: '18%'}} header="Usage" body={usageColumnBody} />
                        <Column header="Actions" style={{width: '10%'}} body={actionColumnBody} />
                    </TableWrapper>
                }
            </div>
        </>
    )
}

export default EventDetailPromoCodes
