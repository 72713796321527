import { useAppSelector } from "./useStore";

function useAuth() {
    const { token, user: userInfo } = useAppSelector((state) => state.userAuth);

    const isOrganisation = !!userInfo?.organisation;
    const isSuperAdmin = !!userInfo?.role && userInfo.role === "superadmin";
    const isAdmin = !!userInfo?.role;

    return {
        loggedIn: !!token && !!userInfo,
        userInfo,
        isAdmin,
        isOrganisation,
        isSuperAdmin,
        userAccess: isAdmin ? 'admin' : 'organisation',
        token,
    };
}
export default useAuth;
