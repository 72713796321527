import React, {useEffect} from "react";
import { Outlet, useMatches } from "react-router-dom";
import { BreadCrumb } from "primereact/breadcrumb";
import { ProgressSpinner } from 'primereact/progressspinner';
import NavigationMenu from "./NavigationMenu";
import HeaderRightMenu from "./HeaderRightMenu";
import {useAppDispatch, useAppSelector} from "../../hooks/useStore";
import homeIconBreadcrumb from "../../assets/images/breadcrumb-home.png";
import { Link } from "react-router-dom";
import {getCountryList} from "../../utils/apiRequestList/countryAPI";
import { setCountry } from '../../redux/slices/commonSlice'

const Layout = (props: any) => {
    const { loading } = useAppSelector((state) => state.common);
    const dispatch = useAppDispatch();
    let matches = useMatches();
    let crumbs = matches
        .filter((match: any) => Boolean(match.handle?.crumb))
        .map((match: any) => match.handle?.crumb);

    let ignorePadding:boolean = false
    matches.map((match:any)=>{
        if( match.handle?.ignorePadding){
            ignorePadding = true
        }
    })

    const home = {
        template: <div className='w-[14px]'>
            <Link to={"/"}>
            <img src={homeIconBreadcrumb} alt=""/>
            </Link>
        </div>
    };

    const fetchCountryList = async ()=> {
        const response = await getCountryList();
        if (response.data.success) {
            const countriesMsg:any = response?.data?.data.map((item:any) => {
                let singleCountry = {
                    _id: item._id,
                    value: item.dial_code,
                    name: item.dial_code,
                    country: item.name,
                    code: item.code,
                    flag:`https://flagcdn.com/${item.code.toLowerCase()}.svg`
                }
                return singleCountry
            });
            dispatch(setCountry(countriesMsg));
        }
    }

    useEffect(() => {
        fetchCountryList();
    }, []);

    return <div className='flex h-screen'>
        <NavigationMenu />
        <div className='flex flex-col flex-1 bg-[#EDECF3]'>
            <div className='sticky z-40 flex flex-row flex-wrap items-center justify-between pt-[28px] pr-[43px] pl-[20px]'>
                <BreadCrumb style={{ background: 'none', border: 'none' }} model={crumbs} home={home} />
                <HeaderRightMenu />
            </div>
            <div className='relative w-full h-full min-h-[84vh] overflow-hidden'>
                <div className="h-full overflow-y-auto">
                    <div className={`min-h-full ${ignorePadding ? 'pl-0 pb-0 pt-0' : 'pl-[20px]' } pr-[43px]  py-8 transparent`}>
                        <Outlet />
                    </div>
                </div>
                <div className={`${loading ? 'flex' : 'hidden'} justify-center items-center w-full h-full absolute z-50 top-0 left-0 bg-black opacity-20`} onClick={(e) => e.stopPropagation()}>
                    <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
                </div>
            </div>
        </div>
    </div>
}

export default Layout;