import { Button } from 'primereact/button';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const Settings = () => {
    const navigate = useNavigate();

    return (
        <>
            <h2 className="text-3xl font-semibold text-aria_dark">Settings</h2>
            <div className='flex gap-x-2 my-3'>
                <Button
                    label="Currency"
                    icon="pi pi-dollar"
                    size="large"
                    className="!text-[14px] !px-12"
                    onClick={() => navigate(`/settings/currency`)}
                />

                <Button
                    label="Event Categories"
                    icon="pi pi-table"
                    size="large"
                    className="!text-[14px] !px-12"
                    onClick={() => navigate(`/settings/event-categories`)}
                />
            </div>
        </>
    )
}

export default Settings
