import { PropsWithChildren } from "react";

export default function FooterAction({ children }: PropsWithChildren) {
  return (
    <>
      <div className="mt-36"></div>
      <div className="absolute w-full bottom-0 left-0 p-[33px] border-t-[.8px] border-[#919191] bg-[#edecf3]">
        {children}
      </div>
    </>
  );
}
