import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type CommonState = {
    loading: boolean,
    locales: any,
    navExpand: boolean,
    country: Array<any>,
};

const navExpand:any = localStorage.getItem("sideExpand") || 'true';

const initialState: CommonState = {
    loading: false,
    locales: null,
    navExpand: navExpand === 'true' ? true : false,
    country: [],
};

const commonSlice = createSlice({
    name: "common",
    initialState,
    reducers: {
        setLoading: (state, { payload }: PayloadAction<boolean>) => {
            state.loading = payload
        },
        setLocales: (state, { payload }: PayloadAction<any>) => {
            state.locales = payload
            localStorage.setItem("locales", payload.available_languages)
        },
        setNavExpand: (state, { payload }: PayloadAction<any>) => {
            state.navExpand = payload
            localStorage.setItem("sideExpand", payload);
        },
        setCountry: (state:any, { payload: country }: PayloadAction<{country: any}>) => {
            state.country = country
        }
    },
});

export const { setLoading, setLocales , setNavExpand, setCountry} = commonSlice.actions;
export default commonSlice.reducer;
