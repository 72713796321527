import axios from "axios";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { RadioButton } from "primereact/radiobutton";
import { useState } from "react";
import AuthWrapper from "../../components/AuthWrapper";

import * as Yup from "yup";

import { Link, useNavigate } from "react-router-dom";

import { FormikProps, FormikValues } from "formik";
import FormWrapper from "../../components/form/FormWrapper";
import { useAppDispatch } from "../../hooks/useStore";
import { setProfile, setToken } from "../../redux/slices/authSlice";
import { organisationUserLogin } from "../../utils/apiRequestList/organisationAPI";
import { userLogin } from "../../utils/apiRequestList/userAPI";

type UserType = "individual" | "organisation";
const Login = () => {
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [userType, setUserType] = useState<UserType>("organisation");

    const initialValues = { email: "", password: "" };

    const LoginSchema = Yup.object({
        email: Yup.string().email("Invalid email").required("Required"),
        password: Yup.string().required("Required"),
    });

    let handleSubmit = async (values: typeof initialValues) => {
        const email = values.email.toLowerCase();
        const data = {
            ...values,
            email
        }
        try {
            setIsLoading(true);
            const response =
                userType === "individual"
                    ? await userLogin(data)
                    : await organisationUserLogin(data);
            setIsLoading(false);

            if (response.data.success) {
                dispatch(setToken({ token: response.data.data.token }));
                axios.defaults.headers.common[
                    "Authorization"
                ] = `Bearer ${response.data.data?.token}`;
                dispatch(setProfile(response.data.data.user));
                navigate("/");
            }
        } catch (e) {
            setIsLoading(false);
            console.error(e);
        }
    };

    const formInputs = ({
        errors,
        touched,
        handleBlur,
        handleChange,
    }: FormikProps<FormikValues>) => (
        <>
            <div className="flex flex-wrap gap-3 mb-8">
                <div className="flex px-6 py-4 border rounded-lg align-items-center">
                    <RadioButton
                        inputId="individual"
                        name="user_type"
                        onChange={(e) => setUserType(e.value)}
                        value="individual"
                        checked={userType === "individual"}
                    />
                    <label htmlFor="individual" className="ml-2">
                        Admin
                    </label>
                </div>
                <div className="flex px-6 py-4 border rounded-lg align-items-center">
                    <RadioButton
                        inputId="organisation"
                        name="user_type"
                        onChange={(e) => setUserType(e.value)}
                        value="organisation"
                        checked={userType === "organisation"}
                    />
                    <label htmlFor="organisation" className="ml-2">
                        Organisation
                    </label>
                </div>
            </div>

            <div className="mb-6">
                <label htmlFor="email" className=" ">
                    Email
                </label>
                <InputText
                    name="email"
                    className={`w-full ${
                        errors.email && touched.email && "p-invalid"
                    }`}
                    type="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                />
                {errors.email && touched.email ? (
                    <p className="p-error">{errors?.email?.toString()}</p>
                ) : null}
            </div>
            <div className="mb-6">
                <label htmlFor="password" className=" ">
                    Password
                </label>
                <Password
                    toggleMask
                    name="password"
                    feedback={false}
                    className="w-full"
                    inputClassName={`w-full ${
                        errors.password && touched.password && "p-invalid"
                    }`}
                    onBlur={handleBlur}
                    onChange={handleChange}
                />

                {errors.password && touched.password ? (
                    <p className="p-error">{errors?.password?.toString()}</p>
                ) : null}
            </div>

            <div className="flex justify-end mb-12">
                <Link
                    to={`/forgot-password?type=${userType}`}
                    className="text-sm font-medium underline text-zinc-600"
                >
                    Forgot password?
                </Link>
            </div>
        </>
    );

    return (
        <AuthWrapper
            title="Sign in"
            description={<h4>Welcome back, please enter your details</h4>}
        >
            <FormWrapper
                noValidate
                initialValues={initialValues}
                validationSchema={LoginSchema}
                onSubmit={handleSubmit}
                formInputs={formInputs}
                submitButton={
                    <div className="mb-6">
                        <Button
                            loading={isLoading}
                            className="justify-center w-full"
                            size="large"
                            type="submit"
                        >
                            Sign In
                        </Button>
                    </div>
                }
                className="gap-2"
            />

            <div className="">
                <div className="mt-6 text-sm text-zinc-600">
                    Having trouble login?{" "}
                    <Link
                        to={"/forgot-password"}
                        className="font-semibold underline"
                    >
                        Contact us
                    </Link>
                </div>
            </div>
        </AuthWrapper>
    );
};

export default Login;
