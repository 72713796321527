import React from "react";
import FormWrapper from "../../../components/form/FormWrapper";
import { FormikProps, FormikValues } from "formik";
import { Button } from "primereact/button";
import * as Yup from 'yup';
import { InputText } from "primereact/inputtext";


const CreateTagForm = (props: any) => {

    const initialValues = {
        folderNames: ''
    };

    const ArtistFormSchema = Yup.object({
        folderNames: Yup.string().required('Required Gallery Name')
    });

    const handleSubmit = (values: any) => {
        props.createNewFolderData(values)
    }

    const formInputs = ({ errors, values, touched, handleBlur, handleChange }: FormikProps<FormikValues>) => (
        <div className="mb-6">
            <label htmlFor="folderNames" className="">
                Tag Display Name
            </label>
            <InputText
                id="folderNames"
                type="text"
                className={`w-full ${errors.folderNames && touched.folderNames && "p-invalid"
                    }`}
                name="folderNames"
                value={values.folderNames}
                onBlur={handleBlur}
                onChange={handleChange}
            />
            {errors.folderNames && touched.folderNames ? (
                <small className="block mt-2 p-error">
                    {errors?.folderNames?.toString()}
                </small>
            ) : null}
        </div>
    )


    return <><FormWrapper
        noValidate
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={ArtistFormSchema}
        formInputs={formInputs}
        submitButton={
            <div className="pb-7">
                <Button className="justify-center w-full" size="large" type="submit">
                    Create
                </Button>
            </div>
        }
        className="gap-2" />
    </>
}

export default CreateTagForm;