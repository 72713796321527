import React, { useRef, useState, useEffect } from 'react';
import { Avatar } from 'primereact/avatar';
import { TieredMenu } from 'primereact/tieredmenu';
import { MenuItem } from 'primereact/menuitem';
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../hooks/useStore";
import { logout } from "../../redux/slices/authSlice";
import useAuth from "../../hooks/useAuth";
import avatar from "../../assets/images/avatar.jpg"
import isHttpsUrl from "../../utils/isHttpsUrl";
import ProfileIcon from "../../assets/images/profile-icon.png";

export default function HeaderRightMenu() {
    const {isOrganisation} = useAuth();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const menu = useRef<TieredMenu>(null);

    const {userInfo} = useAuth();
    const [profilePicAvailable, setProfilePicAvailable] = useState(false);

    useEffect(() => {
        if (userInfo) {
            setProfilePicAvailable(isHttpsUrl(userInfo?.profile_picture_thumb_url || ''))
        }
    }, [userInfo]);


    const onLogout = () => {
        dispatch(logout())
        navigate('/login')
    }

    const items: MenuItem[] = [
        {
            label: 'Profile',
            icon: 'pi pi-fw pi-user',
            url: '/profile',
        },
        {
            separator: true
        },
        {
            label: 'Logout',
            icon: 'pi pi-fw pi-power-off',
            template: (item) => {
                return (
                    <button onClick={(e) => onLogout()} className={`p-menuitem-link w-full p-link flex align-items-center`}>
                        <i className="mr-2 pi pi-fw pi-power-off" />
                        <div className="flex flex-column align">
                            <span>{item.label}</span>
                        </div>
                    </button>
                )
            }
        }
    ];


    return (
        <div className="flex items-center gap-1 card justify-content-center" onClick={(e) => menu?.current?.toggle(e)}>
            <TieredMenu model={items} popup ref={menu}  />
            {/*<Avatar  image={(userInfo && profilePicAvailable) ? userInfo?.profile_picture_thumb_url : avatar} className="user-avatar-thumb flex mr-2 border align-items-center justify-content-center" size="normal" shape="circle" />*/}
            <img className='mr-[5px]' src={ProfileIcon} alt="Profile"/>
            <div className='flex items-center gap-2 text-md font-medium cursor-pointer select-none text-zinc-600'>{userInfo?.first_name} {userInfo?.last_name} <i className='pi pi-chevron-down' style={{ fontSize: "15px", color: '#6650F2'}}></i> </div>
        </div>
    )
}

