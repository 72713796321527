import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';
import { MenuItem } from 'primereact/menuitem';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from '../../../hooks/useStore';
import { setLoading } from '../../../redux/slices/commonSlice';
import { setSelectedTicket, setSelectedTicketGroup } from '../../../redux/slices/eventSlice';
import { TicketGroup, Ticket } from '../../../redux/types/event';
import { get, post, remove } from '../../../utils/AxiosReqeust';
import { formatCurrency } from '../../../utils/formatCurrency';
import Repeater from '../../CMS/Home/Repeater/Index';
import deleteConfirmation from '../../../utils/deleteConfirmation';

const EventDetailTickets = () => {
    const dispatch = useAppDispatch();
    const { basicInfo } = useAppSelector((state) => state.event);
    const navigate = useNavigate();
    const { locales }: any = useAppSelector((state) => state.common);
    const [currentLang, setCurrentLang] = useState<string>(locales?.default_language ?? 'en')
    const [ticketGroups, setTicketGroups] = useState<any[]>([]);
    const menuRight = useRef<any>(null);
    const [menuItems, setMenuItems] = useState<MenuItem[]>([]);

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        try {
            const [
                ticketGroups,
            ] = await Promise.all([
                get(`/ticket/list-group/${basicInfo?._id}`),
            ]);
            if (ticketGroups.data.success && ticketGroups.data.data) {
                setTicketGroups(ticketGroups.data.data.map((ticketGroup: any) => {
                    return {
                        ...ticketGroup,
                        ...{
                            name: ticketGroup?.translations?.filter((translation: any) => translation?.language === currentLang)[0]?.name,
                            tickets: ticketGroup?.tickets?.map((ticket: any) => {
                                return {
                                    ...ticket,
                                    ...{
                                        name: ticket?.translations?.filter((translation: any) => translation?.language === currentLang)[0]?.name,
                                        price_formatted: formatCurrency(ticket?.price, basicInfo.currency_id.currency_code),
                                    }
                                }
                            }),
                        }
                    }
                }));
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    const deleteRecordGroup = async (ticketGroup: any) => {
        const { titleKey, ...rest } = ticketGroup;
        dispatch(setLoading(true));
        const response: any = await remove(`ticket/group/${rest?._id}`);
        if (response?.data?.success) {
            await getData();
            toast.success(response?.data?.message);
        }
        dispatch(setLoading(false));
    }

    const deleteRecord = async (ticket: any) => {
        const { titleKey, ...rest } = ticket;
        dispatch(setLoading(true));
        const response: any = await remove(`ticket/ticket/${rest?._id}`);
        if (response?.data?.success) {
            await getData();
            toast.success(response?.data?.message);
        }
        dispatch(setLoading(false));
    }

    const updateSortOrder = async (values: any) => {
        try {
            const response: any = await post(`/ticket/update-sort-order`, { sort: JSON.stringify(values) });
            if (response?.data?.success && response?.data?.data) {
                toast.success(response?.data?.message);
            }
        }
        catch (error) {
            // toast.error(JSON.stringify(error));
        }
    };

    const updateGroupSortOrder = async (values: any) => {
        try {
            const response: any = await post(`/ticket/update-group-sort-order`, { sort: JSON.stringify(values) });
            if (response?.data?.success && response?.data?.data) {
                toast.success(response?.data?.message);
            }
        }
        catch (error) {
            // toast.error(JSON.stringify(error));
        }
    };

    return (
        <>
            <h2 className="text-3xl font-semibold text-aria_dark mb-4">Tickets</h2>
            <Button
                label="Create Ticket Group"
                icon="pi pi-plus"
                size="large"
                className="!text-[14px] !px-12"
                onClick={() => {
                    dispatch(setSelectedTicketGroup({} as TicketGroup));
                    navigate(`/events/detail/tickets/create-group`)
                }}
            />
            {ticketGroups?.length > 0 && (
                <Repeater
                    data={ticketGroups}
                    titleKey={(ticketGroup) => {
                        return <div className='grow flex flex-row justify-between items-center w-full'>
                            {ticketGroup?.name}
                            <div className='pr-2 flex flex-row justify-end items-center gap-x-2'>
                                <div className='text-[14px] font-normal text-dark shrink-0'>Max. Capacity: <span className="text-aria_dark">{ticketGroup?.max_capacity}</span></div>
                                <Button
                                    size="small"
                                    className="!rounded-full w-8 h-[32px] p-0 flex items-center justify-center"
                                    onClick={(event) => {
                                        setMenuItems([
                                            {
                                                label: 'Edit',
                                                command: () => {
                                                    const { titleKey, ...rest } = ticketGroup;
                                                    dispatch(setSelectedTicketGroup(rest));
                                                    navigate(`/events/detail/tickets/create-group`);
                                                }
                                            },
                                            {
                                                label: 'Delete',
                                                command: () => deleteConfirmation(async () => await deleteRecordGroup(ticketGroup))
                                            },
                                        ]);
                                        menuRight.current.toggle(event);
                                    }}
                                >
                                    <i className="pi pi-ellipsis-v" />
                                </Button>
                            </div>
                        </div>;
                    }}
                    groupName="Ticket Group"
                    onOrderChange={(updated) => {
                        updateGroupSortOrder(updated?.map((item: any) => {
                            return { id: item?.id, sort_order: item?.order }
                        }))
                        setTicketGroups(updated)
                    }}
                    template={{
                        render: (item, index) => (
                            <>
                                <Repeater
                                    data={item?.tickets}
                                    titleKey={(ticket) => {
                                        return <div className='grow flex flex-row justify-between items-center w-full'>
                                            {ticket?.name}
                                            <div className='pr-2 flex flex-row justify-end items-center gap-x-2'>
                                                <Button
                                                    size="small"
                                                    className="!rounded-full w-8 h-[32px] p-0 flex items-center justify-center"
                                                    onClick={(event) => {
                                                        setMenuItems([
                                                            {
                                                                label: 'Edit',
                                                                command: () => {
                                                                    dispatch(setSelectedTicketGroup(item as unknown as TicketGroup));
                                                                    const { titleKey, ...rest } = ticket;
                                                                    dispatch(setSelectedTicket(rest));
                                                                    navigate(`/events/detail/tickets/create`);
                                                                }
                                                            },
                                                            {
                                                                label: 'Delete',
                                                                command: () => deleteConfirmation(async () => await deleteRecord(ticket))
                                                            },
                                                        ]);
                                                        menuRight.current.toggle(event);
                                                    }}
                                                >
                                                    <i className="pi pi-ellipsis-v" />
                                                </Button>
                                            </div>
                                        </div>;
                                    }}
                                    groupName="Ticket"
                                    onOrderChange={(updated) => {
                                        updateSortOrder(updated?.map((item: any) => {
                                            return { id: item?.id, sort_order: item?.order }
                                        }))
                                        const tgs = [...ticketGroups]
                                        setTicketGroups(tgs.map((ticketGroup: any, ticketGroupIndex: number) => {
                                            if (ticketGroupIndex === index) {
                                                return { ...ticketGroup, ...{ tickets: updated } }
                                            }
                                            return ticketGroup
                                        }))
                                    }}
                                    template={{
                                        render: (item, tcx) => (<>
                                            <div className="flex flex-col justify-between gap-y-5 w-full h-full">
                                                <div className="flex flex-row justify-between items-center gap-x-5 w-full">
                                                    <div>
                                                        <div>Sale Start: <span className="text-aria_dark">{item?.sales_start_formatted}</span></div>
                                                        <div>Sale End: <span className="text-aria_dark">{item?.sales_end_formatted}</span></div>
                                                    </div>
                                                    <div>
                                                        <div className="text-right">Sold{item?.max_capacity > 0 && (<span> / Capacity</span>)}</div>
                                                        <div className="text-right text-aria_dark">{item?.sold}{item?.max_capacity > 0 && (<span> / {item?.max_capacity}</span>)}</div>
                                                    </div>
                                                </div>
                                                <div className="flex flex-row justify-between items-center gap-x-5 w-full">
                                                    {item?.ticket_viewability_type_id?._id === "64f7df09728437a86d7b75a8" && (
                                                        <div className="text-aria_dark">{item?.ticket_viewability_type_id?.name}</div>
                                                    )}
                                                    <div className="text-right ml-auto flex items-center gap-x-2">Price: <span className="text-[22px] tracking-[2px] font-semibold text-aria_dark">{item?.price_formatted}</span></div>
                                                </div>
                                            </div>
                                        </>)
                                    }}
                                />
                                {item?.tickets?.length <= 0 && <div className="flex justify-center pt-5">
                                    <Button
                                        text
                                        size="small"
                                        type="button"
                                        icon="pi pi-plus"
                                        label="Create Ticket"
                                        onClick={() => {
                                            dispatch(setSelectedTicket({} as Ticket));
                                            dispatch(setSelectedTicketGroup(item as unknown as TicketGroup));
                                            navigate(`/events/detail/tickets/create`)
                                        }}
                                    />
                                </div>}
                            </>
                        )
                    }}
                />
            )}
            <Menu model={menuItems} popup ref={menuRight} popupAlignment="right" />
        </>
    )
}

export default EventDetailTickets
