import { useState } from 'react';
import * as Yup from 'yup';

import { Button } from "primereact/button";

import FormWrapper from "../../../components/form/FormWrapper";
import { FormikProps, FormikValues } from "formik";
import { InputText } from "primereact/inputtext";
import FooterAction from '../../../components/form/FooterAction';
import { toast } from "react-toastify";
import { post } from "../../../utils/AxiosReqeust";
import {isEqual} from "lodash-es";
import { setLoading } from '../../../redux/slices/commonSlice';
import { useAppDispatch, useAppSelector } from '../../../hooks/useStore';

const PlatformFeeForm = (props: any) => {
    const dispatch = useAppDispatch()
    const { loading }: any = useAppSelector((state) => state.common);
    const initialValues = props?.currentData ? {
        _id: props?.currentData?._id,
        min_range: props?.currentData?.min_range ?? '',
        max_range: props?.currentData?.max_range ?? '',
        fee_amount: props?.currentData?.fee_amount ?? '',
        fee_percentage: props?.currentData?.fee_percentage ?? '',
    } : {
        min_range: '',
        max_range: '',
        fee_amount: '',
        fee_percentage: '',
    };
    let prevData = initialValues

    const PlatformFeeFormSchema = Yup.object({
        min_range: Yup.number().moreThan(-1).required('Required Min Range'),
        max_range: Yup.number().nullable().moreThan(-1).min(0).moreThan(Yup.ref('min_range')),
        fee_amount: Yup.number().nullable().moreThan(-1).min(0),
        fee_percentage:Yup.number().nullable().moreThan(-1).min(0),
    })


    const handleSubmit = async (values: any) => {
        if (isEqual(values, prevData)) return

        dispatch(setLoading(true))
        try {
            const response = await post('/settings/platform-fee/create', {
                ...values,
                event_id: props.eventId
            })
            if (response.status) {
                toast.success(props.currentData ? 'Edited Platform Fee' : "Platform Fee Created.");
                props.setUpdateListing((prev:any) => prev + 1)
                setTimeout(() => {
                    props.setVisible(false)
                }, 1000);
            } else {
                toast.error(props.currentData ? 'Failed Edit Platform Fee' : 'Failed Create Platform Fee');
                setTimeout(() => {
                    props.setVisible(false)
                }, 1000);
            }
        } catch (error: any) {
            dispatch(setLoading(false))
            toast.error(error);
            console.error(error);
        }
    }

    const formInputs = ({ errors, values, touched, handleBlur, handleChange, setFieldValue }: FormikProps<FormikValues>) => (
        <>
            <div className="mb-6">
                <label htmlFor="min_range" className="">
                    Min Range
                </label>
                <InputText
                    id="min_range"
                    type="number"
                    className={`w-full ${(errors.min_range && touched.min_range) && "p-invalid"}`}
                    name="min_range"
                    value={values.min_range}
                    onBlur={handleBlur}
                    onChange={handleChange}
                />
                {errors.min_range && touched.min_range ? (
                    <small className="block mt-2 p-error">{errors?.min_range?.toString()}</small>
                ) : null}
            </div>
            <div className="mb-6">
                <label htmlFor="max_range" className="">
                    Max Range
                </label>
                <InputText
                    id="max_range"
                    type="number"
                    className={`w-full ${(errors.max_range && touched.max_range) && "p-invalid"}`}
                    name="max_range"
                    value={values.max_range}
                    onBlur={handleBlur}
                    onChange={handleChange}
                />
                {errors.max_range && touched.max_range ? (
                    <small className="block mt-2 p-error">{errors?.max_range?.toString()}</small>
                ) : null}
            </div>
            <div className="mb-6">
                <label htmlFor="fee_amount" className="">
                    Fee Amount
                </label>
                <InputText
                    id="fee_amount"
                    type="number"
                    className={`w-full ${(errors.fee_amount && touched.fee_amount) && "p-invalid"}`}
                    name="fee_amount"
                    value={values.fee_amount}
                    onBlur={handleBlur}
                    onChange={handleChange}
                />
                {errors.fee_amount && touched.fee_amount ? (
                    <small className="block mt-2 p-error">{errors?.fee_amount?.toString()}</small>
                ) : null}
            </div>
            <div className="mb-6">
                <label htmlFor="fee_percentage" className="">
                    Fee Percentage (%)
                </label>
                <InputText
                    id="fee_percentage"
                    type="number"
                    className={`w-full ${(errors.fee_percentage && touched.fee_percentage) && "p-invalid"}`}
                    name="fee_percentage"
                    value={values.fee_percentage}
                    onBlur={handleBlur}
                    onChange={handleChange}
                />
                {errors.fee_percentage && touched.fee_percentage ? (
                    <small className="block mt-2 p-error">{errors?.fee_percentage?.toString()}</small>
                ) : null}
            </div>
        </>
    )

    return (
        <>
            <FormWrapper
                noValidate
                enableReinitialize
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={PlatformFeeFormSchema}
                formInputs={formInputs}
                submitButton={
                    <FooterAction>
                        <Button loading={loading} className="justify-center w-full" size="large" type="submit">
                            {props.currentData ? 'Update' : 'Create'}
                        </Button>
                    </FooterAction>
                }
                className="gap-2" />
        </>
    )

}

export default PlatformFeeForm;