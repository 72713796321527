import React, {useEffect, useState} from 'react'
import {useDebounce} from "use-debounce";
import {useAppDispatch} from "../../hooks/useStore";
import {Tag} from "primereact/tag";
import {setLoading} from "../../redux/slices/commonSlice";
import {getBoxofficeUserListing, removeBoxOfficeUser} from "../../utils/apiRequestList/boxofficeAPI";
import {getEventDropdown} from "../../utils/apiRequestList/eventAPI";
import TableWrapper, {OnSortProps} from "../../components/listing/TableWrapper";
import SearchBox from "../../components/SearchBox";
import {Column} from "primereact/column";
import {Dialog} from "primereact/dialog";
import BoxfficeUserForm from "./BoxfficeUserForm";
import {Button} from "primereact/button";

function Boxoffice() {
    const [page, setPage] = useState(1);
    const [records, setRecords] = useState(10);
    const [data, setData]: any = useState(null);

    const [eventDropdown, setEventDropdown] = useState<{ _id: string, name: string }[]>([]);

    const [updateListing, setUpdateListing] = useState(false);
    const [currentData, setCurrentData] = useState<any>({});

    const [sortField, setSortField] = useState<string>('');
    const [sortOrder, setSortOrder] = useState<0 | 1 | -1 | null | undefined>(null);
    const [searchByEmail, setSearchByEmail] = useState("");
    const [debouncedSearchByEmail] = useDebounce(searchByEmail, 500);

    const [showForm, setShowForm] = useState(false);
    const [formHeader, setFormHeader] = useState('');

    const dispatch = useAppDispatch();

    const emailColumnBody = (rowData: any) => {
        return <div className='w-full overflow-hidden text-ellipsis'>
            {rowData?.email}
        </div>
    }

    const nameColumnBody = (rowData: any) => {
        return <div className='w-full overflow-hidden text-ellipsis'>
            {rowData?.name}
        </div>
    }

    const eventsAccessColumnBody = (rowData: any) => {
        if (eventDropdown.length <= 0) return <div></div>

        const eventNames = rowData?.event_ids?.map((obj:any) => {
            const findData = eventDropdown.find((opt) => (opt._id === obj?.event_id))
            return findData?.name ?? ''
        })

        return <div className='w-full overflow-hidden text-ellipsis'>
            {eventNames ? eventNames.join(', ') : ''}
        </div>
    }


    const isActiveColumnBody = (rowData: any) => {
        let statusData : {label?:string, severity?:"warning" | "info" | "success" | "danger" | null | undefined} = {}

        switch (rowData?.is_active) {
            case false:
                statusData = {label: 'Inactive', severity: 'danger'}
                break;
            case true:
                statusData = {label: 'Active', severity: 'success'}
                break;
            default:
                statusData = {label: 'Unknown', severity: 'warning'}
                break;
        }

        return <Tag value={statusData?.label} severity={statusData?.severity} className="p-tag-rounded !px-2 !py-1.5 !font-medium !text-[14px] leading-6" />
    }

    const actionsColumnBody = (rowData:any) => {
        return (
            <div className="flex gap-2">
                <Button
                    className="mr-2 !rounded-full w-8 h-[32px] p-0 flex items-center justify-center"
                    onClick={() => openModelEdit(rowData)}
                    size="small"
                >
                    <i className="pi pi-pencil" />
                </Button>
                <Button onClick={async () => {
                    dispatch(setLoading(true))
                    await removeBoxOfficeUser(rowData?._id)
                    setUpdateListing(true)
                }} size="small" className="mr-2 !rounded-full w-8 h-[32px] p-0 flex items-center justify-center">
                    <i className="pi pi-trash" />
                </Button>
            </div>
        )
    }

    const getListing = async () => {
        let sortQuery = ''
        let searchQuery = ''

        if (sortField) {
            sortQuery += `&sortBy=${sortField}`
            if (sortOrder) {  // positive
                sortQuery += `&sortOrder=${sortOrder}`
            }
        }

        if (debouncedSearchByEmail) {
            searchQuery += `&searchEmail=${debouncedSearchByEmail}`
        }

        dispatch(setLoading(true));
        const response = await getBoxofficeUserListing(`&page=${page}&records=${records}${sortQuery}${searchQuery}`);
        if (response?.data?.success) {
            setData(response?.data?.data);
        }
        dispatch(setLoading(false));
        setUpdateListing(false);
    }

    const getEventDropdownOptions = async () => {
        const response = await getEventDropdown();
        if (response?.data?.success) {
            setEventDropdown(response?.data?.data);
        }
    }

    useEffect(() => {
        getEventDropdownOptions()
    }, []);

    useEffect(() => {
        getListing();
    }, [page, records, sortField, sortOrder, debouncedSearchByEmail])

    useEffect(() => {
        if (updateListing) {
            getListing();
        }
    }, [updateListing])

    const setPageLimit = (limit: number) => {
        setRecords(limit);
        setPage(1);
    }

    const onSort = ({ sortField, sortOrder }: OnSortProps) => {
        setSortField(sortField);
        setSortOrder(sortOrder);
        setPage(1);
    }

    const openModalCreate = () => {
        setFormHeader("Create Boxoffice User");
        setShowForm(true);
        setCurrentData(null);
    };

    const openModelEdit = async (rowData:any) => {
        setFormHeader("Edit Boxoffice User");
        setCurrentData(rowData);
        setShowForm(true);
    };

    return <>
        <Dialog
            header={formHeader}
            visible={showForm}
            onHide={() => setShowForm(false)}
            style={{ width: "50vw", fontSize: '14px' }}
            breakpoints={{ "960px": "75vw", "641px": "100vw" }}
            contentClassName="!pb-4 text-sm"
        >
            <BoxfficeUserForm
                setVisible={setShowForm}
                setUpdateListing={setUpdateListing}
                currentData={currentData}
                eventDropdownOptions={eventDropdown}
            />
        </Dialog>

        <h2 className="mb-[15px] !pb-0 h2 text-aria_dark">Boxoffice User</h2>
        <div className="flex justify-between align-middle">
            <Button
                label="Add a user"
                icon="pi pi-plus"
                size="large"
                className="!text-[14px] !px-12"
                onClick={() => openModalCreate()}
            />
            <SearchBox
                placeholder="Search By Email"
                onChange={(e) => {
                    setPage(1);
                    setSearchByEmail(e.target.value);
                }}
            />
        </div>
        <div className="mt-5">
            {data && (
                <TableWrapper
                    data={data}
                    loading={false}
                    setRecords={setPageLimit}
                    scrollable
                    scrollHeight="70vh"
                    nextPage={() => {
                        if (!data.hasNextPage) return
                        setPage(current => (current + 1))
                    }}
                    prevPage={() => {
                        if (!data.hasPrevPage) return
                        setPage(current => (current - 1))
                    }}
                    sortField={sortField}
                    sortOrder={sortOrder}
                    onSort={onSort}
                >
                    <Column field="email" header="Email" body={emailColumnBody} style={{ width: '121px' }} />
                    <Column field="name" header="Name" body={nameColumnBody} style={{ width: '121px' }} />
                    <Column field="boxoffice_user_events" header="Events Access" body={eventsAccessColumnBody} style={{ width: '121px' }} />
                    <Column field="is_active" header="Is Active" sortable body={isActiveColumnBody} style={{ width: '60px' }} />
                    <Column field="actions" header="Actions" body={actionsColumnBody} style={{ width: '60px' }} />
                </TableWrapper>
            )}
        </div>
    </>
}

export default Boxoffice;