import { useState, useEffect } from "react";
import { customerListing } from "../../utils/apiRequestList/customerAPI";
import TableWrapper from "../../components/listing/TableWrapper";
import SearchBox from "../../components/SearchBox";
import { useAppDispatch } from "../../hooks/useStore";
import { setLoading } from "../../redux/slices/commonSlice";
import { Column } from "primereact/column";
import { useDebounce } from "use-debounce";
import TagStatus from "../../components/TagStatus";

interface ApiResponseType {
    customerId: string;
    phoneNumber: string;
    email: string;
    status: number;
    name: string;
    subscription: boolean;
}

const Customer = () => {
    const [data, setData]: any = useState(null);
    const [params, setParams] = useState({
        sortBy: 'createdAt',
        sortOrder: 'desc'
    })
    const [searchByName, setSearchByName] = useState('')
    const [records, setRecords] = useState(10);
    const [page, setPage] = useState(1);
    const [updateListing, setUpdateListing] = useState(false);
    const dispatch = useAppDispatch();
    const [debouncedSearchByName] = useDebounce(searchByName, 500);

    const getArtistListing = async (params: any) => {
        dispatch(setLoading(true));
        let sortName = '';
        if (debouncedSearchByName) {
            sortName += `&searchByName=${debouncedSearchByName}`
        }
        const response = await customerListing(`&page=${page}&records=${records}&sortOrder=${params?.sortOrder}&sortBy=${params?.sortBy}${sortName}`);
        if (response?.data?.success) {
            setData(response?.data?.data)
        }
        setUpdateListing(false);
        dispatch(setLoading(false));
    }

    const setPageLimit = (limit: number) => {
        setRecords(limit);
        setPage(1);
    }

    const updateData = () => {
        if (!data) {
            setData(data);
        } else {
            getArtistListing(params)
        }
    }

    useEffect(() => {
        updateData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, params, records, debouncedSearchByName])

    useEffect(() => {
        if (updateListing) {
            updateData()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateListing])

    useEffect(() => {
        async function fetchData() {
            dispatch(setLoading(true))
            const response = await customerListing(`records=10&sortBy=createdAt&sortOrder=desc`)
            setData(response?.data?.data)

            dispatch(setLoading(false))
        }

        fetchData();

    }, [])

    const statusColumn = ({ status }: ApiResponseType) => {
        //example to use custom tag
        // return <TagStatus status={status} label={['test', 'tos', 'tas']} severity={['warning', 'warning', 'info']} />
        return <TagStatus status={status} />
    }

    const subscriptionColumn = ({ subscription }: ApiResponseType) => {
        return <TagStatus status={subscription ? 1 : 0} />
    }


    return <>
        <h2 className="mb-[15px] h2 !pb-0 text-aria_dark">Customer</h2>
        <div className="flex justify-end align-middle mb-5">
            <SearchBox
                placeholder="Search By Email"
                onChange={(e) => {
                    setPage(1)
                    setSearchByName(e.target.value)
                }}
            />
        </div>
        <div>
            {data ? (
                <TableWrapper
                    data={data}
                    loading={false}
                    setRecords={setPageLimit}
                    scrollable
                    scrollHeight='70vh'
                    onSort={(e) => {
                        setPage(1)
                        setParams({ ...params, sortBy: e.sortField, sortOrder: params.sortOrder === 'asc' ? 'dsc' : 'asc' })
                    }}
                    nextPage={() => {
                        if (!data.hasNextPage && (page < data.totalPages)) return
                        setPage((current: any) => (current + 1))
                    }}
                    prevPage={() => {
                        if (!data.hasPrevPage) return
                        setPage((current: any) => (current - 1))
                    }}
                >
                    <Column field="customerId" header="Customer Id" body={''}></Column>
                    <Column field="email" header="Email" sortable body={''}></Column>
                    <Column field="name" header="Name" sortable body={''}></Column>
                    <Column field="country" header="Country" body={''}></Column>
                    <Column field="status" header="Status" sortable body={statusColumn} style={{ width: '150px' }}></Column>
                    <Column field="subscription" header="Subscription" body={subscriptionColumn} style={{ width: '150px' }}></Column>
                </TableWrapper>
            ) : null}
        </div>
    </>
}

export default Customer;