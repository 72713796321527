import React, {useEffect, useState} from 'react';
import {Dropdown} from "primereact/dropdown";
import {useAppSelector} from "../../hooks/useStore";

const DialCode = (props:any) => {
    const { country }:any = useAppSelector((state) => state.common);
    const [dialCode, setDialCode] = useState([]);

    useEffect(() => {
        setDialCode(country.map((item:any) => {
            return {name: item.name, flag:`https://flagcdn.com/${item.code.toLowerCase()}.svg`, value: item.name }
        }));
    }, [country]);

    return (
        <div className='flex flex-col'>
            <Dropdown
                inputId={props?.inputId}
                value={props?.value}
                onChange={(e:any)=>{
                    props?.onChange(e)
                }}
                placeholder={props?.placeholder}
                options={dialCode}
                name={props?.name}
                optionLabel={props?.optionLabel}
                filter
                valueTemplate={(option, props) => {
                    if (option) {
                        return (
                            <div className="flex align-items-center">
                                <img
                                    alt={option.name}
                                    src={option.flag}
                                    className={`mr-2 border bg-slate-300`}
                                    style={{ width: "18px" }}
                                />
                                <div>{option.name}</div>
                            </div>
                        );
                    }
                    return <span>{props.placeholder}</span>;
                }}
                itemTemplate={(option) => {
                    return (
                        <div className="flex align-items-center">
                            <img
                                alt={option.name}
                                src={option.flag}
                                className={`mr-2 border bg-slate-300`}
                                style={{ width: "20px" }}
                            />
                            <div>{option.name}</div>
                        </div>
                    );
                }}
                className={`w-[120px] grow-0 shrink-0 ${
                    props?.errors &&
                    props?.touched &&
                    "p-invalid"
                }`}
            />
            {props?.errors && props?.touched ? (
                <small className="block mt-2 p-error">
                    {props?.errors?.toString()}
                </small>
            ) : null}
        </div>
    )
}

export default DialCode;