import { useState, useEffect } from "react";
import { getPolicyCustomContent } from "../../../utils/apiRequestList/eventAPI";
import { useAppSelector } from "../../../hooks/useStore";
import PolicyCustomContentForm from "../components/PolicyCustomContentForm";

const PolicyCustomContent = () => {
    const { basicInfo } = useAppSelector((state) => state.event);
    const [currentData, setCurrentData] = useState<any>(null);

    const fetchData = async () => {
        const response = await getPolicyCustomContent(basicInfo._id)
        setCurrentData(response?.data?.data)
    }
    useEffect(() => {
        fetchData()
    }, [])

    return <>
        <PolicyCustomContentForm basicInfo={basicInfo} currentData={currentData} fetchData={fetchData} />
    </>
}
export default PolicyCustomContent;