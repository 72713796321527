export default function CalendarIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="12"
      viewBox="0 0 10 12"
      fill="none"
    >
      <path
        d="M2.58333 6.75C2.42593 6.75 2.29398 6.69676 2.1875 6.59028C2.08102 6.4838 2.02778 6.35185 2.02778 6.19445C2.02778 6.03704 2.08102 5.90509 2.1875 5.79861C2.29398 5.69213 2.42593 5.63889 2.58333 5.63889C2.74074 5.63889 2.87269 5.69213 2.97917 5.79861C3.08565 5.90509 3.13889 6.03704 3.13889 6.19445C3.13889 6.35185 3.08565 6.4838 2.97917 6.59028C2.87269 6.69676 2.74074 6.75 2.58333 6.75ZM5.04167 6.75C4.88426 6.75 4.75232 6.69676 4.64583 6.59028C4.53935 6.4838 4.48611 6.35185 4.48611 6.19445C4.48611 6.03704 4.53935 5.90509 4.64583 5.79861C4.75232 5.69213 4.88426 5.63889 5.04167 5.63889C5.19907 5.63889 5.33102 5.69213 5.4375 5.79861C5.54398 5.90509 5.59722 6.03704 5.59722 6.19445C5.59722 6.35185 5.54398 6.4838 5.4375 6.59028C5.33102 6.69676 5.19907 6.75 5.04167 6.75ZM7.40278 6.75C7.24537 6.75 7.11343 6.69676 7.00694 6.59028C6.90046 6.4838 6.84722 6.35185 6.84722 6.19445C6.84722 6.03704 6.90046 5.90509 7.00694 5.79861C7.11343 5.69213 7.24537 5.63889 7.40278 5.63889C7.56019 5.63889 7.69213 5.69213 7.79861 5.79861C7.90509 5.90509 7.95833 6.03704 7.95833 6.19445C7.95833 6.35185 7.90509 6.4838 7.79861 6.59028C7.69213 6.69676 7.56019 6.75 7.40278 6.75ZM0.833333 11.1111C0.611111 11.1111 0.416667 11.0278 0.25 10.8611C0.0833333 10.6944 0 10.5 0 10.2778V1.66667C0 1.44444 0.0833333 1.25 0.25 1.08333C0.416667 0.916667 0.611111 0.833333 0.833333 0.833333H1.73611V0H2.63889V0.833333H7.36111V0H8.26389V0.833333H9.16667C9.38889 0.833333 9.58333 0.916667 9.75 1.08333C9.91667 1.25 10 1.44444 10 1.66667V10.2778C10 10.5 9.91667 10.6944 9.75 10.8611C9.58333 11.0278 9.38889 11.1111 9.16667 11.1111H0.833333ZM0.833333 10.2778H9.16667V4.30556H0.833333V10.2778ZM0.833333 3.47222H9.16667V1.66667H0.833333V3.47222Z"
        fill="#000"
      />
    </svg>
  );
}
