import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Menu } from "primereact/menu";
import { MenuItem } from "primereact/menuitem";
import React, { useEffect, useRef, useState } from 'react';
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { useAppDispatch } from '../../../hooks/useStore';
import { setLoading } from '../../../redux/slices/commonSlice';
import { get, remove } from "../../../utils/AxiosReqeust";
import deleteConfirmation from '../../../utils/deleteConfirmation';
import PlatformFeeForm from "../components/PlatformFeeForm";

const PlatformFee = () => {
    const { id } = useParams();
    const dispatch = useAppDispatch();

    const [platformFees, setPlatformFees] = useState([]);
    const [currentEvent, setCurrentEvent] = useState<any>({});

    const menuRight = useRef<any>(null);
    const [menuItems, setMenuItems] = useState<MenuItem[]>([]);

    const [visible, setVisible] = useState(false);
    const [currentData, setCurrentData] = useState<any>(null);
    const [formHeader, setFormHeader] = useState('Create Platform Fee');
    const [updateListing, setUpdateListing] = useState(0);

    useEffect(() => {
        getList()
    }, [updateListing]);

    const getList = async () => {
        dispatch(setLoading(true));
        const response = await get(`/settings/platform-fee/listing/${id}`);
        if (response?.data?.success) {
            setPlatformFees(response?.data?.data?.platformFees);
            setCurrentEvent(response?.data?.data?.event);
        }
        dispatch(setLoading(false));
    }

    const deletePlatformFee = async (id: string) => {
        dispatch(setLoading(true));
        const response = await remove(`/settings/platform-fee/${id}`);
        if (response?.data?.success) {
            toast.success('Deleted Platform Fee');
            setUpdateListing(prev => prev + 1)
        }
        dispatch(setLoading(false));
    }

    const getInnerTitle = ({min_range, max_range, fee_amount, fee_percentage}: any, currencySymbol: string) => {
        let final = ''
        if (min_range > -1 && parseFloat(max_range) > -1) final = final + `${currencySymbol+min_range} ~ ${currencySymbol+max_range}`
        else final = final + `>${currencySymbol+min_range}`

        if (parseFloat(fee_amount) > -1) final = final + ` = ${currencySymbol+fee_amount}`
        else final = final + ` = ${fee_percentage} %`

        return final
    }

    return <>
        <Dialog header={formHeader} visible={visible} onHide={() => setVisible(false)}
                style={{ width: '50vw' }} breakpoints={{ '960px': '75vw', '641px': '100vw' }}
                contentClassName="!pb-4"
        >
            <PlatformFeeForm setVisible={setVisible}
                          setUpdateListing={setUpdateListing}
                          currentData={currentData}
                             eventId={currentEvent?._id ?? ''}
            />
        </Dialog>
        <h2 className="text-3xl font-semibold text-aria_dark mb-[15px]">Platform Fee - {currentEvent?.translations ? currentEvent?.translations[0]?.title : ''}</h2>
        <div className={`relative items-center p-3 bg-[#edecf3] border border-solid border-[#6650f2] my-2 rounded-lg w-full`}>
            {
                platformFees.length ?
                    platformFees.map((platform_fee: any, index) => {
                        return <div key={index} className="relative select-none flex items-center p-3 bg-[#edecf3] border border-solid border-[#6650f2] my-2 rounded-lg full-pannel">
                            <div className='grow flex flex-row justify-between items-center w-full'>
                                {getInnerTitle(platform_fee, currentEvent?.currency_id?.currency_symbol ?? '')}
                                <div className='pr-2 flex flex-row justify-end items-center gap-x-2'>
                                    <Button
                                        size="small"
                                        className="!rounded-full w-8 h-[32px] p-0 flex items-center justify-center"
                                        onClick={(event) => {
                                            setMenuItems([
                                                {
                                                    label: 'Edit',
                                                    command: () => {
                                                        setCurrentData(platform_fee)
                                                        setFormHeader('Edit Platform Fee')
                                                        setVisible(true)
                                                    }
                                                },
                                                {
                                                    label: 'Delete',
                                                    command: () => deleteConfirmation(async () => await deletePlatformFee(platform_fee._id))
                                                },
                                            ]);
                                            menuRight.current.toggle(event);
                                        }}
                                    >
                                        <i className="pi pi-ellipsis-v" />
                                    </Button>
                                </div>
                            </div>
                        </div>
                    })
                    :
                    <div className="p-4 text-xs text-center border-2     text-slate-600">
                        {/* <EmptyIcon className="w-5 h-5 mx-auto" /> */}
                        No item
                    </div>
            }
            <div className="flex justify-center pt-5">
                <Button
                    text
                    size="small"
                    type="button"
                    icon="pi pi-plus"
                    label="Create Platform Fee"
                    onClick={() => {
                        setCurrentData(null)
                        setFormHeader('Create Platform Fee')
                        setVisible(true)
                    }}
                />
            </div>
        </div>
        <Menu model={menuItems} popup ref={menuRight} popupAlignment="right" />
    </>
}

export default PlatformFee;