import axios from "axios"
import {JSEncrypt} from "jsencrypt";
// this util use for api integration setup

// const getStoreCode = async () => {
//     const userLang = 'en';
//     return userLang === 'en' ? 'app_store_en' : 'app_store_cn'
// }

var encrypt = new JSEncrypt();

var publicKey = process.env.REACT_APP_ARIA_PUB_KEY ?? "";

encrypt.setPublicKey(publicKey);

function axiosInit() {
    axios.defaults.withCredentials = true;
    axios.defaults.baseURL = `${process.env.REACT_APP_API_URL}`;
    axios.defaults.headers.post["Content-Type"] = "application/json";
    axios.interceptors.response.use(
        response => response,
        error => {
            return Promise.reject(error);
        }
    );
}

async function setToken() {
    const token = '';
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}

async function post(path: string, payload: object, header = {}) {
    // const storeCode = await getStoreCode()
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const headers = {
        ...{
            // 'store-code': storeCode,
            'sign': '',
            'X-Tz': encrypt.encrypt(timezone),
            'Content-Type': 'application/json'
        },
        ...header
    }
    let params: any = payload
    if (headers["Content-Type"] === 'application/json') {
        params = JSON.stringify(payload)
    }
    let response = axios.post(
        path,
        params,
        {
            headers
        }
    );
    return response;
}

async function put(path: string, payload: object, header = {}) {
    // const storeCode = await getStoreCode()
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const headers = {
        ...{
            // 'store-code': storeCode,
            'sign': '',
            'X-Tz': encrypt.encrypt(timezone),
            'Content-Type': 'application/json'
        },
        ...header
    }
    let params: any = payload
    if (headers["Content-Type"] === 'application/json') {
        params = JSON.stringify(payload)
    }
    let response = axios.put(
        path,
        params,
        {
            headers
        }
    );
    return response;
}

async function get(path: string, header = {}) {
    // const storeCode = await getStoreCode()
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const headers = {
        ...{
            // 'store-code': storeCode,
            'sign': '',
            'X-Tz': encrypt.encrypt(timezone),
            'Content-Type': 'application/json'
        },
        ...header
    }
    let response = axios.get(
        path,
        {
            headers
        }
    );
    return response;
}

async function remove(path: string) {
    // const storeCode = await getStoreCode()
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    let response = await axios.delete(
        path,
        {
            headers: {
                // 'store-code': storeCode,
                'sign': '',
                'X-Tz': encrypt.encrypt(timezone),
                'Content-Type': 'application/json'
            }
        }
    );
    return response;
}

export {
    axiosInit,
    setToken,
    post,
    put,
    get,
    remove,
};