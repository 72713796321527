import React from 'react';
import { store } from '../src/redux/store';
import { Provider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';
import { Outlet } from "react-router-dom";
import ExceptionHandler from "./components/handler/ExceptionHandler";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const helmetContext = {};
  return (
    <HelmetProvider context={helmetContext}>
        <Provider store={store}>
            <Outlet />
            <ToastContainer
              position="top-center"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"
            />
            <ExceptionHandler/>
        </Provider>
    </HelmetProvider>
  );
}

export default App;
