import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';
import { MenuItem } from 'primereact/menuitem';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from '../../../hooks/useStore';
import { setLoading } from '../../../redux/slices/commonSlice';
import { setSelectedAddon } from '../../../redux/slices/eventSlice';
import { get, post, remove } from '../../../utils/AxiosReqeust';
import { formatCurrency } from '../../../utils/formatCurrency';
import Repeater from '../../CMS/Home/Repeater/Index';
import { Addon } from '../../../redux/types/event';
import deleteConfirmation from '../../../utils/deleteConfirmation';

    const EventDetailAddons = () => {
        const dispatch = useAppDispatch();
        const { basicInfo } = useAppSelector((state) => state.event);
        const navigate = useNavigate();
        const { locales }: any = useAppSelector((state) => state.common);
        const [currentLang, setCurrentLang] = useState<string>(locales?.default_language ?? 'en')
        const [addons, setAddons] = useState<any[]>([]);
        const menuRight = useRef<any>(null);
        const [menuItems, setMenuItems] = useState<MenuItem[]>([]);

        useEffect(() => {
            getData();
        }, []);

        const getData = async () => {
            try {
                const [
                    addons,
                ] = await Promise.all([
                    get(`/ticket/addon/list/${basicInfo?._id}`),
                ]);
                if (addons.data.success && addons.data.data) {
                    setAddons(addons.data.data.map((addon: any) => {
                        return {
                            ...addon,
                            ...{
                                name: addon?.translations?.filter((translation: any) => translation?.language === currentLang)[0]?.name,
                                price_formatted: formatCurrency(addon?.price, basicInfo.currency_id.currency_code),
                            }
                        }
                    }));
                }
            }
            catch (error) {
                console.log(error);
            }
        }

        const deleteRecord = async (addon: any) => {
            const {titleKey, ...rest} = addon;
            dispatch(setLoading(true));
            const response: any = await remove(`ticket/addon/${rest?._id}`);
            if (response?.data?.success) {
                await getData();
                toast.success(response?.data?.message);
            }
            dispatch(setLoading(false));
        }

        const updateSortOrder = async (values: any) => {
            try {
                const response: any = await post(`/ticket/addon/update-sort-order`, {sort: JSON.stringify(values)});
                if (response?.data?.success && response?.data?.data) {
                    toast.success(response?.data?.message);
                }
            }
            catch (error) {
                // toast.error(JSON.stringify(error));
            }
        };
        

        return (
            <>
                <h2 className="text-3xl font-semibold text-aria_dark mb-4">Addons</h2>
                <Button
                    label="Create addon"
                    icon="pi pi-plus"
                    size="large"
                    className="!text-[14px] !px-12"
                    onClick={() => {
                        dispatch(setSelectedAddon({} as Addon));
                        navigate(`/events/detail/addons/create`)
                    }}
                />
                {addons?.length > 0 && (
                    <Repeater
                        data={addons}
                        titleKey={(addon) => {
                            return <div className='grow flex flex-row justify-between items-center w-full'>
                                {addon?.name}
                                <div className='pr-2 flex flex-row justify-end items-center gap-x-2'>
                                    <Button
                                        size="small"
                                        className="!rounded-full w-8 h-[32px] p-0 flex items-center justify-center"
                                        onClick={(event) => {
                                            setMenuItems([
                                                {
                                                    label: 'Edit',
                                                    command: () => {
                                                        const {titleKey, ...rest} = addon;
                                                        dispatch(setSelectedAddon(rest));
                                                        navigate(`/events/detail/addons/create`);
                                                    }
                                                },
                                                {
                                                    label: 'Delete',
                                                    command: () => deleteConfirmation(async () => await deleteRecord(addon))
                                                },
                                            ]);
                                            menuRight.current.toggle(event);
                                        }}
                                    >
                                        <i className="pi pi-ellipsis-v" />
                                    </Button>
                                </div>
                            </div>;
                        }}
                        groupName="Addons"
                        onOrderChange={(updated) => {
                            updateSortOrder(updated?.map((item: any) => {
                                return {id: item?.id, sort_order: item?.order}
                            }))
                            setAddons(updated)
                        }}
                        template={{
                            render: (item, index) => (
                                <div className="flex flex-col justify-between gap-y-5 w-full h-full">
                                    <div className="flex flex-row justify-between items-center gap-x-5 w-full">
                                        <div>
                                            <div>Sale Start: <span className="text-aria_dark">{item?.sales_start_formatted}</span></div>
                                            <div>Sale End: <span className="text-aria_dark">{item?.sales_end_formatted}</span></div>
                                        </div>
                                        <div>
                                            <div className="text-right">Sold</div>
                                            <div className="text-right text-aria_dark">{item?.sold}{!!item?.quantity && (<span> / {item?.quantity}</span>)}</div>
                                        </div>
                                    </div>
                                    <div className="flex flex-row justify-between items-center gap-x-5 w-full">
                                        {item?.addon_viewability_type_id?._id === "64f7df36728437a86d7b75ad" && (
                                            <div className="text-aria_dark">{item?.addon_viewability_type_id?.name}</div>
                                        )}
                                        <div className="text-right ml-auto flex items-center gap-x-2">Price: <span className="text-[22px] tracking-[2px] font-semibold text-aria_dark">{item?.price_formatted}</span></div>
                                    </div>
                                </div>
                            )
                        }}
                    />
                )}
                <Menu model={menuItems} popup ref={menuRight} popupAlignment="right" />
            </>
        )
    }

export default EventDetailAddons
