import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { User } from "../types/user";
import axios from "axios";

type AuthState = {
  user: User | null;
  token: string | null;
};

const token = localStorage.getItem("access_token");
const user = localStorage.getItem("user") || "";

if (token) axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

const initialState: AuthState = {
  user: user ? JSON.parse(user) : null,
  token: token ?? null,
};

const authSlice = createSlice({
  name: "userAuth",
  initialState,
  reducers: {
    setToken: (
      state,
      { payload: { token } }: PayloadAction<{ token: string }>
    ) => {
      state.token = token;
      localStorage.setItem("access_token", token);
    },
    setProfile: (state, { payload: user }: PayloadAction<User>) => {
      state.user = user;
      localStorage.setItem("user", JSON.stringify(user));
    },
    logout: (state) => {
      localStorage.removeItem("access_token");
      localStorage.removeItem("user");
      state.token = null;
      state.user = null;
    },
  },
});

export const { setToken, setProfile, logout } = authSlice.actions;
export default authSlice.reducer;
