import { confirmDialog } from 'primereact/confirmdialog';

export default (onConfirmCallback: () => void) => {
    confirmDialog({
        closable: false,
        message: 'You are attempting to delete this record, and this action cannot be undone. Would you like to proceed?',
        header: 'Alert!',
        icon: 'pi pi-info-circle',
        contentClassName: 'min-w-[400px] w-auto h-auto max-w-[200px]',
        acceptClassName: 'p-button-danger',
        accept: onConfirmCallback,
        acceptLabel: 'Yes, Delete',
        rejectLabel: 'Cancel',
        pt: {
            footer: {
                style: { backgroundColor: "#edecf3" },
            },
        },
    });
}