import React, {useEffect, useState} from 'react'
import {Dropdown} from "primereact/dropdown";
import SearchBox from "../../components/SearchBox";
import TableWrapper, {OnSortProps} from "../../components/listing/TableWrapper";
import {Column} from "primereact/column";
import {useDebounce} from "use-debounce";
import {useAppDispatch} from "../../hooks/useStore";
import { getTransactionListing } from "../../utils/apiRequestList/transactionsAPI";
import {setLoading} from "../../redux/slices/commonSlice";
import {Tag} from "primereact/tag";

function Transactions() {
    const [page, setPage] = useState(1);
    const [records, setRecords] = useState(10);
    const [data, setData]: any = useState(null);

    const [updateListing, setUpdateListing] = useState(false);
    const [currentData, setCurrentData] = useState({});

    const [sortField, setSortField] = useState<string>('');
    const [sortOrder, setSortOrder] = useState<0 | 1 | -1 | null | undefined>(null);
    const [searchByOrderId, setSearchByOrderId] = useState("");
    const [filterStatus, setFilterStatus] = useState<any>(null);
    const [debouncedSearchByOrderId] = useDebounce(searchByOrderId, 500);

    const statusOptions = [
        { name: 'Paid', code: 2 },
        { name: 'Pending', code: 1 },
        { name: 'Refunded', code: 0 },
    ];

    const dispatch = useAppDispatch();

    // field="order_no"
    const orderNoColumnBody = (rowData: any) => {
        return <div className='w-full overflow-hidden text-ellipsis'>
            {rowData?.order_no_formatted}
        </div>
    }

    // field="order_id"
    const orderIdColumnBody = (rowData: any) => {
        return <div className='w-full overflow-hidden text-ellipsis'>
            {rowData?.order_id_formatted}
        </div>
    }
    // field="status"
    const statusColumnBody = (rowData: any) => {
        let statusData : {label?:string, severity?:"warning" | "info" | "success" | "danger" | null | undefined} = {}

        switch (rowData?.status) {
            case 0:
                statusData = {label: 'Refunded', severity: 'warning'}
                break;
            case 1:
                statusData = {label: 'Pending', severity: 'info'}
                break;
            case 2:
                statusData = {label: 'Paid', severity: 'success'}
                break;
            default:
                statusData = {label: 'Unknown', severity: 'danger'}
                break;
        }

        return <Tag value={statusData?.label} severity={statusData?.severity} className="p-tag-rounded !px-2 !py-1.5 !font-medium !text-[14px] leading-6" />
    }
    // field="total"
    const totalColumnBody = (rowData: any) => {
        return <div className='w-full overflow-hidden text-ellipsis'>
            {rowData?.total}
        </div>
    }

    // field="currency"
    const currencyColumnBody = (rowData: any) => {
        return <div className='w-full overflow-hidden text-ellipsis'>
            {rowData?.currency}
        </div>
    }

    // field="customer_object"
    const customerIdColumnBody = (rowData: any) => {
        return <div className='w-full overflow-hidden text-ellipsis'>
            {rowData?.customer_ic_last_four ? rowData?.customer_ic_last_four : '-'}
        </div>
    }

    const customerNameColumnBody = (rowData: any) => {
        return <div className='w-full overflow-hidden text-ellipsis'>
            {rowData?.customer_object?.first_name + ' ' + rowData?.customer_object?.last_name}
        </div>
    }

    // field="event_object"
    const eventTitleColumnBody = (rowData: any) => {
        return <div className='w-full overflow-hidden text-ellipsis'>
            {rowData?.event_object?.translations[0]?.title}
        </div>
    }

    const getListing = async () => {
        let sortQuery = ''
        let searchQuery = ''
        let filterStatusQuery = ''

        if (sortField) {
            sortQuery += `&sortBy=${sortField}`
            if (sortOrder) {  // positive
                sortQuery += `&sortOrder=${sortOrder}`
            }
        }

        if (debouncedSearchByOrderId) {
            searchQuery += `&searchOrderId=${debouncedSearchByOrderId}`
        }

        if (filterStatus && filterStatus?.code !== null && !isNaN(filterStatus?.code)) {
            filterStatusQuery += `&filterStatus=${filterStatus?.code}`
        }

        dispatch(setLoading(true));
        const response = await getTransactionListing(`&page=${page}&records=${records}${sortQuery}${searchQuery}${filterStatusQuery}`);
        if (response?.data?.success) {
            setData(response?.data?.data);
        }
        dispatch(setLoading(false));
        setUpdateListing(false);
    }

    useEffect(() => {
        getListing();
    }, [page, records, sortField, sortOrder, debouncedSearchByOrderId, filterStatus])

    useEffect(() => {
        if (updateListing) {
            getListing();
        }
    }, [updateListing])

    const setPageLimit = (limit: number) => {
        setRecords(limit);
        setPage(1);
    }

    const onSort = ({ sortField, sortOrder }: OnSortProps) => {
        setSortField(sortField);
        setSortOrder(sortOrder);
        setPage(1);
    }

    const handleChange = (e: any) => {
        setSearchByOrderId(e.target.value);
    }

    return <>
        <h2 className="mb-[15px] !pb-0 h2 text-aria_dark">Transactions</h2>
        <div className="flex align-middle">
            <SearchBox
                placeholder="Search By Order Id"
                onChange={(e) => {
                    setPage(1);
                    setSearchByOrderId(e.target.value);
                }}
            />

            <Dropdown value={filterStatus} onChange={(e) => setFilterStatus(e.value)} options={statusOptions}
                      optionLabel="name" placeholder="Filter by Status" className= 'ml-3' />
        </div>
        <div className="mt-5">
            {data && (
                <TableWrapper
                    data={data}
                    loading={false}
                    setRecords={setPageLimit}
                    scrollable
                    scrollHeight="70vh"
                    nextPage={() => {
                        console.log('here')
                        if (!data.hasNextPage) return
                        setPage(current => (current + 1))
                    }}
                    prevPage={() => {
                        if (!data.hasPrevPage) return
                        setPage(current => (current - 1))
                    }}
                    sortField={sortField}
                    sortOrder={sortOrder}
                    onSort={onSort}
                >
                    <Column field="order_no" header="Order No" body={orderNoColumnBody} style={{ width: '121px' }} />
                    <Column field="order_id" header="Order Id" body={orderIdColumnBody} style={{ width: '121px' }} />
                    <Column field="status" header="status" body={statusColumnBody} style={{ width: '60px' }} />
                    <Column field="currency" header="Currency" body={currencyColumnBody} style={{ width: '60px' }} />
                    <Column field="total" header="Total" body={totalColumnBody} style={{ width: '121px' }} />
                    <Column field="customer_object" header="Customer Name" body={customerNameColumnBody} style={{ width: '121px' }} />
                    <Column field="customer_ic_last_four" header="Customer ID" body={customerIdColumnBody} style={{ width: '60px' }} />
                    <Column field="event_object" header="Event Title" body={eventTitleColumnBody} style={{ width: '121px' }} />
                </TableWrapper>
            )}
        </div>
    </>
}

export default Transactions;