import { post, get, remove, put } from "../AxiosReqeust";

const userLogin = async (params: { email: string; password: string }) => {
    return await post("/user/login", {
        email: params?.email,
        password: params?.password,
    });
};

const userLogout = ()=> {
    return post("/user/logout", {});
}

const createUser = async (body: object) => {
    return await post(`/user`, body);
};

const updateUserById = async (id: string, body: any) => {
    return await put(`/user/${id}`, body);
};

const userListing = async (query?: string) => {
    return await get(`/user?${query ?? ''}`);
};

const deleteUser = async (id: string) => {
    return await remove(`/user/${id}`);
};

const forgetPassword = async (params: any) => {
    return await post("/user/forget-password", {
        email: params,
    });
};

const resetPassword = (
    token: string,
    password: string,
    confirmPassword: string
) => {
    return post("/user/reset-password/token", {
        token,
        password,
        confirmPassword,
    });
};

const editProfile = (contactNo: string, profilePicture: any,) => {
    const formData = new FormData();

    formData.append('contactNo', contactNo)
    formData.append('profilePicture', profilePicture)

    return post("/user/profile", formData, {
        'Content-Type': 'multipart/form-data'
    });
};

const updatePassword = (oldPassword: string, newPassword: string) => {
    return post("/user/profile/update-password", {
        oldPassword,
        newPassword,
    });
};

export {
    userLogin,
    userListing,
    createUser,
    deleteUser,
    updateUserById,
    forgetPassword,
    resetPassword,
    editProfile,
    updatePassword,
};
