import React, {ReactNode, useState, useEffect} from "react";
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import { Button } from 'primereact/button';

import TableFooter from "./TableFooter";
import SortIcon from "../icons/SortIcon";

export interface TableData {
    "docs": Array<object>,
    "totalDocs": number,
    "offset": number,
    "limit": number,
    "totalPages": number,
    "page": number,
    "pagingCounter": number,
    "hasPrevPage": boolean,
    "hasNextPage": boolean,
    "prevPage": null | number,
    "nextPage": null | number,
}

export interface OnSortProps {
    sortField: string,
    sortOrder: 0 | 1 | -1 | null | undefined,
}

interface TableWrapperProps {
    children?: ReactNode,
    loading?: boolean,
    data: TableData,
    records?: number,
    setRecords: (limit: number) => void,
    scrollable?: boolean,
    scrollHeight?: string,
    nextPage: () => void,
    prevPage: () => void,
    sortField?: string,
    sortOrder?: 0 | 1 | -1 | null | undefined,
    onSort?: (obj: OnSortProps) => void,
}

const TableWrapper = (props: TableWrapperProps) => {

    return <div>
        <DataTable
            value={props.data.docs}
            showGridlines
            scrollable={props?.scrollable}
            scrollHeight={props?.scrollHeight}
            tableStyle={{minWidth: '100%', maxWidth: '100%'}}
            sortField={props?.sortField}
            sortOrder={props?.sortOrder}
            sortIcon={SortIcon}
            onSort={props?.onSort}
            footer={(
                <TableFooter
                    totalRecords={props.data.totalDocs}
                    nextPage={props.nextPage}
                    prevPage={props.prevPage}
                    setRecords={props.setRecords}
                    page={props.data.page}
                    records={props.data.limit}
                />
            )}
            loading={props?.loading}
        >
            {props?.children}
        </DataTable>
    </div>
}

export default TableWrapper;