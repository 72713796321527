import isEqual from "lodash-es/isEqual"
import { FormikProps, FormikValues } from 'formik';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputSwitch } from 'primereact/inputswitch';
import { RadioButton } from 'primereact/radiobutton';
import React, { useEffect, useState } from 'react';
import { toast } from "react-toastify";
import * as Yup from 'yup';
import FormWrapper from "../../../components/form/FormWrapper";
import { useAppDispatch, useAppSelector } from '../../../hooks/useStore';
import { setLoading } from "../../../redux/slices/commonSlice";
import { setBasicInfo } from "../../../redux/slices/eventSlice";
import { get, post } from '../../../utils/AxiosReqeust';
import { getEventBasicInfo } from "../../../utils/apiRequestList/eventAPI";
import { removeNull } from '../../../utils/removeNull';
import FormCategoryTitle from "./FormCategoryTitle";

import 'rc-time-picker/assets/index.css';
import moment from 'moment';
import TimePicker from 'rc-time-picker';
const format = 'h:mm a';


const EventForm = ({ currentLang, onSubmitSuccess, resetDefaultLang, currentData, publishError, setPublishError }: any) => {
    const dispatch = useAppDispatch();
    const { basicInfo } = useAppSelector((state) => state.event);
    const [eventOrganisations, setEventOrganisations] = useState<any[]>([]);
    const [eventCategories, setEventCategories] = useState<any[]>([]);
    const [eventOccurrences, setEventOccurrences] = useState<any[]>([]);
    const [eventViewabilities, setEventViewabilities] = useState<any>(null);
    const [eventTimezones, setEventTimezones] = useState<any[]>([]);
    const [eventArtists, setEventArtists] = useState<any[]>([]);
    const [countries, setCountries] = useState([]);
    const [currencies, setCurrencies] = useState([]);

    useEffect(() => {
        const getFormInfo = async () => {
            try {
                const [
                    allOrganisationsResponse,
                    allCategoriesResponse,
                    allOccurrencesResponse,
                    allViewabilitiesResponse,
                    allTimezonesResponse,
                    allArtistsResponse,
                    allCountryListResponse,
                    allCurrenciesResponse,
                ] = await Promise.all([
                    get(`/organisation/dropdown`),
                    get(`/event/all-event-categories`),
                    get(`/event/all-event-occurrences`),
                    get(`/event/all-event-viewabilities`),
                    get(`/timezone/listing`),
                    get(`/artist/dropdown`),
                    get('/country/get-country'),
                    get(`/settings/currency/all`),
                ]);
                if (allOrganisationsResponse.data.success && allOrganisationsResponse.data.data) {
                    setEventOrganisations(allOrganisationsResponse.data.data.map((item: any) => {
                        return { name: item?.name, value: item?._id }
                    }));
                }
                if (allCategoriesResponse.data.success && allCategoriesResponse.data.data) {
                    setEventCategories(allCategoriesResponse.data.data.map((item: any) => {
                        return { name: item?.name, value: item?._id }
                    }));
                }
                if (allOccurrencesResponse.data.success && allOccurrencesResponse.data.data) {
                    setEventOccurrences(allOccurrencesResponse.data.data.map((item: any) => {
                        return { name: item?.name, value: item?._id }
                    }));
                }
                if (allViewabilitiesResponse.data.success && allViewabilitiesResponse.data.data) {
                    setEventViewabilities(allViewabilitiesResponse.data.data.map((item: any) => {
                        let eT = {}
                        if (item.name !== "Private") {
                            eT = { label: item?.name, value: item?._id }
                        }
                        return eT
                    }).filter((item: any) => Object.keys(item).length > 0))
                }
                if (allTimezonesResponse.data.success && allTimezonesResponse.data.data) {
                    setEventTimezones(allTimezonesResponse.data.data.map((item: any) => {
                        return { name: item?.label, value: item?._id }
                    }));
                }
                if (allArtistsResponse.data.success && allArtistsResponse.data.data) {
                    setEventArtists(allArtistsResponse.data.data.map((item: any) => {
                        return { name: item?.artistName, value: item?.artistId }
                    }));
                }
                if (allCountryListResponse.data.success && allCountryListResponse.data.data) {
                    setCountries(allCountryListResponse.data.data.map((item: any) => {
                        return { name: item?.name, value: item?._id }
                    }));
                }
                if (allCurrenciesResponse.data.success && allCurrenciesResponse.data.data) {
                    setCurrencies(allCurrenciesResponse.data.data.map((item: any) => {
                        return { name: item?.currency_code, value: item?._id }
                    }));
                }
            }
            catch (error) {
                console.log(error);
            }
        }
        getFormInfo();
    }, []);


    const { locales, loading }: any = useAppSelector((state) => state.common);
    // const statusOption = [
    //     {
    //         name: "Active",
    //         value: 1,
    //     },
    //     {
    //         name: "Inactive",
    //         value: 0,
    //     },
    // ];

    const initialValues = currentData ?
        {
            ...currentData,
            ...{
                start_date: new Date(currentData?.start_date),
                start_time: moment(currentData?.start_time),
                end_date: new Date(currentData?.end_date),
                end_time: moment(currentData?.end_time),
                translations: locales?.available_languages?.map((item: any) => {
                    const translation = Array.isArray(currentData.translations) ? currentData.translations.filter((tr: any) => tr.language === item) : [];
                    if (translation.length > 0) {
                        return translation[0]
                    }
                    else {
                        return {
                            language: item,
                            title: "",
                            description: ""
                        }
                    }
                }),
                currency_id: currentData?.currency_id?._id ?? "",
                tax_percentage: currentData?.tax_percentage ?? "",
            }
        } :
        {
            id: null,
            organisation_id: "",
            event_category_id: "",
            event_occurrence_id: "64ed5bb7f0f7ef277760f801",
            event_viewability_type_id: "64ed5c20f0f7ef277760f803",
            event_timezone_id: "",
            start_date: "",
            start_time: moment(),
            end_date: "",
            end_time: moment(),
            currency_id: "",
            tax_percentage: "",
            cover_image_mobile: "",
            cover_image_desktop: "",
            collect_ic: false,
            translations: locales?.available_languages?.map((item: any) => {
                return {
                    language: item,
                    title: "",
                    description: ""
                }
            }),
            order_id_prefix: "",
            // status: true,
            venue_id: null,
            venue_name: "",
            venue_address: "",
            venue_google_map_iframe_url: "",
            venue_baidu_map_url: "",
            venue_state: "",
            venue_city: "",
            venue_province: "",
            venue_country_id: "",
            venue_postalCode: "",
            venue_latitude: "",
            venue_longitude: "",
            event_viewability_private_password: "",
        };
    let prevData = initialValues

    const schema = Yup.object({
        organisation_id: Yup.string().required(
            "Organisation is required"
        ),
        event_category_id: Yup.string().required(
            "Event Category is required"
        ),
        //   event_occurrence_id: Yup.string().required(
        //       "Event Occurrence is required"
        //   ),
        event_viewability_type_id: Yup.string().required(
            "Event Viewability Type is required"
        ),
        event_viewability_private_password: Yup.string().when("event_viewability_type_id", (event_viewability_type_id, schema) => {
            // ObjectId of Private
            if (event_viewability_type_id?.length > 0 && event_viewability_type_id[0] === '64ed5c28f0f7ef277760f804') {
                return schema.required("Viewability Password is required");
            }
            return schema;
        }),
        event_timezone_id: Yup.string().required(
            "Event Timezone is required"
        ),
        start_date: Yup.string().required(
            "Event Start Date is required"
        ),
        start_time: Yup.string().required(
            "Event Start Time is required / Invalid Input Format"
        ),
        end_date: Yup.string().required(
            "Event End Date is required"
        ),
        end_time: Yup.string().required(
            "Event End Time is required / Invalid Input Format"
        ),
        currency_id: Yup.string().required(
            "Currency is required"
        ),
        tax_percentage: Yup.number(),
        translations: Yup.array().of(
            Yup.object().shape({
                title: Yup.string().when("language", (language, schema) => {
                    if (language[0] === 'en') {
                        return schema.required("Event Title is required");
                    }
                    return schema;
                })
                // description: Yup.string().when("language", (language, schema) => {
                //   if (language[0] === 'en') {
                //     return schema.required("Event Description is required");
                //   }
                //   return schema;
                // })
            })
        ),
        order_id_prefix: Yup.string().matches(/^[a-zA-Z]*$/, 'only alphabets are allowed').max(5, 'must be at most 5 characters').required('Required'),
        venue_name: Yup.string().required(
            "Venue Name is required"
        ),
        venue_address: Yup.string().required(
            "Address is required"
        ),
        venue_google_map_iframe_url: Yup.string().test(
            'is-iframe',
            'Google Map Embed HTML must include an <iframe> tag',
            (value: any) => {
                if (!value) {
                    return true;
                }
                const iframeRegex = /<iframe[^>]*>.*?<\/iframe>/i;
                return iframeRegex.test(value);
            }
        ),
        venue_baidu_map_url: Yup.string().url(
            "Invalid URL"
        ),
        venue_city: Yup.string().required(
            "City is required"
        ),
        venue_country_id: Yup.string().required(
            "Country is required"
        ),
        venue_postalCode: Yup.number().required(
            "Postal Code is required"
        ),
    });

    const updateBasicInfo = async () => {
        if (currentData) {
            const responseUpdate = await getEventBasicInfo(basicInfo?._id || '')
            if (responseUpdate?.data?.success) {
                dispatch(setBasicInfo(responseUpdate?.data?.data));
            }
        }
    }

    const handleSubmit = async (values: any, actions: any) => {
        if (isEqual(values, prevData)) return
        dispatch(setLoading(true));
        try {
            const response: any = await post(`/event/create`, removeNull(values));
            dispatch(setLoading(false));
            if (response?.data?.success && response?.data?.data) {
                if (!!onSubmitSuccess) onSubmitSuccess();
                toast.success(response?.data?.message);
                updateBasicInfo();
                actions.resetForm();
                prevData = values;
            }
        }
        catch (error) {
            dispatch(setLoading(false));
            //   toast.error(JSON.stringify(error));
        }
    }

    const formInputs = ({ errors, values, touched, handleBlur, handleChange, setFieldValue }: FormikProps<FormikValues>) => (
        (
            <div className='flex flex-wrap justify-between'>

                <div className='w-full flex justify-between none'>
                    {values.translations && values.translations.length > 0
                        ? values.translations.map((user: any, index: number) => (
                            <div className='w-full' key={`basic-info-${user.language}`} style={{ display: user.language !== currentLang ? 'none' : 'block' }}>
                                <div className="pb-[20px] mr-[9px]">
                                    <label htmlFor={`translations.${index}.title`} className="">
                                        Event Title
                                    </label>
                                    <InputText
                                        id={`translations.${index}.title`}
                                        className={`w-full ${(Array.isArray(errors?.translations) && Object.values(errors?.translations)[index]?.title && Array.isArray(touched?.translations) && touched?.translations[index]?.title) && "p-invalid"}`}
                                        name={`translations.${index}.title`}
                                        placeholder='Title'
                                        value={values.translations && values.translations[index] && values.translations[index].title}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                    />
                                    {Array.isArray(errors?.translations) && Object.values(errors?.translations)[index]?.title && Array.isArray(touched?.translations) && touched?.translations[index]?.title ? (
                                        <small className="block mt-2 p-error">{Object.values(errors?.translations)[index]?.title}</small>
                                    ) : null}
                                </div>
                                {/*<div className="pb-[10px]">*/}
                                {/*    <label htmlFor={`translations.${index}.description`} className="">*/}
                                {/*        Event Description*/}
                                {/*    </label>*/}
                                {/*    <InputText*/}
                                {/*        id={`translations.${index}.description`}*/}
                                {/*        className={`w-full ${(Array.isArray(errors?.translations) && Object.values(errors?.translations)[index]?.description && Array.isArray(touched?.translations) && touched?.translations[index]?.description) && "p-invalid"}`}*/}
                                {/*        name={`translations.${index}.description`}*/}
                                {/*        placeholder='Event Description'*/}
                                {/*        value={values.translations && values.translations[index] && values.translations[index].description}*/}
                                {/*        onBlur={handleBlur}*/}
                                {/*        onChange={handleChange}*/}
                                {/*    />*/}
                                {/*    {Array.isArray(errors?.translations) && Object.values(errors?.translations)[index]?.description && Array.isArray(touched?.translations) && touched?.translations[index]?.description ? (*/}
                                {/*        <small className="block mt-2 p-error">{ Object.values(errors?.translations)[index]?.description }</small>*/}
                                {/*    ) : null}*/}
                                {/*</div>*/}
                            </div>
                        ))
                        : null}
                    <div className="pb-[20px] w-full">
                        <label
                            htmlFor="organisation_id"
                            className=""
                        >
                            Organizer
                        </label>

                        <Dropdown
                            inputId="organisation_id"
                            name="organisation_id"
                            value={values.organisation_id}
                            options={eventOrganisations}
                            optionLabel="name"
                            filter
                            placeholder="Select"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            className={`w-full ${errors.organisation_id &&
                                touched.organisation_id &&
                                "p-invalid"
                                }`}
                        />
                        {errors.organisation_id && touched.organisation_id ? (
                            <small className="block mt-2 p-error">
                                {errors?.organisation_id?.toString()}
                            </small>
                        ) : null}
                    </div>
                </div>
                <div className="w-full flex justify-between mb-[30px]">
                    <div className="w-full mr-[9px]">
                        <label htmlFor="order_id_prefix">
                            Order ID Prefix
                        </label>
                        <InputText
                            id="order_id_prefix"
                            type="order_id_prefix"
                            className={`w-full ${(errors.order_id_prefix && touched.order_id_prefix) && "p-invalid"}`}
                            name="order_id_prefix"
                            value={values.order_id_prefix}
                            onBlur={handleBlur}
                            onChange={handleChange}
                        />
                        {errors.order_id_prefix && touched.order_id_prefix ? (
                            <small className="block mt-2 p-error">{errors?.order_id_prefix?.toString()}</small>
                        ) : null}
                    </div>
                    <div className="w-full">
                        <label htmlFor="collect_ic">
                            Collect last 4 digit IC (On Checkout)
                        </label>
                        <div className="h-[35px] flex items-center">
                            <InputSwitch
                                id="collect_ic"
                                type="collect_ic"
                                className={`w-full ${(errors.collect_ic && touched.collect_ic) && "p-invalid"}`}
                                name="collect_ic"
                                checked={values.collect_ic}
                                onChange={handleChange}
                            />
                        </div>
                        {errors.collect_ic && touched.collect_ic ? (
                            <small className="block mt-2 p-error">{errors?.collect_ic?.toString()}</small>
                        ) : null}
                    </div>
                </div>
                <div className='w-full mb-[30px]'>
                    <FormCategoryTitle title='Event Type' />
                    <div className="pb-[10px]">
                        <div className='flex'>
                            {
                               eventViewabilities && eventViewabilities.map((mode: any) => {
                                    return (
                                        <div key={mode?.value} className="flex flex-row justify-start items-center mr-[20px]">
                                            <RadioButton
                                                className={`${touched.event_viewability_type_id && errors.event_viewability_type_id
                                                    ? "p-invalid"
                                                    : ""
                                                    }`}
                                                inputId={mode?.value}
                                                name="event_viewability_type_id"
                                                value={mode?.value}
                                                onChange={handleChange}
                                                checked={mode?.value === values.event_viewability_type_id}
                                            />
                                            <label htmlFor={mode?.value} className="mb-0 ml-2">{mode?.label}</label>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        {errors.event_viewability_type_id && touched.event_viewability_type_id ? (
                            <small className="block mt-2 p-error">
                                {errors?.event_viewability_type_id?.toString()}
                            </small>
                        ) : null}
                    </div>
                    {/* <div className="pb-[10px]">
                        <Password
                            inputId="event_viewability_private_password"
                            name="event_viewability_private_password"
                            className={`w-full ${touched.event_viewability_private_password && errors.event_viewability_private_password
                                ? "p-invalid"
                                : ""
                                }`}
                            placeholder="Enter viewability password"
                            value={values.event_viewability_private_password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            inputStyle={{ width: "100%" }}
                            toggleMask
                            feedback={false}
                            disabled={values.event_viewability_type_id !== '64ed5c28f0f7ef277760f804'}
                        />
                        {errors.event_viewability_private_password && touched.event_viewability_private_password ? (
                            <small className="block mt-2 p-error">{errors?.event_viewability_private_password?.toString()}</small>
                        ) : null}
                    </div> */}
                    <div className="py-[10px]">
                        <label
                            htmlFor="event_category_id"
                            className=""
                        >
                            Select Event Category
                        </label>

                        <Dropdown
                            inputId="event_category_id"
                            name="event_category_id"
                            value={values.event_category_id}
                            options={eventCategories}
                            optionLabel="name"
                            placeholder="Select"
                            onBlur={handleBlur}
                            onChange={(e)=>{
                                handleChange(e);
                                if (publishError) { setPublishError(false) }
                            }}
                            className={`w-full ${((errors.event_category_id && touched.event_category_id) || publishError) && "p-invalid"}`}
                        />
                        {((errors.event_category_id && touched.event_category_id) || publishError )? (
                            <small className="block mt-2 p-error">
                                {errors?.event_category_id?.toString()}
                                {publishError ? 'Event Category is required' : '' }
                            </small>
                        ) : null}
                    </div>
                </div>

                <div className='w-full mb-[30px]'>
                    <FormCategoryTitle title='Venue Details' />
                    <div className='grid grid-cols-1 gap-x-[9px]'>
                        <div className="py-[10px]">
                            <label htmlFor="venue_name" className="">
                                Venue Name
                            </label>
                            <InputText
                                id="venue_name"
                                className={`w-full ${(errors.venue_name && touched.venue_name) && "p-invalid"}`}
                                name="venue_name"
                                placeholder='Venue Name'
                                value={values.venue_name}
                                onBlur={handleBlur}
                                onChange={handleChange}
                            />
                            {errors.venue_name && touched.venue_name ? (
                                <small className="block mt-2 p-error">{errors?.venue_name?.toString()}</small>
                            ) : null}
                        </div>
                        <div className="py-[10px]">
                            <label htmlFor="venue_address" className="">
                                Address
                            </label>
                            <InputTextarea
                                id="venue_address"
                                className={`w-full min-h-[62px] px-[14px] py-[10px] ${(errors.venue_address && touched.venue_address) && "p-invalid"}`}
                                name="venue_address"
                                placeholder='Address'
                                value={values.venue_address}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                rows={3}
                                cols={30}
                                autoResize
                            />
                            {errors.venue_address && touched.venue_address ? (
                                <small className="block mt-2 p-error">{errors?.venue_address?.toString()}</small>
                            ) : null}
                        </div>

                        <div className="py-[10px]">
                            <label htmlFor="venue_google_map_iframe_url" className="">
                                Google Map Embed HTML
                            </label>
                            <InputText
                                id="venue_google_map_iframe_url"
                                className={`w-full ${(errors.venue_google_map_iframe_url && touched.venue_google_map_iframe_url) && "p-invalid"}`}
                                name="venue_google_map_iframe_url"
                                placeholder='Google Map Embed HTML'
                                value={values.venue_google_map_iframe_url}
                                onBlur={handleBlur}
                                onChange={handleChange}
                            />
                            {errors.venue_google_map_iframe_url && touched.venue_google_map_iframe_url ? (
                                <small className="block mt-2 p-error">{errors?.venue_google_map_iframe_url?.toString()}</small>
                            ) : null}
                        </div>

                        <div className="py-[10px]">
                            <label htmlFor="venue_baidu_map_url" className="">
                                Baidu Map URL
                            </label>
                            <InputText
                                id="venue_baidu_map_url"
                                className={`w-full ${(errors.venue_baidu_map_url && touched.venue_baidu_map_url) && "p-invalid"}`}
                                name="venue_baidu_map_url"
                                placeholder='Baidu Map Url'
                                value={values.venue_baidu_map_url}
                                onBlur={handleBlur}
                                onChange={handleChange}
                            />
                            {errors.venue_baidu_map_url && touched.venue_baidu_map_url ? (
                                <small className="block mt-2 p-error">{errors?.venue_baidu_map_url?.toString()}</small>
                            ) : null}
                        </div>

                    </div>
                    <div className='grid grid-cols-2 gap-x-[9px]'>
                        <div className="py-[10px]">
                            <label htmlFor="venue_state" className="">
                                State
                            </label>
                            <InputText
                                id="venue_state"
                                className={`w-full ${(errors.venue_state && touched.venue_state) && "p-invalid"}`}
                                name="venue_state"
                                placeholder='State'
                                value={values.venue_state}
                                onBlur={handleBlur}
                                onChange={handleChange}
                            />
                            {errors.venue_state && touched.venue_state ? (
                                <small className="block mt-2 p-error">{errors?.venue_state?.toString()}</small>
                            ) : null}
                        </div>
                        <div className="py-[10px]">
                            <label htmlFor="venue_city" className="">
                                City
                            </label>
                            <InputText
                                id="venue_city"
                                className={`w-full ${(errors.venue_city && touched.venue_city) && "p-invalid"}`}
                                name="venue_city"
                                placeholder='City'
                                value={values.venue_city}
                                onBlur={handleBlur}
                                onChange={handleChange}
                            />
                            {errors.venue_city && touched.venue_city ? (
                                <small className="block mt-2 p-error">{errors?.venue_city?.toString()}</small>
                            ) : null}
                        </div>
                        <div className="py-[10px]">
                            <label htmlFor="venue_province" className="">
                                Province
                            </label>
                            <InputText
                                id="venue_province"
                                className={`w-full ${(errors.venue_province && touched.venue_province) && "p-invalid"}`}
                                name="venue_province"
                                placeholder='Province'
                                value={values.venue_province}
                                onBlur={handleBlur}
                                onChange={handleChange}
                            />
                            {errors.venue_province && touched.venue_province ? (
                                <small className="block mt-2 p-error">{errors?.venue_province?.toString()}</small>
                            ) : null}
                        </div>
                        <div className="py-[10px]">
                            <label htmlFor="venue_country_id" className="">
                                Country
                            </label>
                            <Dropdown
                                inputId="venue_country_id"
                                name="venue_country_id"
                                value={values.venue_country_id}
                                options={countries}
                                optionLabel="name"
                                filter
                                placeholder="Select"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                className={`w-full ${errors.venue_country_id &&
                                    touched.venue_country_id &&
                                    "p-invalid"
                                    }`}
                            />
                            {errors.venue_country_id && touched.venue_country_id ? (
                                <small className="block mt-2 p-error">
                                    {errors?.venue_country_id?.toString()}
                                </small>
                            ) : null}

                        </div>
                        <div className="py-[10px]">
                            <label htmlFor="venue_postalCode" className="">
                                Postal Code
                            </label>
                            <InputText
                                id="venue_postalCode"
                                className={`w-full ${(errors.venue_postalCode && touched.venue_postalCode) && "p-invalid"}`}
                                name="venue_postalCode"
                                placeholder='Postal Code'
                                value={values.venue_postalCode}
                                onBlur={handleBlur}
                                onChange={handleChange}
                            />
                            {errors.venue_postalCode && touched.venue_postalCode ? (
                                <small className="block mt-2 p-error">{errors?.venue_postalCode?.toString()}</small>
                            ) : null}
                        </div>
                    </div>
                </div>

                <div className='w-full mb-[30px]'>
                    <FormCategoryTitle title='Date and Time' subtitle='Tell event-goers when your event starts and ends so they can make plans to attend.' />
                    <div className='flex flex-wrap justify-between'>
                        {/* <div className="w-full grid grid-cols-1 mt-[8px]">
                          <div className='flex'>
                              {
                                  eventOccurrences.map((item: any) => {
                                      return (
                                          <div key={item?.value} className="relative flex flex-row justify-start items-center mr-[20px]">
                                              <RadioButton
                                                  className={`custom-p-radiobutton ${
                                                      touched.event_occurrence_id && errors.event_occurrence_id
                                                          ? "p-invalid"
                                                          : ""
                                                  }`}
                                                  inputId={item?.value}
                                                  name="event_occurrence_id"
                                                  value={item?.value}
                                                  onChange={handleChange}
                                                  checked={item?.value === values.event_occurrence_id}
                                              />
                                              <label htmlFor={item?.value} className="custom-label mb-0 ml-0">{`${item?.name} event`}</label>
                                          </div>
                                      )
                                  })
                              }

                          </div>
                          {errors.event_occurrence_id && touched.event_occurrence_id ? (
                              <small className="block mt-2 p-error">
                                  {errors?.event_occurrence_id?.toString()}
                              </small>
                          ) : null}
                          <div className={`${ errors.event_occurrence_id ? 'mt-[0px]' : 'mt-[22px]' }`}>Single event happens once and can last multiple days</div>
                      </div> */}
                        <div className='w-full grid grid-cols-2 gap-x-[9px]'>
                            <div className='py-[10px] w-full'>
                                <label
                                    htmlFor="start_date"
                                    className=""
                                >
                                    Event Starts<span style={{ color: '#D04EFF' }}>*</span>
                                </label>

                                <Calendar
                                    inputId="start_date"
                                    name="start_date"
                                    readOnlyInput
                                    minDate={new Date(new Date().setDate(new Date().getDate() - 1))}
                                    dateFormat="dd M yy, D"
                                    placeholder="Start Date"
                                    className={`w-full ${touched.start_date && errors.start_date
                                        ? "p-invalid"
                                        : ""
                                        }`}
                                    value={values.start_date}
                                    onChange={handleChange}
                                />

                                {errors.start_date && touched.start_date ? (
                                    <small className="block mt-2 p-error">
                                        {errors?.start_date?.toString()}
                                    </small>
                                ) : null}
                            </div>
                            <div className='py-[10px] w-full'>
                                <label
                                    htmlFor="start_time"
                                    className=""
                                >
                                    Start Time
                                </label>
                                <TimePicker
                                    id="start_time"
                                    name="start_time"
                                    showSecond={false}
                                    defaultValue={values.start_time}
                                    className={`w-full ${touched.start_time && errors.start_time
                                        ? "p-invalid"
                                        : ""
                                    }`}
                                    onChange={(momentValue:any) => {
                                        setFieldValue('start_time', momentValue.toISOString());
                                    }}
                                    format={format}
                                    use12Hours
                                    inputReadOnly
                                    allowEmpty={false}
                                />
                                {errors.start_time && touched.start_time ? (
                                    <small className="block mt-2 p-error">
                                        {errors?.start_time?.toString()}
                                    </small>
                                ) : null}
                            </div>
                        </div>
                        <div className='w-full grid grid-cols-2 gap-x-[9px]'>
                            <div className='py-[10px] w-full'>
                                <label
                                    htmlFor="end_date"
                                    className=""
                                >
                                    Event End<span style={{ color: '#D04EFF' }}>*</span>
                                </label>

                                <Calendar
                                    inputId="end_date"
                                    name="end_date"
                                    readOnlyInput
                                    minDate={new Date(new Date().setDate(new Date().getDate() - 1))}
                                    dateFormat="dd M yy, D"
                                    placeholder="End Date"
                                    className={`w-full ${touched.end_date && errors.end_date
                                        ? "p-invalid"
                                        : ""
                                        }`}
                                    value={values.end_date}
                                    onChange={handleChange}
                                />

                                {errors.end_date && touched.end_date ? (
                                    <small className="block mt-2 p-error">
                                        {errors?.end_date?.toString()}
                                    </small>
                                ) : null}
                            </div>
                            <div className='py-[10px] w-full'>
                                <label
                                    htmlFor="end_time"
                                    className=""
                                >
                                    End Time
                                </label>
                                <TimePicker
                                    id="end_time"
                                    name="end_time"
                                    showSecond={false}
                                    defaultValue={values.end_time}
                                    className={`w-full ${touched.end_time && errors.end_time
                                        ? "p-invalid"
                                        : ""
                                    }`}
                                    onChange={(momentValue:any) => {
                                        setFieldValue('end_time', momentValue.toISOString());
                                    }}
                                    format={format}
                                    use12Hours
                                    inputReadOnly
                                    allowEmpty={false}
                                />

                                {errors.end_time && touched.end_time ? (
                                    <small className="block mt-2 p-error">
                                        {errors?.end_time?.toString()}
                                    </small>
                                ) : null}
                            </div>
                            <div className="py-[10px] w-full">
                                <label
                                    htmlFor="event_timezone_id"
                                    className=""
                                >
                                    Time Zone
                                </label>

                                <Dropdown
                                    inputId="event_timezone_id"
                                    name="event_timezone_id"
                                    value={values.event_timezone_id}
                                    options={eventTimezones}
                                    optionLabel="name"
                                    filter
                                    placeholder="Select"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    className={`w-full ${errors.event_timezone_id &&
                                        touched.event_timezone_id &&
                                        "p-invalid"
                                        }`}
                                />
                                {errors.event_timezone_id && touched.event_timezone_id ? (
                                    <small className="block mt-2 p-error">
                                        {errors?.event_timezone_id?.toString()}
                                    </small>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>

                <div className='w-full mb-[30px]'>
                    <FormCategoryTitle title='Payment' />
                    <div className='w-full grid grid-cols-2 gap-x-[9px]'>
                        <div className="pb-[10px]">
                            <label
                                htmlFor="currency_id"
                            >
                                Ticket Price Currency
                            </label>

                            <Dropdown
                                inputId="currency_id"
                                name="currency_id"
                                value={values.currency_id}
                                options={currencies}
                                optionLabel="name"
                                placeholder="Select"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                className={`w-full ${errors.currency_id &&
                                    touched.currency_id &&
                                    "p-invalid"
                                    }`}
                            />
                            {errors.currency_id && touched.currency_id ? (
                                <small className="block mt-2 p-error">
                                    {errors?.currency_id?.toString()}
                                </small>
                            ) : null}
                        </div>
                        <div className="pb-[10px]">
                            <label htmlFor="tax_percentage">
                                Tax Percentage (optional)
                            </label>
                            <InputText
                                id="tax_percentage"
                                className={`w-full ${(errors.tax_percentage && touched.tax_percentage) && "p-invalid"}`}
                                name="tax_percentage"
                                placeholder='%'
                                value={values.tax_percentage}
                                onBlur={handleBlur}
                                onChange={handleChange}
                            />
                            {errors.tax_percentage && touched.tax_percentage ? (
                                <small className="block mt-2 p-error">{errors?.tax_percentage?.toString()}</small>
                            ) : null}
                        </div>
                    </div>
                </div>

                {/*<div className='w-full md:w-1/2'>*/}
                {/*    <FormCategoryTitle title='Others' />*/}
                {/*    <div className="py-[10px]">*/}
                {/*        <label htmlFor="statusOption" className="">Status</label>*/}
                {/*        <TypingPrompt typeChallange={currentData && currentData.translations && currentData.translations.length > 0 ? currentData.translations[0].title : ''}>*/}
                {/*            {(disabled) => (*/}
                {/*                <Dropdown*/}
                {/*                    inputId="status"*/}
                {/*                    name="status"*/}
                {/*                    value={values.status}*/}
                {/*                    options={statusOption}*/}
                {/*                    optionLabel="name"*/}
                {/*                    disabled={!currentData ? false : disabled}*/}
                {/*                    className={`w-full ${(errors.status && touched.status) && "p-invalid"}`}*/}
                {/*                    onChange={(e) => {*/}
                {/*                        setFieldValue('status', e.value);*/}
                {/*                    }}*/}
                {/*                />*/}
                {/*            )}*/}
                {/*        </TypingPrompt>*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*<div className="py-[10px]">*/}
                {/*    <ImageUploadPreview label={'Cover Image (Mobile)'} value={values.cover_image_mobile} setUploadImage={(file: any) => setFieldValue('cover_image_mobile', file)} />*/}
                {/*    {errors.cover_image_mobile && touched.cover_image_mobile ? (*/}
                {/*        <small className="block mt-2 p-error">{errors?.cover_image_mobile?.toString()}</small>*/}
                {/*    ) : null}*/}
                {/*</div>*/}
                {/*<div className="py-[10px]">*/}
                {/*    <ImageUploadPreview label={'Cover Image (Desktop)'} value={values.cover_image_desktop} setUploadImage={(file: any) => setFieldValue('cover_image_desktop', file)} />*/}
                {/*    {errors.cover_image_desktop && touched.cover_image_desktop ? (*/}
                {/*        <small className="block mt-2 p-error">{errors?.cover_image_desktop?.toString()}</small>*/}
                {/*    ) : null}*/}
                {/*</div>*/}
            </div>
        )
    )

    return (
        <div>
            <FormWrapper
                noValidate
                enableReinitialize
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={schema}
                formInputs={formInputs}
                submitButton={
                    <div className='flex justify-end'>
                        <Button className="justify-center w-full md:w-[23.75%]" size="large" type="submit"
                            loading={loading}
                            onClick={() => {
                                if (!!resetDefaultLang) resetDefaultLang();
                            }}
                        >
                            {currentData ? 'Update' : 'Save'}
                        </Button>
                    </div>
                }
                className="gap-2" />
        </div>
    )
}

export default EventForm
