import { useState } from 'react';
import * as Yup from 'yup';

import { Button } from "primereact/button";

import FormWrapper from "../../components/form/FormWrapper";
import { FormikProps, FormikValues } from "formik";
import ImageUploadPreview from "../../components/ImageUploadPreview";
import { InputText } from "primereact/inputtext";
import { createOrganisation, editOrganisationData } from '../../utils/apiRequestList/organisationAPI';
import { Dropdown } from "primereact/dropdown";
import TypingPrompt from '../../components/form/TypingPrompt';
import FooterAction from '../../components/form/FooterAction';
import { Password } from "primereact/password";
import { useAppSelector, useAppDispatch } from "../../hooks/useStore";
import { toast } from "react-toastify";
import LanguageTab from '../../components/LanguageTab';
import TextEditor from '../../components/TextEditor';
import isEqual from "lodash-es/isEqual";
import { setLoading } from '../../redux/slices/commonSlice';

const OrganisationForm = (props: any) => {
    const dispatch = useAppDispatch();
    const statusOption = [
        {
            name: 'Active',
            value: 1
        },
        {
            name: 'Inactive',
            value: 0
        }
    ]

    const { locales, loading }: any = useAppSelector((state) => state.common);
    const [currentLang, setCurrentLang] = useState(locales?.default_language ?? 'en');

    const initialValues = props?.currentData ? {
        name: props?.currentData?.name,
        logoPicture: props?.currentData?.logoPicture,
        translations: props?.currentData?.translations.map((language:any)=>({
            description: language.description,
            display_name: language.display_name,
            language: language.language
        })),
        orgProfilePdf: props?.currentData?.orgProfilePdf,
        order_no_prefix: props?.currentData.order_no_prefix,
        status: props?.currentData.status,
    } : {
        name: '',
        logoPicture: '',
        translations: locales?.available_languages?.map((item: any) => {
            return {
                id: null,
                language: item,
                display_name: '',
                description: ''
            }
        }),
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        orgProfilePdf: '',
        order_no_prefix: '',
        status: 1
    };
    let prevData = initialValues

    const schema = Yup.object().shape({
        name: Yup.string().required('Required'),
        logoPicture: Yup.mixed().required('Required logo picture'),
        orgProfilePdf: Yup.mixed().required('Required Business Profile PDF'),
        translations: Yup.array().of(
            Yup.object().shape({
                display_name: Yup.string().when("language", (language, schema) => {
                    if (language[0] === 'en') {
                        return schema.required("Display Name is required");
                    }
                    return schema;
                }),
                description: Yup.string().when("language", (language, schema) => {
                    if (language[0] === 'en') {
                        return schema.required("Description is required");
                    }
                    return schema;
                }),
            })
        ),
        order_no_prefix: Yup.string().matches(/^[a-zA-Z]*$/, 'only alphabets are allowed').max(5, 'must be at most 5 characters').required('Required'),
        status: Yup.number(),
    })

    const OrganisationFormSchema = !props.currentData
        ? schema.shape({
            firstName: Yup.string().required('Required'),
            lastName: Yup.string().required('Required'),
            email: Yup.string().email("Invalid email format").required("Email is required"),
            password: Yup.string().required("Password is required"),
        })
        : schema;


    const handleSubmit = async (values: any) => {
        if (isEqual(values, prevData)) return

        dispatch(setLoading(true))
        const translations = JSON.stringify(values.translations);
        const stringifyData = {
            ...values,
            translations,
        }
        try {
            const response = props.currentData ?
                await editOrganisationData(props.currentData.organisationId, stringifyData) :
                await createOrganisation(values.name, values.logoPicture, translations, values.email, values.password, values.firstName, values.lastName, values.orgProfilePdf, values.order_no_prefix);
            if (response.status) {
                toast.success("Organisation Created.");
                props.setUpdateListing(true)
                setTimeout(() => {
                    props.setVisible(false)
                }, 1000);
                prevData = values;
            } else {
                toast.error('Failed Adding Organisation');
                setTimeout(() => {
                    props.setVisible(false)
                }, 1000);
            }
        } catch (error: any) {
            dispatch(setLoading(false))
            toast.error(error);
            console.error(error);
        }
    }

    const formInputs = ({ errors, values, touched, handleBlur, handleChange, setFieldValue }: FormikProps<FormikValues>) => (
        <>
            <div>
                <p className='text-[16px] font-bold text-[#211F2E]'>Organisation Company Details</p>
                <hr className='border-solid border-[#211F2E] my-[10px]' />
            </div>
            <div className="mb-6">
                <label htmlFor="name" className="">
                    Organisation Name
                </label>
                <InputText
                    id="name"
                    type="name"
                    className={`w-full ${(errors.name && touched.name) && "p-invalid"}`}
                    name="name"
                    value={values.name}
                    onBlur={handleBlur}
                    onChange={handleChange}
                />
                {errors.name && touched.name ? (
                    <small className="block mt-2 p-error">{errors?.name?.toString()}</small>
                ) : null}
            </div>
            <div className="mb-6">
                <LanguageTab onChange={setCurrentLang} activeLanguage={currentLang} />
                <div className='mb-4'>
                    {values.translations && values.translations.length > 0
                        ? locales?.available_languages?.map((language: any, index: number) => (
                            <div key={language} className={language === currentLang ? 'block' : 'hidden'}>
                                <div className="mb-6">
                                    <div className='mb-[15px]'>
                                        <label htmlFor={`translations.${index}.display_name`} className="">
                                            Display Name
                                        </label>
                                        <InputText
                                            id={`translations.${index}.display_name`}
                                            type="name"
                                            className={`w-full ${(errors.display_name && touched.display_name) && "p-invalid"}`}
                                            name={`translations.${index}.display_name`}
                                            //value={values.displayName}
                                            value={values.translations && values.translations[index] && values.translations[index].display_name}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        />
                                        {Array.isArray(errors?.translations) && Object.values(errors?.translations)[index]?.display_name && Array.isArray(touched?.translations) && touched?.translations[index]?.display_name ? (
                                            <small className="block mt-2 p-error">{Object.values(errors?.translations)[index]?.display_name}</small>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        ))
                        : null
                    }
                </div>
            </div>
            <div className="mb-6">
                <div className="flex-1 ">
                    <ImageUploadPreview id={'logo-image'} label={'Upload Logo Image'} value={values.logoPicture} setUploadImage={(file: any) => setFieldValue('logoPicture', file)} />
                    {errors.logoPicture && touched.logoPicture ? (
                        <small className="block mt-2 p-error">{errors?.logoPicture?.toString()}</small>
                    ) : null}
                </div>
            </div>
            <div className="mb-6">
                {values.translations && values.translations.length > 0
                    ? locales?.available_languages.map((language: any, index: number) => (

                        <div key={language} className={language === currentLang ? 'block' : 'hidden'}>
                            <div className="mb-6">
                                <div>
                                    <label htmlFor={`translations.${index}.description`} className="">
                                        Description
                                    </label>
                                    <TextEditor
                                        id={`translations.${index}.description`}
                                        name={`translations.${index}.description`}
                                        value={values.translations && values.translations[index] && values.translations[index].description}
                                        onTextChange={(e:any) => {
                                            if (e.htmlValue){
                                                setFieldValue(`translations.${index}.description`, e.htmlValue);
                                            }else {
                                                setFieldValue(`translations.${index}.description`, '');
                                            }
                                        }} />
                                    {Array.isArray(errors?.translations) && Object.values(errors?.translations)[index]?.description && Array.isArray(touched?.translations) && touched?.translations[index]?.description ? (
                                        <small className="block mt-2 p-error">{Object.values(errors?.translations)[index]?.description}</small>
                                    ) : null}
                                </div>
                            </div>
                        </div>

                    ))
                    : null
                }
            </div>
            <div className="mb-6">
                <ImageUploadPreview
                    id={'pdf-org'}
                    label="Upload PDF file"
                    fileType=".pdf"
                    value={values.orgProfilePdf}
                    setUploadImage={(file: any) => setFieldValue('orgProfilePdf', file)}
                />
                {errors.orgProfilePdf && touched.orgProfilePdf ? (
                    <small className="block mt-2 p-error">{errors?.orgProfilePdf?.toString()}</small>
                ) : null}
            </div>
            <div className="mb-6">
                <label htmlFor="order_no_prefix">
                    Order No. Prefix
                </label>
                <InputText
                    id="order_no_prefix"
                    type="order_no_prefix"
                    className={`w-full ${(errors.order_no_prefix && touched.order_no_prefix) && "p-invalid"}`}
                    name="order_no_prefix"
                    value={values.order_no_prefix}
                    onBlur={handleBlur}
                    onChange={handleChange}
                />
                {errors.order_no_prefix && touched.order_no_prefix ? (
                    <small className="block mt-2 p-error">{errors?.order_no_prefix?.toString()}</small>
                ) : null}
            </div>
            {props?.currentData &&
                <div className="mb-6">
                    <label htmlFor="statusOption" className="">Status</label>
                    <TypingPrompt typeChallange={props?.currentData.name}>
                        {(disabled) => (
                            <Dropdown
                                inputId="status"
                                name="status"
                                value={values.status}
                                options={statusOption}
                                optionLabel="name"
                                className={`w-full ${(errors.status && touched.status) && "p-invalid"}`}
                                onChange={(e) => {
                                    setFieldValue('status', e.value);
                                }}
                            />
                        )}
                    </TypingPrompt>
                </div>
            }
            {!props?.currentData &&
                <>
                    <div>
                        <p className='text-[16px] font-bold text-[#211F2E]'>Organisation Account Details</p>
                        <p className='text-[12px] text-[#211F2E] mt-[4px]'>Create a first user for your organisation</p>
                        <hr className='border-solid border-[#211F2E] mt-[12px] mb-[22px]' />
                    </div>
                    <div className='flex justify-between gap-4'>
                        <div className="w-full mb-6">
                            <label htmlFor="firstName" className="">
                                User First Name
                            </label>
                            <InputText
                                id="firstName"
                                type="name"
                                className={`w-full ${(errors.firstName && touched.firstName) && "p-invalid"}`}
                                name="firstName"
                                value={values.firstName}
                                onBlur={handleBlur}
                                onChange={handleChange}
                            />
                            {errors.firstName && touched.firstName ? (
                                <small className="block mt-2 p-error">{errors?.firstName?.toString()}</small>
                            ) : null}
                        </div>
                        <div className="w-full mb-6">
                            <label htmlFor="lastName" className="">
                                User Last Name
                            </label>
                            <InputText
                                id="lastName"
                                type="name"
                                className={`w-full ${(errors.lastName && touched.lastName) && "p-invalid"}`}
                                name="lastName"
                                value={values.lastName}
                                onBlur={handleBlur}
                                onChange={handleChange}
                            />
                            {errors.lastName && touched.lastName ? (
                                <small className="block mt-2 p-error">{errors?.lastName?.toString()}</small>
                            ) : null}
                        </div>
                    </div>
                    <div className='flex justify-between gap-4'>
                        <div className="w-full mb-6">
                            <label htmlFor="email" className="">
                                User Email
                            </label>
                            <InputText
                                id="email"
                                type="email"
                                className={`w-full ${(errors.email && touched.email) && "p-invalid"}`}
                                name="email"
                                value={values.email}
                                onBlur={handleBlur}
                                onChange={handleChange}
                            />
                            {errors.email && touched.email ? (
                                <small className="block mt-2 p-error">{errors?.email?.toString()}</small>
                            ) : null}
                        </div>
                        <div className="w-full mb-6">
                            <label
                                htmlFor="password"
                                className=""
                            >
                                User Password
                            </label>
                            <Password
                                id="password"
                                type="text"
                                className={`w-full ${errors.password &&
                                    touched.password &&
                                    "p-invalid"
                                    }`}
                                name="password"
                                value={values.password}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                autoComplete="off"
                                aria-autocomplete="none"
                                toggleMask
                                inputClassName="w-full"
                            />
                            {errors.password && touched.password ? (
                                <small className="block mt-2 p-error">
                                    {errors?.password?.toString()}
                                </small>
                            ) : null}
                        </div>
                    </div>
                </>
            }
        </>
    )

    return (
        <>
            <FormWrapper
                noValidate
                enableReinitialize
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={OrganisationFormSchema}
                formInputs={formInputs}
                submitButton={
                    <FooterAction>
                        <Button className="justify-center w-full" size="large" type="submit" loading={loading}>
                            {props.currentData ? 'Update' : 'Create'}
                        </Button>
                    </FooterAction>
                }
                className="gap-2" />
        </>
    )

}

export default OrganisationForm;