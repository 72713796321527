import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
    Addon,
    EventBasicInfo,
    Promotion,
    // PromotionCodeTagging,
    Ticket,
    TicketGroup 
} from "../types/event";

type EventState = {
    basicInfo: EventBasicInfo,
    selectedTicketGroup: TicketGroup,
    selectedTicket: Ticket,
    selectedAddon: Addon,
    selectedPromotion: Promotion,
    // selectedPromotionCodeTagging: PromotionCodeTagging
};

const initialState: EventState = {
    basicInfo: {
        _id: '',
        organisation_id: '',
        event_category_id: '',
        event_occurrence_id: '',
        event_viewability_type_id: '',
        event_timezone_id: '',
        event_venue_id: {
            _id: '',
            name: '',
            address: '',
            state: '',
            city: '',
            province: '',
            country: '',
            postalCode: 0,
            google_map_url: '',
            baidu_map_url: '',
            latitude: '',
            longitude: '',
        },
        start_date: '',
        start_time: '',
        end_date: '',
        end_time: '',
        slug: '',
        cover_image_mobile: null,
        cover_image_desktop: null,
        view_count: 0,
        translations: [{
            language: '',
            title: '',
            description: '',
            _id: ''
        }],
        tax_percentage: 0,
        currency_id: {
            currency_code: '',
            currency_symbol: ''
        },
        status: 0,
    },
    selectedTicketGroup: {
        _id: "",
        event_id: "",
        translations: [
            {
                language: "",
                name: "",
                _id: "",
            },
        ],
        max_capacity: 0,
        createdAt: "",
        updatedAt: "",
        sort_order: 0,
        id: "",
        name: "",
        groupName: "",
    },
    selectedTicket: {
        _id: "",
        ticket_group_id: "",
        ticket_type_id: "",
        price: 0,
        max_capacity: null,
        sales_start_date: "",
        sales_start_time: "",
        sales_end_date: "",
        sales_end_time: "",
        ticket_viewability_type_id: {
          _id: "",
          name: "",
        },
        min_tickets_per_order: 0,
        max_tickets_per_order: 0,
        sort_order: 0,
        translations: [
            {
                language: "",
                name: "",
                description: "",
                _id: "",
            },
        ],
        createdAt: "",
        updatedAt: "",
        sales_start_formatted: "",
        sales_end_formatted: "",
        id: "",
        name: "",
        price_formatted: "",
        groupName: "",
    },
    selectedAddon: {
        _id: "",
        event_id: "",
        price: 0,
        quantity: 0,
        image_url: null,
        sales_start_date: "",
        sales_start_time: "",
        sales_end_date: "",
        sales_end_time: "",
        addon_viewability_type_id: {
          _id: "",
          name: "",
        },
        min_addons_per_order: 0,
        max_addons_per_order: 0,
        sort_order: 0,
        translations: [
            {
                language: "",
                name: "",
                description: "",
                _id: "",
            }
        ],
        createdAt: "",
        updatedAt: "",
        sales_start_formatted: "",
        sales_end_formatted: "",
        id: "",
        name: "",
        price_formatted: "",
    },
    selectedPromotion: {
        _id: "",
        promo_name: "",
        promo_quantity: 0,
        reveal_hidden_ticket: false,
        is_public: false,
        ticket_limit: false,
        ticket_limit_amount: null,
        discount_amount: 0,
        discount_percentage: null,
        promo_start_type: 0,
        promo_start_date: "",
        promo_start_time: "",
        promo_end_type: 0,
        promo_end_date: "",
        promo_end_time: "",
        applied_type: 0,
        createdAt: "",
        updatedAt: "",
        promo_start_formatted: "",
        promo_end_formatted: "",
        id: "",
    },
    // selectedPromotionCodeTagging: {
    //     _id: "",
    //     promotion_id: {
    //         _id: "",
    //         promo_quantity: 0,
    //         reveal_hidden_ticket: false,
    //         is_public: false,
    //         ticket_limit: false,
    //         ticket_limit_amount: null,
    //         discount_amount: 0,
    //         discount_percentage: null,
    //         promo_start_type: 0,
    //         promo_start_date: "",
    //         promo_start_time: "",
    //         promo_end_type: 0,
    //         promo_end_date: "",
    //         promo_end_time: "",
    //         applied_type: 0,
    //         createdAt: "",
    //         updatedAt: "",
    //         promo_start_formatted: "",
    //         promo_end_formatted: "",
    //         id: "",
    //     },
    //     code_name: "",
    //     user_id: null,
    //     status: 0,
    //     createdAt: "",
    //     updatedAt: "",
    //     id: "",
    // }
};

const eventSlice = createSlice({
    name: "event",
    initialState,
    reducers: {
        setBasicInfo: (state, { payload }: PayloadAction<EventBasicInfo>) => {
            state.basicInfo = payload
        },
        setSelectedTicketGroup: (state, { payload }: PayloadAction<TicketGroup>) => {
            state.selectedTicketGroup = payload;
        },
        setSelectedTicket: (state, { payload }: PayloadAction<Ticket>) => {
            state.selectedTicket = payload;
        },
        setSelectedAddon: (state, { payload }: PayloadAction<Addon>) => {
            state.selectedAddon = payload;
        },
        setSelectedPromotion: (state, { payload }: PayloadAction<Promotion>) => {
            state.selectedPromotion = payload;
        },
        // setSelectedPromotionCodeTagging: (state, { payload }: PayloadAction<PromotionCodeTagging>) => {
        //     state.selectedPromotionCodeTagging = payload;
        // },
    },
});

export const {
    setBasicInfo,
    setSelectedTicketGroup,
    setSelectedTicket,
    setSelectedAddon,
    setSelectedPromotion,
    // setSelectedPromotionCodeTagging,
} = eventSlice.actions;

export default eventSlice.reducer;
