export default function SortIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={17}
      height={17}
      viewBox="0 0 17 17"
      fill="none"
    >
      <path
        d="M12.25 1L12.6036 0.646447L12.25 0.292894L11.8964 0.646447L12.25 1ZM12.25 8.5L11.75 8.5L11.75 8.5L12.25 8.5ZM10.375 9.875C10.0989 9.875 9.875 10.0989 9.875 10.375C9.875 10.6511 10.0989 10.875 10.375 10.875L10.375 9.875ZM16.3536 4.39645L12.6036 0.646447L11.8964 1.35355L15.6464 5.10355L16.3536 4.39645ZM11.8964 0.646447L8.14645 4.39645L8.85355 5.10355L12.6036 1.35355L11.8964 0.646447ZM11.75 1L11.75 8.5L12.75 8.5L12.75 1L11.75 1ZM11.75 8.5C11.75 9.25939 11.1344 9.875 10.375 9.875L10.375 10.875C11.6867 10.875 12.75 9.81168 12.75 8.5L11.75 8.5Z"
        fill="white"
      />
      <path
        d="M4.75 16L5.10355 16.3536L4.75 16.7071L4.39645 16.3536L4.75 16ZM4.75 8.5L4.25 8.5L4.75 8.5ZM6.625 6.125C6.90114 6.125 7.125 6.34886 7.125 6.625C7.125 6.90114 6.90114 7.125 6.625 7.125L6.625 6.125ZM8.85355 12.6036L5.10355 16.3536L4.39645 15.6464L8.14645 11.8964L8.85355 12.6036ZM4.39645 16.3536L0.646447 12.6036L1.35355 11.8964L5.10355 15.6464L4.39645 16.3536ZM4.25 16L4.25 8.5L5.25 8.5L5.25 16L4.25 16ZM4.25 8.5C4.25 7.18833 5.31332 6.125 6.625 6.125L6.625 7.125C5.86561 7.125 5.25 7.74061 5.25 8.5L4.25 8.5Z"
        fill="white"
      />
    </svg>
  );
}
