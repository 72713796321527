import { FormikProps, FormikValues } from 'formik';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import ImageUploadPreview from '../../../components/ImageUploadPreview';
import FormWrapper from '../../../components/form/FormWrapper';
import { useAppDispatch, useAppSelector } from '../../../hooks/useStore';
import { setLoading } from '../../../redux/slices/commonSlice';
import { get, post } from '../../../utils/AxiosReqeust';
import { removeNull } from '../../../utils/removeNull';
import FormCategoryTitle from './FormCategoryTitle';
import CurrencyInput from "react-currency-input-field";
import { isEqualWith } from 'lodash';

import 'rc-time-picker/assets/index.css';
import moment from 'moment';
import TimePicker from 'rc-time-picker';
const format = 'h:mm a';

const AddonForm = ({currentLang, onSubmitSuccess, resetDefaultLang, currentData}: any) => {
    const { basicInfo, selectedAddon } = useAppSelector((state) => state.event);
    const { locales, loading }: any = useAppSelector((state) => state.common);
    const dispatch = useAppDispatch();

    const [addonViewabilityTypes, setAddonViewabilityTypes] = useState<any[]>([]);

    useEffect(() => {
      const getFormInfo = async () => {
        try {
          const [
            addonViewabilityTypes,
          ] = await Promise.all([
            get(`/ticket/all-addon-viewability-type`),
          ]);
          if (addonViewabilityTypes.data.success && addonViewabilityTypes.data.data) {
            setAddonViewabilityTypes(addonViewabilityTypes.data.data.map((item: any) => {
              return {name: item?.name, value: item?._id}
            }));
          }
        }
        catch (error) {
          console.log(error);
        }
      }
      getFormInfo();
    }, [currentLang]);

    const initialValues = selectedAddon && Object.keys(selectedAddon).length !== 0 ? 
    {
        ...selectedAddon,
        ...{
            translations: locales?.available_languages?.map((item: any) => {
                const translation = Array.isArray(selectedAddon.translations) ? selectedAddon.translations.filter((tr: any) => tr.language === item) : [];
                if (translation.length > 0) {
                    return translation[0]
                }
                else {
                    return {
                        language: item,
                        name: '',
                        description: ''
                    }
                }
            }),
            sales_start_date: new Date(selectedAddon?.sales_start_date),
            sales_start_time: moment(selectedAddon?.sales_start_time),
            sales_end_date: new Date(selectedAddon?.sales_end_date),
            sales_end_time: moment(selectedAddon?.sales_end_time),
            addon_viewability_type_id: selectedAddon?.addon_viewability_type_id?._id,
            quantity: selectedAddon?.quantity ?? '',
        }
    } : {
        id: null,
        event_id: basicInfo?._id,
        translations: locales?.available_languages?.map((item: any) => {
            return {
                id: null,
                language: item,
                name: '',
                description: ''
            }
        }),
        price: '',
        quantity: 0,
        image_url: '',
        sales_start_date: '',
        sales_start_time: moment(),
        sales_end_date: '',
        sales_end_time: moment(),
        min_addons_per_order: 1,
        max_addons_per_order: 10,
        addon_viewability_type_id: '64f7df2c728437a86d7b75ac',
    };

    const schema = Yup.object({
        translations: Yup.array().of(
            Yup.object().shape({
              name: Yup.string().when("language", (language, schema) => {
                if (language[0] === 'en') {
                  return schema.required("Name is required");
                }
                return schema;
              })
              // description: Yup.string().when("language", (language, schema) => {
              //   if (language[0] === 'en') {
              //     return schema.required("Event Description is required");
              //   }
              //   return schema;
              // })
            })
        ),
        price: Yup.number().min(0).required(
            "Price is required"
        ),
        // quantity: Yup.number().integer().min(1).required(
        //     "Quantity is required"
        // ),
        sales_start_date: Yup.string().required(
            "Sales Start Date is required"
        ),
        sales_start_time: Yup.string().required(
            "Sales Start Time is required"
        ),
        sales_end_date: Yup.string().required(
            "Sales End Date is required"
        ),
        sales_end_time: Yup.string().required(
            "Sales End Time is required"
        ),
        // event_timezone_id: Yup.string().required(
        //     "Event Timezone is required"
        // ),
        min_addons_per_order: Yup.number().integer().min(1).required(
            "Minimum Addons per. Order is required"
        ),
        max_addons_per_order: Yup.number().integer().min(1).required(
            "Maximum Addons per. Order is required"
        ),
        addon_viewability_type_id: Yup.string().required(
            "Addon Viewability Type is required"
        ),
    });

    let prevData = initialValues;
    function customizer(objValue: any, othValue: any, key: any) {
        if (key === "_id") {
            return true;
        }
    }

    const handleSubmit = async (values: any, actions: any) => {
        if (isEqualWith(values, prevData, customizer)) return;
        
        dispatch(setLoading(true));
        try {
            const val = removeNull(values);
            const translations = JSON.stringify(val?.translations);
            const dt = {...val, translations};
            const formData = new FormData();
            Object.entries(dt).forEach(([key, value]: [string, any]) => {
                if (typeof value === 'object' && Object.keys(value)?.includes('objectURL')) {
                    formData.append(key.toString(), value, value?.name)
                }
                else {
                    formData.append(key.toString(), value)
                }
            })
            const response: any = await post(
                `/ticket/addon/create`,
                formData,
                {
                    'Content-Type': 'multipart/form-data'
                }
            );
            dispatch(setLoading(false));
            if (response?.data?.success && response?.data?.data) {
                if (!!onSubmitSuccess) onSubmitSuccess();
                toast.success(response?.data?.message);
                actions.resetForm();
            }
        }
        catch (error) {
            dispatch(setLoading(false));
            // toast.error(JSON.stringify(error));
        }
    };

    const formInputs = (
        {
            errors,
            values,
            touched,
            handleBlur,
            handleChange,
            setFieldValue
        }: FormikProps<FormikValues>
    ) => (
        <div className='flex flex-wrap justify-between'>
            <div className='w-full'>
                <FormCategoryTitle title='Basic Info' />
            </div>
            <div className='w-full grid grid-cols-2 gap-x-[9px]'>
                <div className='py-[10px]'>
                    {
                        values.translations && values.translations.length > 0
                        ? values.translations.map(
                            (translation: any, index: number) => (
                                <div
                                    key={`basic-info-${translation.language}`}
                                    className='w-full'
                                    style={{display: translation.language !== currentLang ? 'none' : 'block'}}
                                >
                                    <div className="mr-[9px]">
                                        <label htmlFor={`translations.${index}.name`} className="">
                                            Name
                                        </label>
                                        <InputText
                                            id={`translations.${index}.name`}
                                            className={`w-full ${(Array.isArray(errors?.translations) && Object.values(errors?.translations)[index]?.name && Array.isArray(touched?.translations) && touched?.translations[index]?.name) && "p-invalid"}`}
                                            name={`translations.${index}.name`}
                                            placeholder='Title'
                                            value={values.translations && values.translations[index] && values.translations[index].name}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        />
                                        {Array.isArray(errors?.translations) && Object.values(errors?.translations)[index]?.name && Array.isArray(touched?.translations) && touched?.translations[index]?.name ? (
                                            <small className="block mt-2 p-error">{ Object.values(errors?.translations)[index]?.name }</small>
                                        ) : null}
                                    </div>
                                    {/* <div className="pb-[10px]">
                                        <label htmlFor={`translations.${index}.description`} className="">
                                            Description
                                        </label>
                                        <InputText
                                            id={`translations.${index}.description`}
                                            className={`w-full ${(Array.isArray(errors?.translations) && Object.values(errors?.translations)[index]?.description && Array.isArray(touched?.translations) && touched?.translations[index]?.description) && "p-invalid"}`}
                                            name={`translations.${index}.description`}
                                            placeholder='Description'
                                            value={values.translations && values.translations[index] && values.translations[index].description}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        />
                                        {Array.isArray(errors?.translations) && Object.values(errors?.translations)[index]?.description && Array.isArray(touched?.translations) && touched?.translations[index]?.description ? (
                                            <small className="block mt-2 p-error">{ Object.values(errors?.translations)[index]?.description }</small>
                                        ) : null}
                                    </div> */}
                                </div>
                            )
                        )
                        : null
                    }
                </div>
                {/* <div className='py-[10px]'>
                    <label htmlFor="quantity">
                        Quantity
                    </label>
                    <InputText
                        id="quantity"
                        className={`w-full ${(errors.quantity && touched.quantity) && "p-invalid"}`}
                        name="quantity"
                        placeholder='Quantity'
                        value={values.quantity}
                        onBlur={handleBlur}
                        onChange={handleChange}
                    />
                    {errors.quantity && touched.quantity ? (
                        <small className="block mt-2 p-error">{errors?.quantity?.toString()}</small>
                    ) : null}
                </div> */}
                <div className='py-[10px]'>
                    <label htmlFor="price">
                        Price ({basicInfo.currency_id.currency_code})
                    </label>
                    <CurrencyInput
                        id="price"
                        className={`p-inputtext p-component w-full ${(errors.price && touched.price) && "p-invalid"}`}
                        name="price"
                        placeholder='0.00'
                        defaultValue={values.price}
                        onValueChange={(value, name) => setFieldValue(name ?? 'price', value)}
                    />
                    {errors.price && touched.price ? (
                        <small className="block mt-2 p-error">{errors?.price?.toString()}</small>
                    ) : null}
                </div>
            </div>
            <div className='w-full'>
                <div className="py-[10px]">
                    <ImageUploadPreview
                        id={'image_url'}
                        label={'Image'}
                        value={values.image_url}
                        setUploadImage={(file: any) => setFieldValue('image_url', file)}
                    />
                    {errors.image_url && touched.image_url ? (
                        <small className="block mt-2 p-error">
                            {errors?.image_url?.toString()}
                        </small>
                    ) : null}
                </div>
                <FormCategoryTitle title='Date and Time' subtitle='Lorem ipsum dolor sit amet consectetur adipisicing elit. Iste, cumque!' />
                <div className='flex flex-wrap justify-between'>
                    <div className='w-full grid grid-cols-2 gap-x-[9px]'>
                        <div className='py-[10px] w-full'>
                            <label
                                htmlFor="sales_start_date"
                                className=""
                            >
                                Sale Starts<span style={{color: '#D04EFF'}}>*</span>
                            </label>

                            <Calendar
                                inputId="sales_start_date"
                                name="sales_start_date"
                                readOnlyInput
                                minDate={new Date(new Date().setDate(new Date().getDate() - 1))}
                                dateFormat="dd M yy, D"
                                placeholder="Start Date"
                                className={`w-full ${
                                    touched.sales_start_date && errors.sales_start_date
                                        ? "p-invalid"
                                        : ""
                                }`}
                                value={values.sales_start_date}
                                onChange={handleChange}
                            />

                            {errors.sales_start_date && touched.sales_start_date ? (
                                <small className="block mt-2 p-error">
                                    {errors?.sales_start_date?.toString()}
                                </small>
                            ) : null}
                        </div>
                        <div className='py-[10px] w-full'>
                            <label
                                htmlFor="sales_start_time"
                                className=""
                            >
                                Start Time
                            </label>

                            <TimePicker
                                id="sales_start_time"
                                name="sales_start_time"
                                showSecond={false}
                                defaultValue={values.sales_start_time}
                                className={`w-full ${touched.sales_start_time && errors.sales_start_time
                                    ? "p-invalid"
                                    : ""
                                }`}
                                onChange={(momentValue:any) => {
                                    setFieldValue('sales_start_time', momentValue.toISOString());
                                }}
                                format={format}
                                use12Hours
                                inputReadOnly
                                allowEmpty={false}
                            />

                            {errors.sales_start_time && touched.sales_start_time ? (
                                <small className="block mt-2 p-error">
                                    {errors?.sales_start_time?.toString()}
                                </small>
                            ) : null}
                        </div>
                    </div>
                    <div className='w-full grid grid-cols-2 gap-x-[9px]'>
                        <div className='py-[10px] w-full'>
                            <label
                                htmlFor="sales_end_date"
                                className=""
                            >
                                Sale End<span style={{color: '#D04EFF'}}>*</span>
                            </label>

                            <Calendar
                                inputId="sales_end_date"
                                name="sales_end_date"
                                readOnlyInput
                                minDate={new Date(new Date().setDate(new Date().getDate() - 1))}
                                dateFormat="dd M yy, D"
                                placeholder="End Date"
                                className={`w-full ${
                                    touched.sales_end_date && errors.sales_end_date
                                        ? "p-invalid"
                                        : ""
                                }`}
                                value={values.sales_end_date}
                                onChange={handleChange}
                            />

                            {errors.sales_end_date && touched.sales_end_date ? (
                                <small className="block mt-2 p-error">
                                    {errors?.sales_end_date?.toString()}
                                </small>
                            ) : null}
                        </div>
                        <div className='py-[10px] w-full'>
                            <label
                                htmlFor="sales_end_time"
                                className=""
                            >
                                End Time
                            </label>

                            <TimePicker
                                id="sales_end_time"
                                name="sales_end_time"
                                showSecond={false}
                                defaultValue={values.sales_end_time}
                                className={`w-full ${touched.sales_end_time && errors.sales_end_time
                                    ? "p-invalid"
                                    : ""
                                }`}
                                onChange={(momentValue:any) => {
                                    setFieldValue('sales_end_time', momentValue.toISOString());
                                }}
                                format={format}
                                use12Hours
                                inputReadOnly
                                allowEmpty={false}
                            />

                            {errors.sales_end_time && touched.sales_end_time ? (
                                <small className="block mt-2 p-error">
                                    {errors?.sales_end_time?.toString()}
                                </small>
                            ) : null}
                        </div>
                        {/* <div className="py-[10px] w-full">
                            <label
                                htmlFor="event_timezone_id"
                                className=""
                            >
                                Time Zone
                            </label>

                            <Dropdown
                                inputId="event_timezone_id"
                                name="event_timezone_id"
                                value={values.event_timezone_id}
                                options={eventTimezones}
                                optionLabel="name"
                                filter
                                placeholder="Select"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                className={`w-full ${
                                    errors.event_timezone_id &&
                                    touched.event_timezone_id &&
                                    "p-invalid"
                                }`}
                            />
                            {errors.event_timezone_id && touched.event_timezone_id ? (
                                <small className="block mt-2 p-error">
                                    {errors?.event_timezone_id?.toString()}
                                </small>
                            ) : null}
                        </div> */}
                    </div>
                </div>
            </div>
            <div className='w-full'>
                <FormCategoryTitle title='Advanced Settings' />
            </div>
            <div className='w-full mb-[30px] grid grid-cols-2 gap-x-[9px]'>
                <div className="py-[10px]">
                    <label
                        htmlFor="addon_viewability_type_id"
                    >
                        Visibility
                    </label>
                    <Dropdown
                        inputId="addon_viewability_type_id"
                        name="addon_viewability_type_id"
                        value={values?.addon_viewability_type_id}
                        options={addonViewabilityTypes}
                        optionLabel="name"
                        placeholder="Select"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        className={`w-full ${
                            errors.addon_viewability_type_id &&
                            touched.addon_viewability_type_id &&
                            "p-invalid"
                        }`}
                    />
                    {errors.addon_viewability_type_id && touched.addon_viewability_type_id ? (
                        <small className="block mt-2 p-error">
                            {errors?.addon_viewability_type_id?.toString()}
                        </small>
                    ) : null}
                </div>
                <div className='py-[10px]'>
                    <label htmlFor="min_addons_per_order">
                        Minimum Addons per. Order
                    </label>
                    <InputText
                        id="min_addons_per_order"
                        className={`w-full ${(errors.min_addons_per_order && touched.min_addons_per_order) && "p-invalid"}`}
                        name="min_addons_per_order"
                        placeholder='Minimum Addons per. Order'
                        value={values.min_addons_per_order}
                        onBlur={handleBlur}
                        onChange={handleChange}
                    />
                    {errors.min_addons_per_order && touched.min_addons_per_order ? (
                        <small className="block mt-2 p-error">{errors?.min_addons_per_order?.toString()}</small>
                    ) : null}
                </div>
                <div className='py-[10px]'>
                    <label htmlFor="max_addons_per_order">
                        Maximum Addons per. Order
                    </label>
                    <InputText
                        id="max_addons_per_order"
                        className={`w-full ${(errors.max_addons_per_order && touched.max_addons_per_order) && "p-invalid"}`}
                        name="max_addons_per_order"
                        placeholder='Minimum Addons per. Order'
                        value={values.max_addons_per_order}
                        onBlur={handleBlur}
                        onChange={handleChange}
                    />
                    {errors.max_addons_per_order && touched.max_addons_per_order ? (
                        <small className="block mt-2 p-error">{errors?.max_addons_per_order?.toString()}</small>
                    ) : null}
                </div>
            </div>
        </div>
    );

    return (
        <FormWrapper
            noValidate
            enableReinitialize
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={schema}
            formInputs={formInputs}
            submitButton={
                <div className='flex justify-end'>
                    <Button loading={loading} className="justify-center w-full md:w-[23.75%]" size="large" type="submit"
                            onClick={() => {
                                if (!!resetDefaultLang) resetDefaultLang();
                            }}
                    >
                        {selectedAddon?.id ? 'Update' : 'Save'}
                    </Button>
                </div>
            }
            className="gap-2"
        />
    )
}

export default AddonForm
