import { FormikProps, FormikValues } from 'formik';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import React from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import FormWrapper from '../../../components/form/FormWrapper';
import { useAppDispatch, useAppSelector } from '../../../hooks/useStore';
import { setLoading } from '../../../redux/slices/commonSlice';
import { post } from '../../../utils/AxiosReqeust';
import { removeNull } from '../../../utils/removeNull';
import isEqual from "lodash-es/isEqual";


const TicketGroupForm = ({ currentLang, onSubmitSuccess, resetDefaultLang, currentData }: any) => {
    const { basicInfo, selectedTicketGroup } = useAppSelector((state) => state.event);
    const { locales, loading }: any = useAppSelector((state) => state.common);
    const dispatch = useAppDispatch();

    const initialValues = selectedTicketGroup && Object.keys(selectedTicketGroup).length !== 0 ?
        {
            ...selectedTicketGroup,
            ...{
                translations: locales?.available_languages?.map((item: any) => {
                    const translation = Array.isArray(selectedTicketGroup.translations) ? selectedTicketGroup.translations.filter((tr: any) => tr.language === item) : [];
                    if (translation.length > 0) {
                        return translation[0]
                    }
                    else {
                        return {
                            language: item,
                            name: '',
                        }
                    }
                }),
            }
        } : {
            id: null,
            translations: locales?.available_languages?.map((item: any) => {
                return {
                    id: null,
                    language: item,
                    name: '',
                }
            }),
            event_id: basicInfo?._id,
            max_capacity: '',
        };

    const schema = Yup.object({
        translations: Yup.array().of(
            Yup.object().shape({
                name: Yup.string().when("language", (language, schema) => {
                    if (language[0] === 'en') {
                        return schema.required("Name is required");
                    }
                    return schema;
                })
            })
        ),
        max_capacity: Yup.number().integer().required(
            "Maximum Capacity is required"
        ),
    });
    
    let prevData = initialValues
    const handleSubmit = async (values: any, actions: any) => {
        if (isEqual(values, prevData)) return

        dispatch(setLoading(true));
        try {
            const response: any = await post(`/ticket/create-group`, removeNull(values));
            if (response?.data?.success && response?.data?.data) {
                if (!!onSubmitSuccess) onSubmitSuccess();
                toast.success(response?.data?.message);
                actions.resetForm();
            }
        }
        catch (error) {
            dispatch(setLoading(false));
            // toast.error(JSON.stringify(error));
        }

        dispatch(setLoading(false));
    };

    const formInputs = (
        {
            errors,
            values,
            touched,
            handleBlur,
            handleChange,
            setFieldValue
        }: FormikProps<FormikValues>
    ) => (
        <div className='flex flex-wrap justify-between'>
            <div className='w-full grid grid-cols-2 gap-x-[9px]'>
                <div className='py-[10px]'>
                    {
                        values.translations && values.translations.length > 0
                            ? values.translations.map(
                                (translation: any, index: number) => (
                                    <div
                                        key={`basic-info-${translation.language}`}
                                        className='w-full'
                                        style={{ display: translation.language !== currentLang ? 'none' : 'block' }}
                                    >
                                        <div className="mr-[9px]">
                                            <label htmlFor={`translations.${index}.name`} className="">
                                                Name
                                            </label>
                                            <InputText
                                                id={`translations.${index}.name`}
                                                className={`w-full ${(Array.isArray(errors?.translations) && Object.values(errors?.translations)[index]?.name && Array.isArray(touched?.translations) && touched?.translations[index]?.name) && "p-invalid"}`}
                                                name={`translations.${index}.name`}
                                                placeholder='Title'
                                                value={values.translations && values.translations[index] && values.translations[index].name}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                            />
                                            {Array.isArray(errors?.translations) && Object.values(errors?.translations)[index]?.name && Array.isArray(touched?.translations) && touched?.translations[index]?.name ? (
                                                <small className="block mt-2 p-error">{Object.values(errors?.translations)[index]?.name}</small>
                                            ) : null}
                                        </div>
                                    </div>
                                )
                            )
                            : null
                    }
                </div>
                <div className='py-[10px]'>
                    <label htmlFor="max_capacity">
                        Maximum Capacity
                    </label>
                    <InputText
                        id="max_capacity"
                        className={`w-full ${(errors.max_capacity && touched.max_capacity) && "p-invalid"}`}
                        name="max_capacity"
                        placeholder='Maximum Capacity'
                        value={values.max_capacity}
                        onBlur={handleBlur}
                        onChange={handleChange}
                    />
                    {errors.max_capacity && touched.max_capacity ? (
                        <small className="block mt-2 p-error">{errors?.max_capacity?.toString()}</small>
                    ) : null}
                </div>
            </div>
        </div>
    );

    return (
        <FormWrapper
            noValidate
            enableReinitialize
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={schema}
            formInputs={formInputs}
            submitButton={
                <div className='flex justify-end'>
                    <Button loading={loading} className="justify-center w-full md:w-[23.75%]" size="large" type="submit"
                        onClick={() => {
                            if (!!resetDefaultLang) resetDefaultLang();
                        }}
                    >
                        {selectedTicketGroup?.id ? 'Update' : 'Save'}
                    </Button>
                </div>
            }
            className="gap-2"
        />
    )
}

export default TicketGroupForm
