import { useState } from "react";
import TableWrapper from "../../components/listing/TableWrapper";
import { Column } from "primereact/column";
import { Button } from "primereact/button";

interface ApiResponseType {
    id: string;
    name: string;
    language: string;
}


const Finance = () => {
    const [page, setPage] = useState(1);
    const [params, setParams] = useState({
        sortBy: 'createdAt',
        sortOrder: 'desc'
    });
    const [records, setRecords] = useState(10);
    const comingSoon = true;

    const data = {
        "docs": [
            {
                "id": "652cee741d0e84ca9590fe48",
                "name": "Finance 1",
                "language": "en",
            },
            {
                "id": "6553168095ba5f481a08732c",
                "name": "Finance 2 ",
                "language": "zh",
            },
            {
                "id": "654c9d53620e91408f23878a",
                "name": "Finance 3",
                "language": "en",
            },
            {
                "id": "655482ce58918cbc0b231bfe",
                "name": "Finance 4",
                "language": "zh",
            },
            {
                "id": "6554804358918cbc0b2314b7",
                "name": "Finance 5",
                "language": "zh",
            },
            {
                "id": "652d07dc0bee4cf6dbbc5226",
                "name": "Finance 6",
                "language": "en",
            },
            {
                "id": "655c481f79f58aff51ecc06c",
                "name": "Finance 7",
                "language": "zh",
            },
            {
                "id": "654c9c9c3f8cc025c4984f96",
                "name": "Finance 8",
                "language": "en",
            },
            {
                "id": "652cee421d0e84ca9590fde1",
                "name": "Finance 9",
                "language": "en",
            }
        ],

        "totalDocs": 9,
        "offset": 0,
        "limit": 10,
        "totalPages": 1,
        "page": 1,
        "pagingCounter": 1,
        "hasPrevPage": false,
        "hasNextPage": false,
        "prevPage": null,
        "nextPage": null
    }

    const setPageLimit = (limit: number) => {
        setRecords(limit);
        setPage(1);
    }

    const actionColumnBody = () => {
        return <div className="flex align-middle">
            <div className="mr-2">
                <Button className="!rounded-3xl !p-3"><i className='pi pi-pencil' /></Button>
            </div>
            <div>
                <Button className="!rounded-3xl !p-3"><i className='pi pi-trash' /></Button>
            </div>
        </div>
    }

    const nameTemplate = ({ name }: ApiResponseType) => {
        return <div className='overflow-hidden text-ellipsis line-clamp-2 flex items-center'>
            <span>{name}</span>
        </div>
    }
    const idTemplate = ({ id }: ApiResponseType) => {
        return <div className='overflow-hidden text-ellipsis line-clamp-2 flex items-center'>
            <span>{id}</span>
        </div>
    }


    return <>
        {!comingSoon ? <TableWrapper
            data={data}
            loading={false}
            setRecords={setPageLimit}
            scrollable
            scrollHeight='70vh'
            onSort={(e) => {
                setPage(1)
                setParams({ ...params, sortBy: e.sortField, sortOrder: params.sortOrder === 'asc' ? 'dsc' : 'asc' })
            }}
            nextPage={() => {
                if (!data.hasNextPage && (page < data.totalPages)) return
                setPage((current: any) => (current + 1))
            }}
            prevPage={() => {
                if (!data.hasPrevPage) return
                setPage((current: any) => (current - 1))
            }}
        >
            <Column field="id" header="Id" style={{ width: '121px' }} body={idTemplate}></Column>
            <Column field="name" header="Finance Name" sortable body={nameTemplate}></Column>
            <Column header="Action" body={actionColumnBody} style={{ width: '150px' }}></Column>
        </TableWrapper> : <div>
            <div className='top-[50%] left-[50%] absolute translate-y-[-50%] translate-x-[-50%] text-center'>
                <h2 className='font-bold text-aria_dark_purple text-[64px]'>Coming Soon</h2>
            </div>
        </div>
        }
    </>
}

export default Finance;