import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Tooltip } from 'primereact/tooltip';
import {setNavExpand} from "../../redux/slices/commonSlice";
import {useAppDispatch, useAppSelector} from "../../hooks/useStore";
import useAuth from "../../hooks/useAuth";
import navigationMenuContent from "../../utils/navigationMenuContent";

import Logo from "../../assets/images/aria_icon.svg"
import LogoIcon from "../../assets/images/aria_icon_logo.svg"
import MenuArrowBtn from '../../assets/images/menu-arrow-btn.png';

const NavigationMenu = () => {
    const navigate = useNavigate();
    const {navExpand} = useAppSelector((state) => state.common);
    const [navContent, setNavContent] = useState<Array<any>>([]);
    const { userAccess } = useAuth();
    const location = useLocation()
    const dispatch = useAppDispatch();

    useEffect(() => {
        setNavContent(navigationMenuContent.filter(nav => (nav.access.includes(userAccess) && !nav?.hide)))
    }, [userAccess, setNavContent]);

    const toggleMenu = (value:any) => {
        if (value) {
            dispatch(setNavExpand(false));
        }else{
            dispatch(setNavExpand(true));
        }
    }

    return <>
        <aside className={`flex flex-col justify-between text-gray-300 px-[13px] py-[21px] bg-[#0a0a0b] transition-all duration-300 ease-in-out ${navExpand ? 'w-[200px]' : 'w-[69px]'}`}>
            <div>
                <div className={`flex items-center ${navExpand?'justify-start':'justify-center'} overflow-hidden bg-[#0a0a0b] mb-[23.5px] hover:text-gray-100 hover:bg-opacity-50 focus:outline-none focus:text-gray-100 focus:bg-opacity-50`}>
                    <img className='flex-shrink-0 object-contain text-center' src={navExpand ? Logo : LogoIcon } alt="logo" />
                </div>
                <div className={`space-y-2 font-medium align-middle ${navExpand ? 'overflow-y-auto overflow-x-hidden' : 'overflow-hidden'}`}>
                    {
                        navContent.length > 0 && navContent.map((content, i) => (
                            <div key={i}>
                                { !navExpand &&
                                    <Tooltip target='.tooltip-nav-menu'/>
                                }
                                <div onClick={() => navigate(content?.url)} className={`tooltip-nav-menu ${location.pathname === content.url ? 'bg-aria_dark' : 'bg-transparent'} ${navExpand ? '' : 'flex justify-center'} relative transition-all flex items-center rounded-md h-[42px] mb-2 text-white duration-150 cursor-pointer ease-in-outcursor-pointer focus:outline-none focus:shadow-outline`}
                                     data-pr-tooltip={content?.label}
                                     data-pr-position="right"
                                     data-pr-at="right-15 top"
                                >
                                    <span className={`w-[42px] min-w-[42px] ${navExpand ? '' : 'absolute'}`}>
                                        {
                                            content?.icon ?
                                                <img className="w-auto m-auto" src={content?.icon} alt=""/>
                                                :
                                                <i className={'!text-[16px] px-[14px] m-auto ' + content?.iconClass} />
                                        }
                                    </span>
                                    <span className={`ml-2 duration-300 ease-in-out ${navExpand ? 'block' : 'hidden'}`}
                                          data-pr-tooltip={content?.label}
                                          data-pr-position="right"
                                    >{content?.label}</span>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
            <div onClick={() => {
                toggleMenu(navExpand)
            }}
                style={arrowButtonStyle} className='cursor-pointer flex items-center justify-self-end hover:bg-[#0a0a0b] rounded-full'>
                <img className="max-h-full" src={MenuArrowBtn} alt=""
                    style={{
                        transform: navExpand ? 'scaleX(-1)': 'scaleX(1)',
                        transition: 'all 0.2s ease-in',
                    }}
                />
            </div>
        </aside>
    </>
}

export default NavigationMenu;

const arrowButtonStyle= {
    // background: '#0A0A0B',
    // width: '62px',
    display: 'flex',
    justifyContent: 'center',
    // height: '60px',
    marginLeft: '0px',
    alignSelf: 'flex-end',
    marginRight: '-55px',
    marginBottom: '8px',
    zIndex: 1,
};