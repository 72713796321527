import { FC, PropsWithChildren } from "react";
import illustration from "../assets/images/login-bg.png";
interface Props extends PropsWithChildren {
  title?: string;
  description?: string | JSX.Element;
}

const AuthWrapper: FC<Props> = ({ children, title, description }) => {
  return (
    <div className="w-full h-[100vh] flex bg-slate-100">
      <aside className="h-full w-[60%] bg-primary overflow-hidden">
        <img src={illustration} className="object-cover w-full h-full" alt="" />
      </aside>
      <main className="flex items-center flex-1 p-8 ">
        <div className="flex-1 max-w-lg p-8 mx-auto bg-white border shadow-sm rounded-xl">
          {title ? (
            <div className="pb-2 text-4xl font-bold tracking-tight transition-colors scroll-m-20 first:mt-0">
              {title}
            </div>
          ) : null}
          {description ? (
            <div className="mb-12 text-zinc-600">{description}</div>
          ) : null}
          {children}
        </div>
      </main>
    </div>
  );
};

export default AuthWrapper;
