import { FormikProps, FormikValues } from 'formik';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { RadioButton } from 'primereact/radiobutton';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import FormWrapper from '../../../components/form/FormWrapper';
import { useAppDispatch, useAppSelector } from '../../../hooks/useStore';
import { setLoading } from '../../../redux/slices/commonSlice';
import { get, post } from '../../../utils/AxiosReqeust';
import { removeNull } from '../../../utils/removeNull';
import FormCategoryTitle from '../../Events/components/FormCategoryTitle';
import TextEditor from "../../../components/TextEditor";
import CurrencyInput from 'react-currency-input-field';
import isEqual from "lodash-es/isEqual";

import 'rc-time-picker/assets/index.css';
import moment from 'moment';
import TimePicker from 'rc-time-picker';
const format = 'h:mm a';

const TicketForm = ({ currentLang, onSubmitSuccess, resetDefaultLang, currentData }: any) => {
    const { basicInfo, selectedTicketGroup, selectedTicket } = useAppSelector((state) => state.event);
    const { locales, loading }: any = useAppSelector((state) => state.common);
    const dispatch = useAppDispatch();

    const [ticketGroups, setTicketGroups] = useState<any[]>([]);
    const [ticketTypes, setTicketTypes] = useState<any[]>([]);
    const [ticketViewabilityTypes, setTicketViewabilityTypes] = useState<any[]>([]);

    useEffect(() => {
        const getFormInfo = async () => {
            try {
                const [
                    ticketGroupsResponse,
                    ticketTypesResponse,
                    ticketViewabilityTypes,
                ] = await Promise.all([
                    get(`/ticket/all-ticket-group-by-event/${basicInfo?._id}`, { 'Accept-Language': currentLang }),
                    get(`/ticket/all-ticket-type`),
                    get(`/ticket/all-ticket-viewability-type`),
                ]);
                if (ticketGroupsResponse.data.success && ticketGroupsResponse.data.data) {
                    setTicketGroups(ticketGroupsResponse.data.data.map((item: any) => {
                        return { name: item?.name, value: item?._id }
                    }));
                }
                if (ticketTypesResponse.data.success && ticketTypesResponse.data.data) {
                    setTicketTypes(ticketTypesResponse.data.data.map((item: any) => {
                        return { name: item?.name, value: item?._id }
                    }));
                }
                if (ticketViewabilityTypes.data.success && ticketViewabilityTypes.data.data) {
                    setTicketViewabilityTypes(ticketViewabilityTypes.data.data.map((item: any) => {
                        return { name: item?.name, value: item?._id }
                    }));
                }
            }
            catch (error) {
                console.log(error);
            }
        }
        getFormInfo();
    }, [currentLang]);

    const initialValues = selectedTicket && Object.keys(selectedTicket).length !== 0 ?
        {
            ...selectedTicket,
            ...{
                translations: locales?.available_languages?.map((item: any) => {
                    const translation = Array.isArray(selectedTicket.translations) ? selectedTicket.translations.filter((tr: any) => tr.language === item) : [];
                    if (translation.length > 0) {
                        return translation[0]
                    }
                    else {
                        return {
                            language: item,
                            name: '',
                            description: ''
                        }
                    }
                }),
                sales_start_date: new Date(selectedTicket?.sales_start_date),
                sales_start_time: moment(selectedTicket?.sales_start_time),
                sales_end_date: new Date(selectedTicket?.sales_end_date),
                sales_end_time: moment(selectedTicket?.sales_end_time),
                ticket_viewability_type_id: selectedTicket?.ticket_viewability_type_id?._id,
                // max_capacity: selectedTicket?.max_capacity ?? '',
            }
        } : {
            id: null,
            translations: locales?.available_languages?.map((item: any) => {
                return {
                    id: null,
                    language: item,
                    name: '',
                    description: ''
                }
            }),
            price: '',
            // max_capacity: '',
            sales_start_date: '',
            sales_start_time: moment(),
            sales_end_date: '',
            sales_end_time: moment(),
            min_tickets_per_order: 1,
            max_tickets_per_order: 10,
            ticket_viewability_type_id: '64f7defe728437a86d7b75a7',
            ticket_type_id: '64f7e14396088a2e5e451304',
            ticket_group_id: selectedTicketGroup?.id,
        };

    const schema = Yup.object({
        translations: Yup.array().of(
            Yup.object().shape({
                name: Yup.string().when("language", (language, schema) => {
                    if (language[0] === 'en') {
                        return schema.required("Name is required");
                    }
                    return schema;
                })
                // description: Yup.string().when("language", (language, schema) => {
                //   if (language[0] === 'en') {
                //     return schema.required("Event Description is required");
                //   }
                //   return schema;
                // })
            })
        ),
        price: Yup.number().min(0).required(
            "Price is required"
        ),
        // max_capacity: Yup.number().integer().min(1),
        sales_start_date: Yup.string().required(
            "Sales Start Date is required"
        ),
        sales_start_time: Yup.string().required(
            "Sales Start Time is required"
        ),
        sales_end_date: Yup.string().required(
            "Sales End Date is required"
        ),
        sales_end_time: Yup.string().required(
            "Sales End Time is required"
        ),
        // event_timezone_id: Yup.string().required(
        //     "Event Timezone is required"
        // ),
        min_tickets_per_order: Yup.number().integer().min(1).required(
            "Minimum Tickets per. Order is required"
        ),
        max_tickets_per_order: Yup.number().integer().min(1).required(
            "Maximum Tickets per. Order is required"
        ),
        ticket_viewability_type_id: Yup.string().required(
            "Ticket Viewability Type is required"
        ),
        // ticket_type_id: Yup.string().required(
        //     "Ticket Type is required"
        // ),
        ticket_group_id: Yup.string().required(
            "Ticket Group is required"
        ),
    });
    let prevData = initialValues
    const handleSubmit = async (values: any, actions: any) => {
        if (isEqual(values, prevData)) return

        dispatch(setLoading(true));
        try {
            const response: any = await post(`/ticket/create`, removeNull(values));
            dispatch(setLoading(false));
            if (response?.data?.success && response?.data?.data) {
                if (!!onSubmitSuccess) onSubmitSuccess();
                toast.success(response?.data?.message);
                actions.resetForm();
            }
        }
        catch (error) {
            dispatch(setLoading(false));
            // toast.error(JSON.stringify(error));
        }
    };

    const formInputs = (
        {
            errors,
            values,
            touched,
            handleBlur,
            handleChange,
            setFieldValue
        }: FormikProps<FormikValues>
    ) => (
        <div className='flex flex-wrap justify-between'>
            <div className='w-full'>
                <FormCategoryTitle title='Basic Info' />
            </div>
            {/* <div className="w-full grid grid-cols-1 mt-[8px]">
                <div className='flex'>
                    {
                        ticketTypes.map((item: any) => {
                            return (
                                <div key={item?.value} className="relative flex flex-row justify-start items-center mr-[20px]">
                                    <RadioButton
                                        className={`custom-p-radiobutton ${
                                            touched.ticket_type_id && errors.ticket_type_id
                                                ? "p-invalid"
                                                : ""
                                        }`}
                                        inputId={item?.value}
                                        name="ticket_type_id"
                                        value={item?.value}
                                        onChange={handleChange}
                                        checked={item?.value === values.ticket_type_id}
                                    />
                                    <label htmlFor={item?.value} className="custom-label mb-0 ml-0">{item?.name}</label>
                                </div>
                            )
                        })
                    }
                </div>
                {errors.ticket_type_id && touched.ticket_type_id ? (
                    <small className="block mt-2 p-error">
                        {errors?.ticket_type_id?.toString()}
                    </small>
                ) : null}
            </div> */}
            <div className='w-full grid grid-cols-2 gap-x-[9px]'>
                <div className='py-[10px]'>
                    {
                        values.translations && values.translations.length > 0
                            ? values.translations.map(
                                (translation: any, index: number) => (
                                    <div
                                        key={`basic-info-${translation.language}`}
                                        className='w-full'
                                        style={{ display: translation.language !== currentLang ? 'none' : 'block' }}
                                    >
                                        <div className="mr-[9px] mb-[9px]">
                                            <label htmlFor={`translations.${index}.name`} className="">
                                                Name
                                            </label>
                                            <InputText
                                                id={`translations.${index}.name`}
                                                className={`w-full ${(Array.isArray(errors?.translations) && Object.values(errors?.translations)[index]?.name && Array.isArray(touched?.translations) && touched?.translations[index]?.name) && "p-invalid"}`}
                                                name={`translations.${index}.name`}
                                                placeholder='Title'
                                                value={values.translations && values.translations[index] && values.translations[index].name}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                            />
                                            {Array.isArray(errors?.translations) && Object.values(errors?.translations)[index]?.name && Array.isArray(touched?.translations) && touched?.translations[index]?.name ? (
                                                <small className="block mt-2 p-error">{Object.values(errors?.translations)[index]?.name}</small>
                                            ) : null}
                                        </div>
                                        <div className="pb-[10px]">
                                            <label htmlFor={`translations.${index}.description`} className="">
                                                Description
                                            </label>
                                            <TextEditor
                                                id={`translations.${index}.description`}
                                                name={`translations.${index}.description`}
                                                placeholder='Description'
                                                value={values.translations && values.translations[index] && values.translations[index].description}
                                                onTextChange={(e: any) => {
                                                    setFieldValue(`translations.${index}.description`, e.htmlValue);
                                                }} />
                                            {Array.isArray(errors?.translations) && Object.values(errors?.translations)[index]?.description && Array.isArray(touched?.translations) && touched?.translations[index]?.description ? (
                                                <small className="block mt-2 p-error">{Object.values(errors?.translations)[index]?.description}</small>
                                            ) : null}
                                        </div>
                                    </div>
                                )
                            )
                            : null
                    }
                </div>
                <div className="py-[10px]">
                    <label htmlFor="ticket_group_id">Group</label>
                    <Dropdown
                        inputId="ticket_group_id"
                        name="ticket_group_id"
                        value={values?.ticket_group_id}
                        options={ticketGroups}
                        optionLabel="name"
                        placeholder="Select"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        className={`w-full ${errors.ticket_group_id &&
                            touched.ticket_group_id &&
                            "p-invalid"
                            }`}
                        disabled
                    />
                    {errors.ticket_group_id && touched.ticket_group_id ? (
                        <small className="block mt-2 p-error">
                            {errors?.ticket_group_id?.toString()}
                        </small>
                    ) : null}
                </div>
                <div className='py-[10px]'>
                    <label htmlFor="price">
                        Price ({basicInfo.currency_id.currency_code})
                    </label>
                    <CurrencyInput
                        id="price"
                        className={`p-inputtext p-component w-full ${(errors.price && touched.price) && "p-invalid"}`}
                        name="price"
                        placeholder='0.00'
                        defaultValue={values.price}
                        onValueChange={(value, name) => setFieldValue(name ?? 'price', value)}
                    />
                    {errors.price && touched.price ? (
                        <small className="block mt-2 p-error">{errors?.price?.toString()}</small>
                    ) : null}
                </div>
            </div>
            <div className='w-full'>
                <FormCategoryTitle title='Date and Time' subtitle='Lorem ipsum dolor sit amet consectetur adipisicing elit. Iste, cumque!' />
                <div className='flex flex-wrap justify-between'>
                    <div className='w-full grid grid-cols-2 gap-x-[9px]'>
                        <div className='py-[10px] w-full'>
                            <label
                                htmlFor="sales_start_date"
                                className=""
                            >
                                Sale Starts<span style={{ color: '#D04EFF' }}>*</span>
                            </label>

                            <Calendar
                                inputId="sales_start_date"
                                name="sales_start_date"
                                readOnlyInput
                                minDate={new Date(new Date().setDate(new Date().getDate() - 1))}
                                dateFormat="dd M yy, D"
                                placeholder="Start Date"
                                className={`w-full ${touched.sales_start_date && errors.sales_start_date
                                        ? "p-invalid"
                                        : ""
                                    }`}
                                value={values.sales_start_date}
                                onChange={handleChange}
                            />

                            {errors.sales_start_date && touched.sales_start_date ? (
                                <small className="block mt-2 p-error">
                                    {errors?.sales_start_date?.toString()}
                                </small>
                            ) : null}
                        </div>
                        <div className='py-[10px] w-full'>
                            <label
                                htmlFor="sales_start_time"
                                className=""
                            >
                                Start Time
                            </label>

                            <TimePicker
                                id="sales_start_time"
                                name="sales_start_time"
                                showSecond={false}
                                defaultValue={values.sales_start_time}
                                className={`w-full ${touched.sales_start_time && errors.sales_start_time
                                    ? "p-invalid"
                                    : ""
                                }`}
                                onChange={(momentValue:any) => {
                                    setFieldValue('sales_start_time', momentValue.toISOString());
                                }}
                                format={format}
                                use12Hours
                                inputReadOnly
                                allowEmpty={false}
                            />

                            {errors.sales_start_time && touched.sales_start_time ? (
                                <small className="block mt-2 p-error">
                                    {errors?.sales_start_time?.toString()}
                                </small>
                            ) : null}
                        </div>
                    </div>
                    <div className='w-full grid grid-cols-2 gap-x-[9px]'>
                        <div className='py-[10px] w-full'>
                            <label
                                htmlFor="sales_end_date"
                                className=""
                            >
                                Sale End<span style={{ color: '#D04EFF' }}>*</span>
                            </label>

                            <Calendar
                                inputId="sales_end_date"
                                name="sales_end_date"
                                readOnlyInput
                                minDate={new Date(new Date().setDate(new Date().getDate() - 1))}
                                dateFormat="dd M yy, D"
                                placeholder="End Date"
                                className={`w-full ${touched.sales_end_date && errors.sales_end_date
                                        ? "p-invalid"
                                        : ""
                                    }`}
                                value={values.sales_end_date}
                                onChange={handleChange}
                            />

                            {errors.sales_end_date && touched.sales_end_date ? (
                                <small className="block mt-2 p-error">
                                    {errors?.sales_end_date?.toString()}
                                </small>
                            ) : null}
                        </div>
                        <div className='py-[10px] w-full'>
                            <label
                                htmlFor="sales_end_time"
                                className=""
                            >
                                End Time
                            </label>

                            <TimePicker
                                id="sales_end_time"
                                name="sales_end_time"
                                showSecond={false}
                                defaultValue={values.sales_end_time}
                                className={`w-full ${touched.sales_end_time && errors.sales_end_time
                                    ? "p-invalid"
                                    : ""
                                }`}
                                onChange={(momentValue:any) => {
                                    setFieldValue('sales_end_time', momentValue.toISOString());
                                }}
                                format={format}
                                use12Hours
                                inputReadOnly
                                allowEmpty={false}
                            />

                            {errors.sales_end_time && touched.sales_end_time ? (
                                <small className="block mt-2 p-error">
                                    {errors?.sales_end_time?.toString()}
                                </small>
                            ) : null}
                        </div>
                        {/* <div className="py-[10px] w-full">
                            <label
                                htmlFor="event_timezone_id"
                                className=""
                            >
                                Time Zone
                            </label>

                            <Dropdown
                                inputId="event_timezone_id"
                                name="event_timezone_id"
                                value={values.event_timezone_id}
                                options={eventTimezones}
                                optionLabel="name"
                                filter
                                placeholder="Select"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                className={`w-full ${
                                    errors.event_timezone_id &&
                                    touched.event_timezone_id &&
                                    "p-invalid"
                                }`}
                            />
                            {errors.event_timezone_id && touched.event_timezone_id ? (
                                <small className="block mt-2 p-error">
                                    {errors?.event_timezone_id?.toString()}
                                </small>
                            ) : null}
                        </div> */}
                    </div>
                </div>
            </div>
            <div className='w-full'>
                <FormCategoryTitle title='Advanced Settings' />
            </div>
            <div className='w-full mb-[30px] grid grid-cols-2 gap-x-[9px]'>
                <div className="py-[10px]">
                    <label
                        htmlFor="ticket_viewability_type_id"
                    >
                        Visibility
                    </label>
                    <Dropdown
                        inputId="ticket_viewability_type_id"
                        name="ticket_viewability_type_id"
                        value={values?.ticket_viewability_type_id}
                        options={ticketViewabilityTypes}
                        optionLabel="name"
                        placeholder="Select"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        className={`w-full ${errors.ticket_viewability_type_id &&
                            touched.ticket_viewability_type_id &&
                            "p-invalid"
                            }`}
                    />
                    {errors.ticket_viewability_type_id && touched.ticket_viewability_type_id ? (
                        <small className="block mt-2 p-error">
                            {errors?.ticket_viewability_type_id?.toString()}
                        </small>
                    ) : null}
                </div>
                {/* <div className='py-[10px]'>
                    <label htmlFor="max_capacity">
                        Maximum Capacity (optional)
                    </label>
                    <InputText
                        id="max_capacity"
                        className={`w-full ${(errors.max_capacity && touched.max_capacity) && "p-invalid"}`}
                        name="max_capacity"
                        placeholder='Maximum Capacity'
                        value={values.max_capacity}
                        onBlur={handleBlur}
                        onChange={handleChange}
                    />
                    {errors.max_capacity && touched.max_capacity ? (
                        <small className="block mt-2 p-error">{errors?.max_capacity?.toString()}</small>
                    ) : null}
                </div> */}
                <div className='py-[10px]'>
                    <label htmlFor="min_tickets_per_order">
                        Minimum Tickets per. Order
                    </label>
                    <InputText
                        id="min_tickets_per_order"
                        className={`w-full ${(errors.min_tickets_per_order && touched.min_tickets_per_order) && "p-invalid"}`}
                        name="min_tickets_per_order"
                        placeholder='Minimum Tickets per. Order'
                        value={values.min_tickets_per_order}
                        onBlur={handleBlur}
                        onChange={handleChange}
                    />
                    {errors.min_tickets_per_order && touched.min_tickets_per_order ? (
                        <small className="block mt-2 p-error">{errors?.min_tickets_per_order?.toString()}</small>
                    ) : null}
                </div>
                <div className='py-[10px]'>
                    <label htmlFor="max_tickets_per_order">
                        Maximum Tickets per. Order
                    </label>
                    <InputText
                        id="max_tickets_per_order"
                        className={`w-full ${(errors.max_tickets_per_order && touched.max_tickets_per_order) && "p-invalid"}`}
                        name="max_tickets_per_order"
                        placeholder='Minimum Tickets per. Order'
                        value={values.max_tickets_per_order}
                        onBlur={handleBlur}
                        onChange={handleChange}
                    />
                    {errors.max_tickets_per_order && touched.max_tickets_per_order ? (
                        <small className="block mt-2 p-error">{errors?.max_tickets_per_order?.toString()}</small>
                    ) : null}
                </div>
            </div>
        </div>
    );

    return (
        <FormWrapper
            noValidate
            enableReinitialize
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={schema}
            formInputs={formInputs}
            submitButton={
                <div className='flex justify-end'>
                    <Button loading={loading} className="justify-center w-full md:w-[23.75%]" size="large" type="submit"
                        onClick={() => {
                            if (!!resetDefaultLang) resetDefaultLang();
                        }}
                    >
                        {selectedTicket?.id ? 'Update' : 'Save'}
                    </Button>
                </div>
            }
            className="gap-2"
        />
    )
}

export default TicketForm