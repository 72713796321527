import React, { useState, useEffect } from "react";
import { Paginator, PaginatorPageChangeEvent } from 'primereact/paginator';

interface TableFooterProps {
    totalRecords: number,
    nextPage: () => void,
    prevPage: () => void,
    setRecords: (limit: number) => void,
    page: number,
    records: number,
}

const TableFooter = (props: TableFooterProps) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [startIndex, setStartIndex] = useState(0);

    useEffect(() => {
        setStartIndex(props?.records * (props?.page - 1))
        setCurrentPage(props?.page)
    }, [props?.page, props?.totalRecords, props?.records]);

    const onPageChange = (event: PaginatorPageChangeEvent) => {
        if (props?.records !== event.rows) {
            props?.setRecords(event.rows)
            return;
        }

        if (currentPage < (Math.ceil(event.first / event.rows) + 1)) {
            props.nextPage()
        }
        else {
            props.prevPage()
        }
    };

    return <div className='w-full flex justify-center items-center'>
        <Paginator
            style={{ background: 'none' }}
            first={startIndex}
            rows={props?.records}
            totalRecords={props?.totalRecords}
            rowsPerPageOptions={[10, 20, 30]}
            onPageChange={onPageChange}
            template={{ layout: 'PrevPageLink CurrentPageReport NextPageLink RowsPerPageDropdown' }}
            currentPageReportTemplate="{currentPage} of {totalPages}"
        />
    </div>
}

export default TableFooter;