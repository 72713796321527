import moment from "moment";
import { Button } from 'primereact/button';
import { Column } from "primereact/column";
import React, { useEffect, useState } from 'react';
import { useLoaderData, useNavigate } from "react-router-dom";
import { useDebounce } from "use-debounce";
import NoImage from '../../assets/images/no-image.png';
import SearchBox from "../../components/SearchBox";
import TableWrapper, { OnSortProps } from "../../components/listing/TableWrapper";
import { useAppDispatch } from "../../hooks/useStore";
import { setLoading } from "../../redux/slices/commonSlice";
import { setBasicInfo, setSelectedTicket, setSelectedTicketGroup } from '../../redux/slices/eventSlice';
import { Ticket, TicketGroup } from '../../redux/types/event';
import { eventListing } from "../../utils/apiRequestList/eventAPI";
import { remove } from "../../utils/AxiosReqeust";
import { toast } from "react-toastify";
import deleteConfirmation from "../../utils/deleteConfirmation";
import useAuth from "../../hooks/useAuth";

const Events = () => {
    const { isSuperAdmin } = useAuth();
    const [events, setEvents]:any = useState(null);
    const [page, setPage] = useState(1);
    const [records, setRecords] = useState(10);
    // const [sortField, setSortField] = useState<string>('');
    // const [sortOrder, setSortOrder] = useState<0 | 1 | -1 | null | undefined>(null);
    const [searchByName, setSearchByName] = useState("");
    const [debouncedSearchByName] = useDebounce(searchByName, 500);
    const loaderData = useLoaderData()
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!events && loaderData) {
            setEvents(loaderData);
        } else {
            getEventList();
        }
    }, [
        page,
        records,
        // sortOrder,
        // sortField,
        debouncedSearchByName
    ])

    const getEventList = async () => {
        // let sortQuery = ''
        let sortName = ''

        // if (sortField) {
        //     sortQuery += `&sortBy=${sortField}`
        //     if (sortOrder) {  // positive
        //         sortQuery += `&sortOrder=${sortOrder}`
        //     }
        // }

        if (debouncedSearchByName) {
            sortName += `&searchName=${debouncedSearchByName}`
        }

        dispatch(setLoading(true));
        const response = await eventListing(`&page=${page}&records=${records}${sortName}`);
        if (response?.data?.success) {
            setEvents(response?.data?.data);
        }
        dispatch(setLoading(false));
    }

    const deleteRecord = async (res: any) => {
        dispatch(setLoading(true));
        const response: any = await remove(`event/${res?._id}`);
        if (response?.data?.success) {
            await getEventList();
            toast.success(response?.data?.message);
        }
        dispatch(setLoading(false));
    }

    const eventColumnBody = (res: any) => {
        const startDate = moment(Date.parse(res?.start_date)).format('D MMM YYYY')
        const startDay = moment(Date.parse(res?.start_date)).format('D')
        const startMonth = moment(Date.parse(res?.start_date)).format('MMM')
        const endDate = moment(Date.parse(res?.end_date)).format('D MMM YYYY')
        return <div className="flex align-middle items-center">
            <div className='mr-[20px] text-center'>
                <div style={{
                    fontFamily: 'Nunito',
                    fontSize: '12px',
                    fontWeight: 700,
                    lineHeight: '18px',
                    color: '#D04EFF'
                }}>
                    {startMonth}
                </div>
                <div style={{
                    fontFamily: 'Nunito',
                    fontSize: '16px',
                    fontWeight: 700,
                    lineHeight: '24px',
                }}>
                    {startDay}
                </div>
            </div>
            <div className='mr-[8px]'>
                <img src={res?.cover_image_desktop || NoImage} alt="" style={{
                    objectFit: 'contain',
                    width: '60px',
                }}/>
            </div>
            <div>
                <div style={{
                    color: '#211F2E',
                    fontFamily: 'Nunito',
                    fontSize: '14px',
                    fontWeight: 700,
                    lineHeight: '21px'
                }}>
                    {res?.translations[0].title}
                </div>
                <div style={{
                    color: '#6650F2',
                    fontFamily: 'Nunito',
                    fontSize: '14px',
                    fontWeight: 400,
                    lineHeight: '21px',
                }}>
                    {res?.event_venue_id?.address ?? ''}
                </div>
                <div style={{
                    color: '#211F2E',
                    fontFamily: 'Nunito',
                    fontSize: '14px',
                    fontWeight: 400,
                    lineHeight: '21px',
                }}>
                    {startDate} - {endDate}
                </div>
            </div>
        </div>
    }

    const soldColumnBody = (res: any) => {
        return <div className="text-center">
            {res?.ticketsSold} / {res?.maxCapacity}
        </div>
    }

    const actionColumnBody = (res: any) => {
        return <div className="flex gap-2">
            <Button
                className="mr-2 !rounded-full w-8 h-[32px] p-0 flex items-center justify-center"
                size="small"
                onClick={() => {
                    dispatch(setSelectedTicketGroup({} as TicketGroup));
                    dispatch(setSelectedTicket({} as Ticket));
                    dispatch(setBasicInfo(res));
                    navigate(`/events/detail`);
                }}
            >
                <i className="pi pi-pencil" />
            </Button>
            {
                isSuperAdmin &&
                <Button
                    className="mr-2 !rounded-full w-8 h-[32px] p-0 flex items-center justify-center"
                    size="small"
                    onClick={() => {
                        navigate(`/events/${res?._id}/platform-fee`)
                    }}
                >
                    <i className="pi pi-dollar"/>
                </Button>
            }
            <Button
                className="mr-2 !rounded-full w-8 h-[32px] p-0 flex items-center justify-center"
                size="small"
                onClick={() => deleteConfirmation(async () => await deleteRecord(res))}
            >
                <i className="pi pi-trash" />
            </Button>
        </div>
    }

    const setPageLimit = (limit: number) => {
        setRecords(limit);
        setPage(1);
    }

    // const onSort = ({ sortField, sortOrder }: OnSortProps) => {
    //     setSortField(sortField);
    //     setSortOrder(sortOrder);
    //     setPage(1);
    // }

    const handleChange = (e: any) => {
        setPage(1);
        setSearchByName(e.target.value);
    }

    return (
        <>
            <h2 className="mb-[15px] h2 !pb-0 text-aria_dark">Events</h2>
            <div className="flex justify-between items-center">
                <Button
                    label="Create Event"
                    icon="pi pi-plus"
                    size="large"
                    className="!text-[14px] !px-12"
                    onClick={() => navigate('/events/create')}
                />
                <SearchBox
                    placeholder="Search Event Name"
                    onChange={handleChange}
                />
            </div>
            <div className="mt-4">
                {events &&
                    <TableWrapper
                        data={events}
                        loading={false}
                        records={records}
                        setRecords={setPageLimit}
                        scrollable
                        scrollHeight="70vh"
                        nextPage={() => {
                            if (!events.hasNextPage) return
                            setPage(current => (current + 1))
                        }}
                        prevPage={() => {
                            if (!events.hasPrevPage) return
                            setPage(current => (current - 1))
                        }}
                        // sortField={sortField}
                        // sortOrder={sortOrder}
                        // onSort={onSort}
                    >
                        <Column field="slug" header="Event" body={eventColumnBody} />
                        <Column field="ticket-sold" style={{width: '25%'}} header="Sold" body={soldColumnBody} />
                        <Column header="Actions" style={{width: '10%'}} body={actionColumnBody} />
                    </TableWrapper>
                }
            </div>
        </>
    )
}

export default Events
