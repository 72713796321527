import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import TableWrapper, { OnSortProps } from '../../../components/listing/TableWrapper';
import { useAppDispatch } from '../../../hooks/useStore';
import { setLoading } from '../../../redux/slices/commonSlice';
import { get } from '../../../utils/AxiosReqeust';
import SearchBox from "../../../components/SearchBox";
import CurrencyForm from "../components/CurrencyForm";
import {Dialog} from "primereact/dialog";

const Currency = () => {
    const [currencies, setCurrencies]: any = useState([]);
    const [page, setPage] = useState(1);
    const [records, setRecords] = useState(10);
    const [sortField, setSortField] = useState<string>('');
    const [sortOrder, setSortOrder] = useState<0 | 1 | -1 | null | undefined>(null);
    const [searchByCode, setSearchByCode] = useState('');
    const [debouncedSearchByCode] = useDebounce(searchByCode, 500);
    const dispatch = useAppDispatch();

    const [updateListing, setUpdateListing] = useState(0);
    const [visible, setVisible] = useState(false);
    const [currentData, setCurrentData] = useState<any>(null);
    const [formHeader, setFormHeader] = useState('Create Currency');

    useEffect(() => {
        getList();
    }, [page, records, sortOrder, sortField, updateListing, debouncedSearchByCode])

    const getList = async () => {
        let sortQuery = ''
        let sortName = ''

        if (sortField) {
            sortQuery += `&sortBy=${sortField}`
            if (sortOrder) {  // positive
                sortQuery += `&sortOrder=${sortOrder}`
            }
        }

        if (debouncedSearchByCode) {
            sortName += `&searchCode=${debouncedSearchByCode}`
        }

        dispatch(setLoading(true));
        const response = await get(`/settings/currency/listing?&page=${page}&records=${records}${sortQuery}${sortName}`);
        if (response?.data?.success) {
            setCurrencies(response?.data?.data);
        }
        dispatch(setLoading(false));
    }

    const setPageLimit = (limit: number) => {
        setRecords(limit);
        setPage(1);
    }

    const onSort = ({ sortField, sortOrder }: OnSortProps) => {
        setSortField(sortField);
        setSortOrder(sortOrder);
        setPage(1);
    }

    // const handleChange = (e: any) => {
    //     setPage(1);
    //     setSearchByName(e.target.value);
    // }

    const codeColumnBody = (res: any) => {
        return <div>{res?.currency_code}</div>
    }

    const symbolColumnBody = (res: any) => {
        return <div>{res?.currency_symbol}</div>
    }

    const actionColumnBody = (res: any) => {
        return <div className="flex gap-2">
            <Button
                className="mr-2 !rounded-full w-8 h-[32px] p-0 flex items-center justify-center"
                onClick={() => {
                    setCurrentData(res); setVisible(true); setFormHeader('Edit Currency') }}
                size="small"
            >
                <i className="pi pi-pencil" />
            </Button>
            {/*<Button onClick={() => {}} size="small" className="mr-2 !rounded-full w-8 h-[32px] p-0 flex items-center justify-center">*/}
            {/*    <i className="pi pi-trash" />*/}
            {/*</Button>*/}
        </div>
    }
    
    return (
        <div>
            <Dialog header={formHeader} visible={visible} onHide={() => setVisible(false)}
                    style={{ width: '50vw' }} breakpoints={{ '960px': '75vw', '641px': '100vw' }}
                    contentClassName="!pb-4"
            >
                <CurrencyForm setVisible={setVisible}
                                  setUpdateListing={setUpdateListing}
                                  currentData={currentData}
                />
            </Dialog>
            <h2 className="text-3xl font-semibold text-aria_dark mb-[15px]">Currency</h2>
            <div className="flex justify-between align-middle mb-5">
                <Button
                    label="Create Currency"
                    icon="pi pi-plus"
                    size="large"
                    className="!text-[14px] !px-12"
                    onClick={() => {
                        setCurrentData(null)
                        setFormHeader('Create Currency')
                        setVisible(true)
                    }}
                />
                <SearchBox
                    placeholder="Search By Currency Code"
                    onChange={(e) => {
                        setPage(1)
                        setSearchByCode(e.target.value)
                    }}
                />
            </div>
            <div className="mt-4">
                {currencies &&
                    <TableWrapper
                        data={currencies}
                        loading={false}
                        records={records}
                        setRecords={setPageLimit}
                        scrollable
                        scrollHeight="70vh"
                        nextPage={() => {
                            if (!currencies.hasNextPage) return
                            setPage(current => (current + 1))
                        }}
                        prevPage={() => {
                            if (!currencies.hasPrevPage) return
                            setPage(current => (current - 1))
                        }}
                        sortField={sortField}
                        sortOrder={sortOrder}
                        onSort={onSort}
                    >
                        <Column field="currency_code" header="Code" body={codeColumnBody} sortable />
                        <Column field="currency_symbol" header="Symbol" body={symbolColumnBody} sortable />
                         <Column header="Actions" style={{width: '10%'}} body={actionColumnBody} />
                    </TableWrapper>
                }
            </div>
        </div>
    )
}

export default Currency
