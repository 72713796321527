import React from "react";
import { Outlet } from "react-router-dom";
import Layout from "../components/basic/Layout";
import Artist from "../screens/Artist";
import Customer from "../screens/Customer";
import Home from "../screens/CMS/Home";
import Dashboard from "../screens/Dashboard";
import Events from "../screens/Events";
import CreateAddon from "../screens/Events/subPages/CreateAddon";
import CreateEventPage from "../screens/Events/subPages/CreateEventPage";
import CreatePromoCode from "../screens/Events/subPages/CreatePromoCode";
import CreateTicket from "../screens/Events/subPages/CreateTicket";
import CreateTicketGroup from "../screens/Events/subPages/CreateTicketGroup";
import EventDetailAddons from "../screens/Events/subPages/EventDetailAddons";
import EventDetailBasicInfo from "../screens/Events/subPages/EventDetailBasicInfo";
import EventDetailDashboard from "../screens/Events/subPages/EventDetailDashboard";
import EventDetailDetails from "../screens/Events/subPages/EventDetailDetails";
import EventDetailGallery from "../screens/Events/subPages/EventDetailGallery";
import EventDetailLayout from "../screens/Events/subPages/EventDetailLayout";
import EventDetailPromoCodes from "../screens/Events/subPages/EventDetailPromoCodes";
import EventDetailPublish from "../screens/Events/subPages/EventDetailPublish";
import EventDetailTickets from "../screens/Events/subPages/EventDetailTickets";
import EventCheckoutCustomContent from "../screens/Events/subPages/EventCheckoutCustomContent";
import NotFound from "../screens/NotFound";
import Organisation from "../screens/Organisation";
import OrganisationUsers from "../screens/OrganisationUsers";
import Profile from "../screens/Profile";
import Settings from "../screens/Settings";
import Currency from "../screens/Settings/subPages/Currency";
import PlatformFee from "../screens/Settings/subPages/PlatformFee";
import EventCategories from "../screens/Settings/subPages/EventCategories";
import Users from "../screens/Users";
import RoleAccessRoute from "./RoleAccessRoute";
import RequireAuth from "./features/RequireAuth";
import Finance from "../screens/Finance";
import Transactions from "../screens/Transactions";
import Boxoffice from "../screens/Boxoffice";
import PolicyCustomContent from "../screens/Events/subPages/PolicyCustomContent";

// eslint-disable-next-line import/no-anonymous-default-export
const ProtectedRoutes = [
    {
        element: <RequireAuth />,
        children: [
            {
                element: <Layout transparent />,
                children: [
                    {
                        name: "Dashboard",
                        index: true,
                        element: <Dashboard />,
                        handle: {
                            crumb: {
                                // icon: "pi pi-home",
                                label: "Dashboard",
                                url: "/",
                            },
                        },
                    },
                ]
            },
            {
                element: <Layout />,
                children: [
                    {
                        element: <RoleAccessRoute />,
                        children: [
                            {
                                name: "Artist",
                                path: "/artist",
                                element: <Artist />,
                                // loader: async function () {
                                //     const artists = await artistListing(
                                //         `records=10&sortBy=createdAt&sortOrder=desc`
                                //     );
                                //     return artists?.data?.data;
                                // },
                                errorElement: <Artist />,
                                handle: {
                                    crumb: {
                                        icon: "pi pi-home",
                                        label: "Artist",
                                        url: "/artist",
                                    },
                                },
                            },
                            {
                                name: "Customer",
                                path: "/customer",
                                element: <Customer />,
                                errorElement: <Customer />,
                                handle: {
                                    crumb: {
                                        icon: "pi pi-home",
                                        label: "Customer",
                                        url: "/customer",
                                    },
                                },
                            },
                            {
                                name: "Organisations",
                                path: "/organisations",
                                element: <Organisation />,
                                // loader: async function () {
                                //     const organisation =
                                //         await getOrganisationListing(
                                //             `records=10`
                                //         );
                                //     return organisation.data.data;
                                // },
                                errorElement: <Organisation />,
                                handle: {
                                    crumb: {
                                        icon: "pi pi-home",
                                        label: "Organisation",
                                        url: "/organisations",
                                    },
                                },
                            },
                            {
                                name: "Org Users",
                                path: "/organisations/users",
                                element: <OrganisationUsers />,
                                // loader: async function () {
                                //     const userList =
                                //         await getOrganisationUserListing(
                                //             `pageSize=10`
                                //         );
                                //     return userList?.data?.data;
                                // },
                                errorElement: <OrganisationUsers />,
                                handle: {
                                    crumb: {
                                        icon: "pi pi-home",
                                        label: "Org Users",
                                        url: "/organisations/users",
                                    },
                                },
                            },
                            {
                                name: "Users",
                                element: <Users />,
                                path: "/users",
                                // loader: async function () {
                                //     const userList = await userListing();
                                //     return userList?.data?.data;
                                // },
                                errorElement: <Users />,
                                handle: {
                                    crumb: {
                                        // icon: "pi pi-home",
                                        label: "Users",
                                        url: "/users",
                                    },
                                },
                            },
                            {
                                name: "Events",
                                path: "/events",
                                element: (<Outlet />),
                                handle: {
                                    crumb: {
                                        icon: "pi pi-calendar",
                                        label: "Events",
                                        url: "/events",
                                    },
                                },
                                children: [
                                    {
                                        index: true,
                                        element: <Events />,
                                        // errorElement: <Events />,
                                        // loader: async function () {
                                        //     const eventList = await eventListing('records=10');
                                        //     return eventList?.data?.data;
                                        // },
                                    },
                                    {
                                        name: 'Create Event',
                                        path: 'create',
                                        element: <CreateEventPage />,
                                    },
                                    {
                                        name: 'Edit Event',
                                        path: 'detail',
                                        element: <EventDetailLayout />,
                                        handle: {
                                            ignorePadding: true,
                                        },
                                        children: [
                                            {
                                                index: true,
                                                element: <EventDetailBasicInfo />,
                                            },
                                            {
                                                name: 'Event Detail Dashboard',
                                                path: 'dashboard',
                                                element: <EventDetailDashboard />,
                                            },
                                            {
                                                name: 'Event Detail Details',
                                                path: 'details',
                                                element: <EventDetailDetails />,
                                            },
                                            {
                                                name: 'Event Detail Gallery',
                                                path: 'gallery',
                                                element: <EventDetailGallery />,
                                            },
                                            {
                                                name: 'Event Detail Tickets',
                                                path: 'tickets',
                                                element: (<Outlet />),
                                                children: [
                                                    {
                                                        index: true,
                                                        element: <EventDetailTickets />,
                                                    },
                                                    {
                                                        name: 'Event - Create Ticket Group',
                                                        path: 'create-group',
                                                        element: <CreateTicketGroup />,
                                                    },
                                                    {
                                                        name: 'Event - Create Ticket',
                                                        path: 'create',
                                                        element: <CreateTicket />,
                                                    },
                                                ]
                                            },
                                            {
                                                name: 'Event Detail Addons',
                                                path: 'addons',
                                                element: (<Outlet />),
                                                children: [
                                                    {
                                                        index: true,
                                                        element: <EventDetailAddons />,
                                                    },
                                                    {
                                                        name: 'Event - Create Addon',
                                                        path: 'create',
                                                        element: <CreateAddon />,
                                                    },
                                                ]
                                            },
                                            {
                                                name: 'Event Detail Promo codes',
                                                path: 'promocodes',
                                                element: (<Outlet />),
                                                children: [
                                                    {
                                                        index: true,
                                                        element: <EventDetailPromoCodes />,
                                                    },
                                                    {
                                                        name: 'Event - Create Promo code',
                                                        path: 'create',
                                                        element: <CreatePromoCode />,
                                                    },
                                                ]
                                            },
                                            {
                                                name: 'Event Checkout Custom Content',
                                                path: 'checkout-custom-content',
                                                element: (<Outlet />),
                                                children: [
                                                    {
                                                        index: true,
                                                        element: <EventCheckoutCustomContent />,
                                                    },
                                                ]
                                            },
                                            {
                                                name: 'Policy Custom Content',
                                                path: 'policy-custom-content',
                                                element: (<Outlet />),
                                                children: [
                                                    {
                                                        index: true,
                                                        element: <PolicyCustomContent />,
                                                    },
                                                ]
                                            },
                                            {
                                                name: 'Event Detail Publish',
                                                path: 'publish',
                                                element: (<Outlet />),
                                                children: [
                                                    {
                                                        index: true,
                                                        element: <EventDetailPublish />,
                                                    },
                                                ]
                                            }
                                        ]
                                    },
                                    {
                                        name: 'Platform Fee',
                                        path: ':id/platform-fee',
                                        element: <PlatformFee />,
                                        handle: {
                                            crumb: {
                                                label: "Platform Fee",
                                                // url: "/settings/platform-fee",
                                            },
                                        },
                                    },
                                ]
                            },
                            {
                                name: "CMS - Home",
                                element: <Home />,
                                path: "/cms/home",
                                // loader: async function () {
                                //     const homeContent = await getHomeContent();

                                //     if (Array.isArray(homeContent?.data?.data?.info_carousel))
                                //         homeContent?.data?.data?.info_carousel.sort(function (a: any, b: any) { return a.order - b.order })

                                //     if (Array.isArray(homeContent?.data?.data?.featured_artist))
                                //         homeContent?.data?.data?.featured_artist.sort(function (a: any, b: any) { return a.order - b.order })

                                //     return homeContent?.data?.data;
                                // },
                                errorElement: <Home />,
                                handle: {
                                    crumb: {
                                        // icon: "pi pi-home",
                                        label: "CMS - Home",
                                        url: "/cms/home",
                                    },
                                },
                            },
                            {
                                name: "Transactions",
                                element: <Transactions />,
                                path: "/transactions",
                                handle: {
                                    crumb: {
                                        // icon: "pi pi-home",
                                        label: "Transactions",
                                        url: "/transactions",
                                    },
                                },
                            },
                            {
                                name: "Boxoffice",
                                element: <Boxoffice />,
                                path: "/boxoffice",
                                handle: {
                                    crumb: {
                                        // icon: "pi pi-home",
                                        label: "Boxoffice",
                                        url: "/boxoffice",
                                    },
                                },
                            }
                        ],
                    },
                    {
                        name: "Profile",
                        path: "/profile",
                        element: <Profile />,
                        handle: {
                            crumb: {
                                icon: "pi pi-home",
                                label: "Profile",
                                url: "/profile",
                            },
                        },
                    },
                    {
                        name: "Finance",
                        path: "/finance",
                        element: <Finance />,
                        handle: {
                            crumb: {
                                icon: "pi pi-money-bill",
                                label: "Finance",
                                url: "/finance",
                            },
                        },
                    },
                    {
                        name: "Settings",
                        path: "/settings",
                        element: (<Outlet />),
                        handle: {
                            crumb: {
                                label: "Settings",
                                url: "/settings",
                            },
                        },
                        children: [
                            {
                                index: true,
                                element: <Settings />,
                            },
                            {
                                name: 'Currency',
                                path: 'currency',
                                element: <Currency />,
                                handle: {
                                    crumb: {
                                        label: "Currency",
                                        url: "/settings/currency",
                                    },
                                },
                            },
                            {
                                name: 'Event Categories',
                                path: 'event-categories',
                                element: <EventCategories />,
                                handle: {
                                    crumb: {
                                        label: "Event categories",
                                        url: "/settings/event-categories",
                                    },
                                },
                            },
                        ]
                    },
                    {
                        name: "NotFound",
                        path: "/*",
                        element: <NotFound />,
                    },
                ],
            },
        ],
    },
];

export default ProtectedRoutes;