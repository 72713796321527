import { nanoid } from "@reduxjs/toolkit";

export const DEFAULT_CAROUSEL = {
  _id: nanoid(),
  cText: "",
  coverImageL: "",
  coverImageP: "",
  order: 1,
  redirectUrl: "",
  translations: [
    {
      tyText: "",
      titText: "",
      titImage: "",
      subText: "",
      textCTA: "",
      language: "en",
    },
    {
      tyText: "",
      titText: "",
      titImage: "",
      subText: "",
      textCTA: "",
      language: "zh",
    },
  ],
};

export const DEFAULT_COLOR = [
  { label: "Black", code: "#000000" },
  { label: "White", code: "#ffffff" },
];
