export default function PinIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="12"
      viewBox="0 0 10 12"
      fill="none"
    >
      <path
        d="M8.53538 1.49987C6.58288 -0.499957 3.41688 -0.499957 1.46438 1.49987C-0.488125 3.4997 -0.488125 6.74245 1.46438 8.74228L4.39388 11.7428C4.72888 12.0859 5.27138 12.0859 5.60638 11.7428L8.53588 8.74228C10.4884 6.74245 10.4884 3.4997 8.53588 1.49987H8.53538ZM3.35238 6.80851C2.44238 5.87645 2.44238 4.3657 3.35238 3.43415C4.26238 2.50209 5.73738 2.50209 6.64688 3.43415C7.55638 4.36621 7.55688 5.87696 6.64688 6.80851C5.73688 7.74057 4.26188 7.74057 3.35238 6.80851Z"
        fill="#000"
      />
    </svg>
  );
}
