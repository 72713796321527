import { useState, useEffect } from "react";
import { getPurchaseCustomContentData } from "../../../utils/apiRequestList/eventAPI";
import { useAppSelector } from "../../../hooks/useStore";
import CheckoutCustomContentForm from "../components/CheckoutCustomContentForm";

const EventCheckoutCustomContent = () => {
    const { basicInfo } = useAppSelector((state) => state.event);
    const [currentData, setCurrentData] = useState<any>(null);

    const fetchData = async () => {
        const response = await getPurchaseCustomContentData(basicInfo._id)
        setCurrentData(response?.data?.data)
    }
    useEffect(() => {
        fetchData()
    }, [])

    return <>
        <CheckoutCustomContentForm basicInfo={basicInfo} currentData={currentData} fetchData={fetchData} />
    </>
}
export default EventCheckoutCustomContent;